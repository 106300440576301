import * as React from 'react';
import { CustomShowButton } from '../../custom/CustomButton';

const TransactionShowButton = props => {
    if (!props.record || !props.record.id) {
        return null;
    }

    return (
        <CustomShowButton {...props} to={`/transactions/${props.record.id}/show/summary`} />
    );
};

export default TransactionShowButton;
