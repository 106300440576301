import React from 'react';
import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Card, Grid, IconButton, MobileStepper, Paper, Typography, useMediaQuery } from '@material-ui/core';
import {
    Pause as PauseIcon,
    PlayArrow as PlayArrowIcon,
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon
} from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none',
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(2),
            height: '85vh',
            width: '90vw',
        },
        [theme.breakpoints.up('md')]: {
            height: '75vh',
            width: '80vw',
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '50px',
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },
    body: {
        display: 'flex',
        height: 'calc(75vh - 100px)'
    },
    details: {
        width: '100%',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        margin: 'auto',
        padding: theme.spacing(2)
    },
    imgRoot: {
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        margin: 'auto'
    },
    img: {
        '-webkit-user-drag': 'none',
        'user-drag': 'none',
        // 'pointer-events': 'none',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '500px'
        }
    },
}));

const featureSteps = [
    {
        title: '¿Qué es TantAI?',
        body: [
            {
                type: 'UNORDERED-LIST',
                content: [
                    'TantAI es un sistema de software que concentra transacciones comerciales de distintas marcas ' +
                    '(cadenas) y sus respectivas sucursales para empresas del rubro de ventas minoristas.',
                    'TantAI contempla el envío, traducción, transformación, validación y almacenamiento de estas ' +
                    'transacciones. Además, permite la visualización en detalle de cada transacción. Asimismo, ' +
                    'facilita el análisis de datos de las mismas mediante reportes estadísticos a los cuales el ' +
                    'usuario tiene acceso desde una interfaz web.',
                    'Al ser un software modular y escalable permite integrar distintos traductores de transacciones, ' +
                    'por lo que no está limitado a un solo tipo de software de punto de venta (POS).',
                    'El almacenamiento de los datos se hace a través de una base de datos relacional SQL, lo que ' +
                    'facilita la gestión y obtención de datos para la realización de análisis personalizados según ' +
                    'las necesidades del cliente.'
                ]
            }
        ],
        imgPath: require('../../resources/landing/1.png').default
    },
    {
        title: 'Principales Funcionalidades',
        body: [
            {
                type: 'TEXT',
                content: 'Dentro de las principales funcionalidades que ofrece TantAI, se encuentran:'
            },
            {
                type: 'UNORDERED-LIST',
                content: [
                    'Estado de Salud del Sistema',
                    'Indicadores Clave de Rendimiento (KPI\'s) y Rankings',
                    'Ver Detalle y Listar Transacciones de POS',
                    'Ver Estadísticas Acerca de las Transacciones'
                ]
            }
        ],
        imgPath: require('../../resources/landing/2.png').default
    },
    {
        title: 'Estado de Salud del Sistema',
        body: [
            {
                type: 'TEXT',
                content: 'Permite visualizar de forma simple y abreviada de cuándo son las últimas transacciones que ' +
                    'se subieron a la plataforma de TantAI. En caso de que las últimas transacciones de todas las ' +
                    'tiendas hayan sido de hace un día o menos, el funcionamiento indicado será correcto. ' +
                    'De lo contrario se advertirá al usuario que los datos están actualizados, por lo que hay que ' +
                    'revisar las causas del problema.'
            }
        ],
        imgPath: require('../../resources/landing/3.png').default
    },
    {
        title: 'Indicadores Clave de Rendimiento (KPI\'s) y Rankings',
        body: [
            {
                type: 'TEXT',
                content: 'Los indicadores KPI y rankings tienen por objetivo resumir y entregar información ' +
                    'estadística relevante respecto de las ventas y artículos vendidos y anulados por las distintas ' +
                    'tiendas.'
            },
            {
                type: 'TEXT',
                content: 'Tanto los KPI\'s como los rankings permiten filtrar los resultados por un determinado ' +
                    'periodo de tiempo. Además, las tablas y valores mostrados pueden ser exportados a un archivo de ' +
                    'imagen PNG.'
            },
            {
                type: 'TEXT',
                content: 'Los distintos indicadores KPI y rankings son los siguientes:'
            },
            {
                type: 'UNORDERED-LIST',
                content: [
                    'Ticket promedio',
                    'Promedio de artículos por ticket',
                    'Monto total de ventas',
                    'Cantidad total de clientes',
                    'Cantidad de artículos vendidos',
                    'Artículos digitados versus escaneados',
                    'Horas ahorradas',
                    'Ranking de tiendas por clientes',
                    'Ranking de tiendas por ventas',
                    'Ranking de artículos anulados',
                    'Ranking de operadores POS que más artículos anulan',
                    'Ranking de supervisores que más artículos anulados aprueban',
                    'Ranking de tiendas más productivas',
                    'Ranking de operadores POS más productivos'
                ]
            }
        ],
        imgPath: require('../../resources/landing/4.png').default
    },
    {
        title: 'Ver Detalle y Listar Transacciones de POS',
        body: [
            {
                type: 'TEXT',
                content: 'Puede listar, buscar mediante el uso de filtros, exportar y ver el detalle de cada una de ' +
                    'las transacciones que realizan los puntos de venta.'
            },
            {
                type: 'TEXT',
                content: 'En el detalle de cada transacción puede ver tanto su información principal como los siguientes registros asociados la misma:'
            },
            {
                type: 'UNORDERED-LIST',
                content: [
                    'Artículos',
                    'Formas de pago',
                    'Impuestos',
                    'Pagos electrónicos (EPS)',
                    'Vueltos',
                    'Anulación Admin. / Operador',
                    'Cambios de gaveta',
                    'Entradas de datos',
                    'Cadenas de usuario'
                ]
            }
        ],
        imgPath: require('../../resources/landing/5.png').default
    },
    {
        title: 'Ver Estadísticas Acerca de las Transacciones',
        body: [
            {
                type: 'TEXT',
                content: 'Puede ver y exportar distintos gráficos y tablas que entregan información estadística ' +
                    'acerca de las transacciones. Además puede aplicar diversos filtros y comparar de manera simple ' +
                    'sus resultados.'
            },
            {
                type: 'TEXT',
                content: 'Las estadísticas disponibles se clasifican según los siguientes criterios:'
            },
            {
                type: 'UNORDERED-LIST',
                content: [
                    'Ventas generales',
                    'Venta de artículos',
                    'Formas de pago',
                    'Pagos',
                    'Vueltos',
                    'Productividad del operador',
                    'Anulaciones'
                ]
            }
        ],
        imgPath: require('../../resources/landing/6.png').default
    },
];

const Body = ({ data }) => {
    return (
        <React.Fragment>
            {data && data.map(body => {
                if (body.type === 'TEXT') {
                    return (
                        <Typography variant="body1">
                            {body.content}
                        </Typography>
                    )
                }

                if (body.type === 'UNORDERED-LIST') {
                    return (
                        <ul>
                            {body.content.map(content => (
                                <li>
                                    <Typography variant="body1">
                                        {content}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    );
                }

                if (body.type === 'ORDERED-LIST') {
                    return (
                        <ol>
                            {body.content.map(content => (
                                <li>
                                    <Typography variant="body1">
                                        {content}
                                    </Typography>
                                </li>
                            ))}
                        </ol>
                    );
                }

                return null;
            })}
        </React.Fragment>
    );
};

const Landing = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [activeStep, setActiveStep] = useState(0);
    const [pause, setPause] = useState(false);
    const maxSteps = featureSteps.length;

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleStepChange = step => {
        setActiveStep(step);
    };

    const fragment = (
        <React.Fragment>
            <Paper square elevation={0} className={classes.header}>
                <Grid container alignItems="center" alignContent="center">
                    <Grid item xs={10}>
                        <Typography variant="h5">
                            {featureSteps[activeStep].title}
                        </Typography>
                    </Grid>
                    <Grid item container spacing={1} xs justifyContent="flex-end">
                        <Grid item>
                            <IconButton
                                onClick={() => setPause(!pause)}
                                color="primary"
                            >
                                {pause ? <PlayArrowIcon /> : <PauseIcon />}
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                autoplay={!pause}
                interval={5000}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {featureSteps.map((step, index) => (
                    <div key={step.title}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            isSmall ? (
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} className={classes.details}>
                                        <Body data={step.body} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img
                                            src={step.imgPath}
                                            title={step.title}
                                            alt={step.title}
                                            className={classes.img}
                                            draggable={false}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <div className={classes.body}>
                                    <div className={classes.details}>
                                        <Body data={step.body} />
                                    </div>
                                    <div className={classes.imgRoot}>
                                        <img
                                            src={step.imgPath}
                                            title={step.title}
                                            alt={step.title}
                                            className={classes.img}
                                            draggable={false}
                                        />
                                    </div>
                                </div>
                            )
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position={isSmall ? 'bottom' : 'static'}
                variant="dots"
                activeStep={activeStep}
                nextButton={
                    <Button
                        color="primary"
                        endIcon={theme.direction === 'rtl' ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        {translate('ra.navigation.next')}
                    </Button>
                }
                backButton={
                    <Button
                        color="primary"
                        startIcon={theme.direction === 'rtl' ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                        onClick={handleBack}
                        disabled={activeStep === 0}
                    >
                        {translate('ra.navigation.prev')}
                    </Button>
                }
            />
        </React.Fragment>
    );

    if (isSmall) {
        return (
            <div className={classes.root}>
                {fragment}
            </div>
        );
    }

    return (
        <Card className={classes.root}>
            {fragment}
        </Card>
    );
};

export default Landing;