import * as React from 'react';
import { Children, createElement, cloneElement, isValidElement } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { escapePath } from 'ra-core';
import { TabbedShowLayoutTabs } from 'ra-ui-materialui';

const getTabFullPath = function (tab, index, baseUrl) {
    return "" + baseUrl + (tab.props.path ? "/" + tab.props.path : index > 0 ? "/" + index : '');
};
let __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        let s, i = 1, n = arguments.length;
        for (; i < n; i++) {
            s = arguments[i];
            for (const p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
const __rest = (this && this.__rest) || function (s, e) {
    let i;
    let p;
    const t = {};
    for (p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        {
            i = 0;
        }

        p = Object.getOwnPropertySymbols(s);
        for (; i < p.length; i++) {
                    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                        t[p[i]] = s[p[i]];
                }

    return t;
};

const sanitizeRestProps = function (_a) {
    return __rest(_a, ["children", "className", "record", "resource", "basePath", "version", "initialValues", "staticContext", "translate", "tabs"]);
};
const useStyles = makeStyles(function (theme) {
    return ({
        content: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    });
}, { name: 'RaTabbedShowLayout' });

const TabbedShowLayout = function (props) {
    const record = props.record;
    const children = props.children.filter((item) => {
        if(!item.props.hidden ||
            (item.props.hidden && Array.isArray(record[item.props.hidden]) && record[item.props.hidden].length > 0) ||
            (item.props.hidden && !Array.isArray(record[item.props.hidden]) && record[item.props.hidden])
        ) return item;
        return null;
    });
    const basePath = props.basePath,
        className = props.className, resource = props.resource, version = props.version,
        tabs = props.tabs,
        rest = __rest(props, ["basePath", "children", "classes", "className", "record", "resource", "version", "value", "tabs"]);
    const match = useRouteMatch();
    const classes = useStyles(props);

    return (React.createElement("div", __assign({className: className, key: version}, sanitizeRestProps(rest)),
        cloneElement(tabs, {}, children),
        React.createElement(Divider, null),
        React.createElement("div", {className: classes.content}, Children.map(children, function (tab, index) {
            return tab && isValidElement(tab) ? (createElement(Route, {
                exact: true, path: escapePath(getTabFullPath(tab, index, match.url)), render: function () {
                    return cloneElement(tab, {
                        context: 'content',
                        resource: resource,
                        record: record,
                        basePath: basePath,
                    });
                }
            })) : null;
        }))));
};
TabbedShowLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    location: PropTypes.object,
    match: PropTypes.object,
    record: PropTypes.object,
    resource: PropTypes.string,
    basePath: PropTypes.string,
    value: PropTypes.number,
    version: PropTypes.number,
    tabs: PropTypes.element,
};
TabbedShowLayout.defaultProps = {
    tabs: createElement(TabbedShowLayoutTabs, null),
};
export default TabbedShowLayout;
