import * as React from 'react';
import { TextInput, useListContext, useTranslate } from 'react-admin';
import {
    FilterList as FilterListIcon,
    Search as SearchIcon
} from '@material-ui/icons';
import { Form } from 'react-final-form';
import { Box, Button, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    input: { minWidth: '190px' },
});

const DiscountFilter = props => {
    return props.context === 'button' ? (
        <DiscountFilterButton {...props} />
    ) : (
        <DiscountFilterForm {...props} />
    );
};

const DiscountFilterButton = () => {
    const translate = useTranslate();
    const { showFilter } = useListContext();

    return (
        <Button
            size="small"
            color="primary"
            onClick={() => showFilter('main')}
            startIcon={<FilterListIcon />}
        >
            {translate('pos.generic.filter')}
        </Button>
    );
};

const DiscountFilterForm = ({ open, filter }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const {
        displayedFilters,
        filterValues,
        setFilters,
    } = useListContext();

    if (!displayedFilters.main) return null;

    const onSubmit = values => {
        setFilters(values);
    };

    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box mt={8} />
                        <Box display="flex" alignItems="flex-end" mb={1}>
                            <Box component="span" mr={2}>
                                <TextInput
                                    resettable
                                    helperText={false}
                                    className={classes.input}
                                    source="q"
                                    label={translate('pos.generic.generalSearch')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon color="disabled" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                            <Box component="span" mr={2} mb={1.5}>
                                <Button variant="outlined" color="primary" type="submit">
                                    {translate('pos.generic.filter')}
                                </Button>
                            </Box>
                            <Box component="span" mb={1.5}>
                                <Button variant="outlined" onClick={resetFilter}>
                                    {translate('ra.action.close')}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Form>
        </div>
    );
};

export default DiscountFilter;
