import * as React from 'react';
import { useState, useMemo, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
        minWidth: '40px'
    }
});

const CustomMenuItemLink = ({ icon, primary, to, classes, onClick }) => {
    const location = useLocation();
    const defaultClasses = useStyles();
    const sidebarOpen = useSelector(state => state.admin.ui.sidebarOpen);

    const [openTooltip, setOpenTooltip] = useState(false);
    const handleCloseTooltip = () => {
        setOpenTooltip(false);
    };
    const handleOpenTooltip = () => {
        if (!sidebarOpen) {
            setOpenTooltip(true);
        }
    };

    const renderLink = useMemo(() => {
        return forwardRef((itemProps, ref) => {
            return <RouterLink to={to} ref={ref} {...itemProps} />;
        });
    }, [to]);

    return (
        <li>
            <Tooltip
                placement="right"
                title={primary}
                open={openTooltip}
                onClose={handleCloseTooltip}
                onOpen={handleOpenTooltip}
            >
                <MenuItem
                    button
                    component={renderLink}
                    classes={classes}
                    selected={location.pathname === to}
                    onClick={onClick}
                >
                    {icon ?
                        <ListItemIcon classes={{ root: defaultClasses.root }}>{icon}</ListItemIcon> : null
                    }
                    <ListItemText primary={primary} />
                </MenuItem>
            </Tooltip>
        </li>
    );
};

CustomMenuItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default CustomMenuItemLink;
