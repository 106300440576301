import * as React from 'react';
import { Labeled, BooleanField, ReferenceField, TextField, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';
import PermissionsField from "./PermissionsField";

const DefaultRoleReferenceField = props => {
    return (
        <ReferenceField source="defaultRoleId" reference="defaultRoles" link={false} {...props}>
            <DefaultRoleField userRole={props.record} />
        </ReferenceField>
    );
};

const DefaultRoleField = ({ userRole, ...props }) => {
    const translate = useTranslate();

    const record = {
        defaultRole: translate(`resources.users.fields.defaultRole.options.${props.record.name}`)
            + ` (${props.record.name})`
    };
    if (userRole && userRole.permissions.length !== props.record.permissions.length) {
        record.defaultRole += ' *';
    }

    return (
        <TextField record={record} source="defaultRole" />
    );
};

const UserGeneral = props => {
    const translate = useTranslate();
    const emptyText = translate('pos.generic.unknown');

    return (
        <span>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.users.fields.email">
                        <TextField record={props.record} source="email" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.users.fields.operatorCode">
                        <TextField record={props.record} source="operatorCode" emptyText={emptyText} />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.users.fields.name">
                        <TextField record={props.record} source="name" emptyText={emptyText} />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.users.fields.lastname">
                        <TextField record={props.record} source="lastname" emptyText={emptyText} />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.users.fields.status">
                        <TextField record={props.record} source="status" emptyText={emptyText} />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.users.fields.twoFaIsActive">
                        <BooleanField record={props.record} source="twoFaIsActive" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.users.fields.defaultRole.name">
                        <ReferenceField
                            label="resources.users.fields.defaultRole.name"
                            source="userRoleId"
                            reference="userRoles"
                            link={false}
                        >
                            <DefaultRoleReferenceField/>
                        </ReferenceField>
                    </Labeled>
                </Grid>
                {(props.record && props.record.userRole) &&
                    <Grid item xs={12}>
                        <PermissionsField source="userRole.permissions" userRole={props.record.userRole}/>
                    </Grid>
                }
            </Grid>
        </span>
    );
};

export default UserGeneral;
