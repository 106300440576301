import { TranslationMessages } from 'react-admin';
import spanishMessages from '@blackbox-vision/ra-language-spanish';

const customSpanishMessages: TranslationMessages = {
    ...spanishMessages,
    pos: {
        auth: {
            twoFaToken: 'Token de autenticación',
            notification: {
                failed: {
                    generic: 'No se ha podido autenticar',
                    emailOrPasswordIsInvalid: 'Correo electrónico o contraseña inválidos',
                    twoFaTokenHasExpired: 'El token de autenticación ha expirado',
                    twoFaTokenIsInvalid: 'El token de autenticación es inválido'
                },
                info: {
                    requireTwoFaToken: 'Se ha enviado un token de autenticación a su correo electrónico'
                }
            }
        },
        dashboard: {
            name: 'Inicio',
            userManual: 'Manual de Usuario',
            kpi: {
                avgTransactionsAmount: {
                    name: 'Ticket Promedio',
                },
                avgItemsPerTransaction: {
                    name: 'Promedio Artículos por Ticket',
                },
                totalTransactionsAmount: {
                    name: 'Monto Total de Ventas',
                },
                totalTransactionsQuantity: {
                    name: 'Cantidad Total de Clientes',
                },
                totalItemsQuantity: {
                    name: 'Cantidad de Artículos Vendidos',
                },
                storeTransactionsQuantityRanking: {
                    name: 'Ranking de Tiendas por Clientes'
                },
                storeTransactionsAmountRanking: {
                    name: 'Ranking de Tiendas por Ventas'
                },
                voidedItemsAmountRanking: {
                    name: 'Ranking de Artículos Anulados'
                },
                voidedItemsTransactionOperatorsAmountRanking: {
                    name: 'Ranking de Operadores POS que más Artículos Anulan',
                    posOperator: 'Operador POS',
                },
                voidedItemsOverrideNumbersAmountRanking: {
                    name: 'Ranking de Supervisores que más Artículos Anulados Aprueban',
                    supervisor: 'Supervisor',
                },
                itemsEntryMethod: {
                    name: 'Artículos (Líneas) Digitados vs. Escaneados',
                    itemLineScannedQuantity: 'Escaneados',
                    itemLineKeyedQuantity: 'Digitados',
                    proportion: 'Proporción'
                },
                storeOperatorsProductivity: {
                    name: 'Ranking de Tiendas más Productivas',
                    secondsPerItemLine: 'Segs./línea'
                },
                operatorsProductivity: {
                    name: 'Ranking de Operadores POS más Productivos',
                    posOperator: 'Operador POS',
                    secondsPerItemLine: 'Segs./línea'
                },
                hoursSaved: {
                    name: 'Horas Ahorradas',
                },
                periods: {
                    yesterday: 'Ayer',
                    thisMonth: 'Este mes',
                    lastMonth: 'Mes pasado',
                    thisYear: 'Este año',
                    lastYear: 'Año pasado'
                },
                errors: {
                    noKpi: 'No se ha podido obtener el KPI %{kpi_name}.',
                    noData: 'No hay datos para mostrar.',
                }
            }
        },
        configuration: {
            name: 'Configuración',
            language: {
                name: 'Idioma',
                spanish: 'Español',
                english: 'Inglés'
            }
        },
        help: {
            name: 'Ayuda',
            about: {
                title: 'Acerca de',
                text: 'TantAI es una plataforma de inteligencia de negocios desarrollada por 33 Grados Sur.',
                version: 'Versión: %{version}',
                versionBuild: 'Compilación: %{version_build}',
                versionDate: 'Fecha de compilación: %{version_date}',
                homepage: 'Más información en el sitio web: ',
                email: 'Para consultas comuníquese al correo electrónico: ',
            },
            userManual: 'Manual de Usuario'
        },
        health: {
            name: {
                default: 'Estado de Salud',
                base: 'Estado de Salud %{component}'
            },
            components: {
                system: 'del Sistema',
                chain: 'de la Cadena',
                store: 'de la Tienda'
            },
            showDetails: 'Ver detalles',
            lastTransaction: 'Última transacción',
            startLastBusinessDayDate: 'Fecha y hora (primera transacción)',
            lastBusinessDayDate: 'Fecha y hora (última transacción)',
            status: {
                name: 'Estado',
                success: {
                    title: 'Funcionamiento correcto',
                    message: 'Hace %{duration} (o menos) que se recibió la última transacción en %{component}.',
                    components: {
                        system: 'todas las tiendas del sistema', // cadenas
                        chain: 'todas las tiendas de la cadena',
                        store: 'al menos algunas de las cajas de la tienda'
                    }
                },
                error: {
                    title: 'Los datos no están actualizados',
                    message: 'Hay %{component} en donde hace más de %{duration} no se recibe una nueva transacción.',
                    components: {
                        system: 'algunas tiendas de cadenas',
                        chain: 'tiendas',
                        store: 'cajas'
                    }
                }
            },
            errors: {
                noHealth: 'No se ha podido obtener el estado de salud %{component}.',
                noLastRegister: 'No existe un último registro para mostrar.',
                noData: 'No hay datos para mostrar.',
            }
        },
        generic: {
            login: 'Iniciar sesión',
            approximatelyAbbr: 'Aprox.',
            filter: 'Filtrar',
            ok: 'Listo',
            cancelChanges: 'Anular cambios',
            pressEnter: 'Presione Enter',
            clear: 'Limpiar valor |||| Limpiar valores',
            from: 'Desde',
            to: 'Hasta',
            groupBy: 'Agrupar por',
            quantifier: 'Cuantificador',
            precision: 'Precisión',
            quantity: 'Cantidad',
            quantityOf: 'Cantidad de %{of}',
            amount: 'Monto',
            amountOf: 'Monto de %{of}',
            percentage: 'Porcentaje',
            percentageOf: 'Porcentaje de %{of}',
            totalPercentage: 'Porcentaje total',
            accumulated: 'Acumulado',
            total: 'Total |||| Totales',
            average: 'Promedio |||| Promedios',
            customers: 'Clientes',
            error: 'Error',
            warning: 'Advertencia',
            compare: 'Comparar',
            show: 'Ver',
            showAll: 'Ver todos',
            detail: 'Detalle',
            chart: 'Gráfico',
            download: 'Descargar',
            exportTable: 'Exportar tabla',
            exportChart: 'Exportar gráfico',
            exportAll: 'Exportar todos',
            exportSelected: 'Exportar seleccionados',
            exportDetailAll: 'Exportar detalle todos',
            exportDetailSelected: 'Exportar detalle seleccionados',
            exportAllAs: 'Exportar todos (%{as})',
            exportSelectedAs: 'Exportar seleccionados (%{as})',
            exportDetailAllAs: 'Exportar detalle todos (%{as})',
            exportDetailSelectedAs: 'Exportar detalle seleccionados (%{as})',
            noOptions: 'Sin opciones',
            limit: 'Límite',
            timeLimit: 'Tiempo límite',
            order: {
                orderBy: 'Ordenar por',
                orderType: {
                    name: 'Tipo de orden',
                    asc: 'Ascendente',
                    desc: 'Descendente'
                }
            },
            generalSearch: 'Búsqueda general',
            validation: {
                indicateAValid: 'Indique un %{field} válido'
            },
            unknown: 'Desconocido',
            reset: 'Reiniciar'
        },
        titles: {
            edit: 'Editar %{resource} ID: %{id}',
            show: 'Ver %{resource} ID: %{id}',
        },
        datetime: {
            dateName: 'Fecha',
            datetime: 'DD-MM-YYYY HH:mm:ss',
            date: 'DD-MM-YYYY',
            time: 'HH:mm:ss',
            timeName: 'Tiempo',
            totalTime: 'Tiempo total',
            second: 'Segundo |||| Segundos',
            minute: 'Minuto |||| Minutos',
            hour: 'Hora |||| Horas',
            day: 'Día |||| Días',
            week: 'Semana |||| Semanas',
            month: 'Mes |||| Meses',
            year: 'Año |||| Años',
            weekday: 'Día de la semana',
            period: 'Período'
        },
    },
    resources: {
        transactions: {
            name: 'Transacción |||| Transacciones',
            fields: {
                terminalNumber: 'Número de terminal',
                terminalType: 'Tipo de terminal',
                withoutTerminalType: 'Sin tipo',
                storeCode: 'Código de tienda',
                storeNumber: 'Número de tienda',
                sequenceNumber: 'Número de secuencia',
                businessDayDate: 'Día de negocio',
                beginDateTime: 'Comienza el',
                endDateTime: 'Finaliza el',
                invoiceNumber: 'Número de factura',
                type: {
                    name: 'Tipo de transacción',
                    options: {
                        '00': 'Transacción de venta',
                        '01': 'Cobro de forma de pago',
                        '02': 'Cambio de forma de pago',
                        '03': 'Dotación de dinero en caja',
                        '04': 'Retiro de dinero en caja',
                        '05': 'Listado de forma de pago',
                        '06': 'Verificación / cambio de precio',
                        '07': 'Sesión de entrenamiento',
                        '08': 'Transferencia de terminal',
                        '09': 'Monitor de terminal',
                        '10': 'Recuento de forma de pago',
                        '11': 'Reservado',
                        '12': 'Transacción de devolución de artículo',
                        '13': 'Transacción WIC',
                        '14': 'Reservado',
                        '15': 'Reimprimir recibo de pago',
                        '16': 'Transacción de venta anulada',
                        '17': 'Desconexión del operador',
                        '18': 'Transacción fuera de línea',
                        '20': 'Consulta de saldo EBT',
                        '21': 'Consulta de saldo de tarjeta de valor',
                        '22': 'Consulta de saldo de WIC EBT',
                        '80': 'Informe de totales de departamento'
                    }
                },
                numberOfStrings: 'Cantidad de registros asociados',
                operatorCode: 'Código de operador',
                operatorName: 'Nombre del operador',
                grossPositive: 'Total bruto positivo',
                grossNegative: 'Total bruto negativo',
                subtotal: 'Subtotal',
                totalTaxes: 'Impuestos totales',
                total: 'Total',
                ringElapsedTime: 'Tiempo escaneo de ítems',
                tenderElapsedTime: 'Tiempo de pago',
                specialElapsedTime: 'Tiempo de asegurado',
                inactiveElapsedTime: 'Tiempo entre transacción',
                TOFRecovered: 'Es recuperada por TOF',
                transactionCopiedAfterPreviousTLOGCloseString: 'Copiada después de la cadena de cierre de TLog anterior',
                CVBPaper: 'Cash Value Benefit paper',
                digitalReceiptCreated: 'Recibo digital creado',
                paperReceiptSuppressed: 'Recibo en papel suprimido',
                eatInTransaction: 'Transacción para servir (comida)',
                preferredCustomer: 'Cliente preferido',
                grossPosIsNegative: 'Total bruto positivo es negativo',
                grossNegIsNegative: 'Total bruto negativo es negativo',
                additionalRecords: 'Existen registros adicionales',
                notFirstRecord: 'No es el primer registro',
                tillChange: 'Es de cierre con cadena de cambio de gaveta (falla de LAN)',
                termInitialized: 'Inicializado antes o durante la trans.',
                rollbackPriceItem: 'Rollback price item', // <<
                transTransferred: 'Transacción transferida',
                transactionMonitored: 'Transacción monitoreada',
                tenderRemoval: 'Eliminación de forma de pago sin venta antes o durante la trans.',
                tillContents: 'Informe del contenido de la gaveta sin venta antes de esta trans.',
                tillExchanged: 'Sin venta hasta el cambio de gaveta antes de esta trans.',
                tenderVerified: 'Verificación de pago sin venta antes de la trans.',
                operatorSignOn: 'Inicio de sesión del operador antes de la trans.',
                tenderRejected: 'Forma de pago rechazada',
                signOffIsFalse: 'No hay desconexión',
                dataEntry: 'Entrada de datos en la siguiente cadena',
                openDrawer: 'Cajón abierto antes o durante la trans.',
                specialSignOff: 'Desconexión forzada antes o durante la trans.',
                terminalAcct: 'Terminal accountability', // <<
                isTransactionRecovered: 'Es recuperada',
                items: {
                    itemCode: 'Código de artículo',
                    xPrice: 'Precio ampliado',
                    department: 'Código de departamento',
                    itemName: 'Nombre del artículo',
                    departmentName: 'Nombre del departamento',
                    familyNumberCurr: 'Número de familia del cupón (actual)',
                    familyNumberPrev: 'Número de familia del cupón (anterior)',
                    ordinalNumber: 'Número ordinal',
                    voidedOrdinalNumber: 'Número ordinal anulado',
                    itemType: {
                        name: 'Tipo',
                        options: {
                            '0': 'Venta de artículo normal o venta de artículo de bonificación',
                            '1': 'Depósito',
                            '2': 'Reembolso',
                            '3': 'Devolución del depósito',
                            '4': 'Transacción miscelánea de venta',
                            '5': 'Transacción miscelánea de pago',
                            '6': 'Cupón de fabricante',
                            '7': 'Cupón de tienda',
                            '8': 'Venta de artículo cancelada',
                            '9': 'Cancelar depósito',
                        }
                    },
                    entryMethod: {
                        name: 'Método de entrada',
                        options: {
                            '0': 'Código de artículo escaneado',
                            '1': 'Código de artículo tecleado',
                            '2': 'Clave de búsqueda de artículo utilizada',
                            '3': 'Código de artículo vinculado a',
                            '4': 'Reservado (4)',
                            '5': 'Artículo creado por el servicio',
                            '6': 'Reservado (6)',
                            '7': 'Reservado (7)',
                            '8': 'Canje de puntos',
                            '9': 'Puntos extra',
                        }
                    },
                    MPGroup: 'Número de grupo multiprecio',
                    dealQuantity: 'Número de artículos hasta este punto',
                    pricingMethod: {
                        name: 'Método de fijación de precio',
                        options: {
                            '0': 'Precio por paquete unitario o dividido',
                            '1': 'Precio base + 1',
                            '2': 'Precio de umbral de grupo',
                            '3': 'Precio ajustado por grupo (oferta reducida)',
                            '4': 'Precio ajustado por unidad (mayor oferta)',
                        }
                    },
                    saleQuantity: 'Cantidad de venta (oferta/granel)',
                    salePrice: 'Precio de venta (oferta/granel)',
                    quantityWeightOrVolume: 'Cantidad, peso o volumen',
                    tareWeight: 'Peso de tara',
                    GS1DataBar: 'Barra de datos GS1',
                    rainCheckItem: 'Cotización',
                    fuelItem: 'Artículo de combustible',
                    rollbackPriceItem: 'Artículo con precio de reversión',
                    weightItem: 'Peso de artículo',
                    priceEntered: 'Precio ingresado',
                    priceRequired: 'Precio requerido',
                    logAll: 'Registrar todas las ocurrencias',
                    logEx: 'Registro de artículo como excepción',
                    alias: 'Alias',
                    noMovement: 'No se mantuvo movimiento de artículo',
                    taxableA: 'Plan de impuesto A aplicado',
                    taxableB: 'Plan de impuesto B aplicado',
                    taxableC: 'Plan de impuesto C aplicado',
                    taxableD: 'Plan de impuesto D aplicado',
                    foodStamp: 'Cupón de alimentos',
                    pointsItem: 'Artículo de puntos',
                    nonDiscountable: 'Artículo sin descuento',
                    couponMultiplicationNotAllowed: 'No se permite la multiplicación de cupones',
                    overrideRequired: 'Anulación requerida',
                    dataEntry: 'Con entrada de datos',
                    multiPriced: 'Artículo con precio múltiple',
                    weightOrQuantityOrVolume: 'Peso, cantidad o volumen ingresado',
                    couponMultiplier: 'Multiplicador de cupones',
                    taxKey: 'Tecla de impuestos presionada',
                    foodStampKey: 'Tecla de cupón de alimentos presionada',
                    cancelKey: 'Tecla para cancelar presionada',
                    refundKey: 'Tecla de reembolso presionada',
                    storeCouponKey: 'Tecla de cupón de tienda presionada',
                    manufacturerCouponKey: 'Tecla de cupón de fabricante presionada',
                    depositKey: 'Tecla de depósito presionada',
                    xPriceNegative: 'Precio negativo',
                    extensionExists: 'Existe extensión',
                    foodStampForgivenFee: 'Cuota perdonada de cupón de alimentos',
                    taxableE: 'Plan de impuesto E aplicado',
                    taxableF: 'Plan de impuesto F aplicado',
                    taxableG: 'Plan de impuesto G aplicado',
                    taxableH: 'Plan de impuesto H aplicado',
                    automaticVoid: 'Anulación automática',
                    markDownCoupon: 'Cupón de descuento',
                    qualifiedHealthCareProduct: 'Producto sanitario calificado (QHP)',
                    prescriptionItem: 'Artículo con receta (Rx)',
                    dealQuantityKey: 'Se introdujo la cantidad de la oferta',
                    weightOrVolumeKey: 'Se tecleó el peso o el volumen',
                    quantityKey: 'Se introdujo la cantidad',
                    tareKey: 'Se introdujo el código de tara',
                    scaleWeight: 'El peso vino de la escala',
                    quantityRequired: 'Cantidad requerida',
                    repeatQuantity: 'El precio incluye descuento',
                    couponQuantity: 'Cantidad ampliada del cupón',
                },
                journal: {
                    valuedCustomer: 'Cliente valorado',
                    raw: {
                        valuedCustomer: 'Cliente valorado',
                        preAdditionalData: 'Datos adicionales previos',
                        errors: 'Errores',
                        ticket: 'Ticket',
                        paymentTicket: 'Comprobante de pago',
                        paymentTicketCopy: 'Copia del comprobante de pago',
                        additionalData: 'Datos adicionales'
                    }
                },
                discounts: {
                    type: {
                        name: 'Tipo',
                        options: {
                            '03': 'Normal',
                            '04': 'Anulado'
                        }
                    },
                    discountGroup: 'Número de grupo de descuento',
                    discountRate: 'Tasa de descuento',
                    amount: 'Monto',
                    taxAmountExempted: 'Monto de impuesto exento',
                    taxableAmountExempted: 'Monto imponible exento',
                    taxExemptID: 'ID de exención de impuesto',
                    taxExemptAmountA: 'Monto del impuesto exento (A)',
                    taxExemptAmountB: 'Monto del impuesto exento (B)',
                    taxExemptAmountC: 'Monto del impuesto exento (C)',
                    taxExemptAmountD: 'Monto del impuesto exento (D)',
                    taxableAmountExemptedA: 'Monto imponible exento (A)',
                    taxableAmountExemptedB: 'Monto imponible exento (B)',
                    taxableAmountExemptedC: 'Monto imponible exento (C)',
                    taxableAmountExemptedD: 'Monto imponible exento (D)',
                    taxExemptAmountE: 'Monto del impuesto exento (E)',
                    taxExemptAmountF: 'Monto del impuesto exento (F)',
                    taxExemptAmountG: 'Monto del impuesto exento (G)',
                    taxExemptAmountH: 'Monto del impuesto exento (H)',
                    taxableAmountExemptedE: 'Monto imponible exento (E)',
                    taxableAmountExemptedF: 'Monto imponible exento (F)',
                    taxableAmountExemptedG: 'Monto imponible exento (G)',
                    taxableAmountExemptedH: 'Monto imponible exento (H)',
                },
                tenders: {
                    type: {
                        name: 'Tipo',
                        options: {
                            '05': 'Normal',
                            '06': 'Corrección'
                        }
                    },
                    tenderType: {
                        name: 'Tipo de forma de pago',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención', // no son formas de pago como tal
                            '63': 'Retención de IVA', // no son formas de pago como tal
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón electrónico de fabricante',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónica',
                        }
                    },
                    amountTender: 'Monto pagado',
                    amountFee: 'Monto de la tasa',
                    customer: 'N° cuenta del cliente',
                    status: {
                        name: 'Estado',
                        options: {
                            '00': 'Ninguno',
                            '50': 'Pago aceptado',
                            '51': 'Pago rechazado - riesgo 1',
                            '52': 'Pago rechazado - riesgo 2',
                            '53': 'Pago rechazado - riesgo 3',
                            '54': 'Pago rechazado - riesgo 4',
                            '60': 'Pago aceptado - sin cargo'
                        }
                    }
                },
                epsTenders: {
                    hostID: 'ID de host',
                    totalsType: {
                        name: 'Tipo de totales',
                        options: {
                            '01': 'Venta aprobada por el anfitrión (en línea)',
                            '05': 'Anulación aprobada por el anfitrión (en línea)',
                            '07': 'Venta aprobada por SurePOS ACE EPS',
                            '11': 'Anulación aprobada por SurePOS ACE EPS',
                            '19': 'Reembolso aprobado por el anfitrión (en línea)',
                            '23': 'Reembolso aprobado por SurePOS ACE EPS'
                        }
                    },
                    totalAmount: 'Monto total',
                    dateTime: 'Fecha y hora',
                    actionCode: {
                        name: 'Código de acción',
                        options: {
                            'A00': 'Aprobado',
                            'A120': 'Aprobado localmente',
                            'C01': 'Solicitar autorización',
                            'D94': 'Transacción duplicada',
                            'I104': 'Pago inválido',
                            'I105': 'Traducción fallida',
                            'I40': 'Trx no admitido'
                        }
                    },
                    responseReferenceNumber: 'Número de referencia de respuesta',
                    sequence: 'Número de secuencia',
                    messageType: {
                        name: 'Tipo de mensaje',
                        options: {
                            '01': 'Solicitud de crédito',
                            '02': 'Respuesta de crédito',
                            '03': 'Solicitud de débito',
                            '04': 'Respuesta de débito',
                            '05': 'Solicitud de verificación',
                            '06': 'Verificar respuesta',
                            '07': 'Solicitud EBT',
                            '08': 'Respuesta EBT',
                            '09': 'Solicitud de totales',
                            '10': 'Respuesta de totales',
                            '11': 'Solicitud anterior de totales',
                            '12': 'Respuesta anterior de totales',
                            '13': 'Solicitud de detalles',
                            '14': 'Respuesta de detalles',
                            '15': 'Solicitud de liquidación',
                            '16': 'Respuesta de liquidación',
                            '17': 'Solicitud de carga de PIN Pad',
                            '18': 'Respuesta de carga de PIN Pad',
                            '19': 'Solicitud de parámetro',
                            '20': 'Respuesta de parámetro',
                            '21': 'Solicitud de handshake',
                            '22': 'Respuesta de handshake',
                            '23': 'Solicitud de crédito SAF',
                            '24': 'Respuesta de crédito SAF',
                            '25': 'Solicitud de débito SAF',
                            '26': 'Respuesta de débito SAF',
                            '27': 'Solicitud de verificación SAF',
                            '28': 'Respuesta de verificación SAF',
                            '29': 'Solicitud SAF EBT',
                            '30': 'Respuesta SAF EBT',
                            '31': 'Solicitud de consulta',
                            '32': 'Respuesta de consulta',
                            '33': 'Solicitud de Pass Through',
                            '34': 'Respuesta de Pass Through',
                            '51': 'Solicitud de actualización de totales',
                            '52': 'Almacenar CAP completo',
                            '53': 'Terminal CAP completo',
                            '54': 'Solicitud de cambio de nombre de registros',
                            '55': 'Pass Through falla al enviar',
                            '56': 'Solicitud de reconciliación',
                            '57': 'Está listo para reconciliar',
                            '58': 'Listo para reconciliar',
                            '59': 'Solicitud de procesamiento de comprobante electrónico',
                            '60': 'Respuesta al procesamiento de comprobantes electrónicos',
                            '99': 'Desconocido',
                        }
                    },
                    responseCode: 'Código de respuesta',
                    reasonCode: {
                        name: 'Código de razón',
                        options: {
                            '0': 'Tiempo de espera',
                            '1': 'Motivo del error de canalización',
                            '2': 'Enlace abajo',
                            '3': 'Mensaje interno',
                            '4': 'Mensaje externo',
                            '5': 'Prueba la aprobación local',
                            '6': 'Volteado',
                            '300': 'Informativo',
                            '301': 'Estado del enlace',
                            '302': 'Datos de seguimiento',
                            '303': 'Evento inesperado',
                            '304': 'Evento no válido',
                            '305': 'Evento de error de canalización',
                            '306': 'Error de registro',
                            '307': 'Determinación de problema',
                        }
                    },
                    expDate: 'Fecha de expiración de la tarjeta',
                    tenderType: {
                        name: 'Tipo de forma de pago',
                        options: {
                            '1': 'Efectivo, variedad %{additional_data}',
                            '2': 'Cheque, variedad %{additional_data}',
                            '3': 'Cupones para alimentos, variedad %{additional_data}',
                            '4': 'Forma de pago miscelánea 1, variedad %{additional_data}',
                            '5': 'Forma de pago miscelánea 2, variedad %{additional_data}',
                            '6': 'Forma de pago miscelánea 3, variedad %{additional_data}',
                            '7': 'Cupón del fabricante, variedad %{additional_data}',
                            '8': 'Cupón de tienda, variedad %{additional_data}',
                        }
                    },
                    cashBack: 'Monto reembolsado a la tarjeta',
                    account: 'Número de cuenta de la tarjeta',
                    entryMethod: {
                        name: 'Método de entrada de la tarjeta',
                        options: {
                            'C': 'OCR',
                            'H': 'Pista deslizada 1',
                            'D': 'Pista deslizada 2',
                            'E': 'Pista deslizada 3',
                            'R': 'Datos de pista deslizados sin procesar',
                            'F': 'Pista Contactless 2',
                            'G': 'Pista Contactless 1',
                            'K': 'Cheque tecleado',
                            'T': 'Pista tecleada',
                            'Y': 'Licencia tecleada',
                            'M': 'Datos MICR',
                            'S': 'Escáner',
                            'L': 'EMV fallback deslizado',
                            'I': 'EMV fallback tecleado',
                            'V': 'Chip de contacto EMV',
                            'W': 'EMV Contactless',
                        }
                    },
                    ID1: 'ID 1',
                    ID2: 'ID 2',
                    approvalCode: 'Código de aprobación',
                    RCDescription: 'Descripción de la respuesta mostrada',
                    approvalCodeSource: {
                        name: 'Fuente del código de aprobación',
                        options: {
                            'A': 'Llamado para autorización',
                            'E': 'De la aplicación SurePOS ACE EPS',
                            'H': 'Desde el host',
                            'W': 'Desde la estación de trabajo',
                        }
                    },
                    accountType: {
                        name: 'Tipo de cuenta',
                        options: {
                            '1': 'Cuenta de ahorro (débito) o cupones para alimentos EBT',
                            '2': 'Cuenta corriente (débito) o efectivo EBT',
                            '5': 'Cuenta de gastos flexible (FSA)',
                        }
                    },
                    voucherNumber: 'Número de voucher',
                    acquiringInstitutionNumber: 'Número de institución adquirente',
                    originalHostResponseCode: 'Código de respuesta del host original',
                    hostResponseIndicator: 'Indicador de respuesta del host',
                    cardValidationNumber: 'Número de validación de la tarjeta',
                    entryMethodSecondID: {
                        name: 'Segundo ID del método de entrada de la tarjeta',
                        options: {
                            'H': 'Pista deslizada 1',
                            'D': 'Pista deslizada 2',
                            'E': 'Pista deslizada 3',
                            'F': 'Pista Contactless 2',
                            'G': 'Pista Contactless 1',
                            'K': 'Cheque tecleado',
                            'T': 'Pista tecleada',
                            'Y': 'Licencia tecleada',
                            'M': 'Datos MICR',
                            'V': 'Chip de contacto EMV',
                            'W': 'EMV Contactless',
                        }
                    },
                    flags1: 'Flags 1',
                    QHPAmount: 'Monto del plan sanitario calificado',
                    rxAmount: 'Monto de receta (Rx)',
                    originalTenderAmount: 'Monto de pago original',
                    alternateTenderID: 'ID de pago alterno',
                    token: 'Token',
                },
                taxes: {
                    type: {
                        name: 'Tipo',
                        options: {
                            '07': 'Normal',
                            '08': 'Devolución'
                        }
                    },
                    taxPlan: 'Plan de impuesto',
                    amountTaxA: 'Monto del impuesto pagado (A)',
                    amountTaxB: 'Monto del impuesto pagado (B)',
                    amountTaxC: 'Monto del impuesto pagado (C)',
                    amountTaxD: 'Monto del impuesto pagado (D)',
                    taxableAmountA: 'Monto imponible (A)',
                    taxableAmountB: 'Monto imponible (B)',
                    taxableAmountC: 'Monto imponible (C)',
                    taxableAmountD: 'Monto imponible (D)',
                    amountTaxE: 'Monto del impuesto pagado (E)',
                    amountTaxF: 'Monto del impuesto pagado (F)',
                    amountTaxG: 'Monto del impuesto pagado (G)',
                    amountTaxH: 'Monto del impuesto pagado (H)',
                    taxableAmountE: 'Monto imponible (E)',
                    taxableAmountF: 'Monto imponible (F)',
                    taxableAmountG: 'Monto imponible (G)',
                    taxableAmountH: 'Monto imponible (H)',
                },
                changes: {
                    tenderType: {
                        name: 'Tipo de forma de pago (vuelto)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónica',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónica',
                        }
                    },
                    amountChange: 'Monto del vuelto'
                },
                overrides: {
                    overrideNumber: 'Código de supervisor',
                    reasonCode: {
                        name: 'Código de razón de anulación',
                        options: {
                            '01': 'Excede el límite de anulación de precio',
                            '02': 'No alcanza el límite de precio mínimo',
                            '03': 'Excede el límite de entrada negativo',
                            '04': 'No alcanza el límite de artículos misc. mínimo',
                            '05': 'Excede artículo/misc. límite',
                            '06': 'Cancelar artículo no vendido en la transacción',
                            '07': 'Aceptar cupón para artículo no vendido',
                            '08': 'Ingreso de cupón/reembolso con Clave de Departamento',
                            '09': 'Excede el límite de anulación de peso',
                            '10': 'Multiplicación del valor del cupón > valor del artículo',
                            '11': 'Artículo no disponible a la venta',
                            '12': 'Seguridad EMV - No se licitó AID con una tarjeta que no sea EMV',
                            '13': 'Omitir fecha de nacimiento',
                            '14': 'Exceder el límite de anulación de volumen',
                            '15': 'Exceder el límite de la tasa de descuento',
                            '16': 'Exceder el límite del monto de descuento',
                            '17': 'Excede el límite de exención fiscal',
                            '18': 'Omitir ID de exención fiscal',
                            '19': 'ID de cliente Alpha no válido',
                            '20': 'Omitir franqueo de licitación',
                            '21': 'Exceder el límite de entrada negativo para trans.',
                            '22': 'Exceder el límite total negativo para trans.',
                            '23': 'Seguridad EMV - No se ofreció AID con la tarjeta EMV',
                            '24': 'Exceder el límite del monto de tipo de pago',
                            '25': 'Exceder el límite del vuelto de tipo de pago',
                            '26': 'Omitir verificación de pago',
                            '27': 'Superar el rechazo de la verificación de pago',
                            '28': 'Realizar anulación retardada',
                            '29': 'Omitir verificación de pago al fallar el acceso',
                            '30': 'Artículo anulado en transacción negativa',
                            '31': 'Artículo no está en la escala correctamente',
                            '32': 'Aceptar pago no permitido',
                            '33': 'Licencia caducada',
                            '34': 'Fecha de nacimiento tecleada',
                            '35': 'Exceder el límite de transacciones nulas',
                            '36': 'Fecha de inicio del cupón no cumplida',
                            '37': 'Fecha de vencimiento del cupón excedida',
                            '38': 'ID de minorista de cupones no permitido',
                            '39': 'Peso del cupón no alcanzado',
                            '40': 'Excede el límite de canje de pago',
                            '41': 'Omitir clasificación de canje de pago',
                            '42': 'Excede el límite de reembolso de la tarifa de pago',
                            '44': 'Aprobación especial no autorizada',
                            '45': 'Salir del cierre de sesión especial sin contraseña',
                            '46': 'Procedimiento de no venta dentro de la transacción',
                            '47': 'Exceder límite de cambio de gaveta',
                            '48': 'Seguridad EMV - Error de chip EMV presentado con tarjeta que no es EMV',
                            '49': 'Seguridad EMV - Error de chip EMV presentado con tarjeta EMV',
                            '50': 'Iniciar sesión cuando el operador ya esté activo',
                            '51': 'Omitir autenticación de operador al fallar el acceso',
                            '52': 'Reinicializar terminal transferido',
                            '55': 'Exceder el límite de préstamo de caja',
                            '56': 'Exceder el límite de retiro en caja',
                            '57': 'Superar el número máximo de suspensiones permitidas',
                            '58': 'Se requiere la recuperación forzada',
                            '59': 'Se requiere suspensión forzada',
                            '60': 'Sin firma electrónica',
                            '61': 'Número de cuenta tecleado',
                            '62': 'SurePOS ACE EPS rechazado',
                            '63': 'Límites de EPS de SurePOS ACE excedidos',
                            '64': 'GIPC abajo',
                            '65': 'Omitir error de papel',
                            '66': 'Se necesitan más puntos',
                            '67': 'ID de cliente tecleado',
                            '68': 'Nuevo ID de cliente',
                            '69': 'Se excedió el límite de uso diario de la tarjeta',
                            '70': 'Verificación/cambio de precio de no venta',
                            '71': 'Prescripción duplicada',
                            '72': 'Receta nula sin venta previa',
                            '73': 'Devolución de receta sin venta previa',
                            '74': 'Información de prescripción no encontrada',
                            '75': 'Solicitud de farmacia inaccesible',
                            '76': 'Reimprimir recibo de pago',
                            '77': 'Se anuló la restricción de edad del operador',
                            '78': 'MICR no presente',
                            '79': 'Señal MICR baja',
                            '80': 'Número de cuenta',
                            '81': 'Código de tránsito',
                            '82': 'Número de cheque',
                            '83': 'Tránsito extranjero',
                            '85': 'Excede el límite de anulación de la cotización',
                            '86': 'Cliente preferido no presente en la transacción',
                            '87': 'Restricción de pago',
                            '88': 'Fecha de vencimiento del artículo vencida',
                            '89': 'Restricción de cantidad inmediata',
                        }
                    },
                    index: 'Índice',
                    initials: 'Iniciales'
                },
                dataEntries: {
                    identifier: {
                        name: 'Identificador',
                        options: {
                            '00': 'Cadena de identificación del cliente',
                            'CC': 'Cadena de datos de transacción automática de cupones',
                            'EE': 'Cadena de datos de transacción de cliente preferido'
                        }
                    },
                    dataEntry: {
                        '00': {},
                        'CC': {},
                        'EE': {}
                    }
                },
                tillChanges: {
                    numberOfLoans: 'Número de dotaciones a la caja',
                    amountOfLoans: 'Monto de las dotaciones a la caja',
                    numberOfPickups: 'Número de recogidas de la caja',
                    amountOfPickups: 'Cantidad de recogidas de la caja',
                    tenderType1: {
                        name: 'Tipo de forma de pago (1)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    amountTender1: 'Monto de forma de pago (1)',
                    tenderType2: {
                        name: 'Tipo de forma de pago (2)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    amountTender2: 'Monto de forma de pago (2)',
                    tenderType3: {
                        name: 'Tipo de forma de pago (3)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    amountTender3: 'Monto de forma de pago (3)',
                    tenderType4: {
                        name: 'Tipo de forma de pago (4)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    amountTender4: 'Monto de forma de pago (4)',
                    tenderType5: {
                        name: 'Tipo de forma de pago (5)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    amountTender5: 'Monto de forma de pago (5)',
                    tenderType6: {
                        name: 'Tipo de forma de pago (6)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    amountTender6: 'Monto de forma de pago (6)',
                    tenderType7: {
                        name: 'Tipo de forma de pago (7)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    amountTender7: 'Monto de forma de pago (7)',
                    tenderType8: {
                        name: 'Tipo de forma de pago (8)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    amountTender8: 'Monto de forma de pago (8)',
                    negative: 'Delimitador para formas de pago negativas',
                    negativeTenderType1: {
                        name: 'Tipo de forma de pago negativa (1)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    negativeAmountTender1: 'Monto de forma de pago negativa (1)',
                    negativeTenderType2: {
                        name: 'Tipo de forma de pago negativa (2)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    negativeAmountTender2: 'Monto de forma de pago negativa (2)',
                    negativeTenderType3: {
                        name: 'Tipo de forma de pago negativa (3)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    negativeAmountTender3: 'Monto de forma de pago negativa (3)',
                    negativeTenderType4: {
                        name: 'Tipo de forma de pago negativa (4)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    negativeAmountTender4: 'Monto de forma de pago negativa (4)',
                    negativeTenderType5: {
                        name: 'Tipo de forma de pago negativa (5)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    negativeAmountTender5: 'Monto de forma de pago negativa (5)',
                    negativeTenderType6: {
                        name: 'Tipo de forma de pago negativa (6)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    negativeAmountTender6: 'Monto de forma de pago negativa (6)',
                    negativeTenderType7: {
                        name: 'Tipo de forma de pago negativa (7)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    negativeAmountTender7: 'Monto de forma de pago negativa (7)',
                    negativeTenderType8: {
                        name: 'Tipo de forma de pago negativa (8)',
                        options: {
                            '11': 'Efectivo',
                            '21': 'Cheques',
                            '41': 'Visa',
                            '42': 'Mastercard',
                            '43': 'Dinners',
                            '45': 'Discover',
                            '46': 'Amex',
                            '47': 'Alia',
                            '48': 'Débito',
                            '51': 'Crédito CER',
                            '52': 'Orden de compra',
                            '53': 'Giftcard CER',
                            '54': 'Billetera móvil',
                            '55': 'Nota de crédito',
                            '56': 'Gastos',
                            '57': 'Vales de caja',
                            '58': 'Retiro dinero',
                            '61': 'Transferencia bancaria',
                            '62': 'Retención',
                            '63': 'Retención de IVA',
                            '69': 'Siniestro',
                            '71': 'Cupón fabricante',
                            '72': 'Cupón fabricante electrónico',
                            '73': 'Vale fabricante',
                            '81': 'Cupones de tienda',
                            '82': 'Bonos',
                            '83': 'Cupón de tienda electrónico',
                        }
                    },
                    negativeAmountTender8: 'Monto de forma de pago negativa (8)',
                },
                userStrings: {
                    originator: {
                        name: 'Originador',
                        options: {
                            '00': 'Toshiba',
                            '06': 'Personalizado',
                            '10': 'Cliente'
                        }
                    },
                    subtype: {
                        name: 'Subtipo',
                        options: {
                            '00-11': 'Bar code data',
                            '00-21': 'Promotion service coupon details',
                            '00-52': 'Void Reason Code',
                            '06-01': 'Tarjeta de afiliación',
                            '06-02': 'Estado offline',
                            '06-15': 'Recargas electrónicas',
                            '06-20': 'Transacción de factura electrónica',
                            '06-21': 'Canje de retención',
                            '06-25': 'Comprobantes de gastos',
                            '06-27': 'Datos de facturación',
                            '06-29': 'Datos de empleados',
                            '06-30': 'Retención como forma de pago',
                            '06-31': 'Pago de retención',
                            '06-32': 'Billetes',
                            '06-33': 'Cheques',
                            '06-37': 'Datos de cupones',
                            '06-41': 'Detalle de impuestos',
                            '06-45': 'Autorización externa medios de pago',
                            '06-46': 'Datos de deducibles',
                            '06-51': 'Compras Pinpad',
                            '06-53': 'Promotion information',
                            '06-54': 'Consumed coupons information',
                            '06-55': 'Extra points by business type information',
                            '06-63': 'Points redemption',
                            '10-03': 'Redención de puntos automático',
                            '10-04': 'Redención de puntos automático (void)'
                        }
                    },
                    userData: {
                        '00': {
                            '11': {
                                barCodeType: {
                                    name: 'Tipo de código de barras',
                                    options: {
                                        '00': 'GS1 DataBar Coupon',
                                        '01': 'Value Card or Code 128',
                                        '02': 'UPC',
                                        '03': 'UPC-E',
                                        '04': 'EAN',
                                        '05': 'GS1 DataBar or GS1 DataBar Expanded',
                                        '06': 'Unknown Bar code type',
                                    }
                                },
                                barCode: 'Código de barras',
                                gs1PrimaryQty: 'GS1 Primary Qty',
                                gs1SecondQty: 'GS1 Second Qty',
                                gs1ThirdQty: 'GS1 Third Qty',
                            },
                            '21': {
                                promotionServiceId: 'Promotion service ID',
                                promotionServiceCouponId: 'Promotion service coupon ID',
                                discountType: {
                                    name: 'Tipo de descuento',
                                    options: {
                                        0: 'PERCENT_OFF',
                                        1: 'FIXED_VALUE',
                                        2: 'NET_VALUE'
                                    }
                                },
                                discountTypeValue: 'Discount type value',
                                psUserData: 'PS user data',
                                numberOfMatchingItems: 'Number of matching items',
                                matchingItemOrdinalNumber: 'Matching item ordinal number',
                                matchingItemDiscountAmount: 'Matching item discount amount',
                                matchingItemPrice: 'Matching item price'
                            },
                            '52': {
                                voidReason: 'Código de motivo de la anulación'
                            }
                        },
                        '06': {
                            '01': {
                                storeType: 'Tipo de tienda',
                                personType: 'Tipo de persona',
                                maskedAccountNumber: 'Numero de cuenta enmascarado',
                                pointRedemptionFlag: 'Bandera de redención de puntos',
                                rucForBilling: 'RUC para facturación',
                                other1: 'Otro 1',
                                other2: 'Otro 2',
                                other3: 'Otro 3',
                            },
                            '02': {
                                offlineIndicator: 'Indicador de fuera de línea',
                                offlineStatus: 'Estado de fuera de línea'
                            },
                            '15': {
                                refillArticleCode: 'Código artículo recarga',
                                storeNumber: 'Número de tienda',
                                terminalNumber: 'Número de terminal',
                                reference: 'Referencia',
                                type: 'Tipo',
                                cellPhoneNumber: 'Número de celular',
                                amount: 'Monto',
                                authorization: 'Autorización',
                                transactionTime: 'Hora de la transacción',
                                cancellation: 'Cancelación',
                            },
                            '20': {
                                date: 'Fecha',
                                time: 'Hora',
                                invoiceReferenceNumber: 'Número de referencia de la factura',
                                documentType: 'Tipo de documento',
                                messageDeliveryStatus: 'Estado del envío del mensaje',
                                invoiceNumberForPrinting: 'Número de factura para impresión',
                                transactionSubTotal: 'Subtotal de la transacción',
                                transactionTotal: 'Total de la transacción',
                            },
                            '21': {
                                terminalNumber: 'Número de terminal',
                                ticketNumber: 'Número de tiquete',
                                date: 'Fecha',
                                voucherNumber: 'Número de voucher',
                                sriValidationNumber: 'Número de validación del SRI',
                                retentionAmount: 'Monto de la retención',
                                taxBase: 'Base imponible',
                                retentionPercentage: 'Porcentaje de retención',
                                clientId: 'ID del cliente',
                                clientName: 'Nombre del cliente',
                                paymentMethodId: 'ID de forma de pago',
                                contributorType: 'Tipo de contribuidor',
                                retentionType: 'Tipo de retención',
                            },
                            '25': {
                                clientId: 'ID del cliente (cédula)',
                                sapCompany: 'Sociedad SAP',
                                voucherNumber: 'Número de voucher',
                                code: 'Código',
                                quantity: 'Cantidad',
                                value: 'Valor',
                                commentary: 'Comentario',
                            },
                            '27': {
                                terminalNumber: 'Número de terminal',
                                clientId: 'ID del cliente',
                                typeId: 'ID del tipo',
                                clientName: 'Nombre del cliente',
                                clientAddress: 'Dirección del cliente',
                                clientPhone: 'Teléfono del cliente',
                                finalFlag: 'Bandera final',

                            },
                            '29': {
                                terminalNumber: 'Número de terminal',
                                transactionNumber: 'Número de transacción',
                                employeeNumber: 'Número de empleado',
                                carrierId: 'ID del portador',
                                carrierName: 'Nombre del portador',
                                trackCard1: 'Tarjeta pista 1',
                                trackCard2: 'Tarjeta pista 2',
                            },
                            '30': {
                                voucherNumber: 'Número de voucher',
                                sriNumber: 'Número del SRI',
                                retentionType: 'Tipo de retención',
                                retentionPercentage: 'Porcentaje de retención',
                                taxBase: 'Base imponible',
                                retentionValue: 'Valor de retención',
                                ruc: 'RUC',
                                clientName: 'Nombre del cliente',
                                other1: 'Otro 1',
                            },
                            '31': {
                                terminalNumber: 'Número de terminal',
                                voucherNumber: 'Número de voucher',
                                sriValidationNumber: 'Número de validación del SRI',
                                retentionAmount: 'Monto de retención',
                                taxBase: 'Base imponible',
                                retentionPercentage: 'Porcentaje de retención',
                                clientId: 'ID del cliente',
                                clientName: 'Nombre del cliente',
                                retentionType: 'Tipo de retención',
                                paymentMethodId: 'ID de forma de pago',
                                contributorType: 'Tipo de contribuidor',
                                invoiceDate: 'Fecha de la factura',
                            },
                            '32': {
                                banknotesDenomination: 'Denominación de los billetes',
                                banknoteSerialNumber: 'Número de serie de los billetes',
                            },
                            '33': {
                                bankName: 'Nombre del banco',
                                checkAccountNumber: 'Número de cuenta del cheque',
                                checkNumber: 'Número de cheque',
                                dateOnCheck: 'Fecha del cheque',
                                checkAmount: 'Monto del cheque',
                                other1: 'Otro 1',
                            },
                            '37': {
                                maxRedemptions: 'Máximo de redenciones',
                                startDate: 'Fecha de inicio',
                                expirationDate: 'Fecha de expiración',
                                ticketCode: 'Código del ticket',
                                ticketType: 'Tipo de ticket',
                                quantityTickets: 'Cantidad de tickets',
                                other1: 'Otro 1',
                            },
                            '41': {
                                terminalNumber: 'Número de terminal',
                                transactionNumber: 'Número de transacción',
                                date: 'Fecha',
                                itemCode: 'Código del artículo',
                                salePrice: 'Precio de venta',
                                vatValue: 'Valor de IVA',
                                priceWithoutVat: 'Precio sin IVA',
                                overridePrice: 'Precio reemplazado',
                                discount: 'Descuento',
                            },
                            '45': {
                                cardNumber: 'Número de tarjeta',
                                paymentMethodType: 'Tipo de forma de pago',
                                externalAuthorizationNumber: 'Número de autorización externa',
                                sequenceNumber: 'Numero de secuencia (referencia de TRX)',
                                paymentTime: 'Hora del pago',
                                holderId: 'ID del titular',
                                amountPaidWithCard: 'Monto pagado con la tarjeta',
                                availableSpace: 'Cupo disponible',
                                responseCode: 'Código de respuesta',
                                cardStatus: 'Estado de la tarjeta (normal o anulado)',
                            },
                            '46': {
                                groceriesDeductible: 'Deducible comestibles',
                                clothingDeductible: 'Deducible ropa',
                                schoolDeductible: 'Deducible escolar',
                            },
                            '51': {
                                acquirerCode: 'Código adquiriente',
                                deferredCode: 'Código diferido',
                                deferredTerm: 'Plazo diferido',
                                graceMonths: 'Meses de gracia',
                                transactionAmount: 'Monto de la transacción',
                                vatTaxBaseAmount: 'Monto base grava IVA',
                                withoutVatTaxBaseAmount: 'Monto base no grava IVA',
                                transactionVat: 'IVA de la transacción',
                                sequentialTransaction: 'Secuencial de la transacción',
                                transactionTime: 'Hora de la transacción',
                                transactionDate: 'Fecha de la transacción',
                                authorizationNumber: 'Número de autorización',
                                mid: 'MID',
                                tid: 'TID',
                                cid: 'CID',
                                deferredValue: 'Valor diferido',
                                arqc: 'ARQC',
                                aid: 'AID',
                                bankName: 'Nombre del banco',
                                brandName: 'Nombre de la marca',
                            },
                            '53': {
                                promotionCode: 'Código de promoción',
                                ordinalNumber: 'Número ordinal',
                                couponValue: 'Valor de cupón',
                                promotionStatus: 'Estado de la promoción',
                            },
                            '54': {
                                couponsUsed: 'Cupones utilizados'
                            },
                            '55': {
                                extraPoints: 'Puntos extras'
                            },
                            '63': {
                                activationTime: 'Hora de la activación',
                                discount: 'Descuento',
                                businessLineCode: 'Código línea de negocio',
                                referenceNumber: 'Número de referencia (SYSCARD)',
                                authorizationNumber: 'Número de autorización (SYSCARD)',
                                redemptionType: 'Tipo de redención',
                                bin: 'Bin',
                                pointsRedeemed: 'Puntos redimidos',
                                itemCode: 'Código del artículo',
                                other1: 'Otro 1',
                            },
                        },
                        '10': {
                            '03': {
                                psCouponId: 'ID de cupón PS',
                                pointsType: 'Tipo de puntos',
                                pointsRedeemed: 'Puntos redimidos',
                                ordinalNumber: 'Número ordinal',
                            },
                            '04': {
                                psCouponId: 'ID de cupón PS',
                                pointsType: 'Tipo de puntos',
                                pointsRedeemed: 'Puntos redimidos',
                                ordinalNumber: 'Número ordinal',
                            }
                        }
                    }
                }
            },
            tabs: {
                summary: {
                    name: 'Resumen',
                    sections: {
                        generalInfo: 'Información General',
                        //itemList: 'Lista de Artículos',
                        //taxList: 'Información de Impuestos',
                        //tendersList: 'Información de Formas de Pago',
                        paymentInfo: 'Información de Pago',
                        //changesList: 'Información de Vueltos',
                        journalInfo: {
                            name: 'Journal',
                            seeMore: 'Ver más',
                            errors: {
                                noJournal: 'No se ha podido obtener el journal.',
                                noData: 'No hay datos del journal para mostrar.',
                            }
                        }
                    }
                },
                general: {
                    name: 'General',
                    sections: {
                        header: 'Cabecera',
                        performance: 'Rendimiento',
                        indicators: 'Indicadores',
                    }
                },
                items: 'Artículos',
                customer: 'Cliente',
                discounts: 'Descuentos',
                tenders: 'Formas de Pago',
                epsTenders: 'Pagos Electrónicos (EPS)',
                taxes: 'Impuestos',
                overrides: 'Anulación Admin./Operador',
                dataEntries: 'Entradas de datos',
                tillChanges: 'Cambios de Gaveta',
                userStrings: 'Cadenas de Usuario',
                changes: 'Vueltos',
                couponsInformation: 'Info. de Cupones',
            },
            statistics: {
                name: 'Estadísticas',
                tabs: {
                    generalSales: {
                        name: 'Ventas Generales',
                        sections: {
                            timeIntervalSales: {
                                name: 'Ventas por %{group_by}',
                                groupBy: {
                                    'dayOfWeek': 'Días de la semana',
                                    'dayOfMonth': 'Días del mes',
                                    'weekOfYear': 'Semanas del año',
                                    'monthOfYear': 'Meses del año'
                                }
                            },
                            sales: 'Ventas por %{group_by}',
                            salesInTime: 'Ventas por %{group_by} en el tiempo',
                        }
                    },
                    itemSales: {
                        name: 'Venta de Artículos',
                        sections: {
                            salesPercentage: 'Porcentaje de ventas por %{group_by}',
                            salesCount: 'Artículos vendidos por %{group_by}',
                            salesInTime: 'Artículos vendidos por %{group_by} en el tiempo',
                            itemsPerTransaction: 'Artículos por transacción',
                            itemsPerTransactionInTime: {
                                name: 'Artículos por transacción en el tiempo',
                                avgTransactionTime: 'Promedio de tiempo por transacción'
                            },
                            itemsZeroPrice: {
                                name: 'Artículos vendidos con precio cero',
                                salePrice: 'Precio unitario',
                                itemBulkQuantity: 'Cantidad, peso o volumen de artículos',
                                posOperator: 'Operador POS',
                                posOperatorCode: 'Código de operador POS',
                                posOperatorName: 'Nombre de operador POS',
                                item: 'Artículo'
                            }
                        }
                    },
                    tenders: {
                        name: 'Formas de Pago',
                        sections: {
                            salesPercentage: 'Porcentaje de ventas según forma de pago',
                            perTransaction: 'Cantidad de formas de pago por transacción',
                            perWeekday: 'Formas de pago por día de la semana',
                            perWeekdayPercentage: 'Formas de pago por día de la semana (porcentaje)'
                        }
                    },
                    payments: {
                        name: 'Pagos',
                        sections: {
                            transactionsExactPayment: {
                                name: 'Transacciones con y sin pago exacto',
                                exactPayments: 'Pagos exactos',
                                noExactPayments: 'Pagos no exactos',
                                transactionCashQuantity: 'Cantidad de transacciones con pago en efectivo',
                            },
                            transactionsWithCoinsPayment: {
                                name: 'Transacciones pagadas con y sin monedas (basado en el total de la venta)',
                                withCoins: 'Pagos con monedas',
                                withoutCoins: 'Pagos sin monedas',
                                transactionCashQuantity: 'Cantidad de transacciones con pago en efectivo',
                            },
                            tendersWithCoinsPayment: {
                                name: 'Transacciones pagadas con y sin monedas (basado en los pagos)',
                                withCoins: 'Pagos con monedas',
                                withoutCoins: 'Pagos sin monedas',
                                transactionCashQuantity: 'Cantidad de transacciones con pago en efectivo',
                            }
                        }
                    },
                    changes: {
                        name: 'Vueltos',
                        sections: {
                            changesAndCashByTerminalNumbers: {
                                name: 'Vueltos y pagos en efectivo por número de terminales',
                                amountChangePercentage: 'Porcentaje monto de vuelto período',
                                amountChangePercentageAbbr: '% vuelto período',
                                amountChangePercentageRemaining: 'Porcentaje monto de vuelto restante',
                                amountChangePercentageRemainingAbbr: '% vuelto restante',
                                totalTransactionQuantity: 'Cant. transacciones totales',
                                totalAmountChange: 'Total vueltos',
                                totalAmountTender: 'Total pagos efectivo',
                                startTime: 'Inicio de período',
                                endTime: 'Fin de período',
                                transactionQuantity: 'Cant. transacciones en período',
                                amountChange: 'Vueltos en período',
                                amountTender: 'Pagos efectivo en período',
                                changePerTransaction: 'Vuelto promedio por TRX',
                                tenderPerTransaction: 'Pago promedio por TRX',
                                changePerTransactionInPeriod: 'Vuelto promedio por TRX en período',
                                tenderPerTransactionInPeriod: 'Pago promedio por TRX en período',
                                coinsReqInPeriod: 'Sencillo estimado req. en período',
                                indicatorsSummary: 'Resumen de indicadores',
                                avgTransactionQuantity: 'Promedio de cantidad de transacciones en período por terminal',
                                avgAmountChange: 'Promedio de vueltos en período por terminal',
                                avgChangePerTransactionInPeriod: 'Vuelto promedio por TRX en período',
                                avgCoinsReqInPeriod: 'Dinero en monedas estimado requerido por terminal en período',
                                avgBillsReqInPeriod: 'Dinero en billetes estimado requerido por terminal en período',
                            },
                            changesInTime: {
                                name: 'Vueltos por %{group_by} en el tiempo',
                                totalAmountChange: 'Total vueltos',
                                billsAmountChange: 'Vuelto en billetes',
                                billsAmountChangeAbbr: 'Vuelto billetes',
                                coinsAmountChange: 'Vuelto en monedas',
                                coinsAmountChangeAbbr: 'Vuelto monedas'
                            }
                        }
                    },
                    operatorProductivity: {
                        name: 'Productividad del Operador',
                        sections: {
                            timesSaleTransactions: {
                                name: 'Tiempos de transacciones de venta',
                                ringElapsedTime: 'T. escaneo ítems',
                                tenderElapsedTime: 'T. pago',
                                specialElapsedTime: 'T. asegurado',
                                inactiveElapsedTime: 'T. entre TRX',
                                transactionsPerHour: 'TRX/hora',
                                itemQuantity: 'Ítems',
                                itemScannedQuantity: 'Í. escaneados',
                                itemKeyedQuantity: 'Í. digitados',
                                itemQuickKeyedQuantity: 'Í. tecl. rápido',
                                itemLineQuantity: 'Líneas',
                                itemsPerMinute: 'Ítems/min.',
                                secondsPerItemLine: 'Segs./línea',
                                startTransaction: 'Inicio transacción',
                                endTransaction: 'Fin transacción',
                                ignoreZeroTimes: 'Ignorar registros con tiempo cero',
                                storeCodeAndDatetime: 'Código de tienda y fecha'
                            },
                            cashiersWithOverridesTransactions: {
                                name: 'Cajeros (separando entre transacciones con y sin anulaciones)',
                                //totalTransactionQuantity: 'Cant. trx. totales',
                                transactionWithOverrideQuantity: 'Con anulación',
                                transactionWithoutOverrideQuantity: 'Sin anulación',
                                totalItemQuantity: 'Total artículos',
                                totalItemLineQuantity: 'Total líneas',
                                totalRingElapsedTime: 'T. total escaneo art.',
                                //ringElapsedTimeWithOverride: 'T. escaneo art. (c/anulación)',
                                //ringElapsedTimeWithoutOverride: 'T. escaneo art. (s/anulación)',
                                withOverride: 'Trx. con anulación',
                                withoutOverride: 'Trx. sin anulación',
                                avgItemTimeAbbr: 'Prom. T. p/art.',
                                avgItemLineTimeAbbr: 'Prom. T. p/línea.',
                                avgItemTimeWithOverride: 'Promedio tiempo por artículo (transacciones con anulación)',
                                avgItemLineTimeWithOverride: 'Promedio tiempo por línea (transacciones con anulación)',
                                avgItemTimeWithoutOverride: 'Promedio tiempo por artículo (transacciones sin anulación)',
                                avgItemLineTimeWithoutOverride: 'Promedio tiempo por línea (transacciones sin anulación)',
                                summaryTitle: '%{group_by} con tiempos fuera de rango',
                                repetitionQuantity: 'Cant. %{precision} sobrepasa rango'
                            },
                            cashiers: {
                                name: 'Cajeros',
                                itemQuantity: 'Cantidad de artículos',
                                itemLineQuantity: 'Cantidad de líneas',
                                avgItemTime: 'Promedio tiempo por artículo',
                                avgItemLineTime: 'Promedio tiempo por línea',
                                summaryTitle: '%{group_by} con tiempos fuera de rango',
                                repetitionQuantity: 'Cantidad de %{precision} que sobrepasa el rango'
                            },
                            compareProductivity: {
                                name: 'Comparar productividad',
                                momentOfChange: 'Momento de cambio',
                                unitOfTime: 'Unidad de tiempo',
                                quantityOfTime: 'Cantidad de tiempo',
                                ignoreZeroTimes: 'Ignorar registros con tiempo cero',
                                before: 'Antes',
                                after: 'Después',
                                avgTransactionTime: 'Prom. tiempo/TRX',
                                avgTransactionAmount: 'Prom. monto/TRX',
                                transactionsPerHour: 'TRX/hora',
                                secondsPerItemLine: 'Segs./línea',
                            },
                            queueLengthPerStore: {
                                name: 'Largo de filas por tienda',
                                nthClient: 'Calcular tiempo de espera al cliente N°',
                                projectedSecondsPerItemLine: 'Segundos por línea proyectado',
                                ignoreZeroTimes: 'Ignorar registros con tiempo cero',
                                currentSituation: 'Situación actual',
                                projectedSituation: 'Situación proyectada (%{seconds_per_item_line} segs.)',
                                secondsPerItemLine: 'Segs./línea',
                                avgItemLineQuantity: 'Prom. líneas por transacción',
                                avgTransactionTime: 'Prom. tiempo por transacción',
                                nthClientWaitTime: 'Prom. tiempo de espera %{nth_client}° cliente',
                                clientsInQueue: 'Cantidad de clientes en fila'
                            },
                            activeCashiers: {
                                name: 'Cajeros activos'
                            },
                            quantityActiveCashiers: {
                                name: 'Cantidad de cajeros activos',
                                compareWith: 'Comparar con',
                                terminalQuantity: 'Cantidad de terminales'
                            },
                            hoursSaved: {
                                name: 'Horas ahorradas',
                                hoursSaved: 'Horas ahorradas',
                                secondsPerItemLine: 'Segs./línea período',
                                worthSecondsPerItemLine: 'Segs./línea período de comparación',
                                worthDatetime: 'Período de comparación'
                            }
                        }
                    },
                    overrides: {
                        name: 'Anulaciones',
                        sections: {
                            overridesVoidedItems: {
                                name: 'Anulaciones de artículos',
                                item: 'Artículo'
                            },
                            overridesVoidedTransactions: {
                                name: 'Anulaciones de transacciones'
                            },
                            overridesVoidedItemsDatetime: {
                                name: 'Anulaciones de artículos en el tiempo'
                            }
                        },
                        supervisor: 'Supervisor |||| Supervisores',
                        posOperator: 'Operador POS',
                        posOperatorCode: 'Código de operador POS',
                        posOperatorName: 'Nombre de operador POS'
                    },
                    news: {
                        name: 'Nuevas',
                        sections: {

                        }
                    }
                },
                errors: {
                    noChart: 'No se ha podido generar el gráfico.',
                    noData: 'No hay datos para mostrar.',
                    noDataError: 'No se ha podido generar la tabla.'
                },
                export: {
                    selectedExports: '¿Qué desea exportar?',
                    widthExport: 'Ajustar ancho de visualización',
                    preview: 'Vista previa',
                    secondaryChart: 'Gráfico secundario',
                    mainTable: 'Tabla principal',
                    secondaryTable: 'Tabla secundaria',
                    summaryTable: 'Tabla resumen',
                    notification: {
                        info: 'Exportando: esta operación puede tardar varios segundos',
                        success: 'Exportación exitosa',
                        failed: 'Error: exportación fallida, por favor vuelva a intentarlo'
                    }
                }
            },
        },
        exceptions: {
            name: 'Excepción |||| Excepciones',
        },
        chains: {
            name: 'Cadena |||| Cadenas',
            fields: {
                id: 'ID de cadena',
                name: 'Nombre',
                status: 'Estado'
            },
            tabs: {
                general: 'General',
                groups: 'Grupos',
                stores: 'Tiendas',
                operators: 'Operadores',
                itemDetails: 'Detalles de Artículos',
                departments: 'Departamentos',
                terminalTypes: 'Tipos de Terminal',
                users: 'Usuarios'
            },
            crud: {
                create: {
                    notification: {
                        success: 'Cadena creada con éxito',
                        failed: 'Error: la cadena no pudo ser creada'
                    },
                    validation: {
                        nameNotUnique: 'Ya existe una cadena con este nombre'
                    }
                },
                edit: {
                    notification: {
                        success: 'Cadena editada con éxito',
                        failed: 'Error: la cadena no pudo ser editada'
                    },
                    validation: {
                        nameNotUnique: 'Ya existe otra cadena con este nombre'
                    }
                },
                delete: {
                    confirmTitle: 'Eliminar cadena',
                    confirmContent: '¿Seguro que quiere eliminar esta cadena?',
                    notification: {
                        success: 'Cadena eliminada con éxito',
                        failed: 'Error: la cadena no pudo ser eliminada'
                    }
                },
                bulkDelete: {
                    confirmTitle: 'Eliminar cadena |||| Eliminar %{smart_count} cadenas',
                    confirmContent: '¿Seguro que quiere eliminar esta cadena? |||| ¿Seguro que quiere eliminar estas %{smart_count} cadenas?',
                    notification: {
                        success: 'Cadena eliminada con éxito |||| Cadenas eliminadas con éxito',
                        failed: 'Error: la cadena no pudo ser eliminada |||| Error: las cadenas no pudieron ser eliminadas'
                    }
                }
            }
        },
        stores: {
            name: 'Tienda |||| Tiendas',
            fields: {
                id: 'ID de tienda',
                name: 'Nombre',
                storeCode: 'Código de tienda',
                storeNumber: 'Número de tienda',
                address: 'Dirección',
                latitude: 'Latitud',
                longitude: 'Longitud',
                squareMeters: 'Metros cuadrados',
                phone: 'Número de teléfono',
                status: 'Estado',
                chainId: 'ID de cadena',
                chain: 'Cadena'
            },
            tabs: {
                general: 'General',
                transactions: 'Transacciones',
                groups: 'Grupos',
                users: 'Usuarios'
            },
            crud: {
                create: {
                    notification: {
                        success: 'Tienda creada con éxito',
                        failed: 'Error: la tienda no pudo ser creada'
                    },
                    validation: {
                        storeCodeNotUnique: 'Ya existe una tienda con este código'
                    }
                },
                edit: {
                    notification: {
                        success: 'Tienda editada con éxito',
                        failed: 'Error: la tienda no pudo ser editada'
                    },
                    validation: {
                        storeCodeNotUnique: 'Ya existe otra tienda con este código'
                    }
                },
                delete: {
                    confirmTitle: 'Eliminar tienda',
                    confirmContent: '¿Seguro que quiere eliminar esta tienda?',
                    notification: {
                        success: 'Tienda eliminada con éxito',
                        failed: 'Error: la tienda no pudo ser eliminada'
                    }
                },
                bulkDelete: {
                    confirmTitle: 'Eliminar tienda |||| Eliminar %{smart_count} tiendas',
                    confirmContent: '¿Seguro que quiere eliminar esta tienda? |||| ¿Seguro que quiere eliminar estas %{smart_count} tiendas?',
                    notification: {
                        success: 'Tienda eliminada con éxito |||| Tiendas eliminadas con éxito',
                        failed: 'Error: la tienda no pudo ser eliminada |||| Error: las tiendas no pudieron ser eliminadas'
                    }
                }
            }
        },
        terminalTypes: {
            name: 'Tipo de terminal |||| Tipos de terminal',
            fields: {
                id: 'ID del tipo de terminal',
                name: 'Nombre',
                chainId: 'ID de cadena',
                chain: 'Cadena'
            },
            tabs: {
                general: 'General',
                terminalNumberTypes: 'N° Terminales -> Tipo'
            },
            crud: {
                create: {
                    terminalTypeNumbers: 'Asociar Tiendas con Números de Terminales',
                    notification: {
                        success: 'Tipo de terminal creado con éxito',
                        failed: 'Error: el tipo de terminal no pudo ser creado'
                    },
                    validation: {
                        nameChainIdUnique: 'Ya existe un tipo de terminal con este nombre y cadena',
                        numbers: {
                            isNotNumber: 'Hay al menos un número de terminal que no es numérico',
                            outOfRange: 'Hay al menos un número de terminal fuera del rango (0 a 2147483647)',
                            isNotArray: 'Se deben indicar uno o varios números de terminal (presione "Enter" en el campo para confirmar el último)'
                        },
                        terminalNumbersStoreCodeUnique: 'Hay al menos un número de terminal que ya está presente en un tipo de terminal para el código de tienda indicado'
                    }
                },
                edit: {
                    terminalTypeNumbers: 'Asociar Tiendas con Números de Terminales',
                    notification: {
                        success: 'Tipo de terminal editado con éxito',
                        failed: 'Error: el tipo de terminal no pudo ser editado'
                    },
                    validation: {
                        nameChainIdUnique: 'Ya existe otro tipo de terminal con este nombre y cadena',
                        numbers: {
                            isNotNumber: 'Hay al menos un número de terminal que no es numérico',
                            outOfRange: 'Hay al menos un número de terminal fuera del rango (0 a 2147483647)',
                            isNotArray: 'Se deben indicar uno o varios números de terminal (presione "Enter" en el campo para confirmar el último)'
                        },
                        terminalNumbersStoreCodeUnique: 'Hay al menos un número de terminal que ya está presente en otro tipo de terminal para el código de tienda indicado'
                    }
                },
                delete: {
                    confirmTitle: 'Eliminar tipo de terminal',
                    confirmContent: '¿Seguro que quiere eliminar este tipo de terminal?',
                    notification: {
                        success: 'Tipo de terminal eliminado con éxito',
                        failed: 'Error: el tipo de terminal no pudo ser eliminado'
                    }
                },
                bulkDelete: {
                    confirmTitle: 'Eliminar tipo de terminal |||| Eliminar %{smart_count} tipos de terminal',
                    confirmContent: '¿Seguro que quiere eliminar este tipo de terminal? |||| ¿Seguro que quiere eliminar estos %{smart_count} tipos de terminal?',
                    notification: {
                        success: 'Tipo de terminal eliminado con éxito |||| Tipos de terminal eliminados con éxito',
                        failed: 'Error: el tipo de terminal no pudo ser eliminado |||| Error: los tipos de terminal no pudieron ser eliminados'
                    }
                }
            }
        },
        terminalNumberTypes: {
            name: 'N° Terminal -> Tipo |||| N° Terminales -> Tipo',
            fields: {
                id: 'ID del N° terminal -> tipo',
                terminalNumber: 'Número de terminal |||| Números de terminal',
                storeCode: 'Código de tienda',
                terminalTypeId: 'ID de tipo de terminal',
                terminalType: 'Tipo de terminal'
            }
        },
        groups: {
            name: 'Grupo |||| Grupos',
            fields: {
                id: 'ID de grupo',
                name: 'Nombre',
                chainId: 'ID de cadena',
                chain: 'Cadena',
                parentId: 'ID de grupo padre',
                parent: 'Grupo padre',
                stores: {
                    name: 'Tiendas'
                },
                users: {
                    name: 'Usuarios'
                }
            },
            tabs: {
                general: 'General',
                children: 'Grupos Hijos',
                stores: 'Tiendas',
                users: 'Usuarios'
            },
            crud: {
                create: {
                    notification: {
                        success: 'Grupo creado con éxito',
                        failed: 'Error: el grupo no pudo ser creado'
                    },
                    validation: {
                        storesNotInChain: 'Hay tiendas que no pertenecen a la cadena especificada'
                    }
                },
                edit: {
                    notification: {
                        success: 'Grupo editado con éxito',
                        failed: 'Error: el grupo no pudo ser editado'
                    },
                    validation: {
                        storesNotInChain: 'Hay tiendas que no pertenecen a la cadena especificada'
                    }
                },
                delete: {
                    confirmTitle: 'Eliminar grupo',
                    confirmContent: '¿Seguro que quiere eliminar este grupo?',
                    notification: {
                        success: 'Grupo eliminado con éxito',
                        failed: 'Error: el grupo no pudo ser eliminado'
                    }
                },
                bulkDelete: {
                    confirmTitle: 'Eliminar grupo |||| Eliminar %{smart_count} grupos',
                    confirmContent: '¿Seguro que quiere eliminar este grupo? |||| ¿Seguro que quiere eliminar estos %{smart_count} grupos?',
                    notification: {
                        success: 'Grupo eliminado con éxito |||| Grupos eliminados con éxito',
                        failed: 'Error: el grupo no pudo ser eliminado |||| Error: los grupos no pudieron ser eliminados'
                    }
                }
            }
        },
        operators: {
            name: 'Operador |||| Operadores',
            fields: {
                id: 'ID de operador',
                operatorCode: 'Código de operador',
                name: 'Nombre',
                type: {
                    name: 'Tipo',
                    options: {
                        '00': 'Cajero',
                        '01': 'Jefe de cajas',
                    }
                },
                inReport: 'Incluir en reporte',
                cLastTransactionBusinessDayDate: 'Última transacción',
                chainId: 'ID de cadena',
                chain: 'Cadena',
                stores: {
                    name: 'Tiendas'
                },
            },
            tabs: {
                general: 'General',
                transactions: 'Transacciones',
                stores: 'Tiendas'
            },
            crud: {
                create: {
                    notification: {
                        success: 'Operador creado con éxito',
                        failed: 'Error: el operador no pudo ser creado'
                    },
                    validation: {
                        operatorCodeNameChainIdUnique: 'Ya existe un operador con este código, nombre y cadena',
                        storesNotInChain: 'Hay tiendas que no pertenecen a la cadena especificada'
                    }
                },
                edit: {
                    notification: {
                        success: 'Operador editado con éxito',
                        failed: 'Error: el operador no pudo ser editado'
                    },
                    validation: {
                        operatorCodeNameChainIdUnique: 'Ya existe otro operador con este código, nombre y cadena',
                        storesNotInChain: 'Hay tiendas que no pertenecen a la cadena especificada'
                    }
                },
                delete: {
                    confirmTitle: 'Eliminar operador',
                    confirmContent: '¿Seguro que quiere eliminar este operador?',
                    notification: {
                        success: 'Operador eliminado con éxito',
                        failed: 'Error: el operador no pudo ser eliminado'
                    }
                },
                bulkEdit: {
                    includeInReport: 'Incluir en reporte',
                    excludeFromReport: 'Excluir del reporte',
                    confirmTitle: 'Editar operador |||| Editar %{smart_count} operadores',
                    confirmContent: '¿Seguro que quiere editar este operador? |||| ¿Seguro que quiere editar estos %{smart_count} operadores?',
                    notification: {
                        success: 'Operador editado con éxito |||| Operadores editados con éxito',
                        failed: 'Error: el operador no pudo ser editado |||| Error: los operadores no pudieron ser editados'
                    }
                },
                bulkDelete: {
                    confirmTitle: 'Eliminar operador |||| Eliminar %{smart_count} operadores',
                    confirmContent: '¿Seguro que quiere eliminar este operador? |||| ¿Seguro que quiere eliminar estos %{smart_count} operadores?',
                    notification: {
                        success: 'Operador eliminado con éxito |||| Operadores eliminados con éxito',
                        failed: 'Error: el operador no pudo ser eliminado |||| Error: los operadores no pudieron ser eliminados'
                    }
                }
            }
        },
        itemDetails: {
            name: 'Detalle de artículo |||| Detalles de artículos',
            fields: {
                id: 'ID de detalle de artículo',
                itemCode: 'Código de artículo',
                name: 'Nombre',
                chainId: 'ID de cadena',
                chain: 'Cadena'
            },
            tabs: {
                general: 'General'
            },
            crud: {
                create: {
                    notification: {
                        success: 'Detalle de artículo creado con éxito',
                        failed: 'Error: el detalle de artículo no pudo ser creado'
                    },
                    validation: {
                        itemCodeChainIdUnique: 'Ya existe un detalle de artículo con este código y cadena'
                    }
                },
                edit: {
                    notification: {
                        success: 'Detalle de artículo editado con éxito',
                        failed: 'Error: el detalle de artículo no pudo ser editado'
                    },
                    validation: {
                        itemCodeChainIdUnique: 'Ya existe otro detalle de artículo con este código y cadena'
                    }
                },
                delete: {
                    confirmTitle: 'Eliminar detalle de artículo',
                    confirmContent: '¿Seguro que quiere eliminar este detalle de artículo?',
                    notification: {
                        success: 'Detalle de artículo eliminado con éxito',
                        failed: 'Error: el detalle de artículo no pudo ser eliminado'
                    }
                },
                bulkDelete: {
                    confirmTitle: 'Eliminar detalle de artículo |||| Eliminar %{smart_count} detalles de artículos',
                    confirmContent: '¿Seguro que quiere eliminar este detalle de artículo? |||| ¿Seguro que quiere eliminar estos %{smart_count} detalles de artículos?',
                    notification: {
                        success: 'Detalle de artículo eliminado con éxito |||| Detalles de artículos eliminados con éxito',
                        failed: 'Error: el detalle de artículo no pudo ser eliminado |||| Error: los detalles de artículos no pudieron ser eliminados'
                    }
                }
            }
        },
        departments: {
            name: 'Departamento |||| Departamentos',
            fields: {
                id: 'ID de departamento',
                departmentCode: 'Código de departamento',
                name: 'Nombre',
                chainId: 'ID de cadena',
                chain: 'Cadena'
            },
            tabs: {
                general: 'General'
            },
            crud: {
                create: {
                    notification: {
                        success: 'Departamento creado con éxito',
                        failed: 'Error: el departamento no pudo ser creado'
                    },
                    validation: {
                        departmentCodeChainIdUnique: 'Ya existe un departamento con este código y cadena'
                    }
                },
                edit: {
                    notification: {
                        success: 'Departamento editado con éxito',
                        failed: 'Error: el departamento no pudo ser editado'
                    },
                    validation: {
                        departmentCodeChainIdUnique: 'Ya existe otro departamento con este código y cadena'
                    }
                },
                delete: {
                    confirmTitle: 'Eliminar departamento',
                    confirmContent: '¿Seguro que quiere eliminar este departamento?',
                    notification: {
                        success: 'Departamento eliminado con éxito',
                        failed: 'Error: el departamento no pudo ser eliminado'
                    }
                },
                bulkDelete: {
                    confirmTitle: 'Eliminar departamento |||| Eliminar %{smart_count} departamentos',
                    confirmContent: '¿Seguro que quiere eliminar este departamento? |||| ¿Seguro que quiere eliminar estos %{smart_count} departamentos?',
                    notification: {
                        success: 'Departamento eliminado con éxito |||| Departamentos eliminados con éxito',
                        failed: 'Error: el departamento no pudo ser eliminado |||| Error: los departamentos no pudieron ser eliminados'
                    }
                }
            }
        },
        files: {
            name: 'Archivo |||| Archivos',
            fields: {
                id: 'ID del archivo',
                originalName: 'Nombre',
                extension: 'Extensión',
                createdAt: 'Fecha de creación'
            },
            tabs: {
                general: 'General',
                chains: 'Cadenas',
                stores: 'Tiendas'
            },
            crud: {
                show: {
                    download: {
                        notification: {
                            success: 'Descarga exitosa',
                            failed: 'Error: descarga fallida, por favor vuelva a intentarlo'
                        }
                    }
                },
                bulkDelete: {
                    confirmTitle: 'Eliminar archivo |||| Eliminar %{smart_count} archivos',
                    confirmContent: '¿Seguro que quiere eliminar este archivo? |||| ¿Seguro que quiere eliminar estos %{smart_count} archivos?',
                    notification: {
                        success: 'Archivo eliminado con éxito |||| Archivos eliminados con éxito',
                        failed: 'Error: el archivo no pudo ser eliminado |||| Error: los archivos no pudieron ser eliminados'
                    }
                }
            }
        },
        users: {
            name: 'Usuario |||| Usuarios',
            fields: {
                id: 'ID de usuario',
                email: 'Correo electrónico',
                password: 'Contraseña',
                name: 'Nombre',
                lastname: 'Apellido',
                operatorCode: 'Código de operador',
                status: 'Estado',
                twoFaIsActive: 'Activar autenticación de doble factor',
                defaultRole: {
                    name: 'Rol base',
                    options: {
                        'GENERAL-ADMIN': 'Administrador General',
                        'CHAIN-ADMIN': 'Administrador de Cadena',
                        'ZONE-MANAGER': 'Jefe de Zona',
                        'STORE-ADMIN': 'Administrador de Tienda',
                        'CASH-MANAGER': 'Jefe de Cajas',
                        'CASHIER': 'Cajero',
                        'GENERAL-ROOT': 'Superusuario General',
                        'CHAIN-ROOT': 'Superusuario de Cadena',
                        'STORE-ROOT': 'Superusuario de Tienda',
                        'GENERAL-SUBSCRIBER': 'Suscriptor General',
                        'CHAIN-SUBSCRIBER': 'Suscriptor de Cadena',
                        'STORE-SUBSCRIBER': 'Suscriptor de Tienda'
                    }
                },
                userRole: {
                    subjects: {
                        statistics: 'Estadísticas',
                        healths: 'Estado de Salud',
                        transactions: 'Transacciones',
                        changes: 'Vueltos',
                        couponsInformations: 'Info. de Cupones',
                        customers: 'Clientes',
                        discounts: 'Descuentos',
                        epsTenders: 'Pagos Electrónicos (EPS)',
                        items: 'Artículos',
                        journals: 'Journals',
                        overrides: 'Anulación Admin./Operador',
                        taxes: 'Impuestos',
                        tenders: 'Formas de Pago',
                        dataEntries: 'Entradas de Datos',
                        tillChanges: 'Cambios de Gaveta',
                        userStrings: 'Cadenas de Usuario',
                        chains: 'Cadenas',
                        stores: 'Tiendas',
                        terminalTypes: 'Tipos de Terminal',
                        terminalNumberTypes: 'N° Terminal -> Tipo',
                        groups: 'Grupos de Tiendas',
                        operators: 'Operadores',
                        itemDetails: 'Detalles de Artículos',
                        departments: 'Departamentos',
                        files: 'Archivos',
                        users: 'Usuarios',
                    },
                    permissions: {
                        'GENERAL-ADMIN': {
                            'ga-find-statistics': 'Ver todas',
                            'ga-find-healths': 'Ver',
                            'ga-find-transactions': 'Listar y buscar',
                            'ga-get-transactions': 'Ver detalle',
                            'ga-find-changes': 'Listar y buscar',
                            'ga-get-changes': 'Ver detalle',
                            'ga-find-couponsInformations': 'Listar y buscar',
                            'ga-get-couponsInformations': 'Ver detalle',
                            'ga-find-customers': 'Listar y buscar',
                            'ga-get-customers': 'Ver detalle',
                            'ga-find-dataEntries': 'Listar y buscar',
                            'ga-get-dataEntries': 'Ver detalle',
                            'ga-find-discounts': 'Listar y buscar',
                            'ga-get-discounts': 'Ver detalle',
                            'ga-find-epsTenders': 'Listar y buscar',
                            'ga-get-epsTenders': 'Ver detalle',
                            'ga-find-items': 'Listar y buscar',
                            'ga-get-items': 'Ver detalle',
                            'ga-find-journals': 'Listar y buscar',
                            'ga-get-journals': 'Ver detalle',
                            'ga-find-overrides': 'Listar y buscar',
                            'ga-get-overrides': 'Ver detalle',
                            'ga-find-taxes': 'Listar y buscar',
                            'ga-get-taxes': 'Ver detalle',
                            'ga-find-tenders': 'Listar y buscar',
                            'ga-get-tenders': 'Ver detalle',
                            'ga-find-tillChanges': 'Listar y buscar',
                            'ga-get-tillChanges': 'Ver detalle',
                            'ga-find-userStrings': 'Listar y buscar',
                            'ga-get-userStrings': 'Ver detalle',
                            'ga-find-chains': 'Listar y buscar',
                            'ga-get-chains': 'Ver detalle',
                            'ga-create-chains': 'Crear',
                            'ga-update-chains': 'Editar',
                            'ga-find-stores': 'Listar y buscar',
                            'ga-get-stores': 'Ver detalle',
                            'ga-create-stores': 'Crear',
                            'ga-update-stores': 'Editar',
                            'ga-find-terminalTypes': 'Listar y buscar',
                            'ga-get-terminalTypes': 'Ver detalle',
                            'ga-create-terminalTypes': 'Crear',
                            'ga-update-terminalTypes': 'Editar',
                            'ga-find-terminalNumberTypes': 'Listar y buscar',
                            'ga-get-terminalNumberTypes': 'Ver detalle',
                            'ga-find-groups': 'Listar y buscar',
                            'ga-get-groups': 'Ver detalle',
                            'ga-find-operators': 'Listar y buscar',
                            'ga-get-operators': 'Ver detalle',
                            'ga-create-operators': 'Crear',
                            'ga-update-operators': 'Editar',
                            'ga-find-itemDetails': 'Listar y buscar',
                            'ga-get-itemDetails': 'Ver detalle',
                            'ga-find-departments': 'Listar y buscar',
                            'ga-get-departments': 'Ver detalle',
                            'ga-find-files': 'Listar y buscar',
                            'ga-get-files': 'Ver detalle',
                            'ga-find-users': 'Listar y buscar',
                            'ga-get-users': 'Ver detalle',
                            'ga-create-users': 'Crear: solo Administradores de Cadena (CHAIN-ADMIN)',
                            'ga-create-users-2': 'Crear: solo Jefes de Zona (ZONE-MANAGER)',
                            'ga-update-users': 'Editar: solo el suyo',
                            'ga-update-users-2': 'Editar: solo Administradores de Cadena (CHAIN-ADMIN)',
                            'ga-update-users-3': 'Editar: solo Jefes de Zona (ZONE-MANAGER)',
                        },
                        'CHAIN-ADMIN': {
                            'cha-find-statistics': 'Ver: solo de ventas generales de su cadena (agrupadas por: atributo, atributo y tiempo)',
                            'cha-find-statistics-2': 'Ver: solo de ventas generales de su cadena (agrupadas por: intervalo de tiempo)',
                            'cha-find-statistics-3': 'Ver: solo de ventas de artículos de su cadena (agrupadas por: atributo, atributo y tiempo; ' +
                                'artículos por transacción; artículos por transacción en el tiempo; promedio artículos por transacción; artículos digitados vs. escaneados)',
                            'cha-find-statistics-4': 'Ver: solo de formas de pago de su cadena',
                            'cha-find-statistics-5': 'Ver: solo de productividad del operador de su cadena (agrupados por: atributo, atributo y tiempo)',
                            'cha-find-statistics-6': 'Ver: solo de vueltos de su cadena',
                            'cha-find-statistics-7': 'Ver: solo de anulaciones de su cadena',
                            'cha-find-statistics-8': 'Ver: solo de vueltos de su cadena (agrupados por: atributo y tiempo)',
                            'cha-find-statistics-9': 'Ver: solo de pagos de su cadena (agrupados por: atributo)',
                            'cha-find-healths': 'Ver: solo de su cadena',
                            'cha-find-transactions': 'Listar y buscar: solo de su cadena',
                            'cha-get-transactions': 'Ver detalle: solo de su cadena',
                            'cha-find-changes': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-changes': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-couponsInformations': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-couponsInformations': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-customers': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-customers': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-dataEntries': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-dataEntries': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-discounts': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-discounts': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-epsTenders': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-epsTenders': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-items': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-items': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-journals': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-journals': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-overrides': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-overrides': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-taxes': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-taxes': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-tenders': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-tenders': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-tillChanges': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-tillChanges': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-userStrings': 'Listar y buscar: solo de transacciones de su cadena',
                            'cha-get-userStrings': 'Ver detalle: solo de transacciones de su cadena',
                            'cha-find-chains': 'Listar y buscar: solo su cadena',
                            'cha-get-chains': 'Ver detalle: solo de su cadena',
                            'cha-find-stores': 'Listar y buscar: solo de su cadena',
                            'cha-get-stores': 'Ver detalle: solo de su cadena',
                            'cha-create-stores': 'Crear: solo para su cadena',
                            'cha-update-stores': 'Editar: solo de su cadena',
                            'cha-find-terminalTypes': 'Listar y buscar: solo de su cadena',
                            'cha-get-terminalTypes': 'Ver detalle: solo de su cadena',
                            'cha-create-terminalTypes': 'Crear: solo para su cadena',
                            'cha-update-terminalTypes': 'Editar: solo de su cadena',
                            'cha-find-terminalNumberTypes': 'Listar y buscar: solo de tiendas de su cadena',
                            'cha-get-terminalNumberTypes': 'Ver detalle: solo de tiendas de su cadena',
                            'cha-find-groups': 'Listar y buscar: solo de su cadena',
                            'cha-get-groups': 'Ver detalle: solo de su cadena',
                            'cha-create-groups': 'Crear: solo para su cadena',
                            'cha-update-groups': 'Editar: solo de su cadena',
                            'cha-find-operators': 'Listar y buscar: solo de su cadena',
                            'cha-get-operators': 'Ver detalle: solo de su cadena',
                            'cha-create-operators': 'Crear: solo para su cadena',
                            'cha-update-operators': 'Editar: solo de su cadena',
                            'cha-find-itemDetails': 'Listar y buscar: solo de su cadena',
                            'cha-get-itemDetails': 'Ver detalle: solo de su cadena',
                            'cha-create-itemDetails': 'Crear: solo para su cadena',
                            'cha-update-itemDetails': 'Editar: solo de su cadena',
                            'cha-find-departments': 'Listar y buscar: solo de su cadena',
                            'cha-get-departments': 'Ver detalle: solo de su cadena',
                            'cha-create-departments': 'Crear: solo para su cadena',
                            'cha-update-departments': 'Editar: solo de su cadena',
                            'cha-find-files': 'Listar y buscar: solo de su cadena',
                            'cha-find-files-2': 'Listar y buscar: solo de tiendas de su cadena',
                            'cha-get-files': 'Ver detalle: solo de su cadena',
                            'cha-get-files-2': 'Ver detalle: solo de tiendas de su cadena',
                            'cha-find-users': 'Listar y buscar: solo de su cadena y el suyo',
                            'cha-find-users-2': 'Listar y buscar: solo de grupos de tiendas de su cadena',
                            'cha-find-users-3': 'Listar y buscar: solo de tiendas de su cadena',
                            'cha-get-users': 'Ver detalle: solo de su cadena y el suyo',
                            'cha-get-users-2': 'Ver detalle: solo de grupos de tiendas de su cadena',
                            'cha-get-users-3': 'Ver detalle: solo de tiendas de su cadena',
                            'cha-create-users': 'Crear: solo Jefes de Zona (ZONE-MANAGER) para grupos de tiendas de su cadena',
                            'cha-create-users-2': 'Crear: solo Administradores de Tienda (STORE-ADMIN) para tiendas de su cadena',
                            'cha-update-users': 'Editar: solo el suyo',
                            'cha-update-users-2': 'Editar: solo Jefes de Zona (ZONE-MANAGER) de grupos de tiendas de su cadena',
                            'cha-update-users-3': 'Editar: solo Administradores de Tienda (STORE-ADMIN) de tiendas de su cadena',
                        },
                        'ZONE-MANAGER': {
                            'zm-find-statistics': 'Ver: solo de ventas generales de su grupo de tiendas (agrupadas por: atributo, atributo y tiempo)',
                            'zm-find-statistics-2': 'Ver: solo de ventas generales de su grupo de tiendas (agrupadas por: intervalo de tiempo)',
                            'zm-find-statistics-3': 'Ver: solo de ventas de artículos de su grupo de tiendas (agrupadas por: atributo, atributo y tiempo; ' +
                                'artículos por transacción; artículos por transacción en el tiempo; promedio artículos por transacción; artículos digitados vs. escaneados)',
                            'zm-find-statistics-4': 'Ver: solo de formas de pago de su grupo de tiendas',
                            'zm-find-statistics-5': 'Ver: solo de productividad del operador de su grupo de tiendas (agrupados por: atributo, atributo y tiempo)',
                            'zm-find-statistics-6': 'Ver: solo de vueltos de su grupo de tiendas',
                            'zm-find-statistics-7': 'Ver: solo de anulaciones de su grupo de tiendas',
                            'zm-find-statistics-8': 'Ver: solo de vueltos de su grupo de tiendas (agrupados por: atributo y tiempo)',
                            'zm-find-statistics-9': 'Ver: solo de pagos de su grupo de tiendas (agrupados por: atributo)',
                            'zm-find-healths': 'Ver: solo de su grupo de tiendas',
                            'zm-find-transactions': 'Listar y buscar: solo de su grupo de tiendas',
                            'zm-get-transactions': 'Ver detalle: solo de su grupo de tiendas',
                            'zm-find-changes': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-changes': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-couponsInformations': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-couponsInformations': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-customers': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-customers': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-dataEntries': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-dataEntries': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-discounts': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-discounts': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-epsTenders': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-epsTenders': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-items': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-items': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-journals': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-journals': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-overrides': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-overrides': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-taxes': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-taxes': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-tenders': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-tenders': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-tillChanges': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-tillChanges': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-userStrings': 'Listar y buscar: solo de transacciones de su grupo de tiendas',
                            'zm-get-userStrings': 'Ver detalle: solo de transacciones de su grupo de tiendas',
                            'zm-find-chains': 'Listar y buscar: solo su cadena',
                            'zm-get-chains': 'Ver detalle: solo de su cadena',
                            'zm-find-stores': 'Listar y buscar: solo de su grupo de tiendas',
                            'zm-get-stores': 'Ver detalle: solo de su grupo de tiendas',
                            'zm-find-terminalTypes': 'Listar y buscar: solo de su cadena',
                            'zm-get-terminalTypes': 'Ver detalle: solo de su cadena',
                            'zm-create-terminalTypes': 'Crear: solo para su cadena',
                            'zm-find-terminalNumberTypes': 'Listar y buscar: solo de su grupo de tiendas',
                            'zm-get-terminalNumberTypes': 'Ver detalle: solo de su grupo de tiendas',
                            'zm-find-groups': 'Listar y buscar: solo su grupo de tiendas',
                            'zm-find-groups-2': 'Listar y buscar: solo grupo padre de su grupo de tiendas',
                            'zm-get-groups': 'Ver detalle: solo de su grupo de tiendas',
                            'zm-get-groups-2': 'Ver detalle: solo grupo padre de su grupo de tiendas',
                            'zm-find-operators': 'Listar y buscar: solo de su grupo de tiendas',
                            'zm-get-operators': 'Ver detalle: solo de su grupo de tiendas',
                            'zm-find-itemDetails': 'Listar y buscar: solo de su cadena',
                            'zm-get-itemDetails': 'Ver detalle: solo de su cadena',
                            'zm-find-departments': 'Listar y buscar: solo de su cadena',
                            'zm-get-departments': 'Ver detalle: solo de su cadena',
                            'zm-find-files': 'Listar y buscar: solo de su grupo de tiendas',
                            'zm-get-files': 'Ver detalle: solo de su grupo de tiendas',
                            'zm-find-users': 'Listar y buscar: solo de su grupo de tiendas y el suyo',
                            'zm-find-users-2': 'Listar y buscar: solo de tiendas de su grupo de tiendas',
                            'zm-get-users': 'Ver detalle: solo de su grupo de tiendas y el suyo',
                            'zm-get-users-2': 'Ver detalle: solo de tiendas de su grupo de tiendas',
                            'zm-create-users': 'Crear: solo Superusuarios de Tienda (STORE-ROOT) y Administradores de Tienda (STORE-ADMIN) para tiendas de su grupo de tiendas',
                            'zm-update-users': 'Editar: solo el suyo',
                            'zm-update-users-2': 'Editar: solo Superusuarios de Tienda (STORE-ROOT) y Administradores de Tienda (STORE-ADMIN) de tiendas de su grupo de tiendas',
                        },
                        'STORE-ADMIN': {
                            'sa-find-statistics': 'Ver: solo de ventas generales de su tienda (agrupadas por: atributo, atributo y tiempo)',
                            'sa-find-statistics-2': 'Ver: solo de ventas generales de su tienda (agrupadas por: intervalo de tiempo)',
                            'sa-find-statistics-3': 'Ver: solo de ventas de artículos de su tienda (agrupadas por: atributo, atributo y tiempo; ' +
                                'artículos por transacción; artículos por transacción en el tiempo; promedio artículos por transacción; artículos digitados vs. escaneados)',
                            'sa-find-statistics-4': 'Ver: solo de formas de pago de su tienda',
                            'sa-find-statistics-5': 'Ver: solo de productividad del operador de su tienda (agrupados por: atributo, atributo y tiempo)',
                            'sa-find-statistics-6': 'Ver: solo de vueltos de su tienda',
                            'sa-find-statistics-7': 'Ver: solo de anulaciones de su tienda',
                            'sa-find-statistics-8': 'Ver: solo de vueltos de su tienda (agrupados por: atributo y tiempo)',
                            'sa-find-statistics-9': 'Ver: solo de pagos de su tienda (agrupados por: atributo)',
                            'sa-find-healths': 'Ver: solo de su tienda',
                            'sa-find-transactions': 'Listar y buscar: solo de su tienda',
                            'sa-get-transactions': 'Ver detalle: solo de su tienda',
                            'sa-update-transactions': 'Editar: solo de su tienda',
                            'sa-find-changes': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-changes': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-couponsInformations': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-couponsInformations': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-customers': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-customers': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-dataEntries': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-dataEntries': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-discounts': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-discounts': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-epsTenders': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-epsTenders': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-items': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-items': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-journals': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-journals': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-overrides': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-overrides': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-taxes': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-taxes': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-tenders': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-tenders': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-tillChanges': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-tillChanges': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-userStrings': 'Listar y buscar: solo de transacciones de su tienda',
                            'sa-get-userStrings': 'Ver detalle: solo de transacciones de su tienda',
                            'sa-find-chains': 'Listar y buscar: solo su cadena',
                            'sa-get-chains': 'Ver detalle: solo de su cadena',
                            'sa-find-stores': 'Listar y buscar: solo su tienda',
                            'sa-get-stores': 'Ver detalle: solo de su tienda',
                            'sa-find-terminalTypes': 'Listar y buscar: solo de su cadena',
                            'sa-get-terminalTypes': 'Ver detalle: solo de su cadena',
                            'sa-create-terminalTypes': 'Crear: solo para su cadena',
                            'sa-find-terminalNumberTypes': 'Listar y buscar: solo de su tienda',
                            'sa-get-terminalNumberTypes': 'Ver detalle: solo de su tienda',
                            'sa-find-operators': 'Listar y buscar: solo de su tienda',
                            'sa-get-operators': 'Ver detalle: solo de su tienda',
                            'sa-create-operators': 'Crear: solo para su tienda',
                            'sa-find-itemDetails': 'Listar y buscar: solo de su cadena',
                            'sa-get-itemDetails': 'Ver detalle: solo de su cadena',
                            'sa-find-departments': 'Listar y buscar: solo de su cadena',
                            'sa-get-departments': 'Ver detalle: solo de su cadena',
                            'sa-find-files': 'Listar y buscar: solo de su tienda',
                            'sa-get-files': 'Ver detalle: solo de su tienda',
                            'sa-find-users': 'Listar y buscar: solo de su tienda y el suyo',
                            'sa-get-users': 'Ver detalle: solo de su tienda y el suyo',
                            'sa-create-users': 'Crear: solo Jefes de Caja (CASH-MANAGER) y Cajeros (CASHIER) para su tienda',
                            'sa-update-users': 'Editar: solo el suyo',
                            'sa-update-users-2': 'Editar: solo Jefes de Caja (CASH-MANAGER) y Cajeros (CASHIER) de su tienda',
                        },
                        'CASH-MANAGER': {
                            'cm-find-statistics': 'Ver: solo de ventas generales de su tienda (agrupadas por: atributo, atributo y tiempo)',
                            'cm-find-statistics-2': 'Ver: solo de ventas generales de su tienda (agrupadas por: intervalo de tiempo)',
                            'cm-find-statistics-3': 'Ver: solo de ventas de artículos de su tienda (agrupadas por: atributo, atributo y tiempo; ' +
                                'artículos por transacción; artículos por transacción en el tiempo; promedio artículos por transacción; artículos digitados vs. escaneados)',
                            'cm-find-statistics-4': 'Ver: solo de formas de pago de su tienda',
                            'cm-find-statistics-5': 'Ver: solo de productividad del operador de su tienda (agrupados por: atributo, atributo y tiempo)',
                            'cm-find-statistics-6': 'Ver: solo de vueltos de su tienda',
                            'cm-find-statistics-7': 'Ver: solo de anulaciones de su tienda',
                            'cm-find-statistics-8': 'Ver: solo de vueltos de su tienda (agrupados por: atributo y tiempo)',
                            'cm-find-statistics-9': 'Ver: solo de pagos de su tienda (agrupados por: atributo)',
                            'cm-find-healths': 'Ver: solo de su tienda',
                            'cm-find-transactions': 'Listar y buscar: solo de su tienda',
                            'cm-get-transactions': 'Ver detalle: solo de su tienda',
                            'cm-find-changes': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-changes': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-couponsInformations': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-couponsInformations': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-customers': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-customers': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-dataEntries': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-dataEntries': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-discounts': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-discounts': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-epsTenders': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-epsTenders': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-items': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-items': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-journals': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-journals': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-overrides': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-overrides': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-taxes': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-taxes': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-tenders': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-tenders': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-tillChanges': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-tillChanges': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-userStrings': 'Listar y buscar: solo de transacciones de su tienda',
                            'cm-get-userStrings': 'Ver detalle: solo de transacciones de su tienda',
                            'cm-find-chains': 'Listar y buscar: solo su cadena',
                            'cm-get-chains': 'Ver detalle: solo de su cadena',
                            'cm-find-stores': 'Listar y buscar: solo su tienda',
                            'cm-get-stores': 'Ver detalle: solo de su tienda',
                            'cm-find-terminalTypes': 'Listar y buscar: solo de su cadena',
                            'cm-get-terminalTypes': 'Ver detalle: solo de su cadena',
                            'cm-create-terminalTypes': 'Crear: solo para su cadena',
                            'cm-find-terminalNumberTypes': 'Listar y buscar: solo de su tienda',
                            'cm-get-terminalNumberTypes': 'Ver detalle: solo de su tienda',
                            'cm-find-operators': 'Listar y buscar: solo de su tienda',
                            'cm-get-operators': 'Ver detalle: solo de su tienda',
                            'cm-create-operators': 'Crear: solo para su tienda',
                            'cm-find-itemDetails': 'Listar y buscar: solo de su cadena',
                            'cm-get-itemDetails': 'Ver detalle: solo de su cadena',
                            'cm-find-departments': 'Listar y buscar: solo de su cadena',
                            'cm-get-departments': 'Ver detalle: solo de su cadena',
                            'cm-find-files': 'Listar y buscar: solo de su tienda',
                            'cm-get-files': 'Ver detalle: solo de su tienda',
                            'cm-find-users': 'Listar y buscar: solo de su tienda y el suyo',
                            'cm-get-users': 'Ver detalle: solo de su tienda y el suyo',
                            'cm-update-users': 'Editar: solo el suyo',
                        },
                        'CASHIER': {
                            'c-find-statistics': 'Ver: solo de ventas generales de su código de operador (agrupadas por: atributo, atributo y tiempo)',
                            'c-find-statistics-2': 'Ver: solo de ventas generales de su código de operador (agrupadas por: intervalo de tiempo)',
                            'c-find-statistics-3': 'Ver: solo de ventas de artículos de su código de operador (agrupadas por: atributo, atributo y tiempo; ' +
                                'artículos por transacción; artículos por transacción en el tiempo; promedio artículos por transacción; artículos digitados vs. escaneados)',
                            'c-find-statistics-4': 'Ver: solo de formas de pago de su código de operador',
                            'c-find-statistics-5': 'Ver: solo de productividad del operador de su código de operador (agrupados por: atributo, atributo y tiempo)',
                            'c-find-statistics-6': 'Ver: solo de vueltos de su código de operador',
                            'c-find-statistics-7': 'Ver: solo de anulaciones de su código de operador',
                            'c-find-statistics-8': 'Ver: solo de vueltos de su código de operador (agrupados por: atributo y tiempo)',
                            'c-find-statistics-9': 'Ver: solo de pagos de su código de operador (agrupados por: atributo)',
                            'c-find-healths': 'Ver: solo de su código de operador',
                            'c-find-transactions': 'Listar y buscar: solo de su código de operador',
                            'c-get-transactions': 'Ver detalle: solo de su código de operador',
                            'c-create-transactions': 'Crear: solo para su tienda y código de operador',
                            'c-find-changes': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-changes': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-couponsInformations': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-couponsInformations': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-customers': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-customers': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-dataEntries': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-dataEntries': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-discounts': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-discounts': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-epsTenders': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-epsTenders': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-items': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-items': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-journals': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-journals': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-overrides': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-overrides': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-taxes': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-taxes': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-tenders': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-tenders': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-tillChanges': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-tillChanges': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-userStrings': 'Listar y buscar: solo de transacciones de su código de operador',
                            'c-get-userStrings': 'Ver detalle: solo de transacciones de su código de operador',
                            'c-find-chains': 'Listar y buscar: solo su cadena',
                            'c-get-chains': 'Ver detalle: solo de su cadena',
                            'c-find-stores': 'Listar y buscar: solo su tienda',
                            'c-find-terminalTypes': 'Listar y buscar: solo de su cadena',
                            'c-get-terminalTypes': 'Ver detalle: solo de su cadena',
                            'c-find-terminalNumberTypes': 'Listar y buscar: solo de su tienda',
                            'c-get-terminalNumberTypes': 'Ver detalle: solo de su tienda',
                            'c-get-operators': 'Ver detalle: solo el suyo',
                            'c-find-itemDetails': 'Listar y buscar: solo de su cadena',
                            'c-get-itemDetails': 'Ver detalle: solo de su cadena',
                            'c-find-departments': 'Listar y buscar: solo de su cadena',
                            'c-get-departments': 'Ver detalle: solo de su cadena',
                            'c-get-stores': 'Ver detalle: solo de su tienda',
                            'c-get-users': 'Ver detalle: solo el suyo',
                            'c-update-users': 'Editar: solo el suyo',
                        },
                        'GENERAL-ROOT': {
                            'gr-find-statistics': 'Ver todas',
                            'gr-find-healths': 'Ver',
                            'gr-find-transactions': 'Listar y buscar',
                            'gr-get-transactions': 'Ver detalle',
                            'gr-find-changes': 'Listar y buscar',
                            'gr-get-changes': 'Ver detalle',
                            'gr-find-couponsInformations': 'Listar y buscar',
                            'gr-get-couponsInformations': 'Ver detalle',
                            'gr-find-customers': 'Listar y buscar',
                            'gr-get-customers': 'Ver detalle',
                            'gr-find-dataEntries': 'Listar y buscar',
                            'gr-get-dataEntries': 'Ver detalle',
                            'gr-find-discounts': 'Listar y buscar',
                            'gr-get-discounts': 'Ver detalle',
                            'gr-find-epsTenders': 'Listar y buscar',
                            'gr-get-epsTenders': 'Ver detalle',
                            'gr-find-items': 'Listar y buscar',
                            'gr-get-items': 'Ver detalle',
                            'gr-find-journals': 'Listar y buscar',
                            'gr-get-journals': 'Ver detalle',
                            'gr-find-overrides': 'Listar y buscar',
                            'gr-get-overrides': 'Ver detalle',
                            'gr-find-taxes': 'Listar y buscar',
                            'gr-get-taxes': 'Ver detalle',
                            'gr-find-tenders': 'Listar y buscar',
                            'gr-get-tenders': 'Ver detalle',
                            'gr-find-tillChanges': 'Listar y buscar',
                            'gr-get-tillChanges': 'Ver detalle',
                            'gr-find-userStrings': 'Listar y buscar',
                            'gr-get-userStrings': 'Ver detalle',
                            'gr-find-chains': 'Listar y buscar',
                            'gr-get-chains': 'Ver detalle',
                            'gr-create-chains': 'Crear',
                            'gr-update-chains': 'Editar',
                            'gr-remove-chains': 'Eliminar',
                            'gr-find-stores': 'Listar y buscar',
                            'gr-get-stores': 'Ver detalle',
                            'gr-create-stores': 'Crear',
                            'gr-update-stores': 'Editar',
                            'gr-remove-stores': 'Eliminar',
                            'gr-find-terminalTypes': 'Listar y buscar',
                            'gr-get-terminalTypes': 'Ver detalle',
                            'gr-create-terminalTypes': 'Crear',
                            'gr-update-terminalTypes': 'Editar',
                            'gr-remove-terminalTypes': 'Eliminar',
                            'gr-find-terminalNumberTypes': 'Listar y buscar',
                            'gr-get-terminalNumberTypes': 'Ver detalle',
                            'gr-find-groups': 'Listar y buscar',
                            'gr-get-groups': 'Ver detalle',
                            'gr-create-groups': 'Crear',
                            'gr-update-groups': 'Editar',
                            'gr-remove-groups': 'Eliminar',
                            'gr-find-operators': 'Listar y buscar',
                            'gr-get-operators': 'Ver detalle',
                            'gr-create-operators': 'Crear',
                            'gr-update-operators': 'Editar',
                            'gr-remove-operators': 'Eliminar',
                            'gr-find-itemDetails': 'Listar y buscar',
                            'gr-get-itemDetails': 'Ver detalle',
                            'gr-create-itemDetails': 'Crear',
                            'gr-update-itemDetails': 'Editar',
                            'gr-remove-itemDetails': 'Eliminar',
                            'gr-find-departments': 'Listar y buscar',
                            'gr-get-departments': 'Ver detalle',
                            'gr-create-departments': 'Crear',
                            'gr-update-departments': 'Editar',
                            'gr-remove-departments': 'Eliminar',
                            'gr-find-files': 'Listar y buscar',
                            'gr-get-files': 'Ver detalle',
                            'gr-remove-files': 'Eliminar',
                            'gr-find-users': 'Listar y buscar',
                            'gr-get-users': 'Ver detalle',
                            'gr-create-users': 'Crear: solo Administradores Generales (GENERAL-ADMIN) y Suscriptores Generales (GENERAL-SUBSCRIBER)',
                            'gr-create-users-2': 'Crear: solo Superusuarios de Cadena (CHAIN-ROOT), Administradores de Cadena (CHAIN-ADMIN) y Suscriptores de Cadena (CHAIN-SUBSCRIBER)',
                            'gr-create-users-3': 'Crear: solo Jefes de Zona (ZONE-MANAGER)',
                            'gr-create-users-4': 'Crear: solo Superusuarios de Tienda (STORE-ROOT), Administradores de Tienda (STORE-ADMIN), Suscriptores de Tienda (STORE-SUBSCRIBER), Jefes de Caja (CASH-MANAGER) y Cajeros (CASHIER)',
                            'gr-update-users': 'Editar: solo el suyo',
                            'gr-update-users-2': 'Editar: solo Administradores Generales (GENERAL-ADMIN) y Suscriptores Generales (GENERAL-SUBSCRIBER)',
                            'gr-update-users-3': 'Editar: solo Superusuarios de Cadena (CHAIN-ROOT), Administradores de Cadena (CHAIN-ADMIN) y Suscriptores de Cadena (CHAIN-SUBSCRIBER)',
                            'gr-update-users-4': 'Editar: solo Jefes de Zona (ZONE-MANAGER)',
                            'gr-update-users-5': 'Editar: solo Superusuarios de Tienda (STORE-ROOT), Administradores de Tienda (STORE-ADMIN), Suscriptores de Tienda (STORE-SUBSCRIBER), Jefes de Caja (CASH-MANAGER) y Cajeros (CASHIER)',
                            'gr-remove-users': 'Eliminar: excepto el suyo',
                        },
                        'CHAIN-ROOT': {
                            'chr-find-statistics': 'Ver: solo de ventas generales de su cadena (agrupadas por: atributo, atributo y tiempo)',
                            'chr-find-statistics-2': 'Ver: solo de ventas generales de su cadena (agrupadas por: intervalo de tiempo)',
                            'chr-find-statistics-3': 'Ver: solo de ventas de artículos de su cadena (agrupadas por: atributo, atributo y tiempo; ' +
                                'artículos por transacción; artículos por transacción en el tiempo; promedio artículos por transacción; artículos digitados vs. escaneados)',
                            'chr-find-statistics-4': 'Ver: solo de formas de pago de su cadena',
                            'chr-find-statistics-5': 'Ver: solo de productividad del operador de su cadena (agrupados por: atributo, atributo y tiempo)',
                            'chr-find-statistics-6': 'Ver: solo de vueltos de su cadena',
                            'chr-find-statistics-7': 'Ver: solo de anulaciones de su cadena',
                            'chr-find-statistics-8': 'Ver: solo de vueltos de su cadena (agrupados por: atributo y tiempo)',
                            'chr-find-statistics-9': 'Ver: solo de pagos de su cadena (agrupados por: atributo)',
                            'chr-find-healths': 'Ver: solo de su cadena',
                            'chr-find-transactions': 'Listar y buscar: solo de su cadena',
                            'chr-get-transactions': 'Ver detalle: solo de su cadena',
                            'chr-find-changes': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-changes': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-couponsInformations': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-couponsInformations': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-customers': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-customers': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-dataEntries': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-dataEntries': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-discounts': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-discounts': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-epsTenders': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-epsTenders': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-items': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-items': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-journals': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-journals': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-overrides': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-overrides': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-taxes': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-taxes': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-tenders': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-tenders': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-tillChanges': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-tillChanges': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-userStrings': 'Listar y buscar: solo de transacciones de su cadena',
                            'chr-get-userStrings': 'Ver detalle: solo de transacciones de su cadena',
                            'chr-find-chains': 'Listar y buscar: solo su cadena',
                            'chr-get-chains': 'Ver detalle: solo de su cadena',
                            'chr-update-chains': 'Editar: solo su cadena',
                            'chr-find-stores': 'Listar y buscar: solo de su cadena',
                            'chr-get-stores': 'Ver detalle: solo de su cadena',
                            'chr-create-stores': 'Crear: solo para su cadena',
                            'chr-update-stores': 'Editar: solo de su cadena',
                            'chr-remove-stores': 'Eliminar: solo de su cadena',
                            'chr-find-terminalTypes': 'Listar y buscar: solo de su cadena',
                            'chr-get-terminalTypes': 'Ver detalle: solo de su cadena',
                            'chr-create-terminalTypes': 'Crear: solo para su cadena',
                            'chr-update-terminalTypes': 'Editar: solo de su cadena',
                            'chr-remove-terminalTypes': 'Eliminar: solo de su cadena',
                            'chr-find-terminalNumberTypes': 'Listar y buscar: solo de tiendas de su cadena',
                            'chr-get-terminalNumberTypes': 'Ver detalle: solo de tiendas de su cadena',
                            'chr-find-groups': 'Listar y buscar: solo de su cadena',
                            'chr-get-groups': 'Ver detalle: solo de su cadena',
                            'chr-create-groups': 'Crear: solo para su cadena',
                            'chr-update-groups': 'Editar: solo de su cadena',
                            'chr-remove-groups': 'Eliminar: solo de su cadena',
                            'chr-find-operators': 'Listar y buscar: solo de su cadena',
                            'chr-get-operators': 'Ver detalle: solo de su cadena',
                            'chr-create-operators': 'Crear: solo para su cadena',
                            'chr-update-operators': 'Editar: solo de su cadena',
                            'chr-remove-operators': 'Eliminar: solo de su cadena',
                            'chr-find-itemDetails': 'Listar y buscar: solo de su cadena',
                            'chr-get-itemDetails': 'Ver detalle: solo de su cadena',
                            'chr-create-itemDetails': 'Crear: solo para su cadena',
                            'chr-update-itemDetails': 'Editar: solo de su cadena',
                            'chr-remove-itemDetails': 'Eliminar: solo de su cadena',
                            'chr-find-departments': 'Listar y buscar: solo de su cadena',
                            'chr-get-departments': 'Ver detalle: solo de su cadena',
                            'chr-create-departments': 'Crear: solo para su cadena',
                            'chr-update-departments': 'Editar: solo de su cadena',
                            'chr-remove-departments': 'Eliminar: solo de su cadena',
                            'chr-find-files': 'Listar y buscar: solo de su cadena',
                            'chr-find-files-2': 'Listar y buscar: solo de tiendas de su cadena',
                            'chr-get-files': 'Ver detalle: solo de su cadena',
                            'chr-get-files-2': 'Ver detalle: solo de tiendas de su cadena',
                            'chr-find-users': 'Listar y buscar: solo de su cadena y el suyo',
                            'chr-find-users-2': 'Listar y buscar: solo de grupos de tiendas de su cadena',
                            'chr-find-users-3': 'Listar y buscar: solo de tiendas de su cadena',
                            'chr-get-users': 'Ver detalle: solo de su cadena y el suyo',
                            'chr-get-users-2': 'Ver detalle: solo de grupos de tiendas de su cadena',
                            'chr-get-users-3': 'Ver detalle: solo de tiendas de su cadena',
                            'chr-create-users': 'Crear: solo Administradores de Cadena (CHAIN-ADMIN) y Suscriptores de Cadena (CHAIN-SUBSCRIBER) para su cadena',
                            'chr-create-users-2': 'Crear: solo Jefes de Zona (ZONE-MANAGER) para grupos de tiendas de su cadena',
                            'chr-create-users-3': 'Crear: solo Superusuarios de Tienda (STORE-ROOT), Administradores de Tienda (STORE-ADMIN), Suscriptores de Tienda (STORE-SUBSCRIBER), Jefes de Caja (CASH-MANAGER) y Cajeros (CASHIER) para tiendas de su cadena',
                            'chr-update-users': 'Editar: solo el suyo',
                            'chr-update-users-2': 'Editar: solo Administradores de Cadena (CHAIN-ADMIN) y Suscriptores de Cadena (CHAIN-SUBSCRIBER) de su cadena',
                            'chr-update-users-3': 'Editar: solo Jefes de Zona (ZONE-MANAGER) de grupos de tiendas de su cadena',
                            'chr-update-users-4': 'Editar: solo Superusuarios de Tienda (STORE-ROOT), Administradores de Tienda (STORE-ADMIN), Suscriptores de Tienda (STORE-SUBSCRIBER), Jefes de Caja (CASH-MANAGER) y Cajeros (CASHIER) de tiendas de su cadena',
                            'chr-remove-users': 'Eliminar: solo de su cadena, excepto el suyo',
                            'chr-remove-users-2': 'Eliminar: solo de grupos de tiendas de su cadena',
                            'chr-remove-users-3': 'Eliminar: solo de tiendas de su cadena',
                        },
                        'STORE-ROOT': {
                            'sr-find-statistics': 'Ver: solo de ventas generales de su tienda (agrupadas por: atributo, atributo y tiempo)',
                            'sr-find-statistics-2': 'Ver: solo de ventas generales de su tienda (agrupadas por: intervalo de tiempo)',
                            'sr-find-statistics-3': 'Ver: solo de ventas de artículos de su tienda (agrupadas por: atributo, atributo y tiempo; ' +
                                'artículos por transacción; artículos por transacción en el tiempo; promedio artículos por transacción; artículos digitados vs. escaneados)',
                            'sr-find-statistics-4': 'Ver: solo de formas de pago de su tienda',
                            'sr-find-statistics-5': 'Ver: solo de productividad del operador de su tienda (agrupados por: atributo, atributo y tiempo)',
                            'sr-find-statistics-6': 'Ver: solo de vueltos de su tienda',
                            'sr-find-statistics-7': 'Ver: solo de anulaciones de su tienda',
                            'sr-find-statistics-8': 'Ver: solo de vueltos de su tienda (agrupados por: atributo y tiempo)',
                            'sr-find-statistics-9': 'Ver: solo de pagos de su tienda (agrupados por: atributo)',
                            'sr-find-healths': 'Ver: solo de su tienda',
                            'sr-find-transactions': 'Listar y buscar: solo de su tienda',
                            'sr-get-transactions': 'Ver detalle: solo de su tienda',
                            'sr-update-transactions': 'Editar: solo de su tienda',
                            'sr-find-changes': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-changes': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-couponsInformations': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-couponsInformations': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-customers': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-customers': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-dataEntries': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-dataEntries': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-discounts': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-discounts': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-epsTenders': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-epsTenders': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-items': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-items': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-journals': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-journals': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-overrides': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-overrides': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-taxes': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-taxes': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-tenders': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-tenders': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-tillChanges': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-tillChanges': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-userStrings': 'Listar y buscar: solo de transacciones de su tienda',
                            'sr-get-userStrings': 'Ver detalle: solo de transacciones de su tienda',
                            'sr-find-chains': 'Listar y buscar: solo su cadena',
                            'sr-get-chains': 'Ver detalle: solo de su cadena',
                            'sr-find-stores': 'Listar y buscar: solo su tienda',
                            'sr-get-stores': 'Ver detalle: solo de su tienda',
                            'sr-update-stores': 'Editar: solo su tienda',
                            'sr-find-terminalTypes': 'Listar y buscar: solo de su cadena',
                            'sr-get-terminalTypes': 'Ver detalle: solo de su cadena',
                            'sr-create-terminalTypes': 'Crear: solo para su cadena',
                            'sr-find-terminalNumberTypes': 'Listar y buscar: solo de su tienda',
                            'sr-get-terminalNumberTypes': 'Ver detalle: solo de su tienda',
                            'sr-find-operators': 'Listar y buscar: solo de su tienda',
                            'sr-get-operators': 'Ver detalle: solo de su tienda',
                            'sr-create-operators': 'Crear: solo para su tienda',
                            'sr-find-itemDetails': 'Listar y buscar: solo de su cadena',
                            'sr-get-itemDetails': 'Ver detalle: solo de su cadena',
                            'sr-find-departments': 'Listar y buscar: solo de su cadena',
                            'sr-get-departments': 'Ver detalle: solo de su cadena',
                            'sr-find-files': 'Listar y buscar: solo de su tienda',
                            'sr-get-files': 'Ver detalle: solo de su tienda',
                            'sr-find-users': 'Listar y buscar: solo de su tienda y el suyo',
                            'sr-get-users': 'Ver detalle: solo de su tienda y el suyo',
                            'sr-create-users': 'Crear: solo Administradores de Tienda (STORE-ADMIN), Suscriptores de Tienda (STORE-SUBSCRIBER), Jefes de Caja (CASH-MANAGER) y Cajeros (CASHIER) para su tienda',
                            'sr-update-users': 'Editar: solo el suyo',
                            'sr-update-users-2': 'Editar: solo Administradores de Tienda (STORE-ADMIN), Suscriptores de Tienda (STORE-SUBSCRIBER), Jefes de Caja (CASH-MANAGER) y Cajeros (CASHIER) de su tienda',
                            'sr-remove-users': 'Eliminar: solo de su tienda, excepto el suyo',
                        },
                        'GENERAL-SUBSCRIBER': {
                            'gs-create-transactions': 'Crear',
                            'gs-create-journals': 'Crear',
                            'gs-get-users': 'Ver detalle: solo el suyo',
                            'gs-update-users': 'Editar: solo el suyo',
                        },
                        'CHAIN-SUBSCRIBER': {
                            'chs-create-transactions': 'Crear: solo para su cadena',
                            'chs-create-journals': 'Crear: solo para su cadena',
                            'chs-find-chains': 'Listar y buscar: solo su cadena',
                            'chs-get-chains': 'Ver detalle: solo de su cadena',
                            'chs-find-stores': 'Listar y buscar: solo de su cadena',
                            'chs-get-stores': 'Ver detalle: solo de su cadena',
                            'chs-get-users': 'Ver detalle: solo el suyo',
                            'chs-update-users': 'Editar: solo el suyo',
                        },
                        'STORE-SUBSCRIBER': {
                            'ss-create-transactions': 'Crear: solo para su tienda',
                            'ss-create-journals': 'Crear: solo para su tienda',
                            'ss-find-chains': 'Listar y buscar: solo su cadena',
                            'ss-get-chains': 'Ver detalle: solo de su cadena',
                            'ss-find-stores': 'Listar y buscar: solo su tienda',
                            'ss-get-stores': 'Ver detalle: solo de su tienda',
                            'ss-get-users': 'Ver detalle: solo el suyo',
                            'ss-update-users': 'Editar: solo el suyo',
                        }
                    },
                },
                chains: {
                    name: 'Cadenas'
                },
                groups: {
                    name: 'Grupos de tiendas'
                },
                stores: {
                    name: 'Tiendas'
                }
            },
            tabs: {
                general: 'General',
                chains: 'Cadenas',
                groups: 'Grupos',
                stores: 'Tiendas'
            },
            crud: {
                create: {
                    identity: 'Identidad',
                    rolesAndPermissions: 'Roles y Permisos',
                    notifyUser: 'Notificar al usuario',
                    notification: {
                        success: 'Usuario creado con éxito',
                        failed: 'Error: el usuario no pudo ser creado'
                    },
                    validation: {
                        emailNotUnique: 'Ya existe un usuario con este correo electrónico'
                    }
                },
                edit: {
                    identity: 'Identidad',
                    rolesAndPermissions: 'Roles y Permisos',
                    notification: {
                        success: 'Usuario editado con éxito',
                        failed: 'Error: el usuario no pudo ser editado'
                    },
                    validation: {
                        emailNotUnique: 'Ya existe otro usuario con este correo electrónico'
                    }
                },
                delete: {
                    confirmTitle: 'Eliminar usuario',
                    confirmContent: '¿Seguro que quiere eliminar este usuario?',
                    notification: {
                        success: 'Usuario eliminado con éxito',
                        failed: 'Error: el usuario no pudo ser eliminado'
                    }
                },
                bulkDelete: {
                    confirmTitle: 'Eliminar usuario |||| Eliminar %{smart_count} usuarios',
                    confirmContent: '¿Seguro que quiere eliminar este usuario? |||| ¿Seguro que quiere eliminar estos %{smart_count} usuarios?',
                    notification: {
                        success: 'Usuario eliminado con éxito |||| Usuarios eliminados con éxito',
                        failed: 'Error: el usuario no pudo ser eliminado |||| Error: los usuarios no pudieron ser eliminados'
                    }
                },
                changePassword: {
                    name: 'Cambiar Contraseña',
                    oldPassword: 'Contraseña actual',
                    password: 'Contraseña nueva',
                    passwordConfirmation: 'Confirme contraseña',
                    notification: {
                        success: 'Contraseña de usuario cambiada con éxito',
                        failed: 'Error: la contraseña de usuario no pudo ser cambiada',
                    },
                    validation: {
                        passwordDistinct: 'La contraseña nueva debe ser igual a la confirmada'
                    }
                }
            }
        },
    },
};

export default customSpanishMessages;
