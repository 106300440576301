import * as React from 'react';
import { createRef, createElement, useState, useEffect, useMemo } from 'react';
import { CheckboxGroupInput, useTranslate, useNotify } from 'react-admin';
import { Button, Grid, Paper, Typography, Slider, CircularProgress, useMediaQuery } from '@material-ui/core';
import { Form } from 'react-final-form';
import DoubleScrollbar from 'react-double-scrollbar';
import CustomDialog from '../../../custom/CustomDialog';
import { exportComponentAsPNG } from 'react-component-export-image';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    paper: {
        margin: theme.spacing(2),
        width: 'fit-content'
    },
    container: {
        padding: theme.spacing(2)
    }
}));

const exportChoices = [
    { id: 'chart', name: 'pos.generic.chart' },
    { id: 'secondaryChart', name: 'resources.transactions.statistics.export.secondaryChart' },
    { id: 'summaryTable', name: 'resources.transactions.statistics.export.summaryTable' },
    { id: 'mainTable', name: 'resources.transactions.statistics.export.mainTable' },
    { id: 'secondaryTable', name: 'resources.transactions.statistics.export.secondaryTable' }
];

const ExportModal = ({ name, open, onClose, filters, data, loading, noCompareButton, exportComponents }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();

    const isXSmall = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.only('sm'));
    const isMedium = useMediaQuery(theme => theme.breakpoints.only('md'));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const [selectedExports, setSelectedExports] = useState([]);
    const [widthExport, setWidthExport] = useState(85);
    const [exportLoading, setExportLoading] = useState(false);
    const exportRef = createRef();

    const availableChoices = useMemo(() => {
        let availableChoices = exportChoices.filter(exportChoice => exportChoice.id in exportComponents);
        setSelectedExports(availableChoices.map(choice => choice.id));
        return availableChoices;
    }, [exportComponents]);

    const maxWidthMark = useMemo(() => {
        if (isXSmall) {
            return 300;
        } else if (isSmall) {
            return 200;
        } else if (isMedium) {
            return 180;
        } else {
            return 120;
        }
    }, [isXSmall, isSmall, isMedium]);

    useEffect(() => {
        if ((isSmall && widthExport > 200) || (isMedium && widthExport > 180)) {
            setWidthExport(maxWidthMark);
        } else if (isLarge && widthExport > 120) {
            setWidthExport(85);
        }
    }, [isSmall, isMedium, isLarge, widthExport, maxWidthMark]);

    const handlePreview = values => {
        setSelectedExports(values.selectedExports);
    };

    const handleExport = () => {
        if (exportRef && exportRef.current) {
            notify(`resources.transactions.statistics.export.notification.info`, 'info');
            setExportLoading(true);
            const clip = CanvasRenderingContext2D.prototype.clip;
            CanvasRenderingContext2D.prototype.clip = function () {};
            exportComponentAsPNG(exportRef, {
                fileName: name,
                html2CanvasOptions: {
                    // allowTaint: true,
                    logging: true,
                    backgroundColor: null,
                    scale: 2,
                    scrollY: 0,
                    ...(noCompareButton && { scrollY: 0 })
                }
            })
                .then(() => {
                    notify(`resources.transactions.statistics.export.notification.success`, 'success');
                })
                .catch(() => {
                    notify(`resources.transactions.statistics.export.notification.failed`, 'error');
                })
                .finally(() => {
                    setExportLoading(false);
                    CanvasRenderingContext2D.prototype.clip = clip;
                });
        }
    };

    return (
        <CustomDialog
            title={translate('ra.action.export')}
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="xl"
        >
            <Grid container spacing={1} justifyContent="center" style={{ minHeight: '80vh' }}>
                <Grid item xs={12}>
                    <Form onSubmit={handlePreview}>
                        {({ handleSubmit, form }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxGroupInput
                                            source="selectedExports"
                                            choices={availableChoices}
                                            defaultValue={selectedExports}
                                            label="resources.transactions.statistics.export.selectedExports"
                                            helperText={false}
                                            className={classes.input}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item container spacing={1} xs justifyContent="flex-end" alignItems="center">
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                type="submit"
                                                disabled={loading ||
                                                    !form.getFieldState('selectedExports') ||
                                                    !form.getFieldState('selectedExports').value ||
                                                    form.getFieldState('selectedExports').value.length === 0 ||
                                                    exportLoading
                                                }
                                            >
                                                {translate('resources.transactions.statistics.export.preview')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                onClick={handleExport}
                                                disabled={loading || !exportRef || exportLoading}
                                            >
                                                {translate('ra.action.export')}
                                                {exportLoading &&
                                                    <CircularProgress size={24} className={classes.buttonProgress} />
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography align="center">
                        {translate('resources.transactions.statistics.export.widthExport')}
                    </Typography>
                    <Slider
                        value={widthExport}
                        onChange={(event, value) => {
                            setWidthExport(value);
                        }}
                        valueLabelFormat={value => `${value}%`}
                        defaultValue={85}
                        step={5}
                        min={20}
                        max={maxWidthMark}
                        marks={true}
                        valueLabelDisplay="auto"
                        className={classes.input}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                        {translate('resources.transactions.statistics.export.preview')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={3}
                        justifyContent={widthExport <= 85 ? 'center' : 'left'}
                        component={widthExport <= 85 ? 'div' : DoubleScrollbar}
                    >
                        <Grid item>
                            <Paper className={classes.paper}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{ width: `${widthExport}vw` }}
                                    className={classes.container}
                                    ref={exportRef}
                                >
                                    {Object.entries(exportComponents).map(([key, component]) => {
                                        if (!selectedExports.includes(key)) {
                                            return null;
                                        }
                                        return (
                                            <Grid item xs={12} key={key}>
                                                {createElement(component, {
                                                    data,
                                                    filters,
                                                    loading,
                                                    exportable: true
                                                })}
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CustomDialog>
    );
};

export default ExportModal;
