import * as React from 'react';
import { createElement, useRef, useEffect, useMemo, useState } from 'react';
import { defaultTheme, useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';
import { AppBar, Button, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import { createTheme } from '@material-ui/core/styles';
import Landing from './Landing';
import CustomDialog from '../custom/CustomDialog';
import logoWhite from '../../resources/logo-white.png';
import logo from '../../resources/logo.png';
import resourcesConfig from '../../config/resourcesConfig.json';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        appBar: {
            backgroundColor: '#03D2AE'
        },
        title: {
            flexGrow: 1
        },
        content: {
            [theme.breakpoints.up('md')]: {
                margin: 'auto'
            }
        },
        logoWhite: {
            flex: 1,
            marginTop: '2px'
        },
        img: {
            '-webkit-user-drag': 'none',
            'user-drag': 'none',
            '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            'user-select': 'none'
        },
        logo: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center'
        }
    }),
    { name: 'RaLogin' }
);

const LoginView = props => {
    const {
        title,
        classes: classesOverride,
        className,
        children,
        notification,
        staticContext,
        ...rest
    } = props;

    const containerRef = useRef();
    const translate = useTranslate();
    const classes = useStyles(props);
    const checkAuth = useCheckAuth();
    const history = useHistory();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const activateLogin = resourcesConfig.general.activateLogin;

    const [openLoginModal, setOpenLoginModal] = useState(false);

    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);

    const handleOpenLoginModal = () => {
        setOpenLoginModal(true);
    };
    const handleCloseLoginModal = () => {
        setOpenLoginModal(false);
    };

    return (
        <div
            className={classnames(classes.main, className)}
            {...rest}
            ref={containerRef}
        >
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        TantAI
                    </Typography>
                    {!isXSmall &&
                        <div className={classes.logoWhite}>
                            <a href="https://www.33sur.cl/" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={logoWhite}
                                    height="45px"
                                    alt="33 Grados Sur"
                                    className={classes.img}
                                    draggable={false}
                                />
                            </a>
                        </div>
                    }
                    {activateLogin &&
                        <Button
                            color="inherit"
                            type="button"
                            startIcon={<AccountCircleIcon />}
                            onClick={handleOpenLoginModal}
                        >
                            {translate('pos.generic.login')}
                        </Button>
                    }
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                <Landing />
            </div>
            <CustomDialog
                title={translate('pos.generic.login')}
                open={openLoginModal}
                onClose={handleCloseLoginModal}
                maxWidth="xs"
                fullWidth
            >
                <div className={classes.logo}>
                    <img
                        src={logo}
                        width="200px"
                        alt="33 Grados Sur"
                        className={classes.img}
                        draggable={false}
                    />
                </div>
                {children}
            </CustomDialog>
            {notification ? createElement(notification) : null}
        </div>
    );
};

const CustomLogin = props => {
    const {
        theme = defaultTheme,
        ...rest
    } = props;
    const muiTheme = useMemo(() => createTheme(theme), [theme]);

    return (
        <ThemeProvider theme={muiTheme}>
            <LoginView {...rest} />
        </ThemeProvider>
    );
};

export default CustomLogin;
