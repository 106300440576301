import * as React from 'react';
import { useContext, useMemo, useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { SelectInput, NumberInput, TextField, NumberField, useLocale, useTranslate } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import PercentageField from '../../../custom/PercentageField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import getGroupByDefaultValue from '../../../../helpers/getGroupByDefaultValue';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import getGroupByChoices from '../../../../helpers/getGroupByChoices';
import resourcesConfig from '../../../../config/resourcesConfig.json';
import { AbilityContext } from '../../../Can';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: { maxHeight: '300px' },
    totalCell: { fontWeight: 500 }
}));

const groupByChoices = [
    {
        id: 'terminalType.id',
        name: 'resources.transactions.fields.terminalType',
        fieldId: 'terminalType.id',
        fieldDataId: 'terminalType.name',
        fieldDataTranslate: 'resources.transactions.fields.terminalType'
    },
    {
        id: 'transaction.terminalNumber',
        name: 'resources.transactions.fields.terminalNumber',
        fieldId: 'transaction.terminalNumber',
        fieldDataId: 'transaction.terminalNumber',
        fieldDataTranslate: 'resources.transactions.fields.terminalNumber'
    },
    {
        id: 'transaction.operatorCode',
        name: 'resources.transactions.fields.operatorCode',
        fieldId: 'transaction.operatorCode',
        fieldDataId: 'operator.name',
        fieldDataTranslate: 'resources.transactions.fields.operatorName'
    },
    {
        id: 'transaction.storeCode',
        name: 'resources.transactions.fields.storeCode',
        fieldId: 'transaction.storeCode',
        fieldDataId: 'transaction.storeCode',
        fieldDataTranslate: 'resources.transactions.fields.storeCode'
    },
    {
        id: 'chain.id',
        name: 'resources.stores.fields.chain',
        fieldId: 'chain.id',
        fieldDataId: 'chain.name',
        fieldDataTranslate: 'resources.stores.fields.chain'
    }
];

const getKey = groupBy => e => {
    let key = '';
    if (['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id)) {
        key += `${e[groupBy.fieldId]}-${e['chain.id']}-${e['transaction.storeCode']}`;
    } else {
        key += e[groupBy.fieldId];
    }
    return key;
};

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const ability = useContext(AbilityContext);

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const checkedGroupByChoices = useMemo(() => {
        const groupByIds = getGroupByChoices(
            groupByChoices.map(e => e.id), 'general-group-by-field', ability.rulesFor('find', 'statistics')
        );
        return groupByChoices.filter(e => groupByIds.includes(e.id));
    }, [ability]);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="groupById"
                                    label="pos.generic.groupBy"
                                    choices={checkedGroupByChoices}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="quantifier"
                                    label="pos.generic.quantifier"
                                    choices={[
                                        { id: 'quantity', name: 'pos.generic.customers' },
                                        { id: 'amount', name: 'pos.generic.amount' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="terminalTypeId"
                                    record={filters}
                                    reference="terminalTypes"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.transactions.fields.terminalType')}
                                    withNull={true}
                                    helperText={false}
                                    //groupByField="chainId"
                                    additionalFilter={{
                                        ...(form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                        _.isArray(form.getFieldState('chainId').value) ? {
                                            chainId: form.getFieldState('chainId').value
                                        } : {}),
                                        ...(form.getFieldState('storeCode') && form.getFieldState('storeCode').value &&
                                        _.isArray(form.getFieldState('storeCode').value) ? {
                                            storeCode: form.getFieldState('storeCode').value
                                        } : {})
                                    }}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="orderType"
                                    label="pos.generic.order.orderType.name"
                                    choices={[
                                        { id: 'ASC', name: 'pos.generic.order.orderType.asc' },
                                        { id: 'DESC', name: 'pos.generic.order.orderType.desc' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="limit"
                                    label="pos.generic.limit"
                                    min="1"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.generalSales.sections.sales', {
                    group_by: translate(groupByChoices.find(e => e.id === filters.groupById).name)
                })}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                groupById: getGroupByDefaultValue(
                                    groupByChoices.map(e => e.id), 'general-group-by-field',
                                    ability.rulesFor('find', 'statistics'), 'transaction.terminalNumber'
                                ),
                                quantifier: 'quantity',
                                businessDayDate: getBusinessDayDateDefaultValue(),
                                orderType: 'DESC',
                                limit: 50
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.generalSales.sections.sales', {
                    group_by: translate(groupByChoices.find(e => e.id === filters.groupById).name)
                })}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    mainTable: DataTable
                }}
            />
        </div>
    );
};

const Chart = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const classes = useStyles({ exportable });
    const currency = resourcesConfig.general.currency;

    const [processedData, setProcessedData] = useState([]);
    const [xMarkerValue, setXMarkerValue] = useState(false);
    const [yMarkerValue, setYMarkerValue] = useState(false);

    let { groupById, quantifier, chainId, storeCode } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            let absTotal = _.sumBy(data, e => Math.abs(e[quantifier]));
            let processedData = data.map(e => {
                let groupByValue = getKey(groupBy)(e);
                return {
                    id: groupByValue,
                    [groupBy.fieldId]: e[groupBy.fieldId],
                    [groupBy.fieldDataId]: e[groupBy.fieldDataId],
                    [quantifier]: _.round(e[quantifier], 4),
                    percentage: _.round(Math.abs(e[quantifier]) / (absTotal ? absTotal : 1) * 100, 4),
                    ...(['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && {
                        'chain.id': e['chain.id'],
                        'chain.name': e['chain.name'],
                        'transaction.storeCode': e['transaction.storeCode'],
                        unknown: !e[groupBy.fieldDataId]
                    })
                };
            });

            setProcessedData(processedData);

            let i = 0;
            let accumulatedPercentage = 0;
            let xMarkerValue = 0;
            let yMarkerValue = 0;
            while(i < processedData.length && accumulatedPercentage <= 80) {
                accumulatedPercentage += processedData[i].percentage;
                xMarkerValue = processedData[i].id;
                yMarkerValue = processedData[i][quantifier];
                i++;
            }
            if (accumulatedPercentage >= 80) {
                setXMarkerValue(xMarkerValue);
                setYMarkerValue(yMarkerValue);
            } else {
                setXMarkerValue(false);
                setYMarkerValue(false);
            }
        }
    }, [data, loading, cancel, groupBy, quantifier, chainId, storeCode]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const axisBottom = value => {
        const groupByData = processedData.find(e => e.id === value);
        if (!groupByData || !(groupBy.fieldId in groupByData)) {
            return value;
        }

        let groupByValue = groupByData[groupBy.fieldDataId];
        if (groupBy.id === 'terminalType.id') {
            if ((storeCode && storeCode.length !== 1) || !storeCode) {
                if (groupByData.unknown) {
                    groupByValue = `${translate('resources.transactions.fields.withoutTerminalType')} (${groupByData['transaction.storeCode']})`;
                } else {
                    groupByValue = `${groupByData[groupBy.fieldDataId]} (${groupByData['transaction.storeCode']})`;
                }
            } else {
                if (groupByData.unknown) {
                    groupByValue = translate('resources.transactions.fields.withoutTerminalType');
                } else {
                    groupByValue = groupByData[groupBy.fieldDataId];
                }
            }

            if (groupByValue.length > 15) {
                return (
                    <tspan>
                        <tspan x="0" dy="10">{groupByValue.substring(0, 15)}-</tspan>
                        <tspan x="0" dy="15">{_.truncate(groupByValue.substring(15), { length: 15 })}</tspan>
                    </tspan>
                );
            }
        } else if (groupBy.id === 'transaction.operatorCode') {
            if ((chainId && chainId.length !== 1) || !chainId) {
                groupByValue = `${groupByData[groupBy.fieldId]} (${groupByData['chain.name']} - ${groupByData['transaction.storeCode']})`;
            } else {
                groupByValue = `${groupByData[groupBy.fieldId]} (${groupByData['transaction.storeCode']})`;
            }
        }

        return _.truncate(groupByValue, { length: 15 });
    };

    const axisLeft = value => {
        if (quantifier === 'amount') {
            return Number(value).toLocaleString(locale, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
        }
        return value.toLocaleString(locale);
    };

    const label = ({ value }) => {
        if (quantifier === 'amount') {
            return Number(value).toLocaleString(locale, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
        }
        return value.toLocaleString(locale);
    };

    const tooltip = ({ data, value, color, index }) => {
        let {
            [groupBy.fieldId]: groupByData, 'terminalType.name': terminalTypeName, 'operator.name': operatorName,
            'chain.name': chainName, 'transaction.storeCode': storeCode, unknown, percentage
        } = data;

        if (quantifier === 'amount') {
            value = Number(value).toLocaleString(locale, {
                style: 'currency',
                currency: currency,
            });
        } else {
            value = value.toLocaleString(locale);
        }

        percentage = percentage.toLocaleString(locale);

        let accumulatedPercentage = 0;
        let allBars = processedData;
        let i = 0;
        while(i < allBars.length && i <= index) {
            accumulatedPercentage += allBars[i].percentage;
            i++;
        }
        accumulatedPercentage = _.round(accumulatedPercentage, 4).toLocaleString(locale);

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                    {(groupBy.id === 'terminalType.id' && !unknown) &&
                        <span>
                            {translate(groupBy.fieldDataTranslate)}: <strong>{terminalTypeName}</strong>
                        </span>
                    }
                    {(groupBy.id === 'terminalType.id' && unknown) &&
                        <span>
                            {translate(groupBy.fieldDataTranslate)}: <strong>{translate('resources.transactions.fields.withoutTerminalType')}</strong>
                        </span>
                    }
                    {(groupBy.id === 'transaction.operatorCode' && !unknown) &&
                        <span>
                            {translate(groupBy.fieldDataTranslate)}: <strong>{operatorName}</strong>
                        </span>
                    }
                    {!['terminalType.id', 'chain.id'].includes(groupBy.id) &&
                        <span>
                            {translate(groupBy.name)}: <strong>{groupByData}</strong>
                        </span>
                    }
                    {['terminalType.id', 'transaction.operatorCode', 'chain.id'].includes(groupBy.id) &&
                        <span>
                            {translate('resources.chains.name', 1)}: <strong>{chainName}</strong>
                        </span>
                    }
                    {['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) &&
                        <span>
                            {translate('resources.transactions.fields.storeCode')}: <strong>{storeCode}</strong>
                        </span>
                    }
                    {quantifier === 'amount' ? (
                        <span>
                            {translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })}: <strong>{value}</strong>
                        </span>
                    ) : (
                        <span>
                            {translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })}: <strong>{value}</strong>
                        </span>
                    )}
                    <span>
                        {translate('pos.generic.accumulated')}: <strong>{accumulatedPercentage} %</strong>
                    </span>
                    <span>
                        {translate('pos.generic.percentage')}: <strong>{percentage} %</strong>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.chart}>
            <ResponsiveBar
                data={processedData}
                label={label}
                tooltip={tooltip}
                keys={[quantifier]}
                indexBy="id"
                margin={{
                    top: 20,
                    bottom: [
                        'terminalType.id', 'transaction.operatorCode', 'transaction.storeCode', 'chain.id'
                    ].includes(groupBy.id) ? 100 : 60,
                    right: 0,
                    left: quantifier === 'amount' ? 100 : 60
                }}
                padding={0.3}
                colors={{ scheme: 'paired' }}
                colorBy="indexValue"
                borderColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    format: axisBottom,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: translate(groupBy.name),
                    legendPosition: 'middle',
                    legendOffset: [
                        'terminalType.id', 'transaction.operatorCode', 'transaction.storeCode', 'chain.id'
                    ].includes(groupBy.id) ? 90 : 50
                }}
                axisLeft={{
                    format: axisLeft,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: translate(`pos.generic.${quantifier}`),
                    legendPosition: 'middle',
                    legendOffset: quantifier === 'amount' ? -80 : -50
                }}
                labelSkipWidth={quantifier === 'amount' ? 48 : 24}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [[ 'darker', 3 ]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                markers={[
                    ...((xMarkerValue !== false && yMarkerValue !== false) ? [
                        {
                            axis: 'x',
                            value: xMarkerValue,
                            lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
                            legend: '80 %',
                            legendPosition: 'top-left',
                        },
                        {
                            axis: 'x',
                            value: xMarkerValue,
                            lineStyle: { stroke: 'transparent' },
                            legend: '20 %',
                            legendPosition: 'top-right',
                        },
                        {
                            axis: 'y',
                            value: yMarkerValue,
                            lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
                        }
                    ] : [])
                ]}
            />
        </div>
    );
};

const DataTable = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');
    const currency = resourcesConfig.general.currency;

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { groupById, quantifier } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    useEffect(() => {
        setProcessingData(true);
        if (data && data.length > 0 && !loading && !cancel) {
            let absTotal = _.sumBy(data, e => Math.abs(e[quantifier]));
            let processedData = {
                data: data.map(e => ({
                    [groupBy.fieldId]: e[groupBy.fieldId],
                    [groupBy.fieldDataId]: e[groupBy.fieldDataId],
                    [quantifier]: _.round(e[quantifier], 4),
                    percentage: _.round(Math.abs(e[quantifier]) / (absTotal ? absTotal : 1) * 100, 4),
                    ...(['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && {
                        'chain.id': e['chain.id'],
                        'chain.name': e['chain.name'],
                        'transaction.storeCode': e['transaction.storeCode']
                    })
                })),
                total: _.sumBy(data, quantifier)
            };
            processedData.totalPercentage = _.round(_.sumBy(processedData.data, 'percentage'));
            setProcessedData(processedData);
            setProcessingData(false);
        }
    }, [data, loading, cancel, groupBy, quantifier]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        {groupBy.id !== 'terminalType.id' &&
                            <TableCell>
                                {translate(groupBy.name)}
                            </TableCell>
                        }
                        {['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && (
                            <React.Fragment>
                                <TableCell>
                                    {translate(groupBy.fieldDataTranslate)}
                                </TableCell>
                                <TableCell>
                                    {translate('resources.transactions.fields.storeCode')}
                                </TableCell>
                            </React.Fragment>
                        )}
                        <TableCell align="right">
                            {quantifier === 'amount' ? (
                                translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })
                            ) : (
                                `${translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                            )}
                        </TableCell>
                        <TableCell align="right">
                            {quantifier === 'amount' ? (
                                translate('pos.generic.percentageOf', { of: translate('pos.generic.amount') })
                            ) : (
                                `${translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                            )}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell
                                colSpan={groupBy.id === 'terminalType.id' ? 4 : groupBy.id === 'transaction.operatorCode' ? 5 : 3}
                                align="center"
                            >
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => {
                            if (!(groupBy.fieldId in row)) {
                                return null;
                            }

                            return (
                                <TableRow key={getKey(groupBy)(row)}>
                                    {groupBy.id !== 'terminalType.id' &&
                                        <TableCell>
                                            <TextField
                                                record={row}
                                                source={groupBy.id === 'chain.id' ? groupBy.fieldDataId : groupBy.fieldId}
                                            />
                                        </TableCell>
                                    }
                                    {groupBy.id === 'terminalType.id' &&
                                        <TableCell>
                                            <TextField
                                                record={row}
                                                source="terminalType.name"
                                                emptyText={translate('resources.transactions.fields.withoutTerminalType')}
                                            />
                                        </TableCell>
                                    }
                                    {groupBy.id === 'transaction.operatorCode' &&
                                        <TableCell>
                                            <TextField record={row} source="operator.name" emptyText={emptyText} />
                                        </TableCell>
                                    }
                                    {['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) &&
                                        <TableCell>
                                            <TextField record={row} source="transaction.storeCode" />
                                        </TableCell>
                                    }
                                    <TableCell align="right">
                                        {quantifier === 'amount' ? (
                                            <NumberField
                                                record={row}
                                                source={quantifier}
                                                locales={locale}
                                                options={{style: 'currency', currency: currency}}
                                            />
                                        ) : (
                                            <NumberField record={row} source={quantifier} locales={locale} />
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <PercentageField record={row} source="percentage" />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow key="total">
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.total', 1)}
                            </TableCell>
                            {groupBy.id === 'terminalType.id' &&
                                <TableCell />
                            }
                            {groupBy.id === 'transaction.operatorCode' &&
                                <TableCell colSpan={2} />
                            }
                            <TableCell align="right">
                                {quantifier === 'amount' ? (
                                    <NumberField
                                        record={processedData}
                                        source="total"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                        className={classes.totalCell}
                                    />
                                ) : (
                                    <NumberField
                                        record={processedData}
                                        source="total"
                                        locales={locale}
                                        className={classes.totalCell}
                                    />
                                )}
                            </TableCell>
                            <TableCell align="right">
                                <PercentageField
                                    record={processedData}
                                    source="totalPercentage"
                                    className={classes.totalCell}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let {
        groupById, quantifier, businessDayDate, chainId, storeCode, terminalTypeId, terminalNumber, operatorCode,
        orderType, limit
    } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const groupBy = groupByChoices.find(e => e.id === groupById);
        const params = {
            type: 'general-group-by-field',
            groupBy: groupBy.fieldId,
            quantifier: quantifier,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalTypeId: terminalTypeId,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderType: orderType,
            limit: limit
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        renderKey, groupById, quantifier, businessDayDate, chainId, storeCode, terminalTypeId, terminalNumber,
        operatorCode, orderType, limit
    ]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const TransactionsBar = () => {
    const translate = useTranslate();
    const ability = useContext(AbilityContext);

    const filters = {
        groupById: getGroupByDefaultValue(
            groupByChoices.map(e => e.id), 'general-group-by-field', ability.rulesFor('find', 'statistics'),
            'transaction.terminalNumber'
        ),
        quantifier: 'quantity',
        businessDayDate: getBusinessDayDateDefaultValue(),
        orderType: 'DESC',
        limit: 50
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.generalSales.sections.sales', {
                                    group_by: translate(groupByChoices.find(e => e.id === filters.groupById).name)
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default TransactionsBar;
