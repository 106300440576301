import * as React from 'react';
import { DateField, TextField } from 'react-admin';
import _ from 'lodash';
import moment from 'moment';

const CustomDateTimeField = ({ originalFormat, showFormat, isUtc = false, ...props }) => {
    let value = _.get(props.record, props.source);

    if (value) {
        if (originalFormat) {
            if (isUtc) {
                value = moment.utc(value, originalFormat).local();
            } else {
                value = moment(value, originalFormat);
            }
        } else {
            if (isUtc) {
                value = moment.utc(value, 'YYYY-MM-DD HH:mm:ss').local();
            } else {
                value = moment(value, 'YYYY-MM-DD HH:mm:ss');
            }
        }

        if (showFormat) {
            value = value.format(showFormat);
        } else if(originalFormat) {
            value = value.format(originalFormat);
        } else {
            value = value.format('YYYY-MM-DD HH:mm:ss');
        }
    }

    const record = {
        [props.source]: value
    };

    return (
        <TextField record={record} source={props.source} emptyText={props.emptyText} />
    );
};

CustomDateTimeField.defaultProps = DateField.defaultProps;

export default CustomDateTimeField;
