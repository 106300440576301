import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { SelectInput, TextField, NumberField, useTranslate, useLocale } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs,
    Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import SwipeableViews from 'react-swipeable-views';
import ExportModal from './ExportModal';
import PercentageField from '../../../custom/PercentageField';
import DurationField from '../../../custom/DurationField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import CustomTab from '../../../custom/CustomTab';
import CustomTabPanel from '../../../custom/CustomTabPanel';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import resourcesConfig from '../../../../config/resourcesConfig.json';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import moment from 'moment';
import humanize from '../../../../config/humanizeDuration';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootDataTable: {
        backgroundColor: theme.palette.grey[100],
        [theme.breakpoints.down('xs')]: {
            width: '95vw'
        }
    },
    scrollableTabs: { width: 0 },
    swipeableViews: {
        width: '100%',
        height: '100%'
    },
    tabPanel: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    tableContainer: { maxHeight: '300px' },
    totalCell: { fontWeight: 500 }
}));

const groupByValues = [
    {
        id: '1',
        isRange: false,
        value: 1
    },
    {
        id: '2',
        isRange: false,
        value: 2
    },
    {
        id: '3',
        isRange: false,
        value: 3
    },
    {
        id: '4',
        isRange: false,
        value: 4
    },
    {
        id: '5-10',
        isRange: true,
        value: {
            from: 5,
            to: 10
        }
    },
    {
        id: '11-20',
        isRange: true,
        value: {
            from: 11,
            to: 20
        }
    },
    {
        id: '21-30',
        isRange: true,
        value: {
            from: 21,
            to: 30
        }
    },
    {
        id: '31-...',
        isRange: true,
        value: {
            from: 31
        }
    }
];

const dateFormatter = translate => (precision, value) => {
    switch (precision) {
        case 'year':
            return moment(value).format('YYYY');
        case 'month':
            return moment(value).format(translate('pos.datetime.date').replace(/-DD|DD-/, ''));
        case 'day':
            return moment(value).format(translate('pos.datetime.date'));
        case 'hour':
            return moment(value).format(`${translate('pos.datetime.date')} HH:00`);
        case 'minute':
        default:
            return moment(value).format(translate('pos.datetime.datetime'));
    }
};

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="quantifier"
                                    label="pos.generic.quantifier"
                                    choices={[
                                        { id: 'transactionQuantity', name: 'pos.generic.customers' },
                                        { id: 'transactionAmount', name: 'pos.generic.amount' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="precision"
                                    label="pos.generic.precision"
                                    choices={[
                                        { id: 'minute', name: translate('pos.datetime.minute', 2) },
                                        { id: 'hour', name: translate('pos.datetime.hour', 2) },
                                        { id: 'day', name: translate('pos.datetime.day', 2) },
                                        { id: 'month', name: translate('pos.datetime.month', 2) },
                                        { id: 'year', name: translate('pos.datetime.year', 2) },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.itemSales.sections.itemsPerTransactionInTime.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                quantifier: 'transactionQuantity',
                                precision: 'day',
                                businessDayDate: getBusinessDayDateDefaultValue()
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.itemSales.sections.itemsPerTransactionInTime.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    mainTable: ExportableDataTable
                }}
            />
        </div>
    );
};

const Chart = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const classes = useStyles({ exportable });
    const currency = resourcesConfig.general.currency;

    const [processedData, setProcessedData] = useState([]);

    let { quantifier, precision } = filters;

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            let processedData = [];
            groupByValues.forEach(groupByValue => {
                let filteredData = data.filter(e => {
                    if (!groupByValue.isRange) {
                        return e.itemQuantity === groupByValue.value;
                    } else if (groupByValue.value.to) {
                        return e.itemQuantity >= groupByValue.value.from && e.itemQuantity <= groupByValue.value.to;
                    } else {
                        return e.itemQuantity >= groupByValue.value.from;
                    }
                });

                const datetimes = _.orderBy(_.uniq(filteredData.map(e => e.datetime)), [], ['asc']);
                let newFilteredData = [];
                datetimes.forEach(datetime => {
                    const filteredData2 = filteredData.filter(e => e.datetime === datetime);
                    newFilteredData.push({
                        x: datetime,
                        y: _.sumBy(filteredData2, quantifier),
                        avgTransactionTime: _.round(_.sumBy(filteredData2, 'totalTransactionTime') / _.sumBy(filteredData2, 'transactionQuantity'))
                    });
                });
                processedData.push({
                    id: groupByValue.id,
                    data: newFilteredData
                });
            });

            setProcessedData(processedData);
        }
    }, [data, loading, cancel, quantifier]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const axisBottom = value => dateFormatter(translate)(precision, value);

    const axisLeft = value => {
        if (quantifier === 'transactionAmount') {
            return Number(value).toLocaleString(locale, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
        }
        return value.toLocaleString(locale);
    };

    const leftLegend = quantifier => {
        switch (quantifier) {
            case 'transactionQuantity':
                return translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) });
            default:
            case 'transactionAmount':
                return translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) });
        }
    };

    const tooltip = ({ point }) => {
        let { borderColor, serieId, data } = point;
        let { x, y, avgTransactionTime } = data;

        if (quantifier === 'transactionAmount') {
            y = Number(y).toLocaleString(locale, {
                style: 'currency',
                currency: currency,
            });
        } else {
            y = y.toLocaleString(locale);
        }

        let duration = humanize(moment.duration(_.round(avgTransactionTime), 'seconds').asMilliseconds(), {
            shortFormat: false,
            language: locale,
            largest: 2
        });

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center',
                    background: 'white',
                    borderRadius: '2px',
                    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
                    padding: '5px 9px'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: borderColor,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                    <span>
                        {translate('pos.generic.quantityOf', { of: translate('resources.transactions.tabs.items', 2) })}: <strong>{serieId}</strong>
                    </span>
                    <span>
                         {translate('pos.datetime.dateName')}: <strong>{dateFormatter(translate)(precision, x)}</strong>
                    </span>
                    {quantifier === 'transactionAmount' ? (
                        <span>
                            {translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })}: <strong>{y}</strong>
                        </span>
                    ) : (
                        <span>
                            {translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })}: <strong>{y}</strong>
                        </span>
                    )}
                    <span>
                        {translate('resources.transactions.statistics.tabs.itemSales.sections.itemsPerTransactionInTime.avgTransactionTime')}: <strong>{duration} {_.lowerFirst(translate('pos.generic.approximatelyAbbr'))}</strong>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.chart}>
            <ResponsiveLine
                data={processedData}
                tooltip={tooltip}
                margin={{
                    top: 50,
                    right: 80,
                    bottom: 120,
                    left: quantifier === 'transactionAmount' ? 120 : 80
                }}
                xScale={{
                    type: 'time',
                    format: '%Y-%m-%d %H:%M:%S',
                    precision: precision,
                    useUTC: false
                }}
                xFormat="time:%Y-%m-%d %H:%M:%S"
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto'
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    format: axisBottom,
                    orient: 'bottom',
                    tickRotation: -45,
                    legend: translate('pos.datetime.dateName'),
                    legendOffset: 100,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    format: axisLeft,
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: leftLegend(quantifier),
                    legendPosition: 'middle',
                    legendOffset: quantifier === 'transactionAmount' ? -80 : -60
                }}
                curve="monotoneX"
                colors={{ scheme: 'paired' }}
                pointSize={5}
                pointColor="white"
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                useMesh={true}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        itemDirection: 'left-to-right',
                        itemsSpacing: 2,
                        itemWidth: 80,
                        itemHeight: 20,
                        symbolSize: 14
                    }
                ]}
            />
        </div>
    );
};

const processData = ({
    translate, data, loading, cancel, quantifier, precision, setProcessingData, setProcessedData, setValue = null
}) => {
    setProcessingData(true);
    if (setValue) setValue(0);
    if (data && data.length > 0 && !loading && !cancel) {
        let processedData = [];
        groupByValues.forEach(groupByValue => {
            let filteredData = data.filter(e => {
                if (!groupByValue.isRange) {
                    return e.itemQuantity === groupByValue.value;
                } else if (groupByValue.value.to) {
                    return e.itemQuantity >= groupByValue.value.from && e.itemQuantity <= groupByValue.value.to;
                } else {
                    return e.itemQuantity >= groupByValue.value.from;
                }
            });
            let total = _.sumBy(filteredData, quantifier);

            const datetimes = _.orderBy(_.uniq(filteredData.map(e => e.datetime)), [], ['asc']);
            let newFilteredData = [];
            datetimes.forEach(datetime => {
                const filteredData2 = filteredData.filter(e => e.datetime === datetime);
                newFilteredData.push({
                    datetime: dateFormatter(translate)(precision, datetime),
                    avgTransactionTime: _.round(_.sumBy(filteredData2, 'totalTransactionTime') / _.sumBy(filteredData2, 'transactionQuantity')),
                    [quantifier]: _.sumBy(filteredData2, quantifier),
                    percentage: _.round(_.sumBy(filteredData2, quantifier) / (total ? total : 1) * 100, 4)
                });
            });

            let newData = {
                itemQuantity: groupByValue.id,
                data: newFilteredData,
                total: total
            };
            newData.totalPercentage = _.round(_.sumBy(newData.data, 'percentage'));
            processedData.push(newData);

        });
        processedData = Object.fromEntries(processedData.map(e => [e.itemQuantity, e]));

        setProcessedData(processedData);
        setProcessingData(false);
    }
};

const DataTable = ({ filters, data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const theme = useTheme();
    const currency = resourcesConfig.general.currency;

    const [value, setValue] = useState(0);
    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { quantifier, precision } = filters;

    useEffect(() => {
        processData({
            translate, data, loading, cancel, quantifier, precision, setProcessingData, setProcessedData, setValue
        });
    }, [translate, data, loading, cancel, quantifier, precision]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = index => {
        setValue(index);
    };

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!groupByValues || !processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <div className={classes.rootDataTable}>
            <Tabs
                value={value}
                onChange={handleChange}
                orientation="horizontal"
                variant="scrollable"
                scrollButtons="on"
                classes={{ scrollable: classes.scrollableTabs }}
            >
                {(loading || processingData) ? (
                    <CustomTab label={<CircularProgress size={25} />} />
                ) : (groupByValues.map(groupByValue => (
                    <CustomTab
                        label={groupByValue.id}
                        index={groupByValues.indexOf(groupByValue)}
                        key={`tab-${groupByValue.id}`}
                        wrapped
                    />
                )))}
            </Tabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                enableMouseEvents={true}
                resistance={true}
                className={classes.swipeableViews}
            >
                {groupByValues.map(groupByValue => (
                    <CustomTabPanel
                        value={value}
                        index={groupByValues.indexOf(groupByValue)}
                        key={`tabpanel-${groupByValue.id}`}
                        dir={theme.direction}
                        className={classes.tabPanel}
                    >
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {translate('pos.datetime.dateName')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('resources.transactions.statistics.tabs.itemSales.sections.itemsPerTransactionInTime.avgTransactionTime')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {quantifier === 'transactionAmount' ? (
                                                translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })
                                            ) : (
                                                `${translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {quantifier === 'transactionAmount' ? (
                                                translate('pos.generic.percentageOf', { of: translate('pos.generic.amount') })
                                            ) : (
                                                `${translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {(loading || processingData) ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {processedData[groupByValue.id].data.map(row => (
                                            <TableRow key={`${groupByValue.id}-${row.datetime}`}>
                                                <TableCell>
                                                    <TextField record={row} source="datetime" />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <DurationField
                                                        record={row}
                                                        source="avgTransactionTime"
                                                        shortFormat
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {quantifier === 'transactionAmount' ? (
                                                        <NumberField
                                                            record={row}
                                                            source={quantifier}
                                                            locales={locale}
                                                            options={{style: 'currency', currency: currency}}
                                                        />
                                                    ) : (
                                                        <NumberField
                                                            record={row}
                                                            source={quantifier}
                                                            locales={locale}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <PercentageField record={row} source="percentage" />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow key={`total-${groupByValue.id}`}>
                                            <TableCell className={classes.totalCell}>
                                                {translate('pos.generic.total', 1)}
                                            </TableCell>
                                            <TableCell />
                                            <TableCell align="right">
                                                {quantifier === 'transactionAmount' ? (
                                                    <NumberField
                                                        record={processedData[groupByValue.id]}
                                                        source="total"
                                                        locales={locale}
                                                        options={{style: 'currency', currency: currency}}
                                                        className={classes.totalCell}
                                                    />
                                                ) : (
                                                    <NumberField
                                                        record={processedData[groupByValue.id]}
                                                        source="total"
                                                        locales={locale}
                                                        className={classes.totalCell}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                <PercentageField
                                                    record={processedData[groupByValue.id]}
                                                    source="totalPercentage"
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </CustomTabPanel>
                ))}
            </SwipeableViews>
        </div>
    );
};

const ExportableDataTable = ({ filters, data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const currency = resourcesConfig.general.currency;

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { quantifier, precision } = filters;

    useEffect(() => {
        processData({ translate, data, loading, cancel, quantifier, precision, setProcessingData, setProcessedData });
    }, [translate, data, loading, cancel, quantifier, precision]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!groupByValues || !processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper}>
            {groupByValues.map(groupByValue => (
                <Table size="small" key={groupByValue.id}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={4}>
                                {groupByValue.id}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {translate('pos.datetime.dateName')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('resources.transactions.statistics.tabs.itemSales.sections.itemsPerTransactionInTime.avgTransactionTime')}
                            </TableCell>
                            <TableCell align="right">
                                {quantifier === 'transactionAmount' ? (
                                    translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })
                                ) : (
                                    `${translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                                )}
                            </TableCell>
                            <TableCell align="right">
                                {quantifier === 'transactionAmount' ? (
                                    translate('pos.generic.percentageOf', { of: translate('pos.generic.amount') })
                                ) : (
                                    `${translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {(loading || processingData) ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {processedData[groupByValue.id].data.map(row => (
                                <TableRow key={`${groupByValue.id}-${row.datetime}`}>
                                    <TableCell>
                                        <TextField record={row} source="datetime" />
                                    </TableCell>
                                    <TableCell align="right">
                                        <DurationField
                                            record={row}
                                            source="avgTransactionTime"
                                            shortFormat
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        {quantifier === 'transactionAmount' ? (
                                            <NumberField
                                                record={row}
                                                source={quantifier}
                                                locales={locale}
                                                options={{style: 'currency', currency: currency}}
                                            />
                                        ) : (
                                            <NumberField
                                                record={row}
                                                source={quantifier}
                                                locales={locale}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <PercentageField record={row} source="percentage" />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow key={`total-${groupByValue.id}`}>
                                <TableCell className={classes.totalCell}>
                                    {translate('pos.generic.total', 1)}
                                </TableCell>
                                <TableCell />
                                <TableCell align="right">
                                    {quantifier === 'transactionAmount' ? (
                                        <NumberField
                                            record={processedData[groupByValue.id]}
                                            source="total"
                                            locales={locale}
                                            options={{style: 'currency', currency: currency}}
                                            className={classes.totalCell}
                                        />
                                    ) : (
                                        <NumberField
                                            record={processedData[groupByValue.id]}
                                            source="total"
                                            locales={locale}
                                            className={classes.totalCell}
                                        />
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <PercentageField
                                        record={processedData[groupByValue.id]}
                                        source="totalPercentage"
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            ))}
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let { groupBy, quantifier, precision, businessDayDate, chainId, storeCode, terminalNumber, operatorCode } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'items-per-transaction-group-by-datetime',
            groupBy: groupBy,
            quantifier: quantifier,
            precision: precision,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderBy: 'itemQuantity',
            orderType: 'ASC'
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        renderKey, groupBy, quantifier, precision, businessDayDate, chainId, storeCode, terminalNumber, operatorCode
    ]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const ItemsPerTransactionDatetimeLine = () => {
    const translate = useTranslate();

    const filters = {
        quantifier: 'transactionQuantity',
        precision: 'day',
        businessDayDate: getBusinessDayDateDefaultValue()
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.itemSales.sections.itemsPerTransactionInTime.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default ItemsPerTransactionDatetimeLine;
