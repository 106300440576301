import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Edit, TextInput, TopToolbar, ListButton, ShowButton, useTranslate, FormWithRedirect, useEditController, maxLength,
    required, useNotify, useRedirect, useRefresh, FormDataConsumer
} from 'react-admin';
import { Grid, Typography, IconButton } from '@material-ui/core';
import {
    Remove as RemoveIcon,
    Add as AddIcon
} from '@material-ui/icons';
import CustomTitle from '../../layout/CustomTitle';
import CustomMultiselectInput from '../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import CustomEditToolbar from '../../custom/CustomEditToolbar';
import axios from '../../../clients/axiosClient';
import _ from 'lodash';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    },
    input: {
        marginTop: '8px',
        marginBottom: '4px'
    }
});

const TerminalTypeEditActions = ({ basePath, data }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath}/>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );
};

const nameChainIdUniqueValidation = async (value, allValues) => {
    try {
        if (allValues.name !== undefined && allValues.chainId !== undefined) {
            const params = {
                name: allValues.name,
                chainId: allValues.chainId,
                id: { $ne: allValues.id }
            }
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/terminalTypes?${queryString}`);
            const { data: terminalTypes } = response.data;
            if (terminalTypes.length > 0) {
                return 'resources.terminalTypes.crud.edit.validation.nameChainIdUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const numbersValidation = values => {
    if (_.isArray(values)) {
        for (let value of values) {
            if (Number.isNaN(Number(value))) {
                return 'resources.terminalTypes.crud.edit.validation.numbers.isNotNumber';
            } else if (Number(value) < 0 || Number(value) > 2147483647) {
                return 'resources.terminalTypes.crud.edit.validation.numbers.outOfRange';
            }
        }
    } else if (_.isString(values)) {
        return 'resources.terminalTypes.crud.edit.validation.numbers.isNotArray';
    }
    return undefined;
};

const terminalNumbersStoreCodeUniqueValidationIndex = index => async (values, allValues) => {
    console.log('terminalNumbersStoreCodeUniqueValidationIndex')
    try {
        let terminalNumberType = allValues.terminalNumberTypes[index];
        if (terminalNumberType && !_.isEmpty(terminalNumberType.storeCode)) {
            const params = {
                terminalNumber: {
                    $in: terminalNumberType.terminalNumbers
                },
                storeCode: terminalNumberType.storeCode
            };
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/terminalNumberTypes?${queryString}`);
            const { data: terminalNumberTypes } = response.data;
            if (terminalNumberTypes.length > 0) {
                return 'resources.terminalTypes.crud.edit.validation.terminalNumbersStoreCodeUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const terminalNumbersStoreCodeUniqueValidation = async (values, allValues) => {
    try {
        if (_.isArray(allValues.terminalNumbers) && allValues.storeCode !== undefined) {
            const params = {
                terminalNumber: {
                    $in: allValues.terminalNumbers
                },
                storeCode: allValues.storeCode,
                terminalTypeId: { $ne: allValues.id }
            }
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/terminalNumberTypes?${queryString}`);
            const { data: terminalTypes } = response.data;
            if (terminalTypes.length > 0) {
                return 'resources.terminalTypes.crud.edit.validation.terminalNumbersStoreCodeUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    name: [maxLength(255), required(), nameChainIdUniqueValidation],
    chainId: [required(), nameChainIdUniqueValidation],
    terminalNumbers: [numbersValidation],
    storeCode: [required()],
    //terminalNumberTypes: [terminalNumbersStoreCodeUniqueValidation]
};

const TerminalTypeEdit2 = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const { record } = useEditController(props);
    const classes = useStyles();

    const [terminalNumberTypes, setTerminalNumberTypes] = useState([{ storeCode: null, terminalNumbers: [] }]);

    const onSuccess = ({ data }) => {
        notify(`resources.terminalTypes.crud.edit.notification.success`, 'success');
        redirect(`/terminalTypes/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.terminalTypes.crud.edit.notification.failed`, 'error');
    };

    const handleRemove = index => {
        const list = [...terminalNumberTypes];
        list.splice(index, 1);
        setTerminalNumberTypes(list);
    };

    const handleAdd = () => {
        setTerminalNumberTypes([...terminalNumberTypes, { storeCode: null, terminalNumbers: [] }]);
    };

    return (
        <Edit
            title={<CustomTitle action="edit" {...props} />}
            actions={<TerminalTypeEditActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            mutationMode="pessimistic"
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="id" resource="terminalTypes" helperText={false} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="terminalTypes"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="chainId"
                                    record={record}
                                    reference="chains"
                                    originSource="id"
                                    label={translate('resources.terminalTypes.fields.chain')}
                                    optionText="name"
                                    validate={validations.chainId}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.terminalTypes.crud.edit.terminalTypeNumbers')}
                                </Typography>
                            </Grid>
                            {terminalNumberTypes.map((terminalNumberType, idx) => (
                                <React.Fragment>
                                    <Grid item xs={12} sm={4}>
                                        <FormDataConsumer>
                                            {({ formData }) => (
                                                <CustomReferenceAutocompleteInput
                                                    source={`terminalNumberTypes.${idx}.storeCode`}
                                                    record={record}
                                                    reference="stores"
                                                    originSource="storeCode"
                                                    label={translate('resources.terminalTypes.fields.storeCode')}
                                                    optionText="storeCode"
                                                    //validate={validations.storeCode}
                                                    additionalFilter={
                                                        formData.chainId ?
                                                            { chainId: formData.chainId } :
                                                            null
                                                    }
                                                    className={classes.input}
                                                />
                                            )}
                                        </FormDataConsumer>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CustomMultiselectInput
                                            source={`terminalNumberTypes.${idx}.terminalNumbers`}
                                            record={record}
                                            label={translate('resources.terminalTypes.fields.terminalNumbers')}
                                            //validate={validations.terminalNumbers}
                                            className={classes.input}
                                        />
                                    </Grid>
                                    <Grid item container spacing={1} xs justifyContent="flex-end">
                                        {terminalNumberTypes.length !== 1 && (
                                            <Grid item>
                                                <IconButton
                                                    aria-label="Quitar"
                                                    color="error"
                                                    onClick={handleRemove}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                        {terminalNumberTypes.length - 1 === idx && (
                                            <Grid item>
                                                <IconButton
                                                    aria-label="Agregar"
                                                    color="primary"
                                                    onClick={handleAdd}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                        <CustomEditToolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            record={record}
                            resource={props.resource}
                            basePath={props.basePath}
                        />
                    </form>
                )}
            />
        </Edit>
    );
};

const TerminalTypeEdit = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const { record } = useEditController(props);
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        notify(`resources.terminalTypes.crud.edit.notification.success`, 'success');
        redirect(`/terminalTypes/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.terminalTypes.crud.edit.notification.failed`, 'error');
    };

    const handleRemove = pop => index => {
        pop('terminalNumberTypesToEdit')
        //list.splice(index, 1);
    };

    const handleAdd = push => () => {
        push('terminalNumberTypesToEdit', { storeCode: null, terminalNumbers: [] });
    };

    return (
        <Edit
            title={<CustomTitle action="edit" {...props} />}
            actions={<TerminalTypeEditActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            mutationMode="pessimistic"
            {...props}
        >
            <FormWithRedirect
                {...props}
                mutators={{
                    ...arrayMutators
                }}
                //initialValues={{ terminalNumberTypesToEdit: [{ storeCode: null, terminalNumbers: [] }] }}
                initialValues={{ terminalNumberTypesToEdit: [{}] }}
                //validateOnBlur={true}
                render={({ saving, handleSubmitWithRedirect, form: { mutators: { push, pop  } } }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="id" resource="terminalTypes" helperText={false} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="terminalTypes"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="chainId"
                                    record={record}
                                    reference="chains"
                                    originSource="id"
                                    label={translate('resources.terminalTypes.fields.chain')}
                                    optionText="name"
                                    validate={validations.chainId}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.terminalTypes.crud.edit.terminalTypeNumbers')}
                                </Typography>
                            </Grid>
                            <FieldArray
                                name="terminalNumberTypesToEdit"
                                // validate={terminalNumbersStoreCodeUniqueValidation}
                            >
                                {({ fields, ...rest }) => {
                                    console.log('rest:', rest)
                                    return fields.map((name, index) => (
                                        <React.Fragment key={name}>
                                            <Grid item xs={12} sm={4}>
                                                <FormDataConsumer>
                                                    {({ formData }) => (
                                                        <CustomReferenceAutocompleteInput
                                                            source={`${name}.storeCode`}
                                                            record={record}
                                                            reference="stores"
                                                            originSource="storeCode"
                                                            label={translate('resources.terminalNumberTypes.fields.storeCode')}
                                                            optionText="storeCode"
                                                            // validate={validations.storeCode}
                                                            additionalFilter={
                                                                formData.chainId ?
                                                                    { chainId: formData.chainId } :
                                                                    null
                                                            }
                                                            className={classes.input}
                                                        />
                                                    )}
                                                </FormDataConsumer>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <CustomMultiselectInput
                                                    source={`${name}.terminalNumbers`}
                                                    record={record}
                                                    label={translate('resources.terminalNumberTypes.fields.terminalNumber', 2)}
                                                    // validate={[
                                                    //     ...validations.terminalNumbers,
                                                    //     terminalNumbersStoreCodeUniqueValidationIndex(index)
                                                    // ]}
                                                    className={classes.input}
                                                />
                                            </Grid>
                                            <Grid item container spacing={1} xs justifyContent="flex-end">
                                                {fields.length !== 1 && (
                                                    <Grid item>
                                                        <IconButton
                                                            aria-label="Quitar"
                                                            color="error"
                                                            //onClick={handleRemove(pop)}
                                                            onClick={() => {
                                                                console.log('name:', name)
                                                                console.log('index:', index)
                                                                console.log('fields:', fields.map(e => e))
                                                                fields.remove(index)
                                                            }}
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                                {fields.length - 1 === index && (
                                                    <Grid item>
                                                        <IconButton
                                                            aria-label="Agregar"
                                                            color="primary"
                                                            onClick={handleAdd(push)}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                }}
                            </FieldArray>

                            {/*{terminalNumberTypes.map((terminalNumberType, idx) => (*/}
                            {/*    <React.Fragment>*/}
                            {/*        <Grid item xs={12} sm={4}>*/}
                            {/*            <FormDataConsumer>*/}
                            {/*                {({ formData }) => (*/}
                            {/*                    <CustomReferenceAutocompleteInput*/}
                            {/*                        source={`terminalNumberTypes.${idx}.storeCode`}*/}
                            {/*                        record={record}*/}
                            {/*                        reference="stores"*/}
                            {/*                        originSource="storeCode"*/}
                            {/*                        label={translate('resources.terminalTypes.fields.storeCode')}*/}
                            {/*                        optionText="storeCode"*/}
                            {/*                        //validate={validations.storeCode}*/}
                            {/*                        additionalFilter={*/}
                            {/*                            formData.chainId ?*/}
                            {/*                                { chainId: formData.chainId } :*/}
                            {/*                                null*/}
                            {/*                        }*/}
                            {/*                        className={classes.input}*/}
                            {/*                    />*/}
                            {/*                )}*/}
                            {/*            </FormDataConsumer>*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={12} sm={6}>*/}
                            {/*            <CustomMultiselectInput*/}
                            {/*                source={`terminalNumberTypes.${idx}.terminalNumbers`}*/}
                            {/*                record={record}*/}
                            {/*                label={translate('resources.terminalTypes.fields.terminalNumbers')}*/}
                            {/*                //validate={validations.terminalNumbers}*/}
                            {/*                className={classes.input}*/}
                            {/*            />*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item container spacing={1} xs justifyContent="flex-end">*/}
                            {/*            {terminalNumberTypes.length !== 1 && (*/}
                            {/*                <Grid item>*/}
                            {/*                    <IconButton*/}
                            {/*                        aria-label="Quitar"*/}
                            {/*                        color="error"*/}
                            {/*                        onClick={handleRemove}*/}
                            {/*                    >*/}
                            {/*                        <RemoveIcon />*/}
                            {/*                    </IconButton>*/}
                            {/*                </Grid>*/}
                            {/*            )}*/}
                            {/*            {terminalNumberTypes.length - 1 === idx && (*/}
                            {/*                <Grid item>*/}
                            {/*                    <IconButton*/}
                            {/*                        aria-label="Agregar"*/}
                            {/*                        color="primary"*/}
                            {/*                        onClick={handleAdd}*/}
                            {/*                    >*/}
                            {/*                        <AddIcon />*/}
                            {/*                    </IconButton>*/}
                            {/*                </Grid>*/}
                            {/*            )}*/}
                            {/*        </Grid>*/}
                            {/*    </React.Fragment>*/}
                            {/*))}*/}
                        </Grid>
                        <CustomEditToolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            record={record}
                            resource={props.resource}
                            basePath={props.basePath}
                        />
                    </form>
                )}
            />
        </Edit>
    );
};

export default TerminalTypeEdit;
