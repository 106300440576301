import * as React from 'react';
import { createRef, useEffect, useState } from 'react';
import { NumberField, useTranslate, useLocale } from 'react-admin';
import {
    Typography, CardContent, Card, CardHeader, IconButton, CircularProgress, Menu, MenuItem, Divider
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import CustomError from '../../custom/CustomError';
import getBusinessDayDateRange from '../../../helpers/getBusinessDayDateRange';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { exportComponentAsPNG } from 'react-component-export-image';
import moment from 'moment';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    card: {
        minHeight: '150px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loading: {
        minHeight: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    kpiText: {
        fontSize: 50,
        color: '#03D2AE'
    },
});

const periodOptions = [
    { id: 'yesterday', name: 'pos.dashboard.kpi.periods.yesterday', type: 'day' },
    { id: 'thisMonth', name: 'pos.dashboard.kpi.periods.thisMonth', type: 'month' },
    { id: 'lastMonth', name: 'pos.dashboard.kpi.periods.lastMonth', type: 'month' },
    { id: 'thisYear', name : 'pos.dashboard.kpi.periods.thisYear', type: 'year' },
    // { id: 'lastYear', name: 'pos.dashboard.kpi.periods.lastYear', type: 'year' }
];

const HoursSaved = ({ filters, periodId = 'yesterday' }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();

    const [periodSelected, setPeriodSelected] = useState(periodOptions.find(e => e.id === periodId));
    const [anchorEl, setAnchorEl] = useState(null);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const cardRef = createRef();

    let { storeCode, chainId } = filters ? filters : {};

    const handleClickMenuButton = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickMenuItem = (event, period) => {
        setPeriodSelected(period);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExport = () => {
        if (cardRef && cardRef.current) {
            exportComponentAsPNG(cardRef, {
                fileName: `${translate('pos.dashboard.kpi.hoursSaved.name')} ` +
                    `(${translate(`pos.dashboard.kpi.periods.${periodSelected.id}`)})`,
                html2CanvasOptions: {
                    backgroundColor: 'white',
                    scale: 2
                }
            }).then();
        }
    };

    useEffect(() => {
        setLoading(true);

        const params = {
            type: 'seconds-per-item-line-group-by-field-and-datetime',
            terminalTypeId: 38,
            storeCode: storeCode,
            chainId: chainId
        };

        const firstQueryString = stringify({
            ...params,
            precision: periodSelected.type,
            businessDayDate: getBusinessDayDateRange(periodSelected.id)
        }, { strictNullHandling: true });

        let compareQueryString;
        switch (periodSelected.id) {
            case 'thisMonth':
                compareQueryString = stringify({
                    ...params,
                    precision: 'month',
                    businessDayDate: {
                        $gte: moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                        //$lte: moment().endOf('year').format('YYYY-MM-DD HH:mm:ss')
                        $lte: moment(getBusinessDayDateRange(periodSelected.id).$gte)
                            .subtract(1, 'day').endOf('month').format('YYYY-MM-DD HH:mm:ss')
                    }
                }, { strictNullHandling: true });
                break;
            case 'lastMonth':
                compareQueryString = stringify({
                    ...params,
                    precision: 'month',
                    businessDayDate: {
                        $gte: moment().subtract(1, 'month').startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                        //$lte: moment().subtract(1, 'month').endOf('year').format('YYYY-MM-DD HH:mm:ss')
                        $lte: moment(getBusinessDayDateRange(periodSelected.id).$gte)
                            .subtract(1, 'day').endOf('month').format('YYYY-MM-DD HH:mm:ss')
                    }
                }, { strictNullHandling: true });
                break;
            case 'thisYear':
                compareQueryString = stringify({
                    ...params,
                    precision: 'month',
                    businessDayDate: {
                        $gte: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                        $lte: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD HH:mm:ss')
                    }
                }, { strictNullHandling: true });
                break;
            case 'yesterday':
            default:
                compareQueryString = stringify({
                    ...params,
                    precision: 'month',
                    businessDayDate: {
                        $gte: moment().subtract(1, 'day').startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                        //$lte: moment().subtract(1, 'day').endOf('year').format('YYYY-MM-DD HH:mm:ss')
                        $lte: moment(getBusinessDayDateRange(periodSelected.id).$gte)
                            .subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
                    }
                }, { strictNullHandling: true });
                break;
        }

        let promises = [
            axios.get(`/statistics?${firstQueryString}`),
            axios.get(`/statistics?${compareQueryString}`)
        ];

        Promise.all(promises)
            .then(([firstResponse, compareResponse]) => {
                const { data: firstData } = firstResponse.data;
                const { data: compareData } = compareResponse.data;

                if (firstData.length > 0 && compareData.length > 0) {
                    const worthMonth = _.maxBy(compareData, 'secondsPerItemLine');

                    setData({
                        hoursSaved: ((worthMonth.secondsPerItemLine * firstData[0].itemLineQuantity) - firstData[0].ringElapsedTime) / 3600
                    });
                } else if (firstData.length === 0 && compareData.length === 0) {
                    setData();
                } else {
                    setData({
                        hoursSaved: 0
                    });
                }
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [storeCode, chainId, periodSelected]);

    if (error) {
        return (
            <CustomError
                errorSecondary={translate('pos.dashboard.kpi.errors.noKpi', {
                    kpi_name: translate('pos.dashboard.kpi.hoursSaved.name')
                })}
            />
        );
    }

    return (
        <Card className={classes.card} ref={cardRef}>
            <CardHeader
                action={
                    <IconButton onClick={handleClickMenuButton}>
                        <MoreVertIcon />
                    </IconButton>
                }
                title={translate('pos.dashboard.kpi.hoursSaved.name')}
                subheader={translate(`pos.dashboard.kpi.periods.${periodSelected.id}`)}
                titleTypographyProps={{
                    variant: 'h5',
                    align: 'center'
                }}
                subheaderTypographyProps={{
                    variant: 'h6',
                    align: 'center',
                    color: 'textSecondary'
                }}
            />
            {loading ? (
                <CardContent className={classes.loading}>
                    <CircularProgress />
                </CardContent>
            ) : (!loading && !data) ? (
                <CardContent>
                    <CustomError
                        severity="warning"
                        errorPrimary={translate('pos.generic.warning')}
                        errorSecondary={translate('pos.dashboard.kpi.errors.noData')}
                    />
                </CardContent>
            ) : (
                <CardContent>
                    <Typography align="center">
                        <NumberField
                            record={data}
                            source="hoursSaved"
                            locales={locale}
                            className={classes.kpiText}
                        />
                    </Typography>
                </CardContent>
            )}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
            >
                {periodOptions.map(option => (
                    <MenuItem
                        key={option.id}
                        selected={option.id === periodSelected.id}
                        onClick={event => handleClickMenuItem(event, option)}
                    >
                        {translate(option.name)}
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem
                    key="export"
                    onClick={handleExport}
                    disabled={loading || !data}
                >
                    {translate('ra.action.export')}
                </MenuItem>
            </Menu>
        </Card>
    );
};

export default HoursSaved;
