import * as React from 'react';
import { useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FieldTitle, useInput } from 'ra-core';
import moment from 'moment';
import 'moment/locale/es';
import MomentUtils from '@date-io/moment';

const CustomDateInput = ({ source, resource, label, isRequired, shouldDisableDate, onChange, ...props }) => {
    const translate = useTranslate();
    const { input, meta } = useInput({ source });
    const { touched, error } = meta;

    const handleChange = useCallback(value => {
        value ? input.onChange(value.format('YYYY-MM-DD')) : input.onChange(null);
    }, [input]);

    const handleBlur = () => {
        const date = input.value ?
            moment(input.value, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;
        input.onBlur(date);
    };

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DatePicker
                label={
                    <FieldTitle
                        source={source}
                        resource={resource}
                        label={label}
                        isRequired={isRequired}
                    />
                }
                error={!!(touched && error)}
                helperText={touched && error}
                value={input.value ? moment(input.value) : null}
                onChange={date => {
                    if (onChange) {
                        onChange(date);
                    }
                    handleChange(date);
                }}
                onBlur={handleBlur}
                shouldDisableDate={shouldDisableDate}
                format={translate('pos.datetime.date')}
                clearable
                inputVariant="filled"
                margin="normal"
                size="small"
                okLabel={translate('pos.generic.ok')}
                cancelLabel={translate('ra.action.cancel')}
                clearLabel={translate('pos.generic.clear', 1)}
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
};

export default CustomDateInput;
