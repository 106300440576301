import * as React from 'react';
import { TextField } from 'react-admin';
import resourcesConfig from '../../../config/resourcesConfig.json';

const InvoiceNumberField = props => {
    const emptyText = resourcesConfig.general.emptyField;

    let invoiceNumber;
    if (props.record.userStrings) {
        const userString = props.record.userStrings.find(e => e.originator === '06' && e.subtype === '20');
        if (userString) {
            invoiceNumber = userString.userData.invoiceNumberForPrinting;
        }
    }

    const record = {
        [props.source]: invoiceNumber
    };

    return (
        <TextField record={record} source={props.source} emptyText={emptyText} />
    );
};

export default InvoiceNumberField;
