import * as React from 'react';
import { Fragment, useState } from 'react';
import ActionDelete from '@material-ui/icons/Delete';
import { fade } from '@material-ui/core/styles/colorManipulator';
import inflection from 'inflection';
import { makeStyles } from '@material-ui/core/styles';
import {
    useTranslate, useDeleteMany, useRefresh, useNotify, useUnselectAll, CRUD_DELETE_MANY, useResourceContext
} from 'ra-core';
import { Button, Confirm } from 'react-admin';

const useStyles = makeStyles(theme => ({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    }),
    { name: 'RaBulkDeleteWithConfirmButton' }
);

const BulkDeleteWithConfirmButton = props => {
    const {
        basePath,
        classes: classesOverride,
        confirmTitle = 'ra.message.bulk_delete_title',
        confirmContent = 'ra.message.bulk_delete_content',
        label,
        onClick,
        selectedIds,
        onSuccess = () => {
            refresh();
            notify(`resources.${resource}.crud.bulkDelete.notification.success`, 'success', selectedIds.length);
            unselectAll(resource);
        },
        onFailure = error => {
            console.log(error);
            notify(`resources.${resource}.crud.bulkDelete.notification.failed`, 'error', selectedIds.length);
            setOpen(false);
        },
        ...rest
    } = props;

    const [isOpen, setOpen] = useState(false);
    const classes = useStyles(props);
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();
    const translate = useTranslate();
    const resource = useResourceContext(props);
    const [deleteMany, { loading }] = useDeleteMany(resource, selectedIds, {
        action: CRUD_DELETE_MANY,
        onSuccess,
        onFailure
    });

    const handleClick = e => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleDelete = e => {
        deleteMany();

        if (typeof onClick === 'function') {
            onClick(e);
        }
    };

    return (
        <Fragment>
            <Button
                onClick={handleClick}
                label="ra.action.delete"
                className={classes.deleteButton}
                {...rest}
            >
                <ActionDelete />
            </Button>
            <Confirm
                isOpen={isOpen}
                loading={loading}
                title={confirmTitle}
                content={confirmContent}
                translateOptions={{
                    smart_count: selectedIds.length,
                    name: translate(`resources.${resource}.forcedCaseName`, {
                        smart_count: selectedIds.length,
                        _: inflection.humanize(
                            translate(`resources.${resource}.name`, {
                                smart_count: selectedIds.length,
                                _: inflection.inflect(
                                    resource,
                                    selectedIds.length
                                ),
                            }),
                            true
                        ),
                    }),
                }}
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default BulkDeleteWithConfirmButton;