import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout, useTranslate } from 'react-admin';
import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    rootListItemIcon: {
        minWidth: '40px'
    }
});

const CustomLogoutButton = forwardRef((props, ref) => {
    const translate = useTranslate();
    const logout = useLogout();
    const classes = useStyles();

    const handleClick = () => logout();

    return (
        <MenuItem onClick={handleClick} ref={ref} button>
            <ListItemIcon classes={{ root: classes.rootListItemIcon }}>
                <ExitIcon />
            </ListItemIcon>
            <ListItemText primary={translate('ra.auth.logout')} />
        </MenuItem>
    );
});

export default CustomLogoutButton;
