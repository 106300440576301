import * as React from 'react';
import {
    AccountCircle as AccountCircleIcon,
    AssignmentInd as AssignmentIndIcon,
    Business as BusinessIcon,
    Class as ClassIcon,
    Create as CreateIcon,
    Dashboard as DashboardIcon,
    Description as DescriptionIcon,
    Error as ErrorIcon,
    Group as GroupIcon,
    GroupWork as GroupWorkIcon,
    Help as HelpIcon,
    PieChart as PieChartIcon,
    Receipt as ReceiptIcon,
    Settings as SettingsIcon,
    Store as StoreIcon,
    ViewList as ViewListIcon,
    DesktopWindows as DesktopWindowsIcon,
    AttachFile as AttachFileIcon
} from '@material-ui/icons';

const getMenu = () => {
    let user = localStorage.getItem('user');
    user = user ? JSON.parse(user) : null;

    return {
        children: [
            {
                name: 'dashboard',
                isRestricted: false,
                translation: {
                    key: 'pos.dashboard.name'
                },
                to: '/',
                icon: <DashboardIcon />
            },
            {
                name: 'transactions',
                subject: 'transactions',
                isRestricted: true,
                translation: {
                    key: 'resources.transactions.name',
                    options: 2
                },
                basePath: 'transactions',
                icon: <ReceiptIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/transactions',
                        icon: <ViewListIcon />
                    },
                    {
                        name: 'statistics',
                        subject: 'statistics',
                        isRestricted: true,
                        translation: {
                            key: 'resources.transactions.statistics.name'
                        },
                        to: '/transactions/statistics',
                        icon: <PieChartIcon />
                    },
                    /*{
                        name: 'transactions-2',
                        subject: 'transactions',
                        isRestricted: false,
                        translation: {
                            key: 'resources.transactions.name',
                            options: 2
                        },
                        basePath: 'transactions/transactions-2',
                        icon: <ReceiptIcon />,
                        children: [
                            {
                                name: 'create',
                                action: 'create',
                                isRestricted: true,
                                translation: {
                                    key: 'ra.action.create'
                                },
                                to: '/transactions/transactions-2/list',
                                icon: <ViewListIcon />
                            }
                        ]
                    }*/
                ]
            },
            {
                name: 'exceptions',
                subject: 'exceptions',
                isRestricted: true,
                translation: {
                    key: 'resources.exceptions.name',
                    options: 2
                },
                basePath: 'exceptions',
                icon: <ErrorIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/exceptions',
                        icon: <ViewListIcon />
                    }
                ]
            },
            {
                name: 'chains',
                subject: 'chains',
                isRestricted: true,
                translation: {
                    key: 'resources.chains.name',
                    options: 2
                },
                basePath: 'chains',
                icon: <BusinessIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/chains',
                        icon: <ViewListIcon />
                    },
                    {
                        name: 'create',
                        action: 'create',
                        translation: {
                            key: 'ra.action.create'
                        },
                        to: '/chains/create',
                        icon: <CreateIcon />
                    },
                ]
            },
            {
                name: 'stores',
                subject: 'stores',
                isRestricted: true,
                translation: {
                    key: 'resources.stores.name',
                    options: 2
                },
                basePath: 'stores',
                icon: <StoreIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/stores',
                        icon: <ViewListIcon />
                    },
                    {
                        name: 'create',
                        action: 'create',
                        translation: {
                            key: 'ra.action.create'
                        },
                        to: '/stores/create',
                        icon: <CreateIcon />
                    },
                ]
            },
            {
                name: 'terminalTypes',
                subject: 'terminalTypes',
                isRestricted: true,
                translation: {
                    key: 'resources.terminalTypes.name',
                    options: 2
                },
                basePath: 'terminalTypes',
                icon: <DesktopWindowsIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/terminalTypes',
                        icon: <ViewListIcon />
                    },
                    {
                        name: 'create',
                        action: 'create',
                        translation: {
                            key: 'ra.action.create'
                        },
                        to: '/terminalTypes/create',
                        icon: <CreateIcon />
                    },
                ]
            },
            {
                name: 'groups',
                subject: 'groups',
                isRestricted: true,
                translation: {
                    key: 'resources.groups.name',
                    options: 2
                },
                basePath: 'groups',
                icon: <GroupWorkIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/groups',
                        icon: <ViewListIcon />
                    },
                    {
                        name: 'create',
                        action: 'create',
                        translation: {
                            key: 'ra.action.create'
                        },
                        to: '/groups/create',
                        icon: <CreateIcon />
                    },
                ]
            },
            {
                name: 'operators',
                subject: 'operators',
                isRestricted: true,
                translation: {
                    key: 'resources.operators.name',
                    options: 2
                },
                basePath: 'operators',
                icon: <AssignmentIndIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/operators',
                        icon: <ViewListIcon />
                    },
                    {
                        name: 'create',
                        action: 'create',
                        translation: {
                            key: 'ra.action.create'
                        },
                        to: '/operators/create',
                        icon: <CreateIcon />
                    },
                ]
            },
            {
                name: 'itemDetails',
                subject: 'itemDetails',
                isRestricted: true,
                translation: {
                    key: 'resources.itemDetails.name',
                    options: 2
                },
                basePath: 'itemDetails',
                icon: <DescriptionIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/itemDetails',
                        icon: <ViewListIcon />
                    },
                    {
                        name: 'create',
                        action: 'create',
                        translation: {
                            key: 'ra.action.create'
                        },
                        to: '/itemDetails/create',
                        icon: <CreateIcon />
                    },
                ]
            },
            {
                name: 'departments',
                subject: 'departments',
                isRestricted: true,
                translation: {
                    key: 'resources.departments.name',
                    options: 2
                },
                basePath: 'departments',
                icon: <ClassIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/departments',
                        icon: <ViewListIcon />
                    },
                    {
                        name: 'create',
                        action: 'create',
                        translation: {
                            key: 'ra.action.create'
                        },
                        to: '/departments/create',
                        icon: <CreateIcon />
                    },
                ]
            },
            {
                name: 'files',
                subject: 'files',
                isRestricted: true,
                translation: {
                    key: 'resources.files.name',
                    options: 2
                },
                basePath: 'files',
                icon: <AttachFileIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/files',
                        icon: <ViewListIcon />
                    },
                ]
            },
            {
                name: 'users',
                subject: 'users',
                isRestricted: false,
                translation: {
                    key: 'resources.users.name',
                    options: 2
                },
                basePath: 'users',
                icon: <GroupIcon />,
                children: [
                    {
                        name: 'list',
                        action: 'find',
                        isRestricted: true,
                        translation: {
                            key: 'ra.action.list'
                        },
                        to: '/users',
                        icon: <ViewListIcon />
                    },
                    {
                        name: 'create',
                        action: 'create',
                        isRestricted: true,
                        translation: {
                            key: 'ra.action.create'
                        },
                        to: '/users/create',
                        icon: <CreateIcon />
                    },
                    ...(user ? [{
                        name: 'show-profile',
                        action: 'get',
                        isRestricted: true,
                        translation: {
                            key: 'ra.auth.user_menu'
                        },
                        to: `/users/${user.id}/show`,
                        icon: <AccountCircleIcon />
                    }] : []),
                ]
            },
            {
                name: 'configuration',
                translation: {
                    key: 'pos.configuration.name'
                },
                to: '/configuration',
                icon: <SettingsIcon />
            },
            {
                name: 'help',
                translation: {
                    key: 'pos.help.name'
                },
                to: '/help',
                icon: <HelpIcon />
            },
        ]
    };
};

export default getMenu;
