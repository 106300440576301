import * as React from 'react';
import { useContext } from 'react';
import { AbilityContext } from '../../Can';
import {
    TabbedShowLayout, TabbedShowLayoutTabs, Show, Tab, TopToolbar, ReferenceManyField, useShowController
} from 'react-admin';
import UserGeneral from './UserGeneral';
import { ChainList } from '../chains';
import { GroupList } from '../groups';
import { StoreList } from '../stores';
import CustomTitle from '../../layout/CustomTitle';
import { CustomListButton, CustomEditButton } from '../../custom/CustomButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { flexGrow: 1 },
    tabs: { width: 0 },
    table: { paddingBottom: '20px' },
});

const UserShowActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
            <CustomEditButton {...props} record={props.data}/>
        </TopToolbar>
    );
};

const UserShow = props => {
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const { record } = useShowController(props);

    return (
        <Show
            title={<CustomTitle action="show" {...props} />}
            actions={<UserShowActions />}
            className={classes.root}
            {...props}
        >
            <TabbedShowLayout
                tabs={
                    <TabbedShowLayoutTabs
                        classes={{ scrollable: classes.tabs }}
                        variant="scrollable"
                        scrollButtons="on"
                    />
                }
            >
                <Tab label="resources.users.tabs.general">
                    <UserGeneral />
                </Tab>
                {(record && ability.can('find', 'chains')) &&
                    <Tab label="resources.users.tabs.chains" path="chains">
                        <ReferenceManyField reference="chains" target="id" addLabel={false} className={classes.table}>
                            <ChainList voidTitle={true} userId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && ability.can('find', 'groups')) &&
                    <Tab label="resources.users.tabs.groups" path="groups">
                        <ReferenceManyField reference="groups" target="id" addLabel={false} className={classes.table}>
                            <GroupList voidTitle={true} userId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && ability.can('find', 'stores')) &&
                    <Tab label="resources.users.tabs.stores" path="stores">
                        <ReferenceManyField reference="stores" target="id" addLabel={false} className={classes.table}>
                            <StoreList voidTitle={true} userId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    );
};

export default UserShow;
