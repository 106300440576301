import moment from 'moment';

export default function getBusinessDayDateRange(period) {
    switch (period) {
        case 'thisMonth':
            return {
                $gte: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                $lte: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
            };
        case 'lastMonth':
            return {
                $gte: moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                $lte: moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
            };
        case 'thisYear':
            return {
                $gte: moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                $lte: moment().endOf('year').format('YYYY-MM-DD HH:mm:ss')
            };
        case 'lastYear':
            return {
                $gte: moment().add(-1, 'year').startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                $lte: moment().add(-1, 'year').endOf('year').format('YYYY-MM-DD HH:mm:ss')
            };
        case 'yesterday':
        default:
            return {
                $gte: moment().add(-1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                $lte: moment().add(-1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')
            };
    }
}
