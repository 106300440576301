import * as React from 'react';
import { useEffect } from 'react';
import { Button as RaButton, ExportButton, TextInput, SelectArrayInput, useListContext, useTranslate } from 'react-admin';
import { Button, InputAdornment, Grid } from '@material-ui/core';
import {
    FilterList as FilterListIcon,
    Search as SearchIcon
} from '@material-ui/icons';
import { Form } from 'react-final-form';
import CustomReferenceAutocompleteArrayInput from '../../custom/CustomReferenceAutocompleteArrayInput';
import CustomDateTimeInput from '../../custom/CustomDateTimeInput';
import resourcesConfig from '../../../config/resourcesConfig.json';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(2)
    },
    input: {
        marginTop: 0
    }
}));

const TransactionFilter = props => {
    const classes = useStyles();

    return (
        <Grid container spacing={1} className={classes.root}>
            <Grid item xs={12}>
                <TransactionFilterButton {...props} />
            </Grid>
            <Grid item xs={12}>
                <TransactionFilterForm {...props} />
            </Grid>
        </Grid>
    );
};

const TransactionFilterButton = ({ maxResults }) => {
    const translate = useTranslate();
    const {
        currentSort,
        resource,
        filterValues,
        showFilter,
        total,
    } = useListContext();

    return (
        <Grid container justifyContent="flex-end">
            <Grid item>
                <RaButton
                    label={translate('pos.generic.filter')}
                    onClick={() => showFilter('main')}
                    size="small"
                    color="primary"
                >
                    <FilterListIcon />
                </RaButton>
            </Grid>
            <Grid item>
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={maxResults}
                />
            </Grid>
        </Grid>
    );
};

const TransactionFilterForm = ({ open, filter, filterDefaultValues = {}, disabledFilters }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const {
        displayedFilters,
        filterValues,
        setFilters,
    } = useListContext();

    useEffect(() => {
        if (displayedFilters.main && !_.isEmpty(filterDefaultValues) && _.isEmpty(filterValues)) {
            setFilters(filterDefaultValues);
        }
    }, [displayedFilters, filterDefaultValues, filterValues, setFilters]);

    if (!displayedFilters.main) return null;

    const onSubmit = values => {
        // if (values.storeCode && values.storeCode.$or) {
        //     let or = values.storeCode.$or;
        //     delete values.storeCode;
        //     values.$or = or;
        // } else if (!values.storeCode) {
        //     delete values.storeCode;
        //     delete values.$or;
        // }
        setFilters(values);
    };

    const resetFilter = () => {
        if (!_.isEmpty(filterDefaultValues)) {
            setFilters(filterDefaultValues, {});
        } else {
            setFilters({}, []);
        }
    };

    const typesAvailable = resourcesConfig.transactions.typesAvailable;
    let typeChoices = typesAvailable.map(type => ({ id: type, name: `resources.transactions.fields.type.options.${type}` }));

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4} lg={3} xl={2}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filterValues}
                                    reference="stores"
                                    disabled={disabledFilters && disabledFilters.storeCode}
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3} xl={2}>
                                <TextInput
                                    source="operatorCode"
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    disabled={disabledFilters && disabledFilters.operatorCode}
                                    helperText={false}
                                    resettable
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon color="disabled" />
                                            </InputAdornment>
                                        )
                                    }}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3} xl={2}>
                                <TextInput
                                    source="terminalNumber"
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    disabled={disabledFilters && disabledFilters.terminalNumber}
                                    helperText={false}
                                    resettable
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon color="disabled" />
                                            </InputAdornment>
                                        )
                                    }}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3} xl={2}>
                                <SelectArrayInput
                                    source="type"
                                    label="resources.transactions.fields.type.name"
                                    choices={typeChoices}
                                    disabled={disabledFilters && disabledFilters.type}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3} xl={2}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    disabled={disabledFilters && disabledFilters.businessDayDate && disabledFilters.businessDayDate.$gte}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3} xl={2}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    disabled={disabledFilters && disabledFilters.businessDayDate && disabledFilters.businessDayDate.$lte}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container spacing={1} xs justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="outlined" color="primary" type="submit">
                                        {translate('pos.generic.filter')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={resetFilter}>
                                        {translate('ra.action.close')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        </div>
    );
};

export default TransactionFilter;
