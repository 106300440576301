import * as React from 'react';
import {createElement, useContext, useState, useMemo, cloneElement} from 'react';
import { Title, useTranslate } from 'react-admin';
import { Card, CardContent, Grid, Tabs } from '@material-ui/core';
import CustomTab from '../../../custom/CustomTab';
import CustomTabPanel from '../../../custom/CustomTabPanel';
import TransactionsBar from './TransactionsBar';
import ItemsPie from './ItemsPie';
import ItemsTreeMapCanvas from './ItemsTreeMapCanvas';
import ItemsDatetimeLine from './ItemsDatetimeLine';
import TransactionsDatetimeLine from './TransactionsDatetimeLine';
import TransactionsTimeIntervalLine from './TransactionsTimeIntervalLine';
import ItemsPerTransactionLine from './ItemsPerTransactionLine';
import ItemsPerTransactionBar from './ItemsPerTransactionBar';
import ItemsPerTransactionDatetimeLine from './ItemsPerTransactionDatetimeLine';
import TendersTenderTypesPie from './TendersTenderTypesPie';
import TendersPerTransactionPie from './TendersPerTransactionPie';
import TendersPerWeekdayBar from './TendersPerWeekdayBar';
import TendersPerWeekdayPercentageBar from './TendersPerWeekdayPercentageBar';
import TransactionsTimesBar from './TransactionsTimesBar';
import ChangesAndCashByTerminalNumberBar from './ChangesAndCashByTerminalNumberBar';
import ChangesDatetimeLine from './ChangesDatetimeLine';
import TransactionsExactPaymentBar from './TransactionsExactPaymentBar';
import TransactionsWithCoinsPaymentBar from './TransactionsWithCoinsPaymentBar';
import TendersWithCoinsPaymentBar from './TendersWithCoinsPaymentBar';
import OverridesVoidedItemsSunburst from './OverridesVoidedItemsSunburst';
import OverridesVoidedTransactionsSunburst from './OverridesVoidedTransactionsSunburst';
import OverridesVoidedItemsDatetimeLine from './OverridesVoidedItemsDatetimeLine';
import Cashiers from './Cashiers';
import CashiersWithOverrides from './CashiersWithOverrides';
import CompareProductivity from './CompareProductivity';
import QueueLengthPerStore from './QueueLengthPerStore';
import ActiveCashiersBar from './ActiveCashiersBar';
import QuantityActiveCashiersBar from './QuantityActiveCashiersBar';
import HoursSaved from './HoursSaved';
import ItemsZeroPriceSunburst from './ItemsZeroPriceSunburst';
import { AbilityContext } from '../../../Can';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import { Route, useLocation } from 'react-router-dom';
import {
    TabbedShowLayout,
    TabbedShowLayoutTabs,
    Tab,
} from 'react-admin';

import CustomTabbedLayout from '../../../custom/CustomTabbedLayout';
import CustomTabbedLayoutTabs, {getTabFullPath} from '../../../custom/CustomTabbedLayoutTabs';
import CustomTab2 from '../../../custom/CustomTab2';
import {escapePath} from "ra-core";



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        minMarginRight: '20px'
    },
    rootTabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    scrollableTabs: { width: 0 },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.divider}`
    }
}));

const statistics = {
    generalSales: {
        'general-group-by-time-interval': TransactionsTimeIntervalLine,
        'general-group-by-field': TransactionsBar,
        'general-group-by-field-and-datetime': TransactionsDatetimeLine
    },
    itemSales: {
        'items-group-by-field': [ItemsPie, ItemsTreeMapCanvas],
        'items-group-by-field-and-datetime': ItemsDatetimeLine,
        'items-per-transaction': [ItemsPerTransactionLine, ItemsPerTransactionBar],
        'items-per-transaction-group-by-datetime': ItemsPerTransactionDatetimeLine,
        'items-zero-price': ItemsZeroPriceSunburst
    },
    tenders: {
        'tenders-tenders-types': TendersTenderTypesPie,
        'tenders-per-transaction': TendersPerTransactionPie,
        'tenders-group-by-weekday': [TendersPerWeekdayBar, TendersPerWeekdayPercentageBar]
    },
    payments: {
        'transactions-exact-and-no-exact-payment': TransactionsExactPaymentBar,
        'transactions-with-coins-and-without-coins-payment': TransactionsWithCoinsPaymentBar,
        'tenders-with-coins-and-without-coins-payment': TendersWithCoinsPaymentBar
    },
    changes: {
        'changes-and-cash-by-terminal-number': ChangesAndCashByTerminalNumberBar,
        'changes-group-by-field-and-datetime': ChangesDatetimeLine
    },
    operatorProductivity: {
        'general-times': TransactionsTimesBar,
        'cashiers': Cashiers,
        'cashiers-with-overrides-transactions': CashiersWithOverrides,
        //'compare-productivity': CompareProductivity,
        'queue-length-per-store': QueueLengthPerStore,
        //'active-cashiers': [ActiveCashiersBar, QuantityActiveCashiersBar],
        'active-cashiers': QuantityActiveCashiersBar,
        'seconds-per-item-line-group-by-field-and-datetime': HoursSaved
    },
    overrides: {
        'overrides-voided-items': OverridesVoidedItemsSunburst,
        'overrides-voided-transactions': OverridesVoidedTransactionsSunburst,
        'overrides-voided-items-by-datetime': OverridesVoidedItemsDatetimeLine
    }
};

const verifyAccess = rules => typesSearch => {
    typesSearch = _.isArray(typesSearch) ? typesSearch : [typesSearch];

    return rules.some(rule => {
        let conditions = _.get(rule, 'conditions');
        if (_.isEmpty(conditions)) {
            return true;
        }
        let types = _.get(conditions, 'type.$in');
        return _.intersection(types, typesSearch).length > 0;
    });
};

const Statistics2 = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const ability = useContext(AbilityContext);

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const verifyAccessWithRules = verifyAccess(ability.rulesFor('find', 'statistics'));

    const checkedStatistics = useMemo(() => {
        let checkedStatistics = {};
        Object.entries(statistics).forEach(([statistic, types], index) => {
            if (verifyAccessWithRules(Object.keys(types))) {
                checkedStatistics[statistic] = {};
                Object.entries(types).forEach(([type, components], index) => {
                    if (verifyAccessWithRules(type)) {
                        checkedStatistics[statistic][type] = components;
                    }
                });
            }
        });
        return checkedStatistics;
    }, [verifyAccessWithRules]);

    return (
        <Card className={classes.root}>
            <CustomTabbedLayout
                tabs={
                    <CustomTabbedLayoutTabs
                        classes={{ scrollable: classes.scrollableTabs }}
                        variant="scrollable"
                        scrollButtons="on"
                    />
                }
                basePath="/transactions/statistics"
                //basePath="/"
            >
                {Object.entries(checkedStatistics).map(([statistic, types], index) => (
                    <CustomTab2 label={translate(`resources.transactions.statistics.tabs.${statistic}.name`)} path={statistic} key={index}>
                        <Title
                            title={`${translate('resources.transactions.statistics.name')} - ${translate(`resources.transactions.statistics.tabs.${statistic}.name`)}`}
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                {Object.values(types).map((components, typeIndex, typeValues) => {
                                    components = _.isArray(components) ? components : [components];
                                    return components.map((component, index) => {
                                        let className;
                                        if ((typeIndex < typeValues.length - 1) || (index < components.length - 1)) {
                                            className = classes.borderBottom;
                                        }

                                        return (
                                            <Grid item xs={12} className={className} key={index}>
                                                {createElement(component)}
                                            </Grid>
                                        );
                                    });
                                })}
                            </Grid>
                        </CardContent>
                    </CustomTab2>
                ))}
            </CustomTabbedLayout>
        </Card>
    );
};

const Statistics = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const ability = useContext(AbilityContext);

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const verifyAccessWithRules = verifyAccess(ability.rulesFor('find', 'statistics'));

    const checkedStatistics = useMemo(() => {
        let checkedStatistics = {};
        Object.entries(statistics).forEach(([statistic, types], index) => {
            if (verifyAccessWithRules(Object.keys(types))) {
                checkedStatistics[statistic] = {};
                Object.entries(types).forEach(([type, components], index) => {
                    if (verifyAccessWithRules(type)) {
                        checkedStatistics[statistic][type] = components;
                    }
                });
            }
        });
        return checkedStatistics;
    }, [verifyAccessWithRules]);

    return (
        <Card className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="on"
                classes={{root: classes.rootTabs, scrollable: classes.scrollableTabs}}
            >
                {Object.entries(checkedStatistics).map(([statistic, types], index) => (
                    <CustomTab
                        label={translate(`resources.transactions.statistics.tabs.${statistic}.name`)}
                        index={index}
                        key={index}
                        to={`statistics/${statistic}`}
                    />
                ))}
            </Tabs>
            {Object.entries(checkedStatistics).map(([statistic, types], index) => (
                <CustomTabPanel value={value} index={index} key={index}>
                    <Title
                        title={`${translate('resources.transactions.statistics.name')} - ${translate(`resources.transactions.statistics.tabs.${statistic}.name`)}`}
                    />
                    <CardContent>
                        <Grid container spacing={3}>
                            {Object.values(types).map((components, typeIndex, typeValues) => {
                                components = _.isArray(components) ? components : [components];
                                return components.map((component, index) => {
                                    let className;
                                    if ((typeIndex < typeValues.length - 1) || (index < components.length - 1)) {
                                        className = classes.borderBottom;
                                    }

                                    return (
                                        <Grid item xs={12} className={className} key={index}>
                                            {createElement(component)}
                                        </Grid>
                                    );
                                });
                            })}
                        </Grid>
                    </CardContent>
                </CustomTabPanel>
            ))}
        </Card>
    );
};

export default Statistics;
