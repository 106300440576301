import _ from 'lodash';

export default function limitLevels(data, currentLimit = 0, limit = 1) {
    if (currentLimit === limit) {
        return;
    }

    currentLimit++;
    let limitedData = [];
    if (_.isArray(data)) {
        data.forEach(e => {
            if (e.children) {
                limitedData.push({
                    ...e,
                    children: limitLevels(e.children, currentLimit, limit)
                });
            } else {
                limitedData.push(e)
            }
        });

        return limitedData;
    }

    return data;
}