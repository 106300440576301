import decodeJwt from 'jwt-decode';
import { updateAbility } from '../config/abilities';

const login = (client, options = {}) => ({ username, password, twoFaToken }) => {
    const { authenticate, userKey, permissionsKey, usernameField, passwordField } = Object.assign({}, {
        authenticate: { strategy: 'local' },
        userKey: 'user',
        permissionsKey: 'permissions',
        usernameField: 'email',
        passwordField: 'password'
    }, options);

    return client.authenticate({
        ...authenticate,
        [usernameField]: username,
        [passwordField]: password,
        ...(twoFaToken && { twoFaToken })
    })
        .then(data => {
            const user = data.user;
            localStorage.setItem(userKey, JSON.stringify(user));
            localStorage.setItem(permissionsKey, JSON.stringify(user.userRole.permissions));
            updateAbility();
            return Promise.resolve(data);
        });
};

const checkError = (client, options = {}) => ({ code }) => {
    const { storageKey, permissionsKey, logoutOnForbidden } = Object.assign({}, {
        storageKey: 'token',
        permissionsKey: 'permissions',
        logoutOnForbidden: true
    }, options);

    if (code === 401 || (logoutOnForbidden && code === 403)) {
        localStorage.removeItem(storageKey);
        localStorage.removeItem(permissionsKey);
        return Promise.reject();
    }
    return Promise.resolve();
};

const checkAuth = (client, options = {}) => () => {
    const { storageKey, redirectTo } = Object.assign({}, {
        storageKey: 'token',
        redirectTo: '/login'
    }, options);

    const hasJwtInStorage = !!localStorage.getItem(storageKey);
    const hasReAuthenticate = Object.getOwnPropertyNames(client).includes('reAuthenticate')
        && typeof client.reAuthenticate === 'function';

    if (hasJwtInStorage && hasReAuthenticate) {
        return client.reAuthenticate()
            .then(() => Promise.resolve())
            .catch(() => Promise.reject({ redirectTo }));
    }

    return hasJwtInStorage ? Promise.resolve() : Promise.reject({ redirectTo });
};

const logout = (client, options = {}) => () => {
    const { storageKey, userKey, permissionsKey } = Object.assign({}, {
        storageKey: 'token',
        userKey: 'user',
        permissionsKey: 'permissions'
    }, options);

    return client.logout()
        .then(() => {
            localStorage.removeItem(userKey);
            localStorage.removeItem(permissionsKey);
            updateAbility();
        })
        .catch(() => {
            localStorage.removeItem(storageKey);
            localStorage.removeItem(userKey);
            localStorage.removeItem(permissionsKey);
            updateAbility();
        });
};

const getIdentity = (client, options = {}) => () => {
    const { userKey, usernameField } = Object.assign({}, {
        userKey: 'user',
        usernameField: 'email'
    }, options);

    try {
        const {
            id, email, [usernameField]: username, name, lastname, operatorCode, status, userRoleId
        } = JSON.parse(localStorage.getItem(userKey));
        return Promise.resolve({
            id, email, [usernameField]: username, name, lastname, operatorCode, status, userRoleId
        });
    } catch (error) {
        return Promise.reject();
    }
};

const getPermissions = (client, options = {}) => () => {
    const { storageKey, permissionsKey, permissionsField } = Object.assign({}, {
        storageKey: 'token',
        permissionsKey: 'permissions',
        permissionsField: 'roles'
    }, options);

    const localStoragePermissions = JSON.parse(localStorage.getItem(permissionsKey));
    if (localStoragePermissions) {
        return Promise.resolve(localStoragePermissions);
    }

    try {
        const jwtToken = localStorage.getItem(storageKey);
        const decodedToken = decodeJwt(jwtToken);
        const jwtPermissions = decodedToken[permissionsField] ? decodedToken[permissionsField] : [];
        localStorage.setItem(permissionsKey, JSON.stringify(jwtPermissions));
        return Promise.resolve(jwtPermissions);
    } catch (error) {
        return Promise.reject();
    }
};

const authClient = (client, options = {}) => {
    return {
        login: login(client, options),
        checkError: checkError(client, options),
        checkAuth: checkAuth(client, options),
        logout: logout(client, options),
        getIdentity: getIdentity(client, options),
        getPermissions: getPermissions(client, options)
    };
};

export default authClient;
