import * as React from 'react';
import { useState } from 'react';
import {
    TextInput, PasswordInput, useLogin, useNotify, useTranslate, email, maxLength, required, number, minValue, maxValue
} from 'react-admin';
import { useSafeSetState } from 'ra-core';
import { Form } from 'react-final-form';
import { CardActions, CircularProgress, Button } from '@material-ui/core';
import CustomLogin from './CustomLogin';
import CustomNotification from './CustomNotification';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        button: {
            width: '100%',
        },
        icon: {
            marginRight: theme.spacing(1),
        }
    }),
    { name: 'RaLoginForm' }
);

const validations = {
    email: [email(), maxLength(255), required()],
    password: [required()],
    twoFaToken: [number(), minValue(0), maxValue(999999), required()]
};

const CustomLoginForm = props => {
    const translate = useTranslate();
    const login = useLogin();
    const notify = useNotify();
    const classes = useStyles(props);

    const [loading, setLoading] = useSafeSetState(false);
    const [requireTwoFaToken, setRequireTwoFaToken] = useState(false);

    const submit = ({ email: username, password, twoFaToken }, { change }) => {
        setLoading(true);
        login({ username, password, twoFaToken })
            .catch(({ data }) => {
                if (!data || data.inactiveUser) {
                    notify('pos.auth.notification.failed.generic', 'error');
                } else if (data.requireTwoFaToken) {
                    setRequireTwoFaToken(true);
                    notify('pos.auth.notification.info.requireTwoFaToken', 'info', null, false, false);
                } else if (data.emailOrPasswordIsInvalid) {
                    notify('pos.auth.notification.failed.emailOrPasswordIsInvalid', 'error');
                } else if (data.twoFaTokenHasExpired) {
                    setRequireTwoFaToken(false);
                    change('twoFaToken', undefined);
                    notify('pos.auth.notification.failed.twoFaTokenHasExpired', 'error');
                } else if (data.twoFaTokenIsInvalid) {
                    setRequireTwoFaToken(false);
                    change('twoFaToken', undefined);
                    notify('pos.auth.notification.failed.twoFaTokenIsInvalid', 'error');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <CustomLogin notification={CustomNotification}>
            <Form
                onSubmit={submit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <TextInput
                                    source="email"
                                    type="email"
                                    label="resources.users.fields.email"
                                    validate={validations.email}
                                    disabled={loading || requireTwoFaToken}
                                    helperText={false}
                                    variant="standard"
                                    fullWidth
                                />
                            </div>
                            <div className={classes.input}>
                                <PasswordInput
                                    source="password"
                                    label="resources.users.fields.password"
                                    validate={validations.password}
                                    helperText={false}
                                    disabled={loading || requireTwoFaToken}
                                    variant="standard"
                                    fullWidth
                                />
                            </div>
                            {requireTwoFaToken &&
                                <div className={classes.input}>
                                    <TextInput
                                        source="twoFaToken"
                                        label="pos.auth.twoFaToken"
                                        validate={validations.twoFaToken}
                                        disabled={loading}
                                        helperText={false}
                                        variant="standard"
                                        fullWidth
                                    />
                                </div>
                            }
                        </div>
                        <CardActions>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                            >
                                {loading && (
                                    <CircularProgress
                                        className={classes.icon}
                                        size={18}
                                        thickness={2}
                                    />
                                )}
                                {translate('ra.auth.sign_in')}
                            </Button>
                        </CardActions>
                    </form>
                )}
            />
        </CustomLogin>
    );
};

export default CustomLoginForm;
