import * as React from 'react';
import { Datagrid, List, TextField, ReferenceField } from 'react-admin';
import GroupFilter from './GroupFilter';
import ParentReferenceField from './ParentReferenceField';
import { CustomShowButton, CustomEditButton } from '../../custom/CustomButton';
import CustomBulkActionButtons from '../../custom/CustomBulkActionButtons';

const GroupList = ({ voidTitle = false, chainId, parentId, storeId, userId, filterDefaultValues, disabledFilters, hiddenColumns, ...props}) => {
    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(chainId && { chainId: chainId }),
        ...(parentId && { parentId: parentId }),
        ...(storeId && { include: 1, storeId: storeId }),
        ...(userId && { include: 1, userId: userId })
    };

    return (
        <List
            {...props}
            title={title}
            actions={null}
            bulkActionButtons={<CustomBulkActionButtons />}
            filters={
                <GroupFilter
                    filter={filter}
                    filterDefaultValues={filterDefaultValues}
                    disabledFilters={disabledFilters}
                />
            }
            filter={filter}
        >
            <Datagrid
                rowClick="show"
                hover={false}
                rowStyle={(record, index) => {
                    if (index % 2 === 0) {
                        return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                    }
                }}
            >
                {(!hiddenColumns || !hiddenColumns.name) &&
                    <TextField source="name"/>
                }
                {(!hiddenColumns || !hiddenColumns.chain) &&
                    <ReferenceField label="resources.groups.fields.chain" source="chainId" reference="chains" link="show">
                        <TextField source="name"/>
                    </ReferenceField>
                }
                {(!hiddenColumns || !hiddenColumns.parent) &&
                    <ParentReferenceField label="resources.groups.fields.parent" />
                }
                <CustomShowButton/>
                <CustomEditButton/>
            </Datagrid>
        </List>
    );
};

export default GroupList;
