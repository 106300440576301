import * as React from 'react';
import { createElement, useContext, useState, useEffect, useMemo } from 'react';
import { Button, Title, useTranslate } from 'react-admin';
import { Grid, Card, CardContent, useMediaQuery } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import Health from '../health/Health';
import {
    AvgTransactionsAmount, AvgItemsPerTransaction, TotalTransactionsAmount, TotalTransactionsQuantity,
    TotalItemsQuantity, StoreTransactionsQuantityRanking, StoreTransactionsAmountRanking, VoidedItemsAmountRanking,
    VoidedItemsTransactionOperatorsAmountRanking, VoidedItemsOverrideNumbersAmountRanking, ItemsEntryMethod,
    StoreOperatorsProductivityRanking, OperatorsProductivityRanking, HoursSaved
} from './kpi';
import { AbilityContext } from '../Can';
import _ from 'lodash';
import userManual from '../../resources/Manual-de-Usuario-TantAI.pdf';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        minMarginRight: '20px'
    }
});

const groupByHealthsSorted = [
    //'store.chainId', // comentar en TUTI
    'transaction.storeCode',
    'transaction.terminalNumber'
];

const kpis = {
    'general-group-by-field': [
        {
            component: AvgTransactionsAmount,
            sort: 1
        },
        {
            component: TotalTransactionsAmount,
            sort: 3
        },
        {
            component: TotalTransactionsQuantity,
            sort: 4
        },
        {
            component: StoreTransactionsQuantityRanking,
            sort: 7,
            groupBy: 'transaction.storeCode'
        },
        {
            component: StoreTransactionsAmountRanking,
            sort: 8,
            groupBy: 'transaction.storeCode'
        }
    ],
    'avg-items-per-transaction': [
        {
            component: AvgItemsPerTransaction,
            sort: 2
        },
        {
            component: TotalItemsQuantity,
            sort: 5
        }
    ],
    'items-entry-method': {
        component: ItemsEntryMethod,
        sort: 6
    },
    'overrides-voided-items': [ // comentar
        {
            component: VoidedItemsAmountRanking,
            sort: 9
        },
        {
            component: VoidedItemsTransactionOperatorsAmountRanking,
            sort: 10
        },
        {
            component: VoidedItemsOverrideNumbersAmountRanking,
            sort: 11
        }
    ],
    'general-times': [
        {
            component: StoreOperatorsProductivityRanking,
            sort: 12,
            groupBy: 'transaction.storeCode'
        },
        {
            component: OperatorsProductivityRanking,
            sort: 13,
            groupBy: 'transaction.operatorCode'
        }
    ],
    'seconds-per-item-line-group-by-field-and-datetime': {
        component: HoursSaved,
        sort: 14
    }
};

const verifyAccess = rules => (typesSearch, groupBy = null) => {
    typesSearch = _.isArray(typesSearch) ? typesSearch : [typesSearch];

    return rules.some(rule => {
        let conditions = _.get(rule, 'conditions');
        if (_.isEmpty(conditions)) {
            return true;
        }

        let types = _.get(conditions, 'type.$in');
        let groupsBy = _.get(conditions, 'groupBy.$in');
        if (_.isArray(groupsBy) && groupBy) {
            return _.intersection(types, typesSearch).length > 0 && groupsBy.includes(groupBy);
        }
        return _.intersection(types, typesSearch).length > 0;
    });
};

const UserManual = () => {
    const translate = useTranslate();

    const handleClick = () => {
        const link = document.createElement('a');
        link.download = 'Manual-de-Usuario-TantAI.pdf';
        link.href = userManual;
        link.click();
    };

    return (
        <Alert
            severity="info"
            action={
                <Button
                    label={'pos.generic.download'}
                    onClick={handleClick}
                >
                    <DownloadIcon />
                </Button>
            }
        >
            <AlertTitle>
                {translate('pos.dashboard.userManual')}
            </AlertTitle>
        </Alert>
    );
};

const MainHealth = () => {
    const ability = useContext(AbilityContext);
    const [groupByHealth, setGroupByHealth] = useState();

    useEffect(() => {
        const abilities = ability.rulesFor('find', 'healths');
        if (abilities.find(rule => rule.conditions === undefined)) {
            setGroupByHealth(groupByHealthsSorted[0])
        } else {
            const groupByHealths = _.uniq(abilities
                .map(rule => {
                    if (rule.conditions && rule.conditions['groupBy'] && rule.conditions['groupBy'].$in) {
                        return rule.conditions['groupBy'].$in;
                    }
                    return [];
                }).flat());

            for (let i = 0; i < groupByHealthsSorted.length; i++) {
                if (groupByHealths.includes(groupByHealthsSorted[i])) {
                    setGroupByHealth(groupByHealthsSorted[i]);
                    break;
                }
            }
        }
    }, [ability]);

    if (!groupByHealth) return null;

    return (
        <Health filters={{ groupBy: groupByHealth }} detailsExpanded={true} />
    );
};

const Dashboard = () => {
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isMedium = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const verifyAccessWithRules = verifyAccess(ability.rulesFor('find', 'statistics'));

    const checkedKpis = useMemo(() => {
        let checkedKpis = [];
        Object.entries(kpis).forEach(([type, kpis], index) => {
            kpis = _.isArray(kpis) ? kpis : [kpis];
            kpis.forEach(kpi => {
                let verify;
                if ('groupBy' in kpi) {
                    verify = verifyAccessWithRules(type, kpi.groupBy);
                } else {
                    verify = verifyAccessWithRules(type);
                }

                if (verify) {
                    checkedKpis.push(kpi);
                }
            });
        });
        return _.sortBy(checkedKpis, 'sort');
    }, [verifyAccessWithRules]);

    return (
        <div className={classes.root}>
            <Title title="pos.dashboard.name" />
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UserManual />
                        </Grid>
                        {ability.can('find', 'healths') &&
                            <Grid item xs={12}>
                                <MainHealth />
                            </Grid>
                        }
                    </Grid>
                    {(() => {
                        if (isXSmall) {
                            return (
                                <Grid container spacing={2}>
                                    {checkedKpis.map((kpi, index) => (
                                        <Grid item xs={12} key={index}>
                                            {createElement(kpi.component)}
                                        </Grid>
                                    ))}
                                </Grid>
                            );
                        } else if (isMedium) {
                            let leftKpis = [];
                            let rightKpis = [];
                            checkedKpis.forEach((kpi, index) => {
                                if ((index + 1) % 2 === 0) {
                                    rightKpis.push(kpi);
                                } else {
                                    leftKpis.push(kpi);
                                }
                            });

                            return (
                                <Grid container spacing={2}>
                                    <Grid item sm={6}>
                                        {leftKpis.map((kpi, index) => (
                                            <Grid item style={{ marginTop: 20, marginBottom: 20 }} key={index}>
                                                {createElement(kpi.component)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid item sm={6}>
                                        {rightKpis.map((kpi, index) => (
                                            <Grid item style={{ marginTop: 20, marginBottom: 20 }} key={index}>
                                                {createElement(kpi.component)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            );
                        } else if (isLarge) {
                            let leftKpis = [];
                            let centerKpis = [];
                            let rightKpis = [];
                            checkedKpis.forEach((kpi, index) => {
                                if ((index + 1) % 3 === 0) {
                                    rightKpis.push(kpi);
                                } else if ((index + 1) % 2 === 0) {
                                    centerKpis.push(kpi);
                                } else {
                                    leftKpis.push(kpi);
                                }
                            });

                            return (
                                <Grid container spacing={2}>
                                    <Grid item lg={4}>
                                        {leftKpis.map((kpi, index) => (
                                            <Grid item style={{ marginTop: 20, marginBottom: 20 }} key={index}>
                                                {createElement(kpi.component)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid item lg={4}>
                                        {centerKpis.map((kpi, index) => (
                                            <Grid item style={{ marginTop: 20, marginBottom: 20 }} key={index}>
                                                {createElement(kpi.component)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid item lg={4}>
                                        {rightKpis.map((kpi, index) => (
                                            <Grid item style={{ marginTop: 20, marginBottom: 20 }} key={index}>
                                                {createElement(kpi.component)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            );
                        }
                    })()}
                </CardContent>
            </Card>
        </div>
    );
};

export default Dashboard;
