import * as React from 'react';
import { useContext } from 'react';
import { AbilityContext } from '../../Can';
import {
    TopToolbar, TabbedShowLayout, TabbedShowLayoutTabs, Show, Tab, Labeled, ReferenceManyField, TextField,
    useShowController
} from 'react-admin';
import { Grid } from '@material-ui/core';
import OperatorGeneral from './OperatorGeneral';
import { TransactionList } from '../transactions';
import { StoreList } from '../stores';
import CustomTitle from '../../layout/CustomTitle';
import { CustomListButton, CustomEditButton } from '../../custom/CustomButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { flexGrow: 1 },
    tabs: { width: 0 },
    table: { paddingBottom: '20px' }
});

const OperatorShowActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
            <CustomEditButton {...props} record={props.data} />
        </TopToolbar>
    );
};

const OperatorShow = props => {
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const { record } = useShowController(props);

    return (
        <Show
            title={<CustomTitle action="show" {...props} />}
            actions={<OperatorShowActions />}
            className={classes.root}
            {...props}
        >
            <TabbedShowLayout
                tabs={
                    <TabbedShowLayoutTabs
                        classes={{ scrollable: classes.tabs }}
                        variant="scrollable"
                        scrollButtons="on"
                    />
                }
            >
                <Tab label="resources.operators.tabs.general">
                    <OperatorGeneral />
                </Tab>
                {(record && ability.can('find', 'transactions')) &&
                    <Tab label="resources.operators.tabs.transactions" path="transactions">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.operators.fields.operatorCode">
                                    <TextField record={record} source="operatorCode" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.operators.fields.name">
                                    <TextField record={record} source="name" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} className={classes.table}>
                                <ReferenceManyField
                                    reference="transactions"
                                    target="operatorCode"
                                    addLabel={false}
                                    filterDefaultValues={{
                                        operatorCode: record.operatorCode,
                                        ...(record.stores && { storeCode: record.stores.map(e => e.storeCode) }),
                                        chainId: record.chainId,
                                        include: 1
                                    }}
                                    disabledFilters={{
                                        storeCode: true,
                                        operatorCode: true
                                    }}
                                    hiddenColumns={{ operatorCode: true }}
                                >
                                    <TransactionList voidTitle={true} {...props} />
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </Tab>
                }
                {(record && ability.can('find', 'stores')) &&
                    <Tab label="resources.operators.tabs.stores" path="stores">
                        <ReferenceManyField reference="stores" target="id" addLabel={false} className={classes.table}>
                            <StoreList voidTitle={true} operatorId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    );
};

export default OperatorShow;
