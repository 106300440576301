import * as React from 'react';
import { useEffect, useState } from 'react';
import { Loading, useDataProvider, useTranslate } from 'react-admin';
import { Box, Tabs, Typography } from '@material-ui/core';
import {
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from '@material-ui/icons';
import CustomTab from '../../custom/CustomTab';
import SwipeableViews from 'react-swipeable-views';
import CustomTabPanel from '../../custom/CustomTabPanel';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        height: '400px',
        marginBottom: '20px'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: '200px'
    },
    swipeableViews: {
        width: '100%',
        height: '100%'
    },
    tabPanel: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    }
}));

const PermissionsField = ({ userRole }) => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [subjectPermissions, setSubjectPermissions] = useState([]);
    const [defaultRole, setDefaultRole] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        dataProvider.getOne('defaultRoles', { id: userRole.defaultRoleId })
            .then(({ data: defaultRole }) => {
                setDefaultRole(defaultRole);
                const subjects = Array.from(new Set(defaultRole.permissions.map(permission => permission.subject)));
                let newSubjectPermissions = [];
                subjects.forEach(subject => {
                    let subjectPermission = {
                        subject: subject,
                        permissions: defaultRole.permissions.filter(permission => permission.subject === subject)
                    };
                    subjectPermission.permissions.forEach(permission => {
                        let userPermission = userRole.permissions.find(userPermission => userPermission.id === permission.id);
                        if (userPermission) {
                            permission.isActive = true;
                        }
                    });
                    newSubjectPermissions.push(subjectPermission);
                });
                setSubjectPermissions(newSubjectPermissions);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dataProvider, userRole]);

    if (loading) {
        return (
            <Loading className={classes.root} />
        );
    }
    if (error) {
        return null;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                orientation="vertical"
                variant="scrollable"
                className={classes.tabs}
            >
                {subjectPermissions.map(subjectPermission => (
                    <CustomTab
                        label={translate(`resources.users.fields.userRole.subjects.${subjectPermission.subject}`)}
                        index={subjectPermissions.indexOf(subjectPermission)}
                        key={`tab-${subjectPermission.subject}`}
                    />
                ))}
            </Tabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                enableMouseEvents={true}
                resistance={true}
                className={classes.swipeableViews}
            >
                {subjectPermissions.map(subjectPermission => (
                    <CustomTabPanel
                        value={value}
                        index={subjectPermissions.indexOf(subjectPermission)}
                        key={`tabpanel-${subjectPermission.subject}`}
                        dir={theme.direction}
                        className={classes.tabPanel}
                    >
                        <Box px={3} py={1}>
                            {subjectPermission.permissions.map(permission => (
                                <Box
                                    display="flex"
                                    style={{ marginLeft: '-11px', alignItems: 'center' }}
                                    key={permission.id}
                                >
                                    <Box p="9px">
                                        {permission.isActive ?
                                            <CheckBoxIcon color="primary" /> :
                                            <CheckBoxOutlineBlankIcon color="disabled" />
                                        }
                                    </Box>
                                    <Box>
                                        <Typography variant="body1">
                                            {translate(`resources.users.fields.userRole.permissions.${defaultRole.name}.${permission.id}`)}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </CustomTabPanel>
                ))}
            </SwipeableViews>
        </div>
    );
};

export default PermissionsField;
