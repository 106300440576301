import * as React from 'react';
import { useContext } from 'react';
import { AbilityContext } from '../../Can';
import {
    TopToolbar, TabbedShowLayout, TabbedShowLayoutTabs, Show, Tab, ReferenceManyField, useShowController
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CustomTitle from '../../layout/CustomTitle';
import FileGeneral from './FileGeneral';
import { ChainList } from '../chains';
import { StoreList } from '../stores';
import { CustomListButton, CustomEditButton } from '../../custom/CustomButton';

const useStyles = makeStyles({
    root: { flexGrow: 1 },
    tabs: { width: 0 },
    table: { paddingBottom: '20px' }
});

const FileShowActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
            <CustomEditButton {...props} record={props.data} />
        </TopToolbar>
    );
};

const FileShow = props => {
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const { record } = useShowController(props);

    return (
        <Show
            title={<CustomTitle action="show" {...props} />}
            actions={<FileShowActions />}
            className={classes.root}
            {...props}
        >
            <TabbedShowLayout
                tabs={
                    <TabbedShowLayoutTabs
                        classes={{ scrollable: classes.tabs }}
                        variant="scrollable"
                        scrollButtons="on"
                    />
                }
            >
                <Tab label="resources.files.tabs.general">
                    <FileGeneral />
                </Tab>
                {(record && ability.can('find', 'chains')) &&
                    <Tab label="resources.files.tabs.chains" path="chains">
                        <ReferenceManyField reference="chains" target="id" addLabel={false} className={classes.table}>
                            <ChainList voidTitle={true} fileId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && ability.can('find', 'stores')) &&
                    <Tab label="resources.files.tabs.stores" path="stores">
                        <ReferenceManyField reference="stores" target="id" addLabel={false} className={classes.table}>
                            <StoreList voidTitle={true} fileId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    );
};

export default FileShow;
