import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { NumberField, TextField, SelectInput, NumberInput, BooleanInput, useLocale, useTranslate } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import DurationField from '../../../custom/DurationField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import CustomDateTimeField from '../../../custom/CustomDateTimeField';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import moment from 'moment';
import humanize from '../../../../config/humanizeDuration';
import { stringify } from 'qs';
import _ from 'lodash';
import clsx from  'clsx';
import resourcesConfig from '../../../../config/resourcesConfig.json';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: {
        maxHeight: '600px',
        maxWidth: '95vw'
    },
    borderX: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`
    },
    borderLeft: {
        borderLeft: `1px solid ${theme.palette.divider}`
    },
    borderRight: {
        borderRight: `1px solid ${theme.palette.divider}`
    },
    totalCell: { fontWeight: 500 },
    tableColumnHighlight: { backgroundColor: theme.palette.grey['100'] },
    highlight: { backgroundColor: green['100'] },
}));

const groupByChoices = [
    {
        id: 'transaction.operatorCode',
        name: 'resources.transactions.fields.operatorCode',
        fieldId: 'transaction.operatorCode',
        fieldDataId: 'operator.name',
        fieldDataTranslate: 'resources.transactions.fields.operatorName',
    },
    {
        id: 'transaction.terminalNumber',
        name: 'resources.transactions.fields.terminalNumber',
        fieldId: 'transaction.terminalNumber',
        fieldDataId: 'transaction.terminalNumber',
        fieldDataTranslate: 'resources.transactions.fields.terminalNumber',
    }
];

const dateFormatter = translate => (precision, value) => {
    switch (precision) {
        case 'year':
            return moment(value).format('YYYY');
        case 'month':
            return moment(value).format(translate('pos.datetime.date').replace(/-DD|DD-/, ''));
        case 'day':
            return moment(value).format(translate('pos.datetime.date'));
        case 'hour':
            return moment(value).format(`${translate('pos.datetime.date')} HH:00`);
        case 'minute':
        default:
            return moment(value).format(translate('pos.datetime.datetime'));
    }
};

const getKey = groupBy => e => {
    let key = '';
    if (groupBy.id === 'transaction.operatorCode') {
        key += `${e[groupBy.fieldId]}-${e['chain.id']}-${e['transaction.storeCode']}`;
    } else {
        key += e[groupBy.fieldId];
    }
    return key;
};

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [groupBy, setGroupBy] = useState(groupByChoices.find(e => e.id === filters.groupById));
    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="groupById"
                                    label="pos.generic.groupBy"
                                    choices={groupByChoices}
                                    onChange={({ target }) => {
                                        const { value } = target;
                                        setGroupBy(groupByChoices.find(e => e.id === value));
                                    }}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="momentOfChange"
                                    label="resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.momentOfChange"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="unitOfTime"
                                    label="resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.unitOfTime"
                                    choices={[
                                        { id: 'hour', name: translate('pos.datetime.hour', 2) },
                                        { id: 'day', name: translate('pos.datetime.day', 2) },
                                        { id: 'week', name: translate('pos.datetime.week', 2) },
                                        { id: 'month', name: translate('pos.datetime.month', 2) },
                                        { id: 'year', name: translate('pos.datetime.year', 2) },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="quantityOfTime"
                                    label="resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.quantityOfTime"
                                    min="1"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                        _.isArray(form.getFieldState('chainId').value) ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            {/*<Grid item xs={12} sm={6}>*/}
                            {/*    <CustomReferenceAutocompleteArrayInput*/}
                            {/*        source="terminalTypeId"*/}
                            {/*        record={filters}*/}
                            {/*        reference="terminalTypes"*/}
                            {/*        originSource="id"*/}
                            {/*        optionText="name"*/}
                            {/*        label={translate('resources.transactions.fields.terminalType')}*/}
                            {/*        withNull={true}*/}
                            {/*        helperText={false}*/}
                            {/*        //groupByField="chainId"*/}
                            {/*        additionalFilter={{*/}
                            {/*            ...(form.getFieldState('chainId') && form.getFieldState('chainId').value &&*/}
                            {/*                _.isArray(form.getFieldState('chainId').value) ? {*/}
                            {/*                chainId: form.getFieldState('chainId').value*/}
                            {/*            } : {}),*/}
                            {/*            ...(form.getFieldState('storeCode') && form.getFieldState('storeCode').value &&*/}
                            {/*                _.isArray(form.getFieldState('storeCode').value) ? {*/}
                            {/*                storeCode: form.getFieldState('storeCode').value*/}
                            {/*            } : {})*/}
                            {/*        }}*/}
                            {/*        className={classes.input}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="orderType"
                                    label="pos.generic.order.orderType.name"
                                    choices={[
                                        { id: 'ASC', name: 'pos.generic.order.orderType.asc' },
                                        { id: 'DESC', name: 'pos.generic.order.orderType.desc' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="limit"
                                    label="pos.generic.limit"
                                    min="1"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput
                                    source="ignoreZeroTimes"
                                    label="resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.ignoreZeroTimes"
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                groupById: 'transaction.operatorCode',
                                momentOfChange: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                                unitOfTime: 'week',
                                quantityOfTime: 1,
                                ignoreZeroTimes: true,
                                orderType: 'ASC',
                                limit: 25
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    mainTable: DataTable
                }}
            />
        </div>
    );
};

const processData = ({ translate, data, loading, cancel, groupBy, setProcessingData, setProcessedData }) => {
    setProcessingData(true);
    const { beforeStartPeriod, beforeEndPeriod, afterStartPeriod, afterEndPeriod, data: arrayData } = data;
    if (arrayData && arrayData.length > 0 && !loading && !cancel) {
        let processedData = {
            beforeStartPeriod: dateFormatter(translate)('minute', beforeStartPeriod),
            beforeEndPeriod: dateFormatter(translate)('minute', beforeEndPeriod),
            afterStartPeriod: dateFormatter(translate)('minute', afterStartPeriod),
            afterEndPeriod: dateFormatter(translate)('minute', afterEndPeriod),
            data: []
        };

        const groupByValues = _.uniq(arrayData.map(e => getKey(groupBy)(e)));
        groupByValues.forEach(groupByValue => {
            let filteredData = arrayData.filter(e => getKey(groupBy)(e) === groupByValue);
            let before = filteredData.find(e => e.beforeOrAfter === 'BEFORE');
            let after = filteredData.find(e => e.beforeOrAfter === 'AFTER');
            let groupByData = before ? before : after;

            processedData.data.push({
                [groupBy.fieldId]: groupByData[groupBy.fieldId],
                [groupBy.fieldDataId]: groupByData[groupBy.fieldDataId],
                ...(groupBy.id === 'transaction.operatorCode' && {
                    'chain.name': groupByData['chain.name'],
                    'transaction.storeCode': groupByData['transaction.storeCode']
                }),
                ...(before && {
                    before: {
                        avgTransactionTime: _.round(before.avgTransactionTime),
                        avgTransactionAmount: before.avgTransactionAmount,
                        transactionQuantity: before.transactionQuantity,
                        transactionAmount: before.transactionAmount,
                        transactionsPerHour: before.transactionsPerHour,
                        secondsPerItemLine: before.secondsPerItemLine,
                        startPeriod: before.startPeriod,
                        endPeriod: before.endPeriod
                    }
                }),
                ...(after && {
                    after: {
                        avgTransactionTime: _.round(after.avgTransactionTime),
                        avgTransactionAmount: after.avgTransactionAmount,
                        transactionQuantity: after.transactionQuantity,
                        transactionAmount: after.transactionAmount,
                        transactionsPerHour: after.transactionsPerHour,
                        secondsPerItemLine: after.secondsPerItemLine,
                        startPeriod: after.startPeriod,
                        endPeriod: after.endPeriod
                    }
                })
            });
        });

        console.log('processedData:', processedData)

        // processedData.totalTotalTime = _.sumBy(processedData.data, 'totalTime');
        // timeTypes.map(e => e.id).forEach(timeType => {
        //     processedData[`total-${timeType}`] = _.sumBy(processedData.data, timeType);
        // });
        // processedData.totalTransactionQuantity = _.sumBy(processedData.data, 'transactionQuantity');
        // processedData.totalItemLineQuantity = _.sumBy(processedData.data, 'itemLineQuantity');
        //
        // processedData.data = _.orderBy(processedData.data, ['secondsPerItemLine'], ['asc'])

        setProcessedData(processedData);
        setProcessingData(false);
    }
};

const DataTable = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');
    const currency = resourcesConfig.general.currency;

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { groupById } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);
    
    useEffect(() => {
        processData({ translate, data, loading, cancel, groupBy, setProcessingData, setProcessedData });
    }, [translate, data, loading, cancel, groupBy]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data.data || data.data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    // if (((!data || !data.data || data.data.length === 0 || !processedData) &&
    //     ((!groupByValues || !processedData.length === 0) ||
    //         (!processedData.data || !processedData.data.length === 0))) || cancel
    // ) {
    //     if ((loading || (loading && processingData)) && !cancel) {
    //         return (
    //             <div className={classes.loadingTable}>
    //                 <CircularProgress/>
    //             </div>
    //         );
    //     }
    //     return (
    //         <CustomError
    //             severity="warning"
    //             errorPrimary={translate('pos.generic.warning')}
    //             errorSecondary={translate('resources.transactions.statistics.errors.noData')}
    //         />
    //     );
    // }


    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow key="rowHeader-1">
                        <TableCell rowSpan={2}>
                            {translate(groupBy.name)}
                        </TableCell>
                        {groupBy.id === 'transaction.operatorCode' && (
                            <React.Fragment>
                                <TableCell rowSpan={2}>
                                    {translate('resources.transactions.fields.operatorName')}
                                </TableCell>
                                <TableCell rowSpan={2}>
                                    {translate('resources.transactions.fields.storeCode')}
                                </TableCell>
                            </React.Fragment>
                        )}
                        <TableCell colSpan={6} align="center" className={classes.borderX}>
                            {(() => {
                                let beforeTitle = translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.before');
                                if (!loading && !processingData) {
                                    beforeTitle += ` [${processedData.beforeStartPeriod}, ${processedData.beforeEndPeriod})`;
                                }
                                return beforeTitle;
                            })()}
                        </TableCell>
                        <TableCell colSpan={6} align="center">
                            {(() => {
                                let afterTitle = translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.after');
                                if (!loading && !processingData) {
                                    afterTitle += ` [${processedData.afterStartPeriod}, ${processedData.afterEndPeriod}]`
                                }
                                return afterTitle;
                            })()}
                        </TableCell>
                    </TableRow>
                    <TableRow key="rowHeader-2">
                        <TableCell align="right" style={{ top: '37px' }} className={classes.borderLeft}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.avgTransactionTime')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.avgTransactionAmount')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.transactionsPerHour')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.secondsPerItemLine')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }} className={classes.borderRight}>
                            {translate('pos.generic.customers')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.avgTransactionTime')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.avgTransactionAmount')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.transactionsPerHour')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.secondsPerItemLine')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('pos.generic.customers')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell
                                colSpan={groupBy.id === 'transaction.operatorCode' ? 15 : 13}
                                align="center"
                            >
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => (
                            <TableRow key={getKey(groupBy)(row)}>
                                <TableCell>
                                    <TextField record={row} source={groupBy.fieldId} />
                                </TableCell>
                                {groupBy.id === 'transaction.operatorCode' &&
                                    <React.Fragment>
                                        <TableCell>
                                            <TextField record={row} source="operator.name" emptyText={emptyText} />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                record={row}
                                                source="transaction.storeCode"
                                                emptyText={emptyText}
                                            />
                                        </TableCell>
                                    </React.Fragment>
                                }
                                <TableCell align="right" className={classes.borderLeft}>
                                    <DurationField
                                        record={row}
                                        source="before.avgTransactionTime"
                                        emptyText={emptyText}
                                        shortFormat
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="before.avgTransactionAmount"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="before.transactionsPerHour"
                                        locales={locale}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="before.secondsPerItemLine"
                                        locales={locale}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="before.transactionAmount"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right" className={classes.borderRight}>
                                    <NumberField
                                        record={row}
                                        source="before.transactionQuantity"
                                        locales={locale}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <DurationField
                                        record={row}
                                        source="after.avgTransactionTime"
                                        emptyText={emptyText}
                                        shortFormat
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="after.avgTransactionAmount"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="after.transactionsPerHour"
                                        locales={locale}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="after.secondsPerItemLine"
                                        locales={locale}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="after.transactionAmount"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row} source="after.transactionQuantity"
                                        locales={locale}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key="total">
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.total', 1)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let {
        groupById, momentOfChange, unitOfTime, quantityOfTime, ignoreZeroTimes, chainId, storeCode, terminalTypeId,
        terminalNumber, operatorCode, orderType, limit
    } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const groupBy = groupByChoices.find(e => e.id === groupById);
        const params = {
            type: 'compare-productivity',
            groupBy: groupBy.fieldId,
            momentOfChange: momentOfChange,
            unitOfTime: unitOfTime,
            quantityOfTime: quantityOfTime,
            ignoreZeroTimes: ignoreZeroTimes,
            chainId: chainId,
            storeCode: storeCode,
            terminalTypeId: terminalTypeId,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderType: orderType,
            limit: limit
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { beforeStartPeriod, beforeEndPeriod, afterStartPeriod, afterEndPeriod, data } = response.data;
                //setData(data.length > 0 ? data : []);
                setData(data.length > 0 ? ({
                    beforeStartPeriod, beforeEndPeriod, afterStartPeriod, afterEndPeriod, data
                }) : {});
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        renderKey, groupById, momentOfChange, unitOfTime, quantityOfTime, ignoreZeroTimes, chainId, storeCode,
        terminalTypeId, terminalNumber, operatorCode, orderType, limit
    ]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const CompareProductivity = () => {
    const translate = useTranslate();

    const filters = {
        groupById: 'transaction.operatorCode',
        momentOfChange: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        unitOfTime: 'week',
        quantityOfTime: 1,
        ignoreZeroTimes: true,
        orderType: 'ASC',
        limit: 25
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.compareProductivity.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Filter
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                                onSubmit={onSubmit}
                                cancelTokenSource={cancelTokenSource}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default CompareProductivity;
