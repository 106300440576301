import * as React from 'react';
import { useEffect, useState } from 'react';
import { InputHelperText, ResettableTextField, FieldTitle, LinearProgress, useDataProvider, useTranslate } from 'react-admin';
import { useInput } from 'ra-core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Chip } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        color: 'red',
        backgroundColor: 'rgb(253, 236, 234)'
    }
});

const filter = createFilterOptions();

const CustomReferenceAutocompleteArrayInput = props => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const {
        originSource,
        optionText,
        additionalFilter,
        className,
        getOptionLabel = option => option.name ? option.name : '',
        groupBy,
        groupByField,
        label,
        format,
        helperText = true,
        onBlur,
        onFocus,
        onChange,
        parse,
        record,
        reference,
        resource,
        source,
        validate,
        withNull = true,
        ...rest
    } = props;

    const {
        id,
        input,
        isRequired,
        meta: { error: inputError, submitError, touched }
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'text',
        validate,
        ...rest
    });

    const [options, setOptions] = useState([]);
    const [initialValue, setInitialValue] = useState();
    const [value, setValue] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const idKey = originSource ? originSource : source;
    const nameKey = optionText ? optionText : source;

    const getMappedValue = value => {
        let mappedValue = Array.isArray(value) && value.length > 0 ? value.map(e => e.id) : [];
        if (mappedValue && mappedValue.includes(null)) {
            mappedValue = {
                $or: [
                    {
                        [source]: mappedValue.filter(e => e !== null)
                    },
                    {
                        [source]: null
                    }
                ]
            };
        }
        return mappedValue;
    };

    useEffect(() => {
        let value;
        if (withNull) {
            value = _.get(record, `$or[0].${source}`);
            if (_.isArray(value) && !value.includes(null)) {
                value.push(null);
            } else {
                value = _.get(record, source);
            }
        } else {
            value = _.get(record, source);
        }

        if (value) {
            setInitialValue(value);
        }
    }, [record, source, withNull]);

    useEffect(() => {
        let filter = { ...additionalFilter };
        if (initialValue && _.isArray(initialValue)) {
            setLoading(true);
            filter[idKey] = initialValue;
        }
        if (inputValue) {
            filter.q = inputValue;
        }

        dataProvider.getList(reference, {
            filter: filter
        })
            .then(({ data }) => {
                let options = Object.values(data).map(e => ({ ...e, id: e[idKey], name: e[nameKey] }));
                if (withNull) {
                    options.push({
                        id: null,
                        name: emptyText,
                        ...(groupByField && { [groupByField]: emptyText })
                    });
                }

                if (initialValue && _.isArray(initialValue)) {
                    const value = options.filter(e => initialValue.includes(e.id));
                    //input.onChange(getMappedValue(value));
                    setValue(value);
                    setInitialValue();
                }

                setOptions(options);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        dataProvider, emptyText, reference, additionalFilter, initialValue, idKey, nameKey, inputValue, withNull,
        groupByField, onChange, value
    ]);

    if (loading) return <LinearProgress />;
    if (error || !options) return null;

    const handleChange = (event, newValue) => {
        input.onChange(getMappedValue(newValue));
        setValue(newValue);
        if (onChange) {
            onChange(event, newValue);
        }
    };

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
            if (!options.map(e => e.id).includes(inputValue)) {
                setInputValue(params.inputValue);
            }
        }
        return filtered;
    };

    return (
        <Autocomplete
            value={value}
            inputValue={inputValue}
            onChange={handleChange}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={options}
            filterOptions={filterOptions}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={getOptionLabel}
            groupBy={groupBy ? groupBy : groupByField ? option => option[groupByField] : null}
            noOptionsText={translate('pos.generic.noOptions')}
            clearOnBlur
            multiple
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        //{...(!options.find(o => o.id === option.id)) && { className: classes.root }}
                        {...(inputError && inputError.optionErrors && inputError.optionErrors.includes(option.id)) && { className: classes.root }}
                    />
                ))
            }
            renderInput={params => (
                <ResettableTextField
                    id={id}
                    {...input}
                    {...params}
                    label={(label !== '' && label !== false) && (
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    )}
                    variant="filled"
                    margin="normal"
                    size="small"
                    error={!!(touched && (inputError || submitError))}
                    helperText={(helperText && (touched && (inputError || submitError))) && (
                        <InputHelperText
                            touched={touched}
                            error={inputError || submitError}
                            helperText={(touched && inputError) ? inputError.message : null}
                        />
                    )}
                    className={className}
                    {...rest}
                />
            )}
            {...rest}
        />
    );
};

export default CustomReferenceAutocompleteArrayInput;
