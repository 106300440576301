import * as React from 'react';
import { BulkDeleteWithUndoButton } from 'react-admin';
import BulkDeleteWithConfirmButton from './BulkDeleteWithConfirmButton';

const BulkDeleteButton = ({ undoable = true, filterValues, ...props }) => undoable ? (
    <BulkDeleteWithUndoButton {...props} />
) : (
    <BulkDeleteWithConfirmButton {...props} />
);

export default BulkDeleteButton;