import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Slide, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    dialogTitleRoot: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogContentRoot: {
        padding: theme.spacing(2),
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialogTitle = ({ children, onClose, ...props}) => {
    const classes = useStyles();

    return (
        <DialogTitle disableTypography className={classes.dialogTitleRoot} {...props}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const CustomDialog = ({ children, title, open, onClose, fullWidth = false, maxWidth = false }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    return (
        <Dialog
            onClose={onClose}
            open={open}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            aria-labelledby={`${title}-dialog`}
            fullScreen={isXSmall}
            TransitionComponent={Transition}
        >
            <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
                {title}
            </CustomDialogTitle>
            <DialogContent className={classes.dialogContentRoot} dividers>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default CustomDialog;
