import resourcesConfig from '../config/resourcesConfig.json';

export default function getBusinessDayDateConfig() {
    let businessDayDateConfig = {
        businessDayDateQuantity: 1,
        businessDayDateUnitOfTime: 'week',
        upperLimitSubtractQuantity: 0,
        upperLimitSubtractUnitOfTime: 'day'
    };
    if (resourcesConfig && resourcesConfig.general && resourcesConfig.general.statistics && resourcesConfig.general.statistics.businessDayDate) {
        let {
            quantity: businessDayDateQuantity,
            unitOfTime: businessDayDateUnitOfTime,
            upperLimitSubtractQuantity,
            upperLimitSubtractUnitOfTime
        } = resourcesConfig.general.statistics.businessDayDate;
        businessDayDateConfig = {
            ...businessDayDateConfig,
            ...(businessDayDateQuantity && { businessDayDateQuantity }),
            ...(businessDayDateUnitOfTime && { businessDayDateUnitOfTime }),
            ...(upperLimitSubtractQuantity && { upperLimitSubtractQuantity }),
            ...(upperLimitSubtractUnitOfTime && { upperLimitSubtractUnitOfTime })
        };
    }
    return businessDayDateConfig;
}
