import * as React from 'react';
import { Grid } from '@material-ui/core';
import { Labeled, TextField, useTranslate } from 'react-admin';
import CustomField from '../../../../custom/CustomField';
import resourcesConfig from '../../../../../config/resourcesConfig.json';

const UserStringAltPanel = props => {
    const translate = useTranslate();
    const emptyText = { emptyText: translate('pos.generic.unknown') };
    const record = props.record.userData;

    return (
        <Grid container>
            {Object.keys(record).map(key => (
                <Grid item xs={12} sm={6} key={key}>
                    <Labeled label={key}>
                        <TextField
                            record={record[key] !== null ? record : emptyText}
                            source={record[key] !== null ? key : 'emptyText'}
                        />
                    </Labeled>
                </Grid>
            ))}
        </Grid>
    );
};

const UserStringPanel = props => {
    const { originator, subtype } = props.record;
    const configFilename = resourcesConfig.transactions.related.userStrings.configFilename;
    const config = require(`../../../../../config/${configFilename}`);
    const originatorConfig = config.fields.find(field => field.name === 'userData').config.originators[originator];
    const subtypeConfig = originatorConfig ? originatorConfig.subtypes[subtype] : null;

    if (!originatorConfig || !subtypeConfig) {
        return (
            <UserStringAltPanel {...props} />
        );
    }

    let fieldsConfig = subtypeConfig.fields.filter(fieldConfig => fieldConfig.inUse);
    fieldsConfig = fieldsConfig.sort((a, b) => {
        if (!b.sort) return -1;
        if (!a.sort) return 1;
        return a.sort - b.sort;
    });

    return (
        <Grid container>
            {fieldsConfig.map(fieldConfig => {
                if(fieldConfig.datatype.type === 'ENUM') {
                    return (
                        <Grid item xs={12} sm={6} key={fieldConfig.name}>
                            <Labeled
                                label={`resources.transactions.fields.userStrings.userData.${originator}.${subtype}.${fieldConfig.name}.name`}
                            >
                                <CustomField
                                    record={props.record.userData}
                                    config={fieldConfig}
                                    fieldTranslate={`resources.transactions.fields.userStrings.userData.${originator}.${subtype}.${fieldConfig.name}`}
                                />
                            </Labeled>
                        </Grid>
                    );
                }
                return (
                    <Grid item xs={12} sm={6} key={fieldConfig.name}>
                        <Labeled
                            label={`resources.transactions.fields.userStrings.userData.${originator}.${subtype}.${fieldConfig.name}`}
                        >
                            <CustomField record={props.record.userData} config={fieldConfig} />
                        </Labeled>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default UserStringPanel;
