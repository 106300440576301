import * as React from 'react';
import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { saveAs } from 'file-saver';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});

const DownloadFileButton = ({ record }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();

    const [loading, setLoading] = useState(false);

    const params = {
        download: true
    };
    const queryString = stringify(params, { strictNullHandling: true });

    const handleClick = () => {
        setLoading(true);

        axios.get(`/files/${record.id}?${queryString}`, {
            responseType: 'blob'
        })
            .then(response => {
                notify(`resources.files.crud.show.download.notification.success`, 'success');

                const file = new Blob([response.data], { type: response.headers['content-type'] });
                const filename = `${record.originalName}.${record.extension}`;
                saveAs(file, filename);
            })
            .catch(() => {
                notify(`resources.files.crud.show.download.notification.failed`, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={handleClick}
            disabled={loading}
            startIcon={<GetAppIcon />}
        >
            {translate('pos.generic.download')}
            {loading &&
                <CircularProgress size={24} className={classes.buttonProgress} />
            }
        </Button>
    );
};

export default DownloadFileButton;
