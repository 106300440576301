import * as React from 'react';
import { RefreshButton, SaveButton, Toolbar, useNotify, useRedirect, useRefresh } from 'react-admin';
import { Backspace as BackspaceIcon } from '@material-ui/icons'
import { CustomDeleteButton } from './CustomButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const RightButtons = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify(`resources.${props.resource}.crud.delete.notification.success`, 'success');
        redirect(props.basePath);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.${props.resource}.crud.delete.notification.failed`, 'error');
    };

    return (
        <div>
            <RefreshButton
                label="pos.generic.cancelChanges"
                icon={<BackspaceIcon />}
                {...props}
            />
            <CustomDeleteButton
                confirmTitle={`resources.${props.resource}.crud.delete.confirmTitle`}
                confirmContent={`resources.${props.resource}.crud.delete.confirmContent`}
                mutationMode="pessimistic"
                onSuccess={onSuccess}
                onFailure={onFailure}
                {...props}
            />
        </div>
    );
};

const CustomEditToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={classes}>
            <SaveButton />
            <RightButtons {...props} />
        </Toolbar>
    );
};

export default CustomEditToolbar;
