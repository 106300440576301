import * as React from 'react';
import { Labeled, BooleanField, NumberField, TextField, useLocale, useTranslate } from 'react-admin';
import { Grid, Typography } from '@material-ui/core';
import InvoiceNumberField from './InvoiceNumberField';
import TypeField from './TypeField';
import TotalTaxesField from './TotalTaxesField';
import SubtotalField from './SubtotalField';
import DurationField from '../../custom/DurationField';
import CustomReferenceField from '../../custom/CustomReferenceField';
import CustomDateTimeField from '../../custom/CustomDateTimeField';
import resourcesConfig from '../../../config/resourcesConfig.json';

const TransactionGeneral = props => {
    const translate = useTranslate();
    const locale = useLocale();
    const emptyText = translate('pos.generic.unknown');
    const currency = resourcesConfig.general.currency;

    return (
        <span>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.tabs.general.sections.header')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.sequenceNumber">
                                <NumberField record={props.record} source="sequenceNumber" locales={locale} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.businessDayDate">
                                <CustomDateTimeField
                                    record={props.record}
                                    source="businessDayDate"
                                    showFormat={translate('pos.datetime.datetime')}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.terminalNumber">
                                <NumberField record={props.record} source="terminalNumber" locales={locale} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.operatorCode">
                                <TextField record={props.record} source="operatorCode" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.operatorName">
                                <TextField
                                    record={props.record}
                                    source="store.chain.operators[0].name"
                                    emptyText={emptyText}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.storeCode">
                                <CustomReferenceField
                                    record={props.record}
                                    originalSource="storeCode"
                                    injectSource="storeId"
                                    reference="stores"
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.storeNumber">
                                <NumberField record={props.record} source="storeNumber" locales={locale} />
                            </Labeled>
                        </Grid>
                        {(props.record && props.record.type === '00') &&
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.transactions.fields.invoiceNumber">
                                    <InvoiceNumberField
                                        record={props.record}
                                        label="resources.transactions.fields.invoiceNumber"
                                    />
                                </Labeled>
                            </Grid>
                        }
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.type.name">
                                <TypeField record={props.record} label="resources.transactions.fields.type.name" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.numberOfStrings">
                                <NumberField record={props.record} source="numberOfStrings" locales={locale} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.grossPositive">
                                <NumberField
                                    record={props.record}
                                    source="grossPositive"
                                    locales={locale}
                                    options={{ style: 'currency', currency: currency }}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.grossNegative">
                                <NumberField
                                    record={props.record}
                                    source="grossNegative"
                                    locales={locale}
                                    options={{ style: 'currency', currency: currency }}
                                />
                            </Labeled>
                        </Grid>
                        {(props.record && props.record.type === '00') &&
                            <React.Fragment>
                                <Grid item xs={12} sm={6}>
                                    <Labeled label="resources.transactions.fields.subtotal">
                                        <SubtotalField
                                            record={props.record}
                                            label="resources.transactions.fields.subtotal"
                                        />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Labeled label="resources.transactions.fields.totalTaxes">
                                        <TotalTaxesField
                                            record={props.record}
                                            label="resources.transactions.fields.totalTaxes"
                                        />
                                    </Labeled>
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.total">
                                <NumberField
                                    source="total"
                                    locales={locale}
                                    options={{ style: 'currency', currency: currency }}
                                />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.tabs.general.sections.performance')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.ringElapsedTime">
                                <DurationField record={props.record} source="ringElapsedTime" emptyText={emptyText} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.tenderElapsedTime">
                                <DurationField record={props.record} source="tenderElapsedTime" emptyText={emptyText} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.specialElapsedTime">
                                <DurationField record={props.record} source="specialElapsedTime" emptyText={emptyText} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.inactiveElapsedTime">
                                <DurationField record={props.record} source="inactiveElapsedTime" emptyText={emptyText} />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.tabs.general.sections.indicators')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.TOFRecovered">
                                <BooleanField record={props.record} source="TOFRecovered" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.transactionCopiedAfterPreviousTLOGCloseString">
                                <BooleanField record={props.record} source="transactionCopiedAfterPreviousTLOGCloseString" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.CVBPaper">
                                <BooleanField record={props.record} source="CVBPaper" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.digitalReceiptCreated">
                                <BooleanField record={props.record} source="digitalReceiptCreated" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.paperReceiptSuppressed">
                                <BooleanField record={props.record} source="paperReceiptSuppressed" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.eatInTransaction">
                                <BooleanField record={props.record} source="eatInTransaction" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.preferredCustomer">
                                <BooleanField record={props.record} source="preferredCustomer" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.grossPosIsNegative">
                                <BooleanField record={props.record} source="grossPosIsNegative" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.grossNegIsNegative">
                                <BooleanField record={props.record} source="grossNegIsNegative" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.additionalRecords">
                                <BooleanField record={props.record} source="additionalRecords" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.notFirstRecord">
                                <BooleanField record={props.record} source="notFirstRecord" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.tillChange">
                                <BooleanField record={props.record} source="tillChange" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.termInitialized">
                                <BooleanField record={props.record} source="termInitialized" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.rollbackPriceItem">
                                <BooleanField record={props.record} source="rollbackPriceItem" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.transTransferred">
                                <BooleanField record={props.record} source="transTransferred" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.transactionMonitored">
                                <BooleanField record={props.record} source="transactionMonitored" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.tenderRemoval">
                                <BooleanField record={props.record} source="tenderRemoval" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.tillContents">
                                <BooleanField record={props.record} source="tillContents" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.tillExchanged">
                                <BooleanField record={props.record} source="tillExchanged" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.tenderVerified">
                                <BooleanField record={props.record} source="tenderVerified" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.operatorSignOn">
                                <BooleanField record={props.record} source="operatorSignOn" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.tenderRejected">
                                <BooleanField record={props.record} source="tenderRejected" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.signOffIsFalse">
                                <BooleanField record={props.record} source="signOffIsFalse" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.dataEntry">
                                <BooleanField record={props.record} source="dataEntry" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.openDrawer">
                                <BooleanField record={props.record} source="openDrawer" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.specialSignOff">
                                <BooleanField record={props.record} source="specialSignOff" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.terminalAcct">
                                <BooleanField record={props.record} source="terminalAcct" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.isTransactionRecovered">
                                <BooleanField record={props.record} source="isTransactionRecovered" />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </span>
    );
};

export default TransactionGeneral;
