import * as React from 'react';
import { Datagrid, List, BooleanField, TextField, ReferenceField, BulkExportButton, useTranslate } from 'react-admin';
import CustomDateTimeField from '../../custom/CustomDateTimeField';
import OperatorFilter from './OperatorFilter';
import {
    CustomShowButton, CustomEditButton, CustomBulkDeleteButton, CustomBulkUpdateButton
} from '../../custom/CustomButton';
import {
    Check as CheckIcon,
    Clear as ClearIcon
} from '@material-ui/icons';

const OperatorBulkActionButtons = props => {
    return (
        <React.Fragment>
            <CustomBulkUpdateButton
                {...props}
                label="resources.operators.crud.bulkEdit.includeInReport"
                data={{ inReport: true }}
                confirmTitle={`resources.${props.resource}.crud.bulkEdit.confirmTitle`}
                confirmContent={`resources.${props.resource}.crud.bulkEdit.confirmContent`}
                icon={<CheckIcon/>}
                undoable={false}
            />
            <CustomBulkUpdateButton
                {...props}
                label="resources.operators.crud.bulkEdit.excludeFromReport"
                data={{ inReport: false }}
                confirmTitle={`resources.${props.resource}.crud.bulkEdit.confirmTitle`}
                confirmContent={`resources.${props.resource}.crud.bulkEdit.confirmContent`}
                icon={<ClearIcon/>}
                undoable={false}
            />
            <BulkExportButton {...props} />
            <CustomBulkDeleteButton
                {...props}
                confirmTitle={`resources.${props.resource}.crud.bulkDelete.confirmTitle`}
                confirmContent={`resources.${props.resource}.crud.bulkDelete.confirmContent`}
                undoable={false}
            />
        </React.Fragment>
    );
};

const OperatorList = ({ voidTitle = false, chainId, userId, filterDefaultValues, disabledFilters, hiddenColumns, ...props }) => {
    const translate = useTranslate();
    const emptyText = translate('pos.generic.unknown');

    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(chainId && { chainId: chainId }),
        ...(userId && { include: 1, userId: userId })
    };

    return (
        <List
            {...props}
            title={title}
            actions={null}
            bulkActionButtons={<OperatorBulkActionButtons />}
            filters={
                <OperatorFilter
                    filter={filter}
                    filterDefaultValues={filterDefaultValues}
                    disabledFilters={disabledFilters}
                />
            }
            filter={filter}
        >
            <Datagrid
                //rowClick="show"
                hover={false}
                rowStyle={(record, index) => {
                    if (index % 2 === 0) {
                        return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                    }
                }}
            >
                {(!hiddenColumns || !hiddenColumns.operatorCode) &&
                    <TextField source="operatorCode" />
                }
                {(!hiddenColumns || !hiddenColumns.name) &&
                    <TextField source="name" />
                }
                {(!hiddenColumns || !hiddenColumns.inReport) &&
                    <BooleanField source="inReport" />
                }
                {(!hiddenColumns || !hiddenColumns.cLastTransactionBusinessDayDate) &&
                    <CustomDateTimeField
                        source="cLastTransactionBusinessDayDate"
                        showFormat={translate('pos.datetime.datetime')}
                        emptyText={emptyText}
                    />
                }
                {(!hiddenColumns || !hiddenColumns.chain) &&
                    <ReferenceField label="resources.operators.fields.chain" source="chainId" reference="chains" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                }
                <CustomShowButton />
                <CustomEditButton />
            </Datagrid>
        </List>
    );
};

export default OperatorList;
