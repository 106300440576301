import * as React from 'react';
import { Datagrid, List, TextField, ReferenceField } from 'react-admin';
import DepartmentFilter from './DepartmentFilter';
import { CustomShowButton, CustomEditButton } from '../../custom/CustomButton';
import CustomBulkActionButtons from '../../custom/CustomBulkActionButtons';

const DepartmentList = ({ voidTitle = false, chainId, itemId, filterDefaultValues, disabledFilters, hiddenColumns, ...props }) => {
    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(chainId && { chainId: chainId }),
        ...(itemId && { include: 1, itemId: itemId })
    };

    return (
        <List
            {...props}
            title={title}
            actions={null}
            bulkActionButtons={<CustomBulkActionButtons />}
            filters={
                <DepartmentFilter
                    filter={filter}
                    filterDefaultValues={filterDefaultValues}
                    disabledFilters={disabledFilters}
                />
            }
            filter={filter}
        >
            <Datagrid
                rowClick="show"
                hover={false}
                rowStyle={(record, index) => {
                    if (index % 2 === 0) {
                        return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                    }
                }}
            >
                {(!hiddenColumns || !hiddenColumns.departmentCode) &&
                    <TextField source="departmentCode" />
                }
                {(!hiddenColumns || !hiddenColumns.name) &&
                    <TextField source="name" />
                }
                {(!hiddenColumns || !hiddenColumns.chain) &&
                    <ReferenceField label="resources.departments.fields.chain" source="chainId" reference="chains" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                }
                <CustomShowButton />
                <CustomEditButton />
            </Datagrid>
        </List>
    );
};

export default DepartmentList;
