import * as React from 'react';
import { useContext, useMemo, useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { SelectInput, NumberInput, TextField, NumberField, useLocale, useTranslate} from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import PercentageField from '../../../custom/PercentageField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import getGroupByDefaultValue from '../../../../helpers/getGroupByDefaultValue';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import getGroupByChoices from '../../../../helpers/getGroupByChoices';
import { AbilityContext } from '../../../Can';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: { maxHeight: '300px' },
    totalCell: { fontWeight: 500 }
}));

const groupByChoices = [
    { id: 'transaction.terminalNumber', name: 'resources.transactions.fields.terminalNumber' },
    { id: 'transaction.operatorCode', name: 'resources.transactions.fields.operatorCode' },
    { id: 'transaction.storeCode', name: 'resources.transactions.fields.storeCode' },
    { id: 'chain.name', name: 'resources.stores.fields.chain' }
];

const paymentTypes = [
    {
        id: 'exactPayments',
        name: 'resources.transactions.statistics.tabs.payments.sections.transactionsExactPayment.exactPayments'
    },
    {
        id: 'noExactPayments',
        name: 'resources.transactions.statistics.tabs.payments.sections.transactionsExactPayment.noExactPayments'
    }
];

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const ability = useContext(AbilityContext);

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const checkedGroupByChoices = useMemo(() => {
        const groupByIds = getGroupByChoices(
            groupByChoices.map(e => e.id), 'transactions-exact-and-no-exact-payment',
            ability.rulesFor('find', 'statistics')
        );
        return groupByChoices.filter(e => groupByIds.includes(e.id));
    }, [ability]);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="groupBy"
                                    label="pos.generic.groupBy"
                                    choices={checkedGroupByChoices}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="orderType"
                                    label="pos.generic.order.orderType.name"
                                    choices={[
                                        { id: 'ASC', name: 'pos.generic.order.orderType.asc' },
                                        { id: 'DESC', name: 'pos.generic.order.orderType.desc' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="limit"
                                    label="pos.generic.limit"
                                    min="1"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.payments.sections.transactionsExactPayment.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                groupBy: getGroupByDefaultValue(
                                    groupByChoices.map(e => e.id), 'transactions-exact-and-no-exact-payment',
                                    ability.rulesFor('find', 'statistics'), 'transaction.terminalNumber'
                                ),
                                businessDayDate: getBusinessDayDateDefaultValue(),
                                orderType: 'DESC',
                                limit: 25
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.payments.sections.transactionsExactPayment.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    mainTable: DataTable
                }}
            />
        </div>
    );
};

const Chart = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles({ exportable });

    const [processedData, setProcessedData] = useState([]);
    const [paymentTypesTranslated] = useState(paymentTypes.map(e => ({
        id: e.id,
        name: translate(e.name)
    })));

    let { groupBy, chainId } = filters;

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            setProcessedData(data.map(e => {
                let groupByValue = e[groupBy];
                if (groupBy === 'transaction.operatorCode' && ((chainId && chainId.length !== 1) || !chainId)) {
                    groupByValue = _.truncate(`${e[groupBy]} (${e['chain.name']})`, { length: 15 });
                }

                let obj = {
                    id: groupByValue,
                    [groupBy]: e[groupBy],
                    totalPayments: 0,
                    ...(groupBy === 'transaction.operatorCode' && {
                        'operator.name': e['operator.name'],
                        'chain.name': e['chain.name'],
                        unknown: !e['operator.name']
                    })
                };

                paymentTypesTranslated.forEach(paymentTypeTranslated => {
                    if (paymentTypeTranslated.id in e) {
                        obj[paymentTypeTranslated.name] = e[paymentTypeTranslated.id];
                        obj.totalPayments += e[paymentTypeTranslated.id];
                    }
                });
                return obj;
            }));
        }
    }, [translate, paymentTypesTranslated, data, loading, cancel, groupBy, chainId]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const axisLeft = value => value.toLocaleString(locale);

    const label = ({ data, value }) => {
        let percentage = _.round(value / (data.totalPayments ? data.totalPayments : 1) * 100, 4);
        return `${percentage.toLocaleString(locale)} %`;
    };

    const tooltip = ({ data, value, color, id }) => {
        let { totalPayments, [groupBy]: groupByData, 'operator.name': operatorName, 'chain.name': chainName, unknown } = data;
        let percentage = _.round(value / (totalPayments ? totalPayments : 1) * 100, 4);

        value = value.toLocaleString(locale);

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                    <span>
                        <strong>{id}</strong>
                    </span>
                    {(groupBy === 'transaction.operatorCode' && !unknown) &&
                        <span>
                            {translate('resources.transactions.fields.operatorName')}: <strong>{operatorName}</strong>
                        </span>
                    }
                    <span>
                        {translate(groupByChoices.find(e => e.id === groupBy).name)}: <strong>{groupByData}</strong>
                    </span>
                    {groupBy === 'transaction.operatorCode' &&
                        <span>
                            {translate('resources.chains.name', 1)}: <strong>{chainName}</strong>
                        </span>
                    }
                    <span>
                        {translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })}: <strong>{value}</strong>
                    </span>
                    <span>
                        {translate('pos.generic.percentage')}: <strong>{percentage.toLocaleString(locale)} %</strong>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.chart}>
            <ResponsiveBar
                data={processedData}
                label={label}
                tooltip={tooltip}
                keys={paymentTypesTranslated.map(e => e.name)}
                indexBy="id"
                margin={{
                    top: 20,
                    bottom: 100,
                    right: 200,
                    left: 100
                }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'paired' }}
                borderColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: translate(groupByChoices.find(e => e.id === groupBy).name),
                    legendPosition: 'middle',
                    legendOffset: 70
                }}
                axisLeft={{
                    format: axisLeft,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: translate('pos.generic.quantity'),
                    legendPosition: 'middle',
                    legendOffset: -80
                }}
                labelSkipWidth={48}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        itemDirection: 'left-to-right',
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        symbolSize: 14,
                    }
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
            />
        </div>
    );
};

const TransactionFieldQuantity = ({ record, source, ...props }) => {
    const locale = useLocale();

    let value = _.get(record, source);
    let percentage = _.round(value / (record.transactionCashQuantity ? record.transactionCashQuantity : 1) * 100, 2);

    const newRecord = {
        [source]: `${value.toLocaleString(locale)} (${percentage.toLocaleString(locale)}%)`
    };

    return (
        <TextField record={newRecord} source={source} {...props} />
    );
};

const DataTable = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { groupBy } = filters;

    useEffect(() => {
        setProcessingData(true);
        if (data && data.length > 0 && !loading && !cancel) {
            let totalTransactionCashQuantity = _.sumBy(data, 'transactionCashQuantity');
            let processedData = {
                data: data.map(e => ({
                    [groupBy]: e[groupBy],
                    transactionCashQuantity: e.transactionCashQuantity,
                    exactPayments: e.exactPayments,
                    noExactPayments: e.noExactPayments,
                    percentage: _.round(e.transactionCashQuantity / (totalTransactionCashQuantity ? totalTransactionCashQuantity : 1) * 100, 4),
                    ...(groupBy === 'transaction.operatorCode' && {
                        'operator.name': e['operator.name'],
                        'chain.name': e['chain.name']
                    })
                })),
                totalTransactionCashQuantity: totalTransactionCashQuantity,
                totalExactPayments: _.sumBy(data, 'exactPayments'),
                totalNoExactPayments: _.sumBy(data, 'noExactPayments')
            };
            processedData.totalPercentage = _.round(_.sumBy(processedData.data, 'percentage'));
            setProcessedData(processedData);
            setProcessingData(false);
        }
    }, [data, loading, cancel, groupBy]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {translate(groupByChoices.find(e => e.id === filters.groupBy).name)}
                        </TableCell>
                        {groupBy === 'transaction.operatorCode' &&
                            <TableCell>
                                {translate('resources.transactions.fields.operatorName')}
                            </TableCell>
                        }
                        {groupBy === 'transaction.operatorCode' &&
                            <TableCell>
                                {translate('resources.chains.name', 1)}
                            </TableCell>
                        }
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.payments.sections.transactionsExactPayment.transactionCashQuantity')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.payments.sections.transactionsExactPayment.exactPayments')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.payments.sections.transactionsExactPayment.noExactPayments')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) })}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell
                                colSpan={groupBy === 'transaction.operatorCode' ? 7 : 5}
                                align="center"
                            >
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => {
                            if (!(groupBy in row)) {
                                return null;
                            }

                            return (
                                <TableRow
                                    key={groupBy === 'transaction.operatorCode' ? `${row[groupBy]}-${row['chain.name']}` : row[groupBy]}
                                >
                                    <TableCell>
                                        <TextField record={row} source={groupBy} />
                                    </TableCell>
                                    {groupBy === 'transaction.operatorCode' &&
                                        <TableCell>
                                            <TextField record={row} source="operator.name" emptyText={emptyText} />
                                        </TableCell>
                                    }
                                    {groupBy === 'transaction.operatorCode' &&
                                        <TableCell>
                                            <TextField record={row} source="chain.name" emptyText={emptyText} />
                                        </TableCell>
                                    }
                                    <TableCell align="right">
                                        <NumberField record={row} source="transactionCashQuantity" locales={locale} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <TransactionFieldQuantity record={row} source="exactPayments" />
                                    </TableCell>
                                    <TableCell align="right">
                                        <TransactionFieldQuantity record={row} source="noExactPayments" />
                                    </TableCell>
                                    <TableCell align="right">
                                        <PercentageField record={row} source="percentage" />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow key="total">
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.total', 1)}
                            </TableCell>
                            {groupBy === 'transaction.operatorCode' &&
                                <TableCell colSpan={2} />
                            }
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="totalTransactionCashQuantity"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="totalExactPayments"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="totalNoExactPayments"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <PercentageField
                                    record={processedData}
                                    source="totalPercentage"
                                    className={classes.totalCell}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let {
        groupBy, businessDayDate, chainId, storeCode, terminalNumber, operatorCode, orderType, limit
    } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'transactions-exact-and-no-exact-payment',
            groupBy: groupBy,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderType: orderType,
            limit: limit,
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [renderKey, groupBy, businessDayDate, chainId, storeCode, terminalNumber, operatorCode, orderType, limit]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const TransactionsExactPaymentBar = () => {
    const translate = useTranslate();
    const ability = useContext(AbilityContext);

    const filters = {
        groupBy: getGroupByDefaultValue(
            groupByChoices.map(e => e.id), 'transactions-exact-and-no-exact-payment',
            ability.rulesFor('find', 'statistics'), 'transaction.terminalNumber'
        ),
        businessDayDate: getBusinessDayDateDefaultValue(),
        orderType: 'DESC',
        limit: 25
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.payments.sections.transactionsExactPayment.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default TransactionsExactPaymentBar;
