import _ from 'lodash';

export default function getGroupByDefaultValue(choices, type, rules, prefer = null) {
    let groupsBy = [];
    for (const rule of rules) {
        let conditions = _.get(rule, 'conditions');
        if (_.isEmpty(conditions)) {
            return choices.includes(prefer) ? prefer : choices[0];
        }

        let typesInCondition = _.get(conditions, 'type.$in');
        let groupsByInCondition = _.get(conditions, 'groupBy.$in');
        if (_.isArray(typesInCondition) && typesInCondition.includes(type) && _.isArray(groupsByInCondition)) {
            groupsBy.push(groupsByInCondition.filter(groupBy => choices.includes(groupBy)));
        } else if (_.isArray(typesInCondition) && typesInCondition.includes(type) && !groupsByInCondition) {
            return choices.includes(prefer) ? prefer : choices[0];
        }
    }

    groupsBy = groupsBy.flat();
    if (groupsBy.includes(prefer)) {
        return prefer;
    }

    return groupsBy[0];
}
