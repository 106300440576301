import * as React from 'react';
import { Fragment } from 'react';
import { BulkExportButton } from 'react-admin';
import { CustomBulkDeleteButton } from './CustomButton';

const CustomBulkActionButtons = props => {
    return (
        <Fragment>
            <BulkExportButton {...props} />
            <CustomBulkDeleteButton
                {...props}
                confirmTitle={`resources.${props.resource}.crud.bulkDelete.confirmTitle`}
                confirmContent={`resources.${props.resource}.crud.bulkDelete.confirmContent`}
                undoable={false}
            />
        </Fragment>
    );
};

export default CustomBulkActionButtons;
