import * as React from 'react';
import { Labeled, TextField, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';
import DownloadFileButton from './DownloadFileButton';
import CustomDateTimeField from '../../custom/CustomDateTimeField';

const FileGeneral = props => {
    const translate = useTranslate();

    return (
        <span>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.files.fields.originalName">
                        <TextField record={props.record} source="originalName" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.files.fields.extension">
                        <TextField record={props.record} source="extension" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.files.fields.createdAt">
                        <CustomDateTimeField
                            record={props.record}
                            source="createdAt"
                            showFormat={translate('pos.datetime.datetime')}
                            isUtc={true}
                        />
                    </Labeled>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '15px' }}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <DownloadFileButton record={props.record} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </span>
    );
};

export default FileGeneral;
