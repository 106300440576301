import * as React from 'react';
import { Datagrid, List, TextField, useTranslate } from 'react-admin';
import ChainFilter from './ChainFilter';
import { CustomShowButton, CustomEditButton } from '../../custom/CustomButton';
import CustomBulkActionButtons from '../../custom/CustomBulkActionButtons';

const ChainList = ({ voidTitle = false, userId, fileId, ...props }) => {
    const translate = useTranslate();
    const emptyText = translate('pos.generic.unknown');
    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(userId && { include: 1, userId: userId }),
        ...(fileId && { include: 1, fileId: fileId })
    };

    return (
        <List
            {...props}
            title={title}
            actions={null}
            bulkActionButtons={<CustomBulkActionButtons />}
            filters={<ChainFilter />}
            filter={filter}
        >
            <Datagrid
                rowClick="show"
                hover={false}
                rowStyle={(record, index) => {
                    if (index % 2 === 0) {
                        return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                    }
                }}
            >
                <TextField source="name" />
                <TextField source="status" emptyText={emptyText} />
                <CustomShowButton />
                <CustomEditButton />
            </Datagrid>
        </List>
    );
};

export default ChainList;
