import * as React from 'react';
import { Labeled, TextField, ReferenceField } from 'react-admin';
import { Grid } from '@material-ui/core';

const ItemDetailGeneral = props => {
    return (
        <span>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.itemDetails.fields.itemCode">
                        <TextField record={props.record} source="itemCode" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.itemDetails.fields.name">
                        <TextField record={props.record} source="name" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.itemDetails.fields.chain">
                        <ReferenceField
                            label="resources.itemDetails.fields.chain"
                            source="chainId"
                            reference="chains"
                            link="show"
                        >
                            <TextField source="name"/>
                        </ReferenceField>
                    </Labeled>
                </Grid>
            </Grid>
        </span>
    );
};

export default ItemDetailGeneral;
