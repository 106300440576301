import * as React from 'react';
import { TopToolbar, TabbedShowLayout, TabbedShowLayoutTabs, Show, Tab } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CustomTitle from '../../layout/CustomTitle';
import DepartmentGeneral from './DepartmentGeneral';
import { CustomListButton, CustomEditButton } from '../../custom/CustomButton';

const useStyles = makeStyles({
    root: { flexGrow: 1 },
    tabs: { width: 0 },
    table: { paddingBottom: '20px' }
});

const DepartmentShowActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
            <CustomEditButton {...props} record={props.data} />
        </TopToolbar>
    );
};

const DepartmentShow = props => {
    const classes = useStyles();

    return (
        <Show
            title={<CustomTitle action="show" {...props} />}
            actions={<DepartmentShowActions />}
            className={classes.root}
            {...props}
        >
            <TabbedShowLayout
                tabs={
                    <TabbedShowLayoutTabs
                        classes={{ scrollable: classes.tabs }}
                        variant="scrollable"
                        scrollButtons="on"
                    />
                }
            >
                <Tab label="resources.departments.tabs.general">
                    <DepartmentGeneral />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default DepartmentShow;
