import * as React from 'react';
import { cloneElement, useState } from 'react';
import { useTranslate, useGetIdentity } from 'react-admin';
import { Tooltip, IconButton, Menu, Button, useMediaQuery } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CustomLogoutButton from './CustomLogoutButton';
import CustomMenuItemLink from '../custom/CustomMenuItemLink';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    userButton: {
        textTransform: 'none',
    }
});

const CustomUserMenu = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const { identity: user, loaded: userLoaded } = useGetIdentity();

    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }

    const label = translate('ra.auth.user_menu');

    return (
        <div>
            {(!isXSmall && userLoaded) ? (
                <Button
                    aria-label={label}
                    className={classes.userButton}
                    color="inherit"
                    startIcon={<AccountCircleIcon />}
                    onClick={handleMenu}
                >
                    {user.name ? user.name : user.email}
                </Button>
            ) : (
                <Tooltip title={label}>
                    <IconButton
                        aria-label={label}
                        aria-owns={open ? 'menu-appbar' : null}
                        aria-haspopup={true}
                        color="inherit"
                        onClick={handleMenu}
                    >
                        <AccountCircleIcon />
                    </IconButton>
                </Tooltip>
            )}
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                {userLoaded &&
                    <CustomMenuItemLink
                        to={`/users/${user.id}/show`}
                        primary={label}
                        icon={<AccountCircleIcon />}
                        onClick={handleClose}
                    />
                }
                {cloneElement(<CustomLogoutButton />)}
            </Menu>
        </div>
    );
};

export default CustomUserMenu;
