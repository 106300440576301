import * as React from 'react';
import {
    Edit, BooleanInput, TextInput, TopToolbar, ListButton, ShowButton, useTranslate, FormDataConsumer, FormWithRedirect,
    useEditController, maxLength, required, number, minValue, maxValue, useNotify, useRedirect, useRefresh
} from 'react-admin';
import { Grid } from '@material-ui/core';
import CustomTitle from '../../layout/CustomTitle';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import CustomEditToolbar from '../../custom/CustomEditToolbar';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import CustomReferenceAutocompleteArrayInput from "../../custom/CustomReferenceAutocompleteArrayInput";

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    },
    input: {
        marginTop: '8px',
        marginBottom: '4px'
    }
});

const OperatorEditActions = ({ basePath, data }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath}/>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );
};

const operatorCodeNameChainIdUniqueValidation = async (value, allValues) => {
    try {
        if (allValues.operatorCode !== undefined && allValues.chainId !== undefined) {
            const params = {
                operatorCode: allValues.operatorCode,
                name: allValues.name || null,
                chainId: allValues.chainId,
                id: { $ne: allValues.id }
            }
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/operators?${queryString}`);
            const { data: operators } = response.data;
            if (operators.length > 0) {
                return 'resources.operators.crud.edit.validation.operatorCodeNameChainIdUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const storesInChainValidation = async (value, allValues) => {
    try {
        if (value && value.length > 0) {
            const queryString = stringify({ id: value }, { strictNullHandling: true });
            const response = await axios.get(`/stores?${queryString}`); //usar Promises.all como en TransactionsTimesBar para obtener mas de 10 stores
            const { data: stores } = response.data;
            if (stores.length > 0 && stores.find(store => store.chainId !== allValues.chainId)) {
                return {
                    message: 'resources.operators.crud.edit.validation.storesNotInChain',
                    // optionErrors: stores.filter(store => store.chainId !== allValues.chainId).map(e => e.id)
                };
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    operatorCode: [
        number(), minValue(-2147483648), maxValue(2147483647), required(),
        operatorCodeNameChainIdUniqueValidation
    ],
    name: [maxLength(255), required(), operatorCodeNameChainIdUniqueValidation],
    type: [], // choices
    chainId: [required(), operatorCodeNameChainIdUniqueValidation],
    storeIds: [storesInChainValidation]
};

const OperatorEdit = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const { record } = useEditController(props);
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        notify(`resources.operators.crud.edit.notification.success`, 'success');
        redirect(`/operators/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.operators.crud.edit.notification.failed`, 'error');
    };

    return (
        <Edit
            title={<CustomTitle action="edit" {...props} />}
            actions={<OperatorEditActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            mutationMode="pessimistic"
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="id" resource="operators" helperText={false} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="operatorCode"
                                    resource="operators"
                                    validate={validations.operatorCode}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="operators"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="type"
                                    resource="operators"
                                    label="resources.operators.fields.type.name"
                                    validate={validations.type}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="chainId"
                                    record={record}
                                    reference="chains"
                                    originSource="id"
                                    label={translate('resources.operators.fields.chain')}
                                    optionText="name"
                                    validate={validations.chainId}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput
                                    source="inReport"
                                    resource="operators"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormDataConsumer>
                                    {({ formData }) => (
                                        <CustomReferenceAutocompleteArrayInput
                                            source="storeIds"
                                            record={record}
                                            reference="stores"
                                            originSource="id"
                                            label={translate('resources.groups.fields.stores.name')}
                                            optionText="storeCode"
                                            withNull={false}
                                            validate={validations.storeIds}
                                            additionalFilter={
                                                formData.chainId ?
                                                    { chainId: formData.chainId } :
                                                    null
                                            }
                                            fullWidth={true}
                                            className={classes.input}
                                        />
                                    )}
                                </FormDataConsumer>
                            </Grid>
                        </Grid>
                        <CustomEditToolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            record={record}
                            resource={props.resource}
                            basePath={props.basePath}
                        />
                    </form>
                )}
            />
        </Edit>
    );
};

export default OperatorEdit;
