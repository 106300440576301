import * as React from 'react';
import { useEffect } from 'react';
import { Layout, useLocale, useSetLocale } from 'react-admin';
import CustomMenu from './CustomMenu';
import CustomAppBar from './CustomAppBar';
import CustomBreadcrumb from './CustomBreadcrumb';
import CustomNotification from './CustomNotification';

const CustomLayout = ({ children, ...props }) => {
    const setLocale = useSetLocale();
    const defaultLocale = useLocale();

    useEffect(() => {
        const locale = localStorage.getItem('locale');
        if (locale) {
            setLocale(locale).then();
        } else {
            localStorage.setItem('locale', defaultLocale);
        }

    }, [setLocale, defaultLocale]);

    return (
        <Layout
            {...props}
            appBar={CustomAppBar}
            menu={CustomMenu}
            notification={CustomNotification}
        >
            <CustomBreadcrumb />
            {children}
        </Layout>
    );
}

export default CustomLayout;
