import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    Show,
    Tab,
    BooleanField, ShowButton
} from 'react-admin';
import TabbedShowLayout from "./TabbedShowLayout";

const ExceptionList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="storeNumber" />
            <TextField source="code" />
            <NumberField source="terminalNumber" />
            <NumberField source="transactionNumber" />
            <DateField source="dateAndTime" showTime />
            <TextField source="operatorCode" />
            <DateField source="createdAt" />
            <ShowButton />
        </Datagrid>
    </List>
);

const ExceptionShow = props => {
    return (
        <Show title="Exception view" {...props}>
            <TabbedShowLayout>
                <Tab label="header">
                    <TextField source="storeNumber" />
                    <TextField source="code" />
                    <NumberField source="terminalNumber" />
                    <NumberField source="transactionNumber" />
                    <DateField source="dateAndTime" />
                    <TextField source="operatorCode" />
                    <DateField source="createdAt" />
                    <DateField source="updatedAt" />
                </Tab>
                <Tab label="sales transaction" hidden={"salesTransactionException"}>
                        <NumberField source="salesTransactionException.override"/>
                        <TextField source="salesTransactionException.reason"/>
                        <NumberField source="salesTransactionException.index"/>
                        <TextField source="salesTransactionException.initials"/>
                        <TextField source="salesTransactionException.userData"/>
                </Tab>
                <Tab label="customer entry" hidden={"customerEntryException"}>
                    <NumberField source="customerEntryException.override" />
                    <TextField source="customerEntryException.reason" />
                    <TextField source="customerEntryException.preferredCustomer" />
                    <NumberField source="customerEntryException.index" />
                    <TextField source="customerEntryException.initials" />
                    <TextField source="customerEntryException.userData" />
                </Tab>
                <Tab label="wic ebt card" hidden={"wicEbtCardException"}>
                    <NumberField source="wicEbtCardException.identifier" />
                    <TextField source="wicEbtCardException.agencyID" />
                    <NumberField source="wicEbtCardException.PANLength" />
                    <NumberField source="wicEbtCardException.PAN" />
                    <TextField source="wicEbtCardException.errorCode" />
                    <TextField source="wicEbtCardException.issuingAgency" />
                    <NumberField source="wicEbtCardException.ICCSystemData" />
                </Tab>
                <Tab label="wic ebt" hidden={"wicEbtCardException"}>
                    <NumberField source="wicEbtCardException.identifier" />
                    <TextField source="wicEbtCardException.agencyID" />
                    <NumberField source="wicEbtCardException.PANLength" />
                    <NumberField source="wicEbtCardException.PAN" />
                    <TextField source="wicEbtCardException.errorCode" />
                    <TextField source="wicEbtCardException.issuingAgency" />
                    <NumberField source="wicEbtCardException.ICCSystemData" />
                </Tab>
                <Tab label="transferred processing" hidden={"transferProcessingException"}>
                    <TextField source="transferProcessingException.firstName" />
                    <NumberField source="transferProcessingException.recordsProcessed" />
                    <NumberField source="transferProcessingException.recordsTransferred" />
                    <NumberField source="transferProcessingException.countOfStoresProcessed" />
                </Tab>
                <Tab label="free disk space" hidden={"freeDiskSpaceException"}>
                    <TextField source="freeDiskSpaceException.nodeID" />
                    <NumberField source="freeDiskSpaceException.cDiskFree" />
                    <NumberField source="freeDiskSpaceException.dDiskFree" />
                </Tab>
                <Tab label="unable to read transaction log" hidden={"unableToReadTlogRecordException"}>
                    <TextField source="unableToReadTlogRecordException.summaryLogNumber" />
                    <TextField source="unableToReadTlogRecordException.recordPointer" />
                    <NumberField source="unableToReadTlogRecordException.userExit" />
                </Tab>
                <Tab label="reload terminal item record file" hidden={"reloadTerminalItemRecordFileException"}>
                    <TextField source="reloadTerminalItemRecordFileException.event" />
                    <TextField source="reloadTerminalItemRecordFileException.buildType" />
                    <NumberField source="reloadTerminalItemRecordFileException.numberOfRecords" />
                    <TextField source="reloadTerminalItemRecordFileException.userData" />
                </Tab>
                <Tab label="DDM operator authorization batch" hidden={"ddmOperatorAuthorizationBatchException"}>
                    <NumberField source="ddmOperatorAuthorizationBatchException.batch" />
                    <TextField source="ddmOperatorAuthorizationBatchException.type" />
                    <NumberField source="ddmOperatorAuthorizationBatchException.recordNumber" />
                    <TextField source="ddmOperatorAuthorizationBatchException.oldData" />
                    <TextField source="ddmOperatorAuthorizationBatchException.newData" />
                    <TextField source="ddmOperatorAuthorizationBatchException.enhancedOperatorID" />
                    <TextField source="ddmOperatorAuthorizationBatchException.reserved" />
                </Tab>
                <Tab label="DDM item movement batch" hidden={"ddmItemMovementBatchException"}>
                    <NumberField source="ddmItemMovementBatchException.batch" />
                    <TextField source="ddmItemMovementBatchException.type" />
                    <NumberField source="ddmItemMovementBatchException.recordNumber" />
                    <TextField source="ddmItemMovementBatchException.oldData" />
                    <TextField source="ddmItemMovementBatchException.newData" />
                    <TextField source="ddmItemMovementBatchException.enhancedOperatorID" />
                    <TextField source="ddmItemMovementBatchException.reserved" />
                </Tab>
                <Tab label="DDM tender verification batch" hidden={"ddmTenderVerificationBatchException"}>
                    <NumberField source="ddmTenderVerificationBatchException.batch" />
                    <TextField source="ddmTenderVerificationBatchException.type" />
                    <NumberField source="ddmTenderVerificationBatchException.recordNumber" />
                    <TextField source="ddmTenderVerificationBatchException.oldData" />
                    <TextField source="ddmTenderVerificationBatchException.newData" />
                    <TextField source="ddmTenderVerificationBatchException.enhancedOperatorID" />
                    <TextField source="ddmTenderVerificationBatchException.reserved" />
                </Tab>
                <Tab label="DDM item record batch" hidden={"ddmItemRecordBatchException"}>
                    <NumberField source="ddmItemRecordBatchException.batch" />
                    <TextField source="ddmItemRecordBatchException.type" />
                    <NumberField source="ddmItemRecordBatchException.recordNumber" />
                    <TextField source="ddmItemRecordBatchException.oldData" />
                    <TextField source="ddmItemRecordBatchException.newData" />
                    <TextField source="ddmItemRecordBatchException.enhancedOperatorID" />
                    <TextField source="ddmItemRecordBatchException.reserved" />
                </Tab>
                <Tab label="DDM executed" hidden={"ddmExecutedException"}>
                    <NumberField source="ddmExecutedException.batch" />
                    <TextField source="ddmExecutedException.action" />
                    <TextField source="ddmExecutedException.enhancedOperatorID" />
                </Tab>
                <Tab label="personalization executed" hidden={"personalizationExecutedException"}>
                    <TextField source="personalizationExecutedException.activity" />
                    <TextField source="personalizationExecutedException.fileID" />
                    <TextField source="personalizationExecutedException.record" />
                    <TextField source="personalizationExecutedException.enhancedOperatorID" />
                    <TextField source="personalizationExecutedException.reserved" />
                </Tab>
                <Tab label="report executed" hidden={"reportExecutedException"}>
                    <TextField source="reportExecutedException.name" />
                    <NumberField source="reportExecutedException.operatorTerminal" />
                    <TextField source="reportExecutedException.type" />
                    <TextField source="reportExecutedException.accountability" />
                    <TextField source="reportExecutedException.enhancedOperatorID" />
                </Tab>
                <Tab label="item movement list record" hidden={"itemMovementListRecordException"}>
                    <TextField source="itemMovementListRecordException.type" />
                    <NumberField source="itemMovementListRecordException.batch" />
                    <TextField source="itemMovementListRecordException.enhancedOperatorID" />
                </Tab>
                <Tab label="tender verification record" hidden={"tenderVerificationRecordException"}>
                    <TextField source="tenderVerificationRecordException.type" />
                    <NumberField source="tenderVerificationRecordException.recordKey" />
                    <TextField source="tenderVerificationRecordException.oldData" />
                    <TextField source="tenderVerificationRecordException.newData" />
                    <TextField source="tenderVerificationRecordException.enhancedOperatorID" />
                    <TextField source="tenderVerificationRecordException.reserved" />
                </Tab>
                <Tab label="item record" hidden={"itemRecordException"}>
                    <TextField source="itemRecordException.type" />
                    <NumberField source="itemRecordException.recordKey" />
                    <TextField source="itemRecordException.oldData" />
                    <TextField source="itemRecordException.newData" />
                    <TextField source="itemRecordException.enhancedOperatorID" />
                    <TextField source="itemRecordException.reserved" />
                </Tab>
                <Tab label="operator authorization record" hidden={"operatorAuthorizationRecordException"}>
                    <TextField source="operatorAuthorizationRecordException.type" />
                    <NumberField source="operatorAuthorizationRecordException.recordKey" />
                    <TextField source="operatorAuthorizationRecordException.oldData" />
                    <TextField source="operatorAuthorizationRecordException.newData" />
                    <TextField source="operatorAuthorizationRecordException.enhancedOperatorID" />
                    <TextField source="operatorAuthorizationRecordException.reserved" />
                </Tab>
                <Tab label="request for close" hidden={"requestForCloseException"}>
                    <TextField source="requestForCloseException.indicator0" />
                    <BooleanField source="requestForCloseException.customerAccountTotalsClosed" />
                    <BooleanField source="requestForCloseException.itemMovementTotalsClosed" />
                    <BooleanField source="requestForCloseException.exceptionLogClosed" />
                    <BooleanField source="requestForCloseException.terminalProductivityLogClosed" />
                    <BooleanField source="requestForCloseException.tenderListingLogClosed" />
                    <TextField source="requestForCloseException.closeName" />
                    <TextField source="requestForCloseException.enhancedOperatorID" />
                    <TextField source="requestForCloseException.reserved" />
                </Tab>
                <Tab label="miscellaneous transaction" hidden={"miscellaneousTransactionException"}>
                    <NumberField source="miscellaneousTransactionException.operatorTerminal" />
                    <TextField source="miscellaneousTransactionException.indicator0" />
                    <TextField source="miscellaneousTransactionException.tenderType" />
                    <TextField source="miscellaneousTransactionException.direction" />
                    <NumberField source="miscellaneousTransactionException.amount" />
                    <NumberField source="miscellaneousTransactionException.account1" />
                    <NumberField source="miscellaneousTransactionException.account2" />
                    <TextField source="miscellaneousTransactionException.enhancedOperatorID" />
                    <TextField source="miscellaneousTransactionException.reserved" />
                </Tab>
                <Tab label="carry forward tender controller" hidden={"carryForwardTenderControllerException"}>
                    <TextField source="carryForwardTenderControllerException.tenderAmount" />
                    <TextField source="carryForwardTenderControllerException.enhancedOperatorID" />
                </Tab>
                <Tab label="transfer tender controller" hidden={"transferTenderControllerException"}>
                    <NumberField source="transferTenderControllerException.operatorTerminal" />
                    <TextField source="transferTenderControllerException.indicator0" />
                    <TextField source="transferTenderControllerException.tenderAmount" />
                    <TextField source="transferTenderControllerException.enhancedOperatorID" />
                </Tab>
                <Tab label="tender count controller" hidden={"tenderCountControllerException"}>
                    <NumberField source="tenderCountControllerException.operatorTerminal" />
                    <TextField source="tenderCountControllerException.indicator0" />
                    <TextField source="tenderCountControllerException.tenderAmount" />
                    <TextField source="tenderCountControllerException.enhancedOperatorID" />
                    <TextField source="tenderCountControllerException.reserved" />
                </Tab>
                <Tab label="cashier pickup controller" hidden={"cashierPickupControllerException"}>
                    <NumberField source="cashierPickupControllerException.operatorTerminal" />
                    <TextField source="cashierPickupControllerException.indicator0" />
                    <TextField source="cashierPickupControllerException.tenderAmount" />
                    <TextField source="cashierPickupControllerException.enhancedOperatorID" />
                    <TextField source="cashierPickupControllerException.reserved" />
                </Tab>
                <Tab label="cashier loan controller" hidden={"cashierLoanControllerException"}>
                    <NumberField source="cashierLoanControllerException.operatorTerminal" />
                    <TextField source="cashierLoanControllerException.indicator0" />
                    <TextField source="cashierLoanControllerException.tenderAmount" />
                    <TextField source="cashierLoanControllerException.enhancedOperatorID" />
                    <TextField source="cashierLoanControllerException.reserved" />
                </Tab>
                <Tab label="user data entered" hidden={"userDataEnteredException"}>
                    <TextField source="userDataEnteredException.userData" />
                    <TextField source="userDataEnteredException.entryMethod" />
                    <TextField source="userDataEnteredException.reserved" />
                </Tab>
                <Tab label="tender count transaction" hidden={"tenderCountTransactionException"}>
                    <TextField source="tenderCountTransactionException.indicator0" />
                    <TextField source="tenderCountTransactionException.tenderAmount" />
                    <NumberField source="tenderCountTransactionException.manufacturerCouponAmount" />
                    <NumberField source="tenderCountTransactionException.storeCouponAmount" />
                    <TextField source="tenderCountTransactionException.userExit" />
                </Tab>
                <Tab label="critical hardware failure" hidden={"criticalHardwareFailureException"}>
                    <TextField source="criticalHardwareFailureException.indicator0" />
                    <TextField source="criticalHardwareFailureException.userExit" />
                    <NumberField source="criticalHardwareFailureException.override" />
                    <TextField source="criticalHardwareFailureException.reason" />
                    <NumberField source="criticalHardwareFailureException.index" />
                    <TextField source="criticalHardwareFailureException.initials" />
                </Tab>
                <Tab label="missing, found or duplicate" hidden={"missingFoundDuplicateException"}>
                    <TextField source="missingFoundDuplicateException.indicator0" />
                    <TextField source="missingFoundDuplicateException.userExit" />
                </Tab>
                <Tab label="store totals close" hidden={"storeTotalsClosedException"}>
                    <TextField source="storeTotalsClosedException.indicator0"/>
                    <BooleanField source="storeTotalsClosedException.customerAccountTotalsClosed"/>
                    <BooleanField source="storeTotalsClosedException.itemMovementTotalsClosed"/>
                    <BooleanField source="storeTotalsClosedException.exceptionLogClosed"/>
                    <BooleanField source="storeTotalsClosedException.terminalProductivityLogClosed"/>
                    <BooleanField source="storeTotalsClosedException.tenderListingLogClosed"/>
                </Tab>
                <Tab label="cashier pickup" hidden={"cashierPickupException"}>
                    <TextField source="cashierPickupException.indicator0" />
                    <TextField source="cashierPickupException.tenderAmount" />
                    <NumberField source="cashierPickupException.storeCouponAmount" />
                    <TextField source="cashierPickupException.reason" />
                    <TextField source="cashierPickupException.userExit" />
                    <NumberField source="cashierPickupException.index" />
                    <TextField source="cashierPickupException.initials" />
                </Tab>
                <Tab label="cashier loan" hidden={"cashierLoanException"}>
                    <TextField source="cashierLoanException.indicator0" />
                    <TextField source="cashierLoanException.tenderAmount" />
                    <NumberField source="cashierLoanException.manufacturerCouponAmount" />
                    <NumberField source="cashierLoanException.storeCouponAmount" />
                    <NumberField source="cashierLoanException.override" />
                    <TextField source="cashierLoanException.reason" />
                    <TextField source="cashierLoanException.userExit" />
                    <NumberField source="cashierLoanException.index" />
                    <TextField source="cashierLoanException.initials" />
                </Tab>
                <Tab label="non sales transaction" hidden={"nonSalesTransactionException"}>
                    <TextField source="nonSalesTransactionException.indicator0" />
                    <TextField source="nonSalesTransactionException.transData" />
                    <TextField source="nonSalesTransactionException.userExit" />
                    <NumberField source="nonSalesTransactionException.override" />
                    <TextField source="nonSalesTransactionException.reason" />
                    <NumberField source="nonSalesTransactionException.index" />
                    <TextField source="nonSalesTransactionException.initials" />
                </Tab>
                <Tab label="operator sign in/off" hidden={"operatorSignOnSignOffException"}>
                    <TextField source="operatorSignOnSignOffException.indicator0" />
                    <TextField source="operatorSignOnSignOffException.password1" />
                    <TextField source="operatorSignOnSignOffException.password2" />
                    <NumberField source="operatorSignOnSignOffException.override" />
                    <TextField source="operatorSignOnSignOffException.reason" />
                    <TextField source="operatorSignOnSignOffException.userExit" />
                    <NumberField source="operatorSignOnSignOffException.index" />
                    <TextField source="operatorSignOnSignOffException.initials" />
                </Tab>
                <Tab label="terminal price change" hidden={"terminalPriceChangeException"}>
                    <NumberField source="terminalPriceChangeException.itemCode" />
                    <NumberField source="terminalPriceChangeException.newDealQuantity" />
                    <NumberField source="terminalPriceChangeException.newPrice" />
                    <NumberField source="terminalPriceChangeException.oldDealQuantity" />
                    <NumberField source="terminalPriceChangeException.oldPrice" />
                    <NumberField source="terminalPriceChangeException.batch" />
                    <TextField source="terminalPriceChangeException.userExit" />
                </Tab>
                <Tab label="invalid item rejected" hidden={"invalidItemRejectedException"}>
                    <NumberField source="invalidItemRejectedException.itemCode" />
                    <TextField source="invalidItemRejectedException.indicator0" />
                    <TextField source="terminalPriceChangeException.userExit" />
                    <TextField source="terminalPriceChangeException.barcode" />
                </Tab>
                <Tab label="standalone entry" hidden={"standaloneEntryExitException"}>
                    <TextField source="standaloneEntryExitException.type" />
                    <NumberField source="standaloneEntryExitException.numberOfTransactions" />
                    <NumberField source="standaloneEntryExitException.grossPositive" />
                    <NumberField source="standaloneEntryExitException.grossNegative" />
                    <NumberField source="standaloneEntryExitException.numberOfPriceChanges" />
                </Tab>
                <Tab label="no sale transaction" hidden={"noSaleTransactionException"}>
                    <TextField source="noSaleTransactionException.indicator0" />
                    <TextField source="noSaleTransactionException.tenderType" />
                    <NumberField source="noSaleTransactionException.override" />
                    <TextField source="noSaleTransactionException.reason" />
                    <TextField source="noSaleTransactionException.userExit" />
                    <NumberField source="noSaleTransactionException.index" />
                    <TextField source="noSaleTransactionException.initials" />
                </Tab>
                <Tab label="tender exchange" hidden={"tenderExchangeException"}>
                    <TextField source="tenderExchangeException.fromTenderType" />
                    <TextField source="tenderExchangeException.toTenderType" />
                    <NumberField source="tenderExchangeException.tenderAmount" />
                    <NumberField source="tenderExchangeException.override" />
                    <TextField source="tenderExchangeException.reason" />
                    <TextField source="tenderExchangeException.userExit" />
                    <NumberField source="tenderExchangeException.index" />
                    <TextField source="tenderExchangeException.initials" />
                </Tab>
                <Tab label="void transaction" hidden={"voidTransactionException"}>
                    <NumberField source="voidTransactionException.voidAmount" />
                    <NumberField source="voidTransactionException.override" />
                    <TextField source="voidTransactionException.reason" />
                    <TextField source="voidTransactionException.userExit" />
                    <NumberField source="voidTransactionException.index" />
                    <TextField source="voidTransactionException.initials" />
                    <NumberField source="voidTransactionException.voidTransactionReasonCode" />
                </Tab>
                <Tab label="tender override" hidden={"tenderOverrideException"}>
                    <TextField source="tenderOverrideException.tenderType" />
                    <TextField source="tenderOverrideException.tenderVariety" />
                    <NumberField source="tenderOverrideException.account" />
                    <NumberField source="tenderOverrideException.tenderAmount" />
                    <NumberField source="tenderOverrideException.balanceDue" />
                    <NumberField source="tenderOverrideException.override" />
                    <TextField source="tenderOverrideException.reason" />
                    <TextField source="tenderOverrideException.preferredCustomer" />
                    <NumberField source="tenderOverrideException.userExit" />
                    <NumberField source="tenderOverrideException.index" />
                    <TextField source="tenderOverrideException.initials" />
                </Tab>
                <Tab label="discount or tax" hidden={"discountOrTaxException"}>
                    <TextField source="discountOrTaxException.discountAmount" />
                    <TextField source="discountOrTaxException.taxableAmount" />
                    <NumberField source="discountOrTaxException.discountRate" />
                    <NumberField source="discountOrTaxException.discountGroup" />
                    <NumberField source="discountOrTaxException.override" />
                    <TextField source="discountOrTaxException.reason" />
                    <TextField source="discountOrTaxException.preferredCustomer" />
                    <NumberField source="discountOrTaxException.userExit" />
                    <NumberField source="discountOrTaxException.index" />
                    <TextField source="discountOrTaxException.initials" />
                    <TextField source="discountOrTaxException.taxExemptID" />
                </Tab>
                <Tab label="item entry" hidden={"itemEntryException"}>
                    <NumberField source="itemEntryException.itemCode" />
                    <BooleanField source="itemEntryException.rainCheckItem" />
                    <BooleanField source="itemEntryException.fuelItem" />
                    <BooleanField source="itemEntryException.allSalesLogged" />
                    <BooleanField source="itemEntryException.priceOverride" />
                    <BooleanField source="itemEntryException.overrideEntered" />
                    <BooleanField source="itemEntryException.logLimitExceeded" />
                    <BooleanField source="itemEntryException.weightItem" />
                    <TextField source="itemEntryException.type" />
                    <NumberField source="itemEntryException.amount" />
                    <NumberField source="itemEntryException.saleMeAs" />
                    <NumberField source="itemEntryException.fileDeal" />
                    <NumberField source="itemEntryException.filePrice" />
                    <NumberField source="itemEntryException.override" />
                    <NumberField source="itemEntryException.userExit" />
                    <NumberField source="itemEntryException.enteredReasonCode" />
                    <NumberField source="itemEntryException.voidReasonCode" />
                    <NumberField source="itemEntryException.index" />
                    <TextField source="itemEntryException.initials" />
                    <NumberField source="itemEntryException.alternativePrice" />
                    <TextField source="itemEntryException.barcode" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export {
    ExceptionList, ExceptionShow
}
