const Breadcrumb =  {
    '/configuration': {
        name: 'configuration',
        translation: {
            key: 'pos.configuration.name'
        },
        isValidLink: true
    },
    '/help': {
        name: 'help',
        translation: {
            key: 'pos.help.name'
        },
        isValidLink: true
    },
    '/transactions': {
        name: 'transactions',
        translation: {
            key: 'resources.transactions.name',
            options: 2
        },
        isValidLink: true
    },
    '/transactions/statistics': {
        name: 'statistics',
        translation: {
            key: 'resources.transactions.statistics.name'
        },
        isValidLink: true
    },
    '/transactions/*': {
        name: 'id',
        isValidLink: false
    },
    '/transactions/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/transactions/*/show/summary': {
        name: 'summary',
        translation: {
            key: 'resources.transactions.tabs.summary.name'
        },
        isValidLink: true
    },
    '/transactions/*/show/general': {
        name: 'general',
        translation: {
            key: 'resources.transactions.tabs.general.name'
        },
        isValidLink: true
    },
    '/transactions/*/show/items': {
        name: 'items',
        translation: {
            key: 'resources.transactions.tabs.items'
        },
        isValidLink: true
    },
    '/transactions/*/show/customer': {
        name: 'customer',
        translation: {
            key: 'resources.transactions.tabs.customer'
        },
        isValidLink: true
    },
    '/transactions/*/show/discounts': {
        name: 'discounts',
        translation: {
            key: 'resources.transactions.tabs.discounts'
        },
        isValidLink: true
    },
    '/transactions/*/show/tenders': {
        name: 'tenders',
        translation: {
            key: 'resources.transactions.tabs.tenders'
        },
        isValidLink: true
    },
    '/transactions/*/show/epsTenders': {
        name: 'tenders',
        translation: {
            key: 'resources.transactions.tabs.epsTenders'
        },
        isValidLink: true
    },
    '/transactions/*/show/taxes': {
        name: 'taxes',
        translation: {
            key: 'resources.transactions.tabs.taxes'
        },
        isValidLink: true
    },
    '/transactions/*/show/overrides': {
        name: 'overrides',
        translation: {
            key: 'resources.transactions.tabs.overrides'
        },
        isValidLink: true
    },
    '/transactions/*/show/dataEntries': {
        name: 'overrides',
        translation: {
            key: 'resources.transactions.tabs.dataEntries'
        },
        isValidLink: true
    },
    '/transactions/*/show/tillChanges': {
        name: 'tillChanges',
        translation: {
            key: 'resources.transactions.tabs.tillChanges'
        },
        isValidLink: true
    },
    '/transactions/*/show/userStrings': {
        name: 'userStrings',
        translation: {
            key: 'resources.transactions.tabs.userStrings'
        },
        isValidLink: true
    },
    '/transactions/*/show/changes': {
        name: 'change',
        translation: {
            key: 'resources.transactions.tabs.changes'
        },
        isValidLink: true
    },
    '/transactions/*/show/couponsInformation': {
        name: 'couponsInformation',
        translation: {
            key: 'resources.transactions.tabs.couponsInformation'
        },
        isValidLink: true
    },
    '/exceptions': {
        name: 'exceptions',
        translation: {
            key: 'resources.exceptions.name',
            options: 2
        },
        isValidLink: true
    },
    '/exceptions/*': {
        name: 'id',
        isValidLink: false
    },
    '/exceptions/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/chains': {
        name: 'chains',
        translation: {
            key: 'resources.chains.name',
            options: 2
        },
        isValidLink: true
    },
    '/chains/*': {
        name: 'id',
        isValidLink: false
    },
    '/chains/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/chains/*/show/groups': {
        name: 'groups',
        translation: {
            key: 'resources.chains.tabs.groups'
        },
        isValidLink: true
    },
    '/chains/*/show/stores': {
        name: 'stores',
        translation: {
            key: 'resources.chains.tabs.stores'
        },
        isValidLink: true
    },
    '/chains/*/show/operators': {
        name: 'operators',
        translation: {
            key: 'resources.chains.tabs.operators'
        },
        isValidLink: true
    },
    '/chains/*/show/itemDetails': {
        name: 'itemDetails',
        translation: {
            key: 'resources.chains.tabs.itemDetails'
        },
        isValidLink: true
    },
    '/chains/*/show/departments': {
        name: 'departments',
        translation: {
            key: 'resources.chains.tabs.departments'
        },
        isValidLink: true
    },
    '/chains/*/show/terminalTypes': {
        name: 'departments',
        translation: {
            key: 'resources.chains.tabs.terminalTypes'
        },
        isValidLink: true
    },
    '/chains/*/show/users': {
        name: 'users',
        translation: {
            key: 'resources.chains.tabs.users'
        },
        isValidLink: true
    },
    '/chains/*/show/health': {
        name: 'health',
        translation: {
            key: 'pos.health.name.default'
        },
        isValidLink: true
    },
    '/chains/create': {
        name: 'create',
        translation: {
            key: 'ra.action.create'
        },
        isValidLink: true
    },
    '/chains/*/edit': {
        name: 'edit',
        translation: {
            key: 'ra.action.edit'
        },
        isValidLink: false
    },
    '/stores': {
        name: 'stores',
        translation: {
            key: 'resources.stores.name',
            options: 2
        },
        isValidLink: true
    },
    '/stores/*': {
        name: 'id',
        isValidLink: false
    },
    '/stores/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/stores/*/show/transactions': {
        name: 'transactions',
        translation: {
            key: 'resources.stores.tabs.transactions'
        },
        isValidLink: true
    },
    '/stores/*/show/groups': {
        name: 'groups',
        translation: {
            key: 'resources.stores.tabs.groups'
        },
        isValidLink: true
    },
    '/stores/*/show/users': {
        name: 'users',
        translation: {
            key: 'resources.stores.tabs.users'
        },
        isValidLink: true
    },
    '/stores/*/show/health': {
        name: 'health',
        translation: {
            key: 'pos.health.name.default'
        },
        isValidLink: true
    },
    '/stores/create': {
        name: 'create',
        translation: {
            key: 'ra.action.create'
        },
        isValidLink: true
    },
    '/stores/*/edit': {
        name: 'edit',
        translation: {
            key: 'ra.action.edit'
        },
        isValidLink: false
    },
    '/terminalTypes': {
        name: 'terminalTypes',
        translation: {
            key: 'resources.terminalTypes.name',
            options: 2
        },
        isValidLink: true
    },
    '/terminalTypes/*': {
        name: 'id',
        isValidLink: false
    },
    '/terminalTypes/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/terminalTypes/*/show/terminalNumberTypes': {
        name: 'children',
        translation: {
            key: 'resources.terminalTypes.tabs.terminalNumberTypes'
        },
        isValidLink: true
    },
    '/terminalTypes/create': {
        name: 'create',
        translation: {
            key: 'ra.action.create'
        },
        isValidLink: true
    },
    '/terminalTypes/*/edit': {
        name: 'edit',
        translation: {
            key: 'ra.action.edit'
        },
        isValidLink: false
    },
    '/groups': {
        name: 'groups',
        translation: {
            key: 'resources.groups.name',
            options: 2
        },
        isValidLink: true
    },
    '/groups/*': {
        name: 'id',
        isValidLink: false
    },
    '/groups/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/groups/*/show/children': {
        name: 'children',
        translation: {
            key: 'resources.groups.tabs.children'
        },
        isValidLink: true
    },
    '/groups/*/show/stores': {
        name: 'stores',
        translation: {
            key: 'resources.groups.tabs.stores'
        },
        isValidLink: true
    },
    '/groups/*/show/users': {
        name: 'users',
        translation: {
            key: 'resources.groups.tabs.users'
        },
        isValidLink: true
    },
    '/groups/create': {
        name: 'create',
        translation: {
            key: 'ra.action.create'
        },
        isValidLink: true
    },
    '/groups/*/edit': {
        name: 'edit',
        translation: {
            key: 'ra.action.edit'
        },
        isValidLink: false
    },
    '/operators': {
        name: 'operators',
        translation: {
            key: 'resources.operators.name',
            options: 2
        },
        isValidLink: true
    },
    '/operators/*': {
        name: 'id',
        isValidLink: false
    },
    '/operators/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/operators/*/show/transactions': {
        name: 'transactions',
        translation: {
            key: 'resources.operators.tabs.transactions'
        },
        isValidLink: true
    },
    '/operators/*/show/stores': {
        name: 'transactions',
        translation: {
            key: 'resources.operators.tabs.stores'
        },
        isValidLink: true
    },
    '/operators/create': {
        name: 'create',
        translation: {
            key: 'ra.action.create'
        },
        isValidLink: true
    },
    '/operators/*/edit': {
        name: 'edit',
        translation: {
            key: 'ra.action.edit'
        },
        isValidLink: false
    },
    '/itemDetails': {
        name: 'itemDetails',
        translation: {
            key: 'resources.itemDetails.name',
            options: 2
        },
        isValidLink: true
    },
    '/itemDetails/*': {
        name: 'id',
        isValidLink: false
    },
    '/itemDetails/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/itemDetails/create': {
        name: 'create',
        translation: {
            key: 'ra.action.create'
        },
        isValidLink: true
    },
    '/itemDetails/*/edit': {
        name: 'edit',
        translation: {
            key: 'ra.action.edit'
        },
        isValidLink: false
    },
    '/departments': {
        name: 'departments',
        translation: {
            key: 'resources.departments.name',
            options: 2
        },
        isValidLink: true
    },
    '/departments/*': {
        name: 'id',
        isValidLink: false
    },
    '/departments/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/departments/create': {
        name: 'create',
        translation: {
            key: 'ra.action.create'
        },
        isValidLink: true
    },
    '/departments/*/edit': {
        name: 'edit',
        translation: {
            key: 'ra.action.edit'
        },
        isValidLink: false
    },
    '/files': {
        name: 'files',
        translation: {
            key: 'resources.files.name',
            options: 2
        },
        isValidLink: true
    },
    '/files/*': {
        name: 'id',
        isValidLink: false
    },
    '/files/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/files/*/show/chains': {
        name: 'transactions',
        translation: {
            key: 'resources.files.tabs.chains'
        },
        isValidLink: true
    },
    '/files/*/show/stores': {
        name: 'transactions',
        translation: {
            key: 'resources.files.tabs.stores'
        },
        isValidLink: true
    },
    '/users': {
        name: 'users',
        translation: {
            key: 'resources.users.name',
            options: 2
        },
        isValidLink: true
    },
    '/users/*': {
        name: 'id',
        isValidLink: false
    },
    '/users/*/show': {
        name: 'show',
        translation: {
            key: 'pos.generic.show'
        },
        isValidLink: false
    },
    '/users/*/show/chains': {
        name: 'transactions',
        translation: {
            key: 'resources.users.tabs.chains'
        },
        isValidLink: true
    },
    '/users/*/show/groups': {
        name: 'transactions',
        translation: {
            key: 'resources.users.tabs.groups'
        },
        isValidLink: true
    },
    '/users/*/show/stores': {
        name: 'transactions',
        translation: {
            key: 'resources.users.tabs.stores'
        },
        isValidLink: true
    },
    '/users/create': {
        name: 'create',
        translation: {
            key: 'ra.action.create'
        },
        isValidLink: true
    },
    '/users/*/edit': {
        name: 'edit',
        translation: {
            key: 'ra.action.edit'
        },
        isValidLink: false
    },
    '/users/changePassword': {
        name: 'changePassword',
        translation: {
            key: 'resources.users.crud.changePassword.name'
        },
        isValidLink: false
    },

};

export default Breadcrumb;
