import * as React from 'react';
import {
    PasswordInput,
    Toolbar,
    Title,
    useTranslate,
    useNotify,
    useRedirect,
    useRefresh,
    required,
    minLength
} from 'react-admin';
import { Card, Grid, Button } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Form } from 'react-final-form';
import axios from '../../../clients/axiosClient';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        minMarginRight: '20px'
    },
    grid: {
        padding: theme.spacing(2)
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}));

const UserChangePasswordToolbar = props => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <Toolbar {...props} classes={classes}>
            <Button
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
            >
                {translate('ra.action.save')}
            </Button>
        </Toolbar>
    );
};

const newPasswordValidation = (value, allValues) => {
    if (allValues.password !== allValues.passwordConfirmation) {
        return 'resources.users.crud.changePassword.validation.passwordDistinct'
    }
    return undefined;
};

const validations = {
    oldPassword: [required()],
    password: [required(), minLength(8), newPasswordValidation],
    passwordConfirmation: [required(), minLength(8), newPasswordValidation],
};

const UserChangePassword = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles();

    const onSubmit = values => {
        let user = localStorage.getItem('user');
        user = user ? JSON.parse(user) : null;

        axios.patch(`/users/${user.id}`, values)
            .then(() => {
                notify(`resources.users.crud.changePassword.notification.success`, 'success');
                redirect(`/users/${user.id}/edit`);
                refresh();
            })
            .catch(error => {
                console.log(error);
                notify(`resources.users.crud.changePassword.notification.failed`, 'error');
            });
    };

    return (
        <Card className={classes.root}>
            <Title
                title={translate('resources.users.crud.changePassword.name')}
            />
            <Form onSubmit={onSubmit}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <PasswordInput
                                    source="oldPassword"
                                    resource="users"
                                    label="resources.users.crud.changePassword.oldPassword"
                                    validate={validations.oldPassword}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PasswordInput
                                    source="password"
                                    resource="users"
                                    label="resources.users.crud.changePassword.password"
                                    validate={validations.password}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PasswordInput
                                    source="passwordConfirmation"
                                    resource="users"
                                    label="resources.users.crud.changePassword.passwordConfirmation"
                                    validate={validations.passwordConfirmation}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <UserChangePasswordToolbar
                            handleSubmit={handleSubmit}
                            {...props}
                        />
                    </form>
                )}
            </Form>
        </Card>
    );
};

export default UserChangePassword;
