import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveTreeMapCanvas } from '@nivo/treemap';
import { SelectInput, NumberInput, TextField, NumberField, useTranslate, useLocale } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import PercentageField from '../../../custom/PercentageField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import resourcesConfig from '../../../../config/resourcesConfig.json';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: { maxHeight: '300px' },
    totalCell: { fontWeight: 500 }
}));

const groupByChoices = [
    {
        id: 'item.department',
        name: 'resources.transactions.fields.items.department',
        fieldDataId: 'department.name',
        fieldDataTranslate: 'resources.transactions.fields.items.departmentName'
    },
    {
        id: 'item.itemCode',
        name: 'resources.transactions.fields.items.itemCode',
        fieldDataId: 'itemDetail.name',
        fieldDataTranslate: 'resources.transactions.fields.items.itemName'
    }
];

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="groupBy"
                                    label="pos.generic.groupBy"
                                    choices={groupByChoices}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="quantifier"
                                    label="pos.generic.quantifier"
                                    choices={[
                                        { id: 'quantity', name: 'pos.generic.quantity' },
                                        { id: 'amount', name: 'pos.generic.amount' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="orderType"
                                    label="pos.generic.order.orderType.name"
                                    choices={[
                                        { id: 'ASC', name: 'pos.generic.order.orderType.asc' },
                                        { id: 'DESC', name: 'pos.generic.order.orderType.desc' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="limit"
                                    label="pos.generic.limit"
                                    min="1"
                                    max={
                                        form.getFieldState('groupBy') &&
                                        form.getFieldState('groupBy').value === 'itemCode' ?
                                            100 : null
                                    }
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                                }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.itemSales.sections.salesCount', {
                    group_by: translate(groupByChoices.find(e => e.id === filters.groupBy).name)
                })}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                groupBy: 'item.department',
                                quantifier: 'quantity',
                                businessDayDate: getBusinessDayDateDefaultValue(),
                                orderType: 'DESC',
                                limit: 100
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.itemSales.sections.salesCount', {
                    group_by: translate(groupByChoices.find(e => e.id === filters.groupBy).name)
                })}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    mainTable: DataTable
                }}
            />
        </div>
    );
};

const Chart = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const classes = useStyles({ exportable });
    const currency = resourcesConfig.general.currency;

    const [processedData, setProcessedData] = useState([]);

    let { groupBy, quantifier } = filters;

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            const fieldDataId = groupByChoices.find(e => e.id === groupBy).fieldDataId;
            setProcessedData({
                id: 'root',
                children: data.map(e => ({
                    id: `${e[groupBy]}-${e['chain.name']}`,
                    label: e[fieldDataId] ? e[fieldDataId] : e[groupBy],
                    value: e[quantifier],
                    [groupBy]: e[groupBy],
                    'chain.name': e['chain.name'],
                    unknown: !e[fieldDataId]
                }))
            });
        }
    }, [data, loading, cancel, groupBy, quantifier]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const label = ({ data, formattedValue, width, height }) => {
        if (width < 80 && height < 80) {
            return formattedValue;
        }
        return `${data.label}: ${formattedValue}`;
    };

    const tooltip = ({ node }) => {
        let { color, value, data } = node;
        let { label, [groupBy]: groupByData, 'chain.name': chainName, unknown } = data;

        if (quantifier === 'amount') {
            value = Number(value).toLocaleString(locale, {
                style: 'currency',
                currency: currency,
            });
        } else {
            value = value.toLocaleString(locale);
        }

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                    {!unknown &&
                        <span>
                            {translate(groupByChoices.find(e => e.id === groupBy).fieldDataTranslate)}: <strong>{label}</strong>
                        </span>
                    }
                    <span>
                        {translate(groupByChoices.find(e => e.id === groupBy).name)}: <strong>{groupByData}</strong>
                    </span>
                    <span>
                        {translate('resources.chains.name', 1)}: <strong>{chainName}</strong>
                    </span>
                    {quantifier === 'amount' ? (
                        <span>
                            {translate('pos.generic.amountOf', { of: translate('resources.transactions.tabs.items', 2) })}: <strong>{value}</strong>
                        </span>
                    ) : (
                        <span>
                            {translate('pos.generic.quantityOf', { of: translate('resources.transactions.tabs.items', 2) })}: <strong>{value}</strong>
                        </span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className={classes.chart}>
            <ResponsiveTreeMapCanvas
                data={processedData}
                label={label}
                tooltip={tooltip}
                valueFormat=">-.2s"
                leavesOnly={true}
                margin={{
                    top: 10,
                    right: 10,
                    bottom: 10,
                    left: 10
                }}
                labelSkipSize={20}
                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 3 ] ] }}
                colors={{ scheme: 'spectral' }}
                colorBy="id"
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
            />
        </div>
    );
};

const DataTable = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');
    const currency = resourcesConfig.general.currency;

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { groupBy, quantifier } = filters;

    useEffect(() => {
        setProcessingData(true);
        if (data && data.length > 0 && !loading && !cancel) {
            const fieldDataId = groupByChoices.find(e => e.id === groupBy).fieldDataId;
            let total = _.sumBy(data, quantifier);
            let processedData = {
                data: data.map(e => ({
                    [groupBy]: e[groupBy],
                    [fieldDataId]: e[fieldDataId],
                    'chain.name': e['chain.name'],
                    [quantifier]: e[quantifier],
                    percentage: _.round(e[quantifier] / (total ? total : 1) * 100, 4)
                })),
                total: total
            };
            processedData.totalPercentage = _.round(_.sumBy(processedData.data, 'percentage'));
            setProcessedData(processedData);
            setProcessingData(false);
        }
    }, [translate, data, loading, cancel, groupBy, quantifier]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {translate(groupByChoices.find(e => e.id === groupBy).name)}
                        </TableCell>
                        <TableCell>
                            {translate(groupByChoices.find(e => e.id === groupBy).fieldDataTranslate)}
                        </TableCell>
                        <TableCell>
                            {translate('resources.chains.name', 1)}
                        </TableCell>
                        <TableCell align="right">
                            {quantifier === 'amount' ? (
                                translate('pos.generic.amountOf', { of: translate('resources.transactions.tabs.items', 2) })
                            ) : (
                                translate('pos.generic.quantityOf', { of: translate('resources.transactions.tabs.items', 2) })
                            )}
                        </TableCell>
                        <TableCell align="right">
                            {translate('pos.generic.percentageOf', { of: translate('resources.transactions.tabs.items', 2) })}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={5} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => {
                            if (!(groupBy in row)) {
                                return null;
                            }

                            return (
                                <TableRow key={`${row[groupBy]}-${row['chain.name']}`}>
                                    <TableCell>
                                        <TextField record={row} source={groupBy} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            record={row}
                                            source={groupByChoices.find(e => e.id === groupBy).fieldDataId}
                                            emptyText={emptyText}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField record={row} source="chain.name" />
                                    </TableCell>
                                    <TableCell align="right">
                                        {quantifier === 'amount' ? (
                                            <NumberField
                                                record={row}
                                                source={quantifier}
                                                locales={locale}
                                                options={{style: 'currency', currency: currency}}
                                            />
                                        ) : (
                                            <NumberField record={row} source={quantifier} locales={locale} />
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <PercentageField record={row} source="percentage" />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow key="total">
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.total', 1)}
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell align="right">
                                {quantifier === 'amount' ? (
                                    <NumberField
                                        record={processedData}
                                        source="total"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                        className={classes.totalCell}
                                    />
                                ) : (
                                    <NumberField
                                        record={processedData}
                                        source="total"
                                        locales={locale}
                                        className={classes.totalCell}
                                    />
                                )}
                            </TableCell>
                            <TableCell align="right">
                                <PercentageField
                                    record={processedData}
                                    source="totalPercentage"
                                    className={classes.totalCell}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let {
        groupBy, quantifier, businessDayDate, chainId, storeCode, terminalNumber, operatorCode, orderType, limit
    } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'items-group-by-field',
            groupBy: groupBy,
            quantifier: quantifier,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderType: orderType,
            limit: limit
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        renderKey, groupBy, quantifier, businessDayDate, chainId, storeCode, terminalNumber, operatorCode, orderType,
        limit
    ]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const ItemsTreeMapCanvas = () => {
    const translate = useTranslate();

    const filters = {
        groupBy: 'item.department',
        quantifier: 'quantity',
        businessDayDate: getBusinessDayDateDefaultValue(),
        orderType: 'DESC',
        limit: 100
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.itemSales.sections.salesCount', {
                                    group_by: translate(groupByChoices.find(e => e.id === filters.groupBy).name)
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default ItemsTreeMapCanvas;
