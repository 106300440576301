import * as React from 'react';
import { NumberField, TextField, useLocale } from 'react-admin';
import resourcesConfig from '../../../config/resourcesConfig.json';

const TotalTaxesField = props => {
    const locale = useLocale();
    const currency = resourcesConfig.general.currency;
    const emptyText = resourcesConfig.general.emptyField;

    if (props.record.type !== '00') {
        return (
            <TextField emptyText={emptyText} />
        );
    }

    let totalTaxes = 0;
    if (props.record.taxes) {
        props.record.taxes.forEach(tax => {
            if (tax.type === '07') {
                totalTaxes += tax.amountTaxA + tax.amountTaxB + tax.amountTaxC + tax.amountTaxD + tax.amountTaxE +
                    tax.amountTaxF + tax.amountTaxG + tax.amountTaxH;
            }
        });
    }

    const record = {
        [props.source]: totalTaxes
    };

    return (
        <NumberField
            record={record}
            source={props.source}
            locales={locale}
            options={{style: 'currency', currency: currency}}
        />
    );
};

export default TotalTaxesField;
