import * as React from 'react';
import { TextField, useLocale } from 'react-admin';
import _ from 'lodash';

const PercentageField = ({ record, source, emptyText, ...props}) => {
    const locale = useLocale();

    let value = _.get(record, source);

    const newRecord = {
        [source]: `${value.toLocaleString(locale)} %`
    };

    return (
        <TextField record={newRecord} source={source} emptyText={emptyText} {...props} />
    );
};

export default PercentageField;
