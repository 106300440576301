import * as React from 'react';
import { ReferenceField, TextField, Labeled, useTranslate } from 'react-admin';

const ParentReferenceField = ({ label, forceLabel, record, ...props }) => {
    const translate = useTranslate();
    const emptyText = translate('pos.generic.unknown');

    if (record && !record.parentId) {
        if (forceLabel) {
            return (
                <Labeled label={label}>
                    <TextField emptyText={emptyText} />
                </Labeled>
            );
        }
        return (
            <TextField emptyText={emptyText} />
        );
    }

    return (
        <ReferenceField
            source="parentId"
            reference="groups"
            basePath="/groups"
            link="show"
            emptyText={emptyText}
            {...props}
        >
            {forceLabel ? (
                <Labeled label={label}>
                    <TextField source="name" emptyText={emptyText} />
                </Labeled>
            ) : (
                <TextField source="name" emptyText={emptyText} />
            )}
        </ReferenceField>
    );
};

export default ParentReferenceField;