import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
    Edit, FormDataConsumer, FormWithRedirect, Loading, RefreshButton, SaveButton, ShowButton, BooleanInput, TextInput,
    Toolbar, TopToolbar, email, maxLength, maxValue, minValue, number, required, useDataProvider, useEditController,
    useGetIdentity, useNotify, useRedirect, useRefresh, useTranslate
} from 'react-admin';
import { Typography, Grid } from '@material-ui/core';
import {
    Backspace as BackspaceIcon,
    VpnKey as VpnKeyIcon
} from '@material-ui/icons';
import CustomTitle from '../../layout/CustomTitle';
import { CustomDeleteButton, CustomListButton, CustomShowButton } from '../../custom/CustomButton';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import CustomReferenceAutocompleteArrayInput from '../../custom/CustomReferenceAutocompleteArrayInput';
import PermissionsSelectInput from './PermissionsSelectInput';
import { AbilityContext } from '../../Can';
import _ from 'lodash';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    grid: {
        padding: theme.spacing(2)
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}));

const UserEditActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
            <CustomShowButton {...props} record={props.data} />
        </TopToolbar>
    );
};

const RightButtons = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const { identity: user, loaded: userLoaded } = useGetIdentity();

    const [isActiveChangePassword, setIsActiveChangePassword] = useState(false);

    useEffect(() => {
        if (props.record && userLoaded) {
            if (props.record.id === user.id) {
                setIsActiveChangePassword(true);
            }
        }
    }, [props.record, userLoaded, user]);

    const onSuccess = () => {
        notify(`resources.users.crud.delete.notification.success`, 'success');
        redirect('/users');
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.users.crud.delete.notification.failed`, 'error');
    };

    return (
        <div>
            <ShowButton
                to="/users/changePassword"
                label="resources.users.crud.changePassword.name"
                disabled={!isActiveChangePassword}
                icon={<VpnKeyIcon />}
                {...props}
            />
            <RefreshButton
                label="pos.generic.cancelChanges"
                icon={<BackspaceIcon />}
                {...props}
            />
            <CustomDeleteButton
                confirmTitle="resources.users.crud.delete.confirmTitle"
                confirmContent="resources.users.crud.delete.confirmContent"
                mutationMode="pessimistic"
                onSuccess={onSuccess}
                onFailure={onFailure}
                {...props}
            />
        </div>
    );
};

const UserEditToolbar = props => {
    const classes = useStyles();

    return (
        <Toolbar {...props} className={classes.toolbar}>
            <SaveButton />
            <RightButtons {...props} />
        </Toolbar>
    );
};

const emailUniqueValidation = async (value, allValues) => {
    try {
        if (value) {
            const params = {
                email: value,
                id: { $ne: allValues.id }
            }
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/users?${queryString}`);
            const { data: users } = response.data;
            if (users.length > 0) {
                return 'resources.users.crud.edit.validation.emailNotUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    email: [email(), maxLength(255), required(), emailUniqueValidation],
    name: [maxLength(255)],
    lastname: [maxLength(255)],
    operatorCode: [number(), minValue(-2147483648), maxValue(2147483647)],
    status: [maxLength(255)],
    'userRole.defaultRoleId': [required()],
    chainIds: [required()],
    groupIds: [required()],
    storeIds: [required()]
};

const UserEdit = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const { record } = useEditController(props);
    const { identity: user, loaded: userLoaded } = useGetIdentity();
    const classes = useStyles();

    const [isOwnUser, setIsOwnUser] = useState(false);
    const [defaultRoleIds, setDefaultRoleIds] = useState();
    const [defaultRoleName, setDefaultRoleName] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const ability = useContext(AbilityContext);

    useEffect(() => {
        setDefaultRoleIds(_.uniq(ability.rulesFor('update', 'users')
            .map(rule => {
                if (rule.conditions && rule.conditions['userRole.defaultRoleId'] && rule.conditions['userRole.defaultRoleId'].$in) {
                    return rule.conditions['userRole.defaultRoleId'].$in;
                }
                return [];
            }).flat())
        );
    }, [ability]);

    useEffect(() => {
        if (record && userLoaded) {
            setIsOwnUser(user && user.id === record.id);
        }
    }, [record, userLoaded, user]);

    useEffect(() => {
        if (record && record.userRole) {
            dataProvider.getOne('defaultRoles', { id: record.userRole.defaultRoleId })
                .then(({ data: defaultRole }) => {
                    setDefaultRoleName(defaultRole.name);
                })
                .catch(error => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [dataProvider, record]);

    if (loading) {
        return (
            <Loading />
        );
    }
    if (error) {
        return null;
    }

    const onSuccess = ({ data }) => {
        notify(`resources.users.crud.edit.notification.success`, 'success');
        redirect(`/users/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.users.crud.edit.notification.failed`, 'error');
    };

    return (
        <Edit
            title={<CustomTitle action="edit" {...props} />}
            actions={<UserEditActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            mutationMode="pessimistic"
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect, form }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.users.crud.edit.identity')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="id" resource="users" helperText={false} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="email"
                                    resource="users"
                                    type="email"
                                    validate={validations.email}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="users"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="lastname"
                                    resource="users"
                                    validate={validations.lastname}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="operatorCode"
                                    resource="users"
                                    validate={validations.operatorCode}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="status"
                                    resource="users"
                                    validate={validations.status}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput
                                    source="twoFaIsActive"
                                    resource="users"
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.users.crud.edit.rolesAndPermissions')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {(record && record.userRole) &&
                                    <CustomReferenceAutocompleteInput
                                        source="userRole.defaultRoleId"
                                        record={record}
                                        reference="defaultRoles"
                                        originSource="id"
                                        label={translate('resources.users.fields.defaultRole.name')}
                                        optionText="name"
                                        validate={validations['userRole.defaultRoleId']}
                                        onChange={(event, newValue) => {
                                            if (!newValue) {
                                                setDefaultRoleName(newValue);
                                                form.change('userRole.permissions', []);
                                            } else {
                                                setDefaultRoleName(newValue.name);
                                            }
                                            form.change('chainIds', []);
                                            form.change('groupIds', []);
                                            form.change('storeIds', []);
                                        }}
                                        getOptionLabel={(option) => {
                                            return translate(`resources.users.fields.defaultRole.options.${option.name}`)
                                                + ` (${option.name})`;
                                        }}
                                        additionalFilter={{ id: { $in: defaultRoleIds } }}
                                        disabled={isOwnUser}
                                    />
                                }
                                {['CHAIN-ADMIN', 'CHAIN-ROOT', 'CHAIN-SUBSCRIBER'].includes(defaultRoleName) &&
                                    <CustomReferenceAutocompleteArrayInput
                                        source="chainIds"
                                        record={record}
                                        reference="chains"
                                        originSource="id"
                                        label={translate('resources.users.fields.chains.name')}
                                        optionText="name"
                                        withNull={false}
                                        validate={validations.chainIds}
                                        disabled={isOwnUser}
                                    />
                                }
                                {['ZONE-MANAGER'].includes(defaultRoleName) &&
                                    <CustomReferenceAutocompleteArrayInput
                                        source="groupIds"
                                        record={record}
                                        reference="groups"
                                        originSource="id"
                                        label={translate('resources.users.fields.groups.name')}
                                        optionText="name"
                                        withNull={false}
                                        validate={validations.groupIds}
                                        disabled={isOwnUser}
                                    />
                                }
                                {['STORE-ADMIN', 'CASH-MANAGER', 'CASHIER', 'STORE-ROOT', 'STORE-SUBSCRIBER'].includes(defaultRoleName) &&
                                    <CustomReferenceAutocompleteArrayInput
                                        source="storeIds"
                                        record={record}
                                        reference="stores"
                                        originSource="id"
                                        label={translate('resources.users.fields.stores.name')}
                                        optionText="storeCode"
                                        withNull={false}
                                        validate={validations.storeIds}
                                        disabled={isOwnUser}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} md={8}>
                                {(record && record.userRole) &&
                                    <FormDataConsumer>
                                        {({ formData }) => formData.userRole && formData.userRole.defaultRoleId ? (
                                            <PermissionsSelectInput
                                                source="userRole.permissions"
                                                record={record}
                                                defaultRoleId={
                                                    formData.userRole && formData.userRole.defaultRoleId ?
                                                        formData.userRole.defaultRoleId : undefined
                                                }
                                                change={form.change}
                                                disabled={isOwnUser}
                                            />
                                        ) : null}
                                    </FormDataConsumer>
                                }
                            </Grid>
                        </Grid>
                        <UserEditToolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            record={record}
                            resource={props.resource}
                            basePath={props.basePath}
                        />
                    </form>
                )}
            />
        </Edit>
    );
};

export default UserEdit;
