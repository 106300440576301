import * as React from 'react';
import { Datagrid, List, TextField, ReferenceField, useTranslate } from 'react-admin';
import UserFilter from './UserFilter';
import { CustomShowButton, CustomEditButton } from '../../custom/CustomButton';
import CustomBulkActionButtons  from '../../custom/CustomBulkActionButtons';

const DefaultRoleReferenceField = props => {
    return (
        <ReferenceField source="defaultRoleId" reference="defaultRoles" link={false} {...props}>
            <DefaultRoleField userRole={props.record} />
        </ReferenceField>
    );
};

const DefaultRoleField = ({ userRole, ...props }) => {
    const translate = useTranslate();

    const record = {
        defaultRole: translate(`resources.users.fields.defaultRole.options.${props.record.name}`)
    };
    if (userRole && userRole.permissions.length !== props.record.permissions.length) {
        record.defaultRole += ' *';
    }

    return (
        <TextField record={record} source="defaultRole" />
    );
};

const UserList = ({ voidTitle = false, chainId, groupId, storeId, ...props}) => {
    const translate = useTranslate();
    const emptyText = translate('pos.generic.unknown');

    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(chainId && { include: 1, chainId: chainId }),
        ...(groupId && { include: 1, groupId: groupId }),
        ...(storeId && { include: 1, storeId: storeId })
    };

    return (
        <List
            {...props}
            title={title}
            actions={null}
            bulkActionButtons={<CustomBulkActionButtons />}
            filters={<UserFilter/>}
            filter={filter}
        >
            <Datagrid
                rowClick="show"
                hover={false}
                rowStyle={(record, index) => {
                    if (index % 2 === 0) {
                        return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                    }
                }}
            >
                <TextField source="email" />
                <TextField source="operatorCode" emptyText={emptyText} />
                <ReferenceField label="resources.users.fields.defaultRole.name" source="userRoleId" reference="userRoles" link={false}>
                    <DefaultRoleReferenceField />
                </ReferenceField>
                <TextField source="status" emptyText={emptyText} />
                <CustomShowButton />
                <CustomEditButton />
            </Datagrid>
        </List>
    );
};

export default UserList;
