import * as React from 'react';
import { createRef, useEffect, useState } from 'react';
import { NumberField, useTranslate, useLocale } from 'react-admin';
import {
    Typography, CardContent, Card, CardHeader, IconButton, CircularProgress, Menu, MenuItem, Divider
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import CustomError from '../../custom/CustomError';
import getBusinessDayDateRange from '../../../helpers/getBusinessDayDateRange';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { exportComponentAsPNG } from 'react-component-export-image';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    card: {
        minHeight: '150px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loading: {
        minHeight: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    kpiText: {
        fontSize: 50,
        color: '#03D2AE'
    },
});

const periodOptions = [
    { id: 'yesterday', name: 'pos.dashboard.kpi.periods.yesterday' },
    { id: 'thisMonth', name: 'pos.dashboard.kpi.periods.thisMonth' },
    { id: 'lastMonth', name: 'pos.dashboard.kpi.periods.lastMonth' },
    { id: 'thisYear', name : 'pos.dashboard.kpi.periods.thisYear' },
    { id: 'lastYear', name: 'pos.dashboard.kpi.periods.lastYear' }
];

const TotalTransactionsQuantity = ({ filters, period = 'yesterday' }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();

    const [periodSelected, setPeriodSelected] = useState(period);
    const [anchorEl, setAnchorEl] = useState(null);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const cardRef = createRef();

    let { storeCode, chainId } = filters ? filters : {};

    const handleClickMenuButton = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickMenuItem = (event, period) => {
        setPeriodSelected(period);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExport = () => {
        if (cardRef && cardRef.current) {
            exportComponentAsPNG(cardRef, {
                fileName: `${translate('pos.dashboard.kpi.totalTransactionsQuantity.name')} ` +
                    `(${translate(`pos.dashboard.kpi.periods.${periodSelected}`)})`,
                html2CanvasOptions: {
                    backgroundColor: 'white',
                    scale: 2
                }
            }).then();
        }
    };

    useEffect(() => {
        setLoading(true);

        const params = {
            type: 'general-group-by-field',
            quantifier: 'quantity',
            businessDayDate: getBusinessDayDateRange(periodSelected),
            storeCode: storeCode,
            chainId: chainId
        };
        const queryString = stringify(params, { strictNullHandling: true });

        axios.get(`/statistics?${queryString}`)
            .then(response => {
                const { data } = response.data;
                if (data.length > 0) {
                    setData({
                        quantity: data[0].quantity || 0
                    });
                } else {
                    setData();
                }
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [storeCode, chainId, periodSelected]);

    if (error) {
        return (
            <CustomError
                errorSecondary={translate('pos.dashboard.kpi.errors.noKpi', {
                    kpi_name: translate('pos.dashboard.kpi.totalTransactionsQuantity.name')
                })}
            />
        );
    }

    return (
        <Card className={classes.card} ref={cardRef}>
            <CardHeader
                action={
                    <IconButton onClick={handleClickMenuButton}>
                        <MoreVertIcon />
                    </IconButton>
                }
                title={translate('pos.dashboard.kpi.totalTransactionsQuantity.name')}
                subheader={translate(`pos.dashboard.kpi.periods.${periodSelected}`)}
                titleTypographyProps={{
                    variant: 'h5',
                    align: 'center'
                }}
                subheaderTypographyProps={{
                    variant: 'h6',
                    align: 'center',
                    color: 'textSecondary'
                }}
            />
            {loading ? (
                <CardContent className={classes.loading}>
                    <CircularProgress />
                </CardContent>
            ) : (!loading && !data) ? (
                <CardContent>
                    <CustomError
                        severity="warning"
                        errorPrimary={translate('pos.generic.warning')}
                        errorSecondary={translate('pos.dashboard.kpi.errors.noData')}
                    />
                </CardContent>
            ) : (
                <CardContent>
                    <Typography align="center">
                        <NumberField
                            record={data}
                            source="quantity"
                            locales={locale}
                            className={classes.kpiText}
                        />
                    </Typography>
                </CardContent>
            )}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
            >
                {periodOptions.map(option => (
                    <MenuItem
                        key={option.id}
                        selected={option.id === periodSelected}
                        onClick={event => handleClickMenuItem(event, option.id)}
                    >
                        {translate(option.name)}
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem
                    key="export"
                    onClick={handleExport}
                    disabled={loading || !data}
                >
                    {translate('ra.action.export')}
                </MenuItem>
            </Menu>
        </Card>
    );
};

export default TotalTransactionsQuantity;
