import * as React from 'react';
import { TextField, useTranslate } from 'react-admin';
import _ from 'lodash';

const EnumTextField = ({ fieldTranslate, ...props }) => {
    const translate = useTranslate();
    let value = _.get(props.record, props.source);
    let additionalData;

    if (props.additionalData) {
        if (props.additionalData.take) {
            additionalData = value.slice(props.additionalData.take.from, props.additionalData.take.to);
        }
    }

    if (props.take) {
        value = value.slice(props.take.from, props.take.to);
    }

    const defaultTranslation = value !== null ? value : '';
    const record = {
        [props.source]: translate(`${fieldTranslate}.options.${value}`,{
            _: defaultTranslation,
            additional_data: additionalData
        })
    };

    return (
        <TextField record={record} source={props.source} emptyText={props.emptyText} />
    );
};

export default EnumTextField;
