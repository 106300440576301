import * as React from 'react';
import { useContext, useMemo, useState, useEffect } from 'react';
import { NumberField, TextField, SelectInput, useLocale, useTranslate } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import getGroupByDefaultValue from '../../../../helpers/getGroupByDefaultValue';
import getGroupByChoices from '../../../../helpers/getGroupByChoices';
import getBusinessDayDateRange from '../../../../helpers/getBusinessDayDateRange';
import { AbilityContext } from '../../../Can';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import moment from 'moment';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import CustomDateTimeField from '../../../custom/CustomDateTimeField';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: { maxHeight: '300px' },
    totalCell: { fontWeight: 500 }
}));

const groupByChoices = [
    {
        id: 'transaction.operatorCode',
        name: 'resources.transactions.fields.operatorCode',
        fieldId: 'transaction.operatorCode',
        fieldDataId: 'operator.name',
        fieldDataTranslate: 'resources.transactions.fields.operatorName',
    },
    {
        id: 'transaction.terminalNumber',
        name: 'resources.transactions.fields.terminalNumber',
        fieldId: 'transaction.terminalNumber',
        fieldDataId: 'transaction.terminalNumber',
        fieldDataTranslate: 'resources.transactions.fields.terminalNumber',
    },
    {
        id: 'transaction.storeCode',
        name: 'resources.transactions.fields.storeCode',
        fieldId: 'transaction.storeCode',
        fieldDataId: 'transaction.storeCode',
        fieldDataTranslate: 'resources.transactions.fields.storeCode',
    },
    {
        id: 'chain.id',
        name: 'resources.stores.fields.chain',
        fieldId: 'chain.id',
        fieldDataId: 'chain.name',
        fieldDataTranslate: 'resources.stores.fields.chain',
    }
];

const periodChoices = [
    { id: 'yesterday', name: 'pos.dashboard.kpi.periods.yesterday', type: 'day' },
    { id: 'thisMonth', name: 'pos.dashboard.kpi.periods.thisMonth', type: 'month' },
    { id: 'lastMonth', name: 'pos.dashboard.kpi.periods.lastMonth', type: 'month' },
    { id: 'thisYear', name : 'pos.dashboard.kpi.periods.thisYear', type: 'year' }
];

const getKey = groupBy => e => {
    let key = '';
    if (groupBy.id === 'transaction.operatorCode') {
        key += `${e[groupBy.fieldId]}-${e['chain.id']}-${e['transaction.storeCode']}`;
    } else {
        key += e[groupBy.fieldId];
    }
    return key;
};

const getCompareConfig = period => {
    switch (period) {
        case 'thisMonth':
            return {
                businessDayDate: {
                    $gte: moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                    //$lte: moment().endOf('year').format('YYYY-MM-DD HH:mm:ss')
                    $lte: moment(getBusinessDayDateRange(period).$gte)
                        .subtract(1, 'day').endOf('month').format('YYYY-MM-DD HH:mm:ss')
                }
            };
        case 'lastMonth':
            return {
                businessDayDate: {
                    $gte: moment().subtract(1, 'month').startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                    //$lte: moment().subtract(1, 'month').endOf('year').format('YYYY-MM-DD HH:mm:ss')
                    $lte: moment(getBusinessDayDateRange(period).$gte)
                        .subtract(1, 'day').endOf('month').format('YYYY-MM-DD HH:mm:ss')
                }
            };
        case 'thisYear':
            return {
                businessDayDate: {
                    $gte: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                    $lte: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD HH:mm:ss')
                }
            };
        case 'yesterday':
        default:
            return {
                businessDayDate: {
                    $gte: moment().subtract(1, 'day').startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                    //$lte: moment().subtract(1, 'day').endOf('year').format('YYYY-MM-DD HH:mm:ss')
                    $lte: moment(getBusinessDayDateRange(period).$gte)
                        .subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
                }
            };
    }
};

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const ability = useContext(AbilityContext);

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const checkedGroupByChoices = useMemo(() => {
        const groupByIds = getGroupByChoices(
            groupByChoices.map(e => e.id), 'general-times',
            ability.rulesFor('find', 'statistics')
        );
        return groupByChoices.filter(e => groupByIds.includes(e.id));
    }, [ability]);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="groupById"
                                    label="pos.generic.groupBy"
                                    choices={checkedGroupByChoices}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="periodId"
                                    label="pos.datetime.period"
                                    choices={periodChoices}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="terminalTypeId"
                                    record={filters}
                                    reference="terminalTypes"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.transactions.fields.terminalType')}
                                    withNull={true}
                                    helperText={false}
                                    //groupByField="chainId"
                                    additionalFilter={{
                                        ...(form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                        _.isArray(form.getFieldState('chainId').value) ? {
                                            chainId: form.getFieldState('chainId').value
                                        } : {}),
                                        ...(form.getFieldState('storeCode') && form.getFieldState('storeCode').value &&
                                        _.isArray(form.getFieldState('storeCode').value) ? {
                                            storeCode: form.getFieldState('storeCode').value
                                        } : {})
                                    }}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => {
                                        cancelTokenSource.first.cancel();
                                        cancelTokenSource.compare.cancel();
                                    }}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => {
                                        cancelTokenSource.first.cancel();
                                        cancelTokenSource.compare.cancel();
                                    }}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.hoursSaved.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                groupById: getGroupByDefaultValue(
                                    groupByChoices.map(e => e.id), 'seconds-per-item-line-group-by-field-and-datetime',
                                    ability.rulesFor('find', 'statistics'), 'transaction.storeCode'
                                ),
                                periodId: 'yesterday'
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.hoursSaved.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    mainTable: DataTable
                }}
            />
        </div>
    );
};

const processData = ({ data, loading, cancel, groupBy, setProcessingData, setProcessedData }) => {
    setProcessingData(true);
    const { firstData, compareData } = data;
    if ((firstData && compareData) && (firstData.length > 0 || compareData.length > 0) && !loading && !cancel) {
        let processedData = {
            data: []
        };

        const allData = firstData.concat(compareData);
        const groupByValues = _.uniq(allData.map(e => getKey(groupBy)(e)));
        groupByValues.forEach(groupByValue => {
            const groupByFirstData = firstData.find(e => getKey(groupBy)(e) === groupByValue);
            const groupByCompareData = compareData.filter(e => getKey(groupBy)(e) === groupByValue);

            const worthData = _.maxBy(groupByCompareData, 'secondsPerItemLine');

            if (groupByFirstData && worthData) {
                processedData.data.push({
                    id: groupByValue,
                    [groupBy.fieldId]: groupByFirstData[groupBy.fieldId],
                    [groupBy.fieldDataId]: groupByFirstData[groupBy.fieldDataId],
                    ...(groupBy.id === 'transaction.operatorCode' && {
                        'chain.name': groupByFirstData['chain.name'],
                        'transaction.storeCode': groupByFirstData['transaction.storeCode']
                    }),
                    hoursSaved: ((worthData.secondsPerItemLine * groupByFirstData.itemLineQuantity) - groupByFirstData.ringElapsedTime) / 3600,
                    secondsPerItemLine: groupByFirstData.secondsPerItemLine,
                    worthSecondsPerItemLine: worthData.secondsPerItemLine,
                    worthDatetime: worthData.datetime
                });
            }
        });

        processedData.data = _.orderBy(processedData.data, 'hoursSaved', ['desc']);
        processedData.totalHoursSaved = _.sumBy(processedData.data, 'hoursSaved');

        setProcessedData(processedData);
        setProcessingData(false);
    }
};

const DataTable = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    const { groupById } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    useEffect(() => {
        processData({ data, loading, cancel, groupBy, setProcessingData, setProcessedData });
    }, [data, loading, cancel, groupBy]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || processedData.data.length === 0 || !data || (data.firstData.length === 0 && data.compareData.length === 0) || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {translate(groupBy.name)}
                        </TableCell>
                        {groupBy.id === 'transaction.operatorCode' && (
                            <React.Fragment>
                                <TableCell>
                                    {translate('resources.transactions.fields.operatorName')}
                                </TableCell>
                                <TableCell>
                                    {translate('resources.transactions.fields.storeCode')}
                                </TableCell>
                            </React.Fragment>
                        )}
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.hoursSaved.hoursSaved')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.hoursSaved.secondsPerItemLine')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.hoursSaved.worthSecondsPerItemLine')}
                        </TableCell>
                        <TableCell>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.hoursSaved.worthDatetime')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell
                                colSpan={groupBy.id === 'transaction.operatorCode' ? 7 : 5}
                                align="center"
                            >
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <TextField
                                        record={row}
                                        source={groupBy.id === 'chain.id' ? groupBy.fieldDataId : groupBy.fieldId}
                                    />
                                </TableCell>
                                {groupBy.id === 'transaction.operatorCode' &&
                                    <TableCell>
                                        <TextField record={row} source="operator.name" emptyText={emptyText} />
                                    </TableCell>
                                }
                                {groupBy.id === 'transaction.operatorCode' &&
                                    <TableCell>
                                        <TextField record={row} source="transaction.storeCode" emptyText={emptyText} />
                                    </TableCell>
                                }
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="hoursSaved"
                                        locales={locale}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="secondsPerItemLine"
                                        locales={locale}
                                        emptyText={emptyText}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="worthSecondsPerItemLine"
                                        locales={locale}
                                    />
                                </TableCell>
                                <TableCell>
                                    <CustomDateTimeField
                                        record={row}
                                        source="worthDatetime"
                                        showFormat={translate('pos.datetime.date').replace(/-DD|DD-/, '')}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key="total">
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.total', 1)}
                            </TableCell>
                            {groupBy.id === 'transaction.operatorCode' &&
                                <TableCell colSpan={2} />
                            }
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="totalHoursSaved"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell colSpan={3} />
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let { groupById, periodId, chainId, storeCode, terminalTypeId, terminalNumber, operatorCode } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const groupBy = groupByChoices.find(e => e.id === groupById);
        const period = periodChoices.find(e => e.id === periodId);

        const params = {
            type: 'seconds-per-item-line-group-by-field-and-datetime',
            groupBy: groupBy.fieldId,
            chainId: chainId,
            storeCode: storeCode,
            terminalTypeId: terminalTypeId,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode
        };

        const firstQueryString = stringify({
            ...params,
            precision: period.type,
            businessDayDate: getBusinessDayDateRange(period.id)
        }, { strictNullHandling: true });
        const firstCancelTokenSource = baseAxios.CancelToken.source();

        const compareQueryString = stringify({
            ...params,
            precision: 'month',
            ...getCompareConfig(period.id)
        }, { strictNullHandling: true });
        const compareCancelTokenSource = baseAxios.CancelToken.source();

        setCancelTokenSource({
            first: firstCancelTokenSource,
            compare: compareCancelTokenSource
        });

        let promises = [
            axios.get(`/statistics?${firstQueryString}`, { cancelToken: firstCancelTokenSource.token }),
            axios.get(`/statistics?${compareQueryString}`, { cancelToken: compareCancelTokenSource.token })
        ];

        Promise.all(promises)
            .then(([firstResponse, compareResponse]) => {
                const { data: firstData } = firstResponse.data;
                const { data: compareData } = compareResponse.data;

                setData({
                    firstData: firstData.length > 0 ? firstData : [],
                    compareData: compareData.length > 0 ? compareData : []
                });
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [renderKey, groupById, periodId, chainId, storeCode, terminalTypeId, terminalNumber, operatorCode]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const HoursSaved = () => {
    const translate = useTranslate();
    const ability = useContext(AbilityContext);

    const filters = {
        groupById: getGroupByDefaultValue(
            groupByChoices.map(e => e.id), 'seconds-per-item-line-group-by-field-and-datetime',
            ability.rulesFor('find', 'statistics'), 'transaction.storeCode'
        ),
        periodId: 'yesterday'
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.hoursSaved.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Filter
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                                onSubmit={onSubmit}
                                cancelTokenSource={cancelTokenSource}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default HoursSaved;
