import * as React from 'react';
import {
    Create,
    TextInput,
    TopToolbar,
    FormWithRedirect,
    Toolbar,
    useTranslate, maxLength, required, number, minValue, maxValue, useNotify, useRedirect, useRefresh
} from 'react-admin';
import { Grid } from '@material-ui/core';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import { CustomListButton } from '../../custom/CustomButton';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    },
    input: {
        marginTop: '8px',
        marginBottom: '4px'
    },
});

const DepartmentCreateActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
        </TopToolbar>
    );
};

const itemCodeChainIdUniqueValidation = async (value, allValues) => {
    try {
        if (allValues.departmentCode !== undefined && allValues.chainId !== undefined) {
            const params = {
                departmentCode: allValues.departmentCode,
                chainId: allValues.chainId
            }
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/departments?${queryString}`);
            const { data: departments } = response.data;
            if (departments.length > 0) {
                return 'resources.departments.crud.create.validation.departmentCodeChainIdUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    departmentCode: [
        number(), minValue(-2147483648), maxValue(2147483647), required(),
        itemCodeChainIdUniqueValidation
    ],
    name: [maxLength(255), required()],
    chainId: [required(), itemCodeChainIdUniqueValidation]
};

const DepartmentCreate = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        notify(`resources.departments.crud.create.notification.success`, 'success');
        redirect(`/departments/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.departments.crud.create.notification.failed`, 'error');
    };

    return (
        <Create
            actions={<DepartmentCreateActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="departmentCode"
                                    resource="departments"
                                    validate={validations.departmentCode}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="departments"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="chainId"
                                    reference="chains"
                                    originSource="id"
                                    label={translate('resources.departments.fields.chain')}
                                    optionText="name"
                                    validate={validations.chainId}
                                    className={classes.input}
                                />
                            </Grid>
                        </Grid>
                        <Toolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                        />
                    </form>
                )}
            />
        </Create>
    );
};

export default DepartmentCreate;
