import * as React from 'react';
import { useEffect, useState } from 'react';
import { InputHelperText, ResettableTextField, FieldTitle, LinearProgress, useDataProvider, useTranslate} from 'react-admin';
import { useInput } from 'ra-core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import _ from 'lodash';

const filter = createFilterOptions();

const CustomReferenceAutocompleteInput = props => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    const {
        reference,
        originSource,
        optionText,
        additionalFilter,
        className,
        getOptionLabel = option => option.name ? option.name : '',
        label,
        format,
        helperText = true,
        onBlur,
        onFocus,
        onChange,
        parse,
        record,
        resource,
        source,
        validate,
        ...rest
    } = props;

    const {
        id,
        input,
        isRequired,
        meta: { error: inputError, submitError, touched }
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'text',
        validate,
        ...rest
    });

    const [options, setOptions] = useState([]);
    const [initialValue, setInitialValue] = useState();
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const idKey = originSource ? originSource : source;
    const nameKey = optionText ? optionText : source;

    useEffect(() => {
        const value = _.get(record, source);
        if (value) {
            setInitialValue(value);
        }
    }, [record, source]);

    useEffect(() => {
        let filter = { ...additionalFilter };
        if (initialValue) {
            setLoading(true);
            filter[idKey] = initialValue;
        }
        if (inputValue && inputValue !== '') {
            filter.q = inputValue;
        }

        dataProvider.getList(reference, {
            filter: filter
        })
            .then(({ data }) => {
                let options = Object.values(data).map(e => ({ id: e[idKey], name: e[nameKey] }));
                if (initialValue) {
                    const value = options.find(e => initialValue === e.id);
                    //input.onChange(value && value.id ? value.id : null);
                    setValue(value);
                    setInitialValue();
                }
                setOptions(options);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dataProvider, reference, additionalFilter, initialValue, idKey, nameKey, inputValue, onChange, value]);

    if (loading) return <LinearProgress />;
    if (error || !options) return null;

    const handleChange = (event, newValue) => {
        input.onChange(newValue && newValue.id ? newValue.id : null);
        setValue(newValue);
        if (onChange) {
            onChange(event, newValue);
        }
    };

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        if (!options.map(e => e.id).includes(inputValue)) {
            setInputValue(params.inputValue);
        }
        return filtered;
    };

    return (
        <Autocomplete
            value={value}
            inputValue={inputValue}
            onChange={handleChange}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={options}
            filterOptions={filterOptions}
            getOptionSelected={(option, value) => value && option.id === value.id}
            getOptionLabel={getOptionLabel}
            noOptionsText={translate('pos.generic.noOptions')}
            clearOnBlur
            renderInput={params => (
                <ResettableTextField
                    id={id}
                    {...input}
                    {...params}
                    label={(label !== '' && label !== false) && (
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    )}
                    variant="filled"
                    margin="normal"
                    size="small"
                    error={!!(touched && (inputError || submitError))}
                    helperText={(helperText && (touched && (inputError || submitError))) && (
                        <InputHelperText
                            touched={touched}
                            error={inputError || submitError}
                            helperText={(touched && inputError) ? inputError : null}
                        />
                    )}
                    className={className}
                    {...rest}
                />
            )}
            {...rest}
        />
    );
};

export default CustomReferenceAutocompleteInput;
