import * as React from 'react';
import { createRef, useContext, useState, useEffect } from 'react';
import { AbilityContext } from '../../Can';
import { Labeled, NumberField, TextField, useLocale, useTranslate, useDataProvider } from 'react-admin';
import {
    Accordion, AccordionSummary, AccordionDetails, CircularProgress, Grid, Paper, Typography, CardContent, Button
} from '@material-ui/core';
import {
    ExpandMore as ExpandMoreIcon,
    GetApp as GetAppIcon
} from '@material-ui/icons';
import InvoiceNumberField from './InvoiceNumberField';
import TypeField from './TypeField';
import TotalTaxesField from './TotalTaxesField';
import SubtotalField from './SubtotalField';
import CustomReferenceField from '../../custom/CustomReferenceField';
import CustomDateTimeField from '../../custom/CustomDateTimeField';
import CustomError from '../../custom/CustomError';
import { exportComponentAsPNG } from 'react-component-export-image';
import { makeStyles } from '@material-ui/core/styles';
import resourcesConfig from '../../../config/resourcesConfig.json';

const useStyles = makeStyles(theme => ({
    root: { marginBottom: '20px' },
    loading: {
        minHeight: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    paper: {
        width: 'fit-content',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2)
    },
    journalField: {
        fontFamily: ['Consolas', 'Courier new', 'Courier', 'Courier new', 'Roboto', 'Helvetica', 'Arial', 'sans-serif']
    }
}));

const JournalField = props => {
    const classes = useStyles();

    return (
        <Labeled label={props.label}>
            <TextField
                record={props.record.raw}
                source={props.source}
                className={classes.journalField}
                component="pre"
            />
        </Labeled>
    );
};

const Journal = ({ transactionId }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const dataProvider = useDataProvider();

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [expanded, setExpanded] = useState(false);
    const exportRef = createRef();

    const handleChange = () => {
        if (expanded) {
            setExpanded(false);
        } else {
            setExpanded(true);
        }
    };

    const handleExport = () => {
        if (exportRef && exportRef.current) {
            exportComponentAsPNG(exportRef, {
                fileName: translate('resources.transactions.tabs.summary.sections.journalInfo.name'),
                html2CanvasOptions: {
                    backgroundColor: 'white',
                    scale: 2
                }
            }).then();
        }
    };

    useEffect(() => {
        setLoading(true);
        setError(false);

        dataProvider.getList('journals', {
            pagination: {
                page: 1,
                perPage: 1
            },
            sort: {
                field: 'createdAt',
                order: 'DESC'
            },
            filter: {
                transactionId: transactionId
            }
        })
            .then(({ data }) => {
                if (data.length > 0) {
                    setData(data[0])
                }
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dataProvider, transactionId]);

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{translate('resources.transactions.tabs.summary.sections.journalInfo.seeMore')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {(() => {
                    if (loading) {
                        return (
                            <CardContent className={classes.loading}>
                                <CircularProgress />
                            </CardContent>
                        );
                    }
                    if (!loading && error) {
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <CustomError
                                        errorSecondary={translate('resources.transactions.tabs.summary.sections.journalInfo.errors.noJournal')}
                                    />
                                </Grid>
                            </Grid>
                        );
                    }
                    if (!loading && !data) {
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <CustomError
                                        severity="warning"
                                        errorPrimary={translate('pos.generic.warning')}
                                        errorSecondary={translate('resources.transactions.tabs.summary.sections.journalInfo.errors.noData')}
                                    />
                                </Grid>
                            </Grid>
                        );
                    }
                    return (
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.transactions.fields.journal.valuedCustomer">
                                    <NumberField record={data} source="valuedCustomer" locales={locale} />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ marginBottom: '15px' }}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleExport}
                                            disabled={!exportRef || (!data.raw.ticket && !data.raw.paymentTicket)}
                                            startIcon={<GetAppIcon />}
                                        >
                                            {translate('ra.action.export')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} justifyContent="center">
                                    {data.raw.ticket &&
                                        <Grid item>
                                            <Paper className={classes.paper} ref={exportRef}>
                                                <JournalField
                                                    record={data}
                                                    source="ticket"
                                                    label="resources.transactions.fields.journal.raw.ticket"
                                                />
                                            </Paper>
                                        </Grid>
                                    }
                                    {data.raw.paymentTicket &&
                                        <Grid item>
                                            <Paper className={classes.paper}>
                                                <JournalField
                                                    record={data}
                                                    source="paymentTicket"
                                                    label="resources.transactions.fields.journal.raw.paymentTicket"
                                                />
                                            </Paper>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })()}
            </AccordionDetails>
        </Accordion>
    );
};

const TransactionSummary = props => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const emptyText = translate('pos.generic.unknown');
    const currency = resourcesConfig.general.currency;

    return (
        <span>
            <Grid container spacing={1} className={classes.root}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.tabs.summary.sections.generalInfo')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.sequenceNumber">
                                <NumberField record={props.record} source="sequenceNumber" locales={locale} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.businessDayDate">
                                <CustomDateTimeField
                                    record={props.record}
                                    source="businessDayDate"
                                    showFormat={translate('pos.datetime.datetime')}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.terminalNumber">
                                <NumberField record={props.record} source="terminalNumber" locales={locale} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.operatorCode">
                                <TextField record={props.record} source="operatorCode" />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.operatorName">
                                <TextField
                                    record={props.record}
                                    source="store.chain.operators[0].name"
                                    emptyText={emptyText}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.storeCode">
                                <CustomReferenceField
                                    record={props.record}
                                    originalSource="storeCode"
                                    injectSource="storeId"
                                    reference="stores"
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.storeNumber">
                                <NumberField record={props.record} source="storeNumber" locales={locale} />
                            </Labeled>
                        </Grid>
                        {(props.record && props.record.type === '00') &&
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.transactions.fields.invoiceNumber">
                                    <InvoiceNumberField
                                        record={props.record}
                                        label="resources.transactions.fields.invoiceNumber"
                                    />
                                </Labeled>
                            </Grid>
                        }
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.type.name">
                                <TypeField record={props.record} label="resources.transactions.fields.type.name" />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.tabs.summary.sections.paymentInfo')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.grossPositive">
                                <NumberField
                                    record={props.record}
                                    source="grossPositive"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.grossNegative">
                                <NumberField
                                    record={props.record}
                                    source="grossNegative"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                />
                            </Labeled>
                        </Grid>
                        {(props.record && props.record.type === '00') &&
                            <React.Fragment>
                                <Grid item xs={12} sm={6}>
                                    <Labeled label="resources.transactions.fields.subtotal">
                                        <SubtotalField
                                            record={props.record}
                                            label="resources.transactions.fields.subtotal"
                                        />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Labeled label="resources.transactions.fields.totalTaxes">
                                        <TotalTaxesField
                                            record={props.record}
                                            label="resources.transactions.fields.totalTaxes"
                                        />
                                    </Labeled>
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={12} sm={6}>
                            <Labeled label="resources.transactions.fields.total">
                                <NumberField
                                    source="total"
                                    locales={locale}
                                    options={{ style: 'currency', currency: currency }}
                                />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Grid>
                {ability.can('find', 'journals') &&
                    <Grid item xs={12}>
                        <Journal transactionId={props.record.id} />
                    </Grid>
                }
            </Grid>
        </span>
    );
}

export default TransactionSummary;
