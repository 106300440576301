import * as React from 'react';
import { useState } from 'react';
import {
    Create, FormDataConsumer, FormWithRedirect, TextInput, Toolbar, TopToolbar, maxLength, required, useNotify,
    useRedirect, useRefresh, useTranslate
} from 'react-admin';
import { Grid, Typography, IconButton } from '@material-ui/core';
import {
    Remove as RemoveIcon,
    Add as AddIcon
} from '@material-ui/icons';
import CustomMultiselectInput from '../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import { CustomListButton } from '../../custom/CustomButton';
import axios from '../../../clients/axiosClient';
import _ from 'lodash';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    },
    input: {
        marginTop: '8px',
        marginBottom: '4px'
    }
});

const TerminalTypeCreateActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
        </TopToolbar>
    );
};

const nameChainIdUniqueValidation = async (value, allValues) => {
    try {
        if (allValues.name !== undefined && allValues.chainId !== undefined) {
            const params = {
                name: allValues.name,
                chainId: allValues.chainId
            }
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/terminalTypes?${queryString}`);
            const { data: terminalTypes } = response.data;
            if (terminalTypes.length > 0) {
                return 'resources.terminalTypes.crud.create.validation.nameChainIdUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const numbersValidation = values => {
    if (_.isArray(values)) {
        for (let value of values) {
            if (Number.isNaN(Number(value))) {
                return 'resources.terminalTypes.crud.create.validation.numbers.isNotNumber';
            } else if (Number(value) < 0 || Number(value) > 2147483647) {
                return 'resources.terminalTypes.crud.create.validation.numbers.outOfRange';
            }
        }
    } else if (_.isString(values)) {
        return 'resources.terminalTypes.crud.create.validation.numbers.isNotArray';
    }
    return undefined;
};

const terminalNumbersStoreCodeUniqueValidationIndex = index => async (values, allValues) => {
    console.log('terminalNumbersStoreCodeUniqueValidationIndex')
    try {
        let terminalNumberType = allValues.terminalNumberTypes[index];
        if (terminalNumberType && !_.isEmpty(terminalNumberType.storeCode)) {
            const params = {
                terminalNumber: {
                    $in: terminalNumberType.terminalNumbers
                },
                storeCode: terminalNumberType.storeCode
            };
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/terminalNumberTypes?${queryString}`);
            const { data: terminalNumberTypes } = response.data;
            if (terminalNumberTypes.length > 0) {
                return 'resources.terminalTypes.crud.create.validation.terminalNumbersStoreCodeUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const terminalNumbersStoreCodeUniqueValidation = async values => {
    console.log('terminalNumbersStoreCodeUniqueValidation')
    console.log('values:', values)
    if (!values.length) {
        return undefined;
    }

    return await Promise.all(
        values.map(async value => {
            let errors = {}
            if (value && !_.isEmpty(value.storeCode) && _.isArray(value.terminalNumbers)) {
                const params = {
                    terminalNumber: {
                        $in: value.terminalNumbers
                    },
                    storeCode: value.storeCode
                };
                const queryString = stringify(params, { strictNullHandling: true });
                const response = await axios.get(`/terminalNumberTypes?${queryString}`);
                const { data: terminalNumberTypes } = response.data;
                if (terminalNumberTypes.length > 0) {
                    errors.terminalNumbers = 'resources.terminalTypes.crud.create.validation.terminalNumbersStoreCodeUnique';
                    //return 'resources.terminalTypes.crud.create.validation.terminalNumbersStoreCodeUnique';
                }
            }
            console.log(errors)
            return errors;
        })
    );
}

const validations = {
    name: [maxLength(255), required(), nameChainIdUniqueValidation],
    chainId: [required(), nameChainIdUniqueValidation],
    terminalNumbers: [numbersValidation],
    storeCode: [required()],
    //terminalNumberTypes: [terminalNumbersStoreCodeUniqueValidation]
};

const TerminalTypeCreate2 = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles();

    const [terminalNumberTypes, setTerminalNumberTypes] = useState([{ storeCode: null, terminalNumbers: [] }]);

    const onSuccess = ({ data }) => {
        notify(`resources.terminalTypes.crud.create.notification.success`, 'success');
        redirect(`/terminalTypes/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.terminalTypes.crud.create.notification.failed`, 'error');
    };

    const handleRemove = index => {
        const list = [...terminalNumberTypes];
        list.splice(index, 1);
        setTerminalNumberTypes(list);
    };

    const handleAdd = () => {
        setTerminalNumberTypes([...terminalNumberTypes, { storeCode: null, terminalNumbers: [] }]);
    };

    return (
        <Create
            actions={<TerminalTypeCreateActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...props}
        >
            <FormWithRedirect
                {...props}
                validateOnBlur={true}
                render={({ saving, handleSubmitWithRedirect, ...props }) => {
                    console.log(props)
                    return (
                        <form>
                            <Grid container spacing={1} className={classes.grid}>
                                <Grid item xs={12} sm={6}>
                                    <TextInput
                                        source="name"
                                        resource="terminalTypes"
                                        validate={validations.name}
                                        helperText={false}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomReferenceAutocompleteInput
                                        source="chainId"
                                        reference="chains"
                                        originSource="id"
                                        label={translate('resources.terminalTypes.fields.chain')}
                                        optionText="name"
                                        validate={validations.chainId}
                                        className={classes.input}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate('resources.terminalTypes.crud.create.terminalTypeNumbers')}
                                    </Typography>
                                </Grid>
                                {terminalNumberTypes.map((terminalNumberType, idx) => (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={4}>
                                            <FormDataConsumer>
                                                {({ formData }) => (
                                                    <CustomReferenceAutocompleteInput
                                                        source={`terminalNumberTypes.${idx}.storeCode`}
                                                        reference="stores"
                                                        originSource="storeCode"
                                                        label={translate('resources.terminalNumberTypes.fields.storeCode')}
                                                        optionText="storeCode"
                                                        //validate={validations.storeCode}
                                                        additionalFilter={
                                                            formData.chainId ?
                                                                { chainId: formData.chainId } :
                                                                null
                                                        }
                                                        className={classes.input}
                                                    />
                                                )}
                                            </FormDataConsumer>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <CustomMultiselectInput
                                                source={`terminalNumberTypes.${idx}.terminalNumbers`}
                                                label={translate('resources.terminalNumberTypes.fields.terminalNumber', 2)}
                                                //validate={validations.terminalNumbers}
                                                className={classes.input}
                                            />
                                        </Grid>
                                        <Grid item container spacing={1} xs justifyContent="flex-end">
                                            {terminalNumberTypes.length !== 1 && (
                                                <Grid item>
                                                    <IconButton
                                                        aria-label="Quitar"
                                                        color="error"
                                                        onClick={handleRemove}
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}
                                            {terminalNumberTypes.length - 1 === idx && (
                                                <Grid item>
                                                    <IconButton
                                                        aria-label="Agregar"
                                                        color="primary"
                                                        onClick={handleAdd}
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                            <Toolbar
                                saving={saving}
                                handleSubmitWithRedirect={handleSubmitWithRedirect}
                            />
                        </form>
                    )
                }}
            />
        </Create>
    );
};

const TerminalTypeCreate = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        notify(`resources.terminalTypes.crud.create.notification.success`, 'success');
        redirect(`/terminalTypes/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.terminalTypes.crud.create.notification.failed`, 'error');
    };

    const handleRemove = pop => index => {
        pop('terminalNumberTypes')
        //list.splice(index, 1);
    };

    const handleAdd = push => () => {
        push('terminalNumberTypes', { storeCode: null, terminalNumbers: [] });
    };

    return (
        <Create
            actions={<TerminalTypeCreateActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...props}
        >
            <FormWithRedirect
                {...props}
                mutators={{
                    ...arrayMutators
                }}
                initialValues={{ terminalNumberTypes: [{ storeCode: null, terminalNumbers: [] }] }}
                validateOnBlur={true}
                render={({
                    saving, handleSubmitWithRedirect, form: { mutators: { push, pop  } }, ...props
                }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="terminalTypes"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="chainId"
                                    reference="chains"
                                    originSource="id"
                                    label={translate('resources.terminalTypes.fields.chain')}
                                    optionText="name"
                                    validate={validations.chainId}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.terminalTypes.crud.create.terminalTypeNumbers')}
                                </Typography>
                            </Grid>
                            <FieldArray
                                name="terminalNumberTypes"
                                // validate={terminalNumbersStoreCodeUniqueValidation}
                            >
                                {({ fields, ...rest }) => {
                                    console.log('rest:', rest)
                                    return fields.map((name, index) => (
                                        <React.Fragment key={name}>
                                            <Grid item xs={12} sm={4}>
                                                <FormDataConsumer>
                                                    {({ formData }) => (
                                                        <CustomReferenceAutocompleteInput
                                                            source={`${name}.storeCode`}
                                                            reference="stores"
                                                            originSource="storeCode"
                                                            label={translate('resources.terminalNumberTypes.fields.storeCode')}
                                                            optionText="storeCode"
                                                            // validate={validations.storeCode}
                                                            additionalFilter={
                                                                formData.chainId ?
                                                                    { chainId: formData.chainId } :
                                                                    null
                                                            }
                                                            className={classes.input}
                                                        />
                                                    )}
                                                </FormDataConsumer>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <CustomMultiselectInput
                                                    source={`${name}.terminalNumbers`}
                                                    label={translate('resources.terminalNumberTypes.fields.terminalNumber', 2)}
                                                    // validate={[
                                                    //     ...validations.terminalNumbers,
                                                    //     terminalNumbersStoreCodeUniqueValidationIndex(index)
                                                    // ]}
                                                    className={classes.input}
                                                />
                                            </Grid>
                                            <Grid item container spacing={1} xs justifyContent="flex-end">
                                                {fields.length !== 1 && (
                                                    <Grid item>
                                                        <IconButton
                                                            aria-label="Quitar"
                                                            color="error"
                                                            //onClick={handleRemove(pop)}
                                                            onClick={() => {
                                                                console.log('name:', name)
                                                                console.log('index:', index)
                                                                console.log('fields:', fields.map(e => e))
                                                                fields.remove(index)
                                                            }}
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                                {fields.length - 1 === index && (
                                                    <Grid item>
                                                        <IconButton
                                                            aria-label="Agregar"
                                                            color="primary"
                                                            onClick={handleAdd(push)}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                }}
                            </FieldArray>
                        </Grid>
                        <Toolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                        />
                    </form>
                )}
            />
        </Create>
    );
};

export default TerminalTypeCreate;
