import * as React from 'react';
import { BooleanField, NumberField, TextField, useLocale, useTranslate } from 'react-admin';
import EnumTextField from './EnumTextField';
import CustomDateTimeField from './CustomDateTimeField';
import resourcesConfig from '../../config/resourcesConfig.json';

const CustomField = ({ record, config, ...props }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const currency = resourcesConfig.general.currency;

    if (config.isNullable) {
        props.emptyText = translate('pos.generic.unknown');
    }

    switch (config.datatype.type) {
        case 'NUMBER':
            props = {
                ...props,
                locales: locale
            };
            if (config.datatype.subType === 'CURRENCY') {
                props.options = {
                    style: 'currency',
                    currency: currency
                };
            }
            return (
                <NumberField record={record} source={config.name} {...props} />
            );
        case 'TEXT':
            return (
                <TextField record={record} source={config.name} {...props} />
            );
        case 'BOOLEAN':
            return (
                <BooleanField record={record} source={config.name} {...props} />
            );
        case 'DATETIME':
            props = {
                ...props,
                originalFormat: config.datatype.format ? config.datatype.format : 'YYYY-MM-DD HH:mm:ss',
                showFormat: translate('pos.datetime.datetime')
            };
            return (
                <CustomDateTimeField record={record} source={config.name} {...props} />
            );
        case 'DATE':
            props = {
                ...props,
                originalFormat: config.datatype.format ? config.datatype.format : 'YYYY-MM-DD',
                showFormat: translate('pos.datetime.date')
            };
            return (
                <CustomDateTimeField record={record} source={config.name} {...props} />
            );
        case 'TIME':
            props = {
                ...props,
                originalFormat: config.datatype.format ? config.datatype.format : 'HH:mm:ss',
                showFormat: translate('pos.datetime.time')
            };
            return (
                <CustomDateTimeField record={record} source={config.name} {...props} />
            );
        case 'ENUM':
            props = {
                ...props,
                ...(config.datatype.take && { take: config.datatype.take }),
                ...(config.datatype.additionalData && { additionalData: config.datatype.additionalData })
            };
            return (
                <EnumTextField record={record} source={config.name} {...props} />
            );
        default:
            return (
                <TextField record={record} source={config.name} {...props} />
            );
    }
};

export default CustomField;
