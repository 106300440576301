import * as React from 'react';
import { useContext } from 'react';
import { AbilityContext } from '../../Can';
import {
    TabbedShowLayout, TabbedShowLayoutTabs, Show, Tab, TopToolbar, Labeled, ReferenceManyField, TextField,
    useShowController
} from 'react-admin';
import { Grid } from '@material-ui/core';
import ChainGeneral from './ChainGeneral';
import { GroupList } from '../groups';
import { StoreList } from '../stores';
import { OperatorList } from '../operators';
import { ItemDetailList } from '../itemDetails';
import { DepartmentList } from '../departments';
import { TerminalTypeList } from '../terminalTypes';
import { UserList } from '../users';
import Health from '../../health/Health';
import CustomTitle from '../../layout/CustomTitle';
import { CustomListButton, CustomEditButton } from '../../custom/CustomButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { flexGrow: 1 },
    tabs: { width: 0 },
    table: { paddingBottom: '20px' }
});

const ChainShowActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
            <CustomEditButton {...props} record={props.data} />
        </TopToolbar>
    );
}


const ChainShow = props => {
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const { record } = useShowController(props);

    return (
        <Show
            title={<CustomTitle action="show" {...props} />}
            actions={<ChainShowActions />}
            className={classes.root}
            {...props}
        >
            <TabbedShowLayout
                tabs={
                    <TabbedShowLayoutTabs
                        classes={{ scrollable: classes.tabs }}
                        variant="scrollable"
                        scrollButtons="on"
                    />
                }
            >
                <Tab label="resources.chains.tabs.general">
                    <ChainGeneral />
                </Tab>
                {(record && ability.can('find', 'groups')) &&
                    <Tab label="resources.chains.tabs.groups" path="groups">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.chains.fields.name">
                                    <TextField record={record} source="name" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} className={classes.table}>
                                <ReferenceManyField
                                    reference="groups"
                                    target="id"
                                    addLabel={false}
                                    hiddenColumns={{ chain: true }}
                                >
                                    <GroupList voidTitle={true} chainId={record.id} {...props} />
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </Tab>
                }
                {(record && ability.can('find', 'stores')) &&
                    <Tab label="resources.chains.tabs.stores" path="stores">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.chains.fields.name">
                                    <TextField record={record} source="name" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} className={classes.table}>
                                <ReferenceManyField
                                    reference="stores"
                                    target="id"
                                    addLabel={false}
                                    filterDefaultValues={{ chainId: [record.id] }}
                                    disabledFilters={{ chainId: true }}
                                    hiddenColumns={{ chain: true }}
                                >
                                    <StoreList voidTitle={true} chainId={record.id} {...props} />
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </Tab>
                }
                {(record && ability.can('find', 'operators')) &&
                    <Tab label="resources.chains.tabs.operators" path="operators">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.chains.fields.name">
                                    <TextField record={record} source="name" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} className={classes.table}>
                                <ReferenceManyField
                                    reference="operators"
                                    target="id"
                                    addLabel={false}
                                    filterDefaultValues={{ chainId: [record.id] }}
                                    disabledFilters={{ chainId: true }}
                                    hiddenColumns={{ chain: true }}
                                >
                                    <OperatorList voidTitle={true} chainId={record.id} {...props} />
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </Tab>
                }
                {(record && ability.can('find', 'itemDetails')) &&
                    <Tab label="resources.chains.tabs.itemDetails" path="itemDetails">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.chains.fields.name">
                                    <TextField record={record} source="name" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} className={classes.table}>
                                <ReferenceManyField
                                    reference="itemDetails"
                                    target="id"
                                    addLabel={false}
                                    filterDefaultValues={{ chainId: [record.id] }}
                                    disabledFilters={{ chainId: true }}
                                    hiddenColumns={{ chain: true }}
                                >
                                    <ItemDetailList voidTitle={true} chainId={record.id} {...props} />
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </Tab>
                }
                {(record && ability.can('find', 'departments')) &&
                    <Tab label="resources.chains.tabs.departments" path="departments">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.chains.fields.name">
                                    <TextField record={record} source="name" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} className={classes.table}>
                                <ReferenceManyField
                                    reference="departments"
                                    target="id"
                                    addLabel={false}
                                    filterDefaultValues={{ chainId: [record.id] }}
                                    disabledFilters={{ chainId: true }}
                                    hiddenColumns={{ chain: true }}
                                >
                                    <DepartmentList voidTitle={true} chainId={record.id} {...props} />
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </Tab>
                }
                {(record && ability.can('find', 'terminalTypes')) &&
                    <Tab label="resources.chains.tabs.terminalTypes" path="terminalTypes">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.chains.fields.name">
                                    <TextField record={record} source="name" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} className={classes.table}>
                                <ReferenceManyField
                                    reference="terminalTypes"
                                    target="id"
                                    addLabel={false}
                                    filterDefaultValues={{ chainId: [record.id] }}
                                    disabledFilters={{ chainId: true }}
                                    hiddenColumns={{ chain: true }}
                                >
                                    <TerminalTypeList voidTitle={true} chainId={record.id} {...props} />
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </Tab>
                }
                {(record && ability.can('find', 'users')) &&
                    <Tab label="resources.chains.tabs.users" path="users">
                        <ReferenceManyField reference="users" target="id" addLabel={false} className={classes.table}>
                            <UserList voidTitle={true} chainId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && ability.can('find', 'healths')) &&
                    <Tab label="pos.health.name.default" path="health">
                        <Health
                            filters={{ groupBy: 'transaction.storeCode', chainId: record.id }}
                            componentName="chain"
                            className={classes.table}
                        />
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    );
}

export default ChainShow;
