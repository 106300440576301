import * as React from 'react';
import { useTranslate } from 'react-admin';
import { Alert, AlertTitle } from '@material-ui/lab';

const CustomError = ({ severity = 'error', errorPrimary, errorSecondary }) => {
    const translate = useTranslate();

    errorPrimary = errorPrimary ? errorPrimary : translate('pos.generic.error');

    return (
        <Alert severity={severity}>
            <AlertTitle>{errorPrimary}</AlertTitle>
            {errorSecondary}
        </Alert>
    );
};

export default CustomError;
