import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocale } from 'react-admin';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Paper, Checkbox,
    CircularProgress
} from '@material-ui/core';
import { esES, enUS } from '@material-ui/core/locale';
import CustomTableToolbar from './CustomTableToolbar';
import _ from 'lodash';
import clsx from  'clsx';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    tableRoot: {
        width: '100%',
        //flexGrow: 1,
        maxWidth: '95vw'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: '750px',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableCell: {
        padding: '6px 6px 6px 6px',
        minWidth: '100px'
    },
    tableTotalCell: {
        fontWeight: 500
    },
}));

const CustomTableHead = ({ headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) => {
    const classes = useStyles();

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all rows' }}
                        color="primary"
                    />
                </TableCell>
                {_.sortBy(headCells, 'sort').map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={clsx(classes.tableCell, headCell.classes)}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label !== false && headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const paginationTheme = locale => {
    switch (locale) {
        case 'en':
            return createTheme({}, enUS);
        case 'es':
        default:
            return createTheme({}, esES);
    }
}

const CustomTable = ({ children, tableToolbar, totalRow, title, data, rowIdKey, headCells, orderByField = null, orderType = 'asc', filters, loading }) => {
    const locale = useLocale();
    const classes = useStyles();
    const [order, setOrder] = useState(orderType);
    const [orderBy, setOrderBy] = useState(orderByField || rowIdKey);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (loading) {
            setSelected([]);
            setPage(0);
        }
    }, [data, loading]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelected = data.data.map(n => n[rowIdKey]);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, rowIdKey) => {
        const selectedIndex = selected.indexOf(rowIdKey);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, rowIdKey);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = rowIdKey => selected.indexOf(rowIdKey) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.data.length - page * rowsPerPage);

    return (
        <div className={classes.tableRoot}>
            <Paper className={classes.paper}>
                {tableToolbar ? (
                    tableToolbar({
                        data, filters, rowIdKey, headCells, title, numSelected: selected.length,
                        onSelectAllClick: handleSelectAllClick, order, orderBy, selected
                    })
                ) : (
                    <CustomTableToolbar
                        data={data}
                        filters={filters}
                        rowIdKey={rowIdKey}
                        headCells={headCells}
                        title={title}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        selected={selected}
                        onSelectAllClick={handleSelectAllClick}
                    />
                )}
                <TableContainer>
                    <Table
                        size="small"
                        className={classes.table}
                        aria-labelledby={`table-${title}`}
                    >
                        <CustomTableHead
                            headCells={headCells}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data.data.length}
                        />
                        {loading ? (
                            <TableBody>
                                <TableRow style={{ height: 33 * rowsPerPage }}>
                                    <TableCell colSpan={headCells.length + 1} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {_.orderBy(data.data, [orderBy], [order])
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row[rowIdKey]);
                                        const labelId = `custom-table-checkbox-${index}`;

                                        return (children({
                                            row, handleClick, isItemSelected, labelId, tableCellClass: classes.tableCell
                                        }));
                                    })
                                }
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 33 * emptyRows }}>
                                        <TableCell colSpan={headCells.length + 1} />
                                    </TableRow>
                                )}
                                {totalRow && totalRow({
                                    data: data,
                                    tableCellClass: classes.tableCell,
                                    tableTotalCellClass: classes.tableTotalCell
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <ThemeProvider theme={paginationTheme(locale)}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </ThemeProvider>
            </Paper>
        </div>
    );
};

export default CustomTable;
