import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { divergingColorSchemes } from '@nivo/colors';
import { NumberField, TextField, useLocale, useTranslate } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import CustomDateInput from '../../../custom/CustomDateInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import getDateDefaultValue from '../../../../helpers/getDateDefaultValue';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import moment from 'moment';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const { spectral } = divergingColorSchemes;

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: { maxHeight: '300px' },
    borderX: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`
    },
    borderLeft: {
        borderLeft: `1px solid ${theme.palette.divider}`
    },
    borderRight: {
        borderRight: `1px solid ${theme.palette.divider}`
    },
}));

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <CustomDateInput
                                    source="date"
                                    label="pos.datetime.dateName"
                                    onChange={date => {
                                        const compareWithDate = form.getFieldState('compareWith') && form.getFieldState('compareWith').value ?
                                            moment(form.getFieldState('compareWith').value, 'YYYY-MM-DD') : null;

                                        if (compareWithDate && date.day() !== compareWithDate.day()) {
                                            form.change('compareWith', null);
                                        }
                                    }}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateInput
                                    source="compareWith"
                                    label="resources.transactions.statistics.tabs.operatorProductivity.sections.quantityActiveCashiers.compareWith"
                                    shouldDisableDate={date => {
                                        const firstDate = form.getFieldState('date') && form.getFieldState('date').value ?
                                            moment(form.getFieldState('date').value, 'YYYY-MM-DD') : null;

                                        return !(firstDate && date.day() === firstDate.day() && date.format() !== firstDate.format());
                                    }}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="terminalTypeId"
                                    record={filters}
                                    reference="terminalTypes"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.transactions.fields.terminalType')}
                                    withNull={true}
                                    helperText={false}
                                    //groupByField="chainId"
                                    additionalFilter={{
                                        ...(form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                        _.isArray(form.getFieldState('chainId').value) ? {
                                            chainId: form.getFieldState('chainId').value
                                        } : {}),
                                        ...(form.getFieldState('storeCode') && form.getFieldState('storeCode').value &&
                                        _.isArray(form.getFieldState('storeCode').value) ? {
                                            storeCode: form.getFieldState('storeCode').value
                                        } : {})
                                    }}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => {
                                        cancelTokenSource.first.cancel();
                                        cancelTokenSource.compare.cancel();
                                    }}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => {
                                        cancelTokenSource.first.cancel();
                                        cancelTokenSource.compare.cancel();
                                    }}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.quantityActiveCashiers.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FirstChart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CompareChart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                date: getDateDefaultValue(),
                                compareWith: moment(getDateDefaultValue(), 'YYYY-MM-DD').subtract(7, 'day').format('YYYY-MM-DD')
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FirstChart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CompareChart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.quantityActiveCashiers.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: FirstChart,
                    secondaryChart: CompareChart,
                    mainTable: DataTable
                }}
            />
        </div>
    );
};

const FirstChart = ({ data, ...props }) => {
    const translate = useTranslate();

    const { date } = props.filters;
    const title = moment(date, 'YYYY-MM-DD').format(translate('pos.datetime.date'));

    return (
        <Chart data={data.firstData} title={title} {...props} />
    );
};

const CompareChart = ({ data, ...props }) => {
    const translate = useTranslate();

    const { compareWith } = props.filters;
    const title = moment(compareWith, 'YYYY-MM-DD').format(translate('pos.datetime.date'));

    return (
        <Chart data={data.compareData} title={title} {...props} />
    );
};

const Chart = ({ data, title, loading, cancel, error, exportable = false }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const classes = useStyles({ exportable });

    const [processedData, setProcessedData] = useState([]);
    const [colors] = useState([...spectral[11]].reverse());
    const [times, setTimes] = useState([]);
    const [minHour, setMinHour] = useState();
    const [maxHour, setMaxHour] = useState();

    const [minTransactionQuantity, setMinTransactionQuantity] = useState();
    const [maxTransactionQuantity, setMaxTransactionQuantity] = useState();


    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            let processedData = [];
            const groupByValues = _.orderBy(_.uniq(data.map(e => e.terminalQuantity)), [], ['asc']);
            const minHour = _.minBy(data, 'hour').hour;
            const maxHour = _.maxBy(data, 'hour').hour + 100;

            const minTransactionQuantity = _.minBy(data, 'totalTransactionQuantity').totalTransactionQuantity;
            const maxTransactionQuantity = _.maxBy(data, 'totalTransactionQuantity').totalTransactionQuantity;

            let times = [];
            for (let i = 0; i <= (maxHour - minHour) / 100; i++) {
                const hour = (i * 100) + minHour;
                let time;
                if (hour === 0) {
                    time = '00:00';
                } else if (hour < 1000) {
                    time = moment(String(hour), 'Hmm').format('HH:mm');
                } else {
                    time = moment(String(hour), 'HHmm').format('HH:mm');
                }
                times.push({
                    hour: hour,
                    time: time,
                    inactiveTime: `${time}-inactive`
                });
            }

            groupByValues.forEach(groupByValue => {
                let groupByData = {
                    id: groupByValue,
                    terminalQuantity: groupByValue
                };
                let filteredData = data.filter(e => e.terminalQuantity === groupByValue);
                let greaterTerminalQuantityData = data.filter(e => e.terminalQuantity > groupByValue);
                times.forEach(time => {
                    let greaterTerminalQuantityTimeData = greaterTerminalQuantityData.find(e => e.time === time.time);
                    let filteredTimeData = filteredData.find(e => e.time === time.time);
                    if (filteredTimeData || greaterTerminalQuantityTimeData) {
                        if (time.hour === minHour) {
                            groupByData[time.time] = minHour + 100;
                        } else {
                            groupByData[time.time] = 100;
                        }

                        if (filteredTimeData) {
                            groupByData[`${time.time}-transactionQuantity`] = filteredTimeData.totalTransactionQuantity;
                        } else if (greaterTerminalQuantityTimeData) {
                            groupByData[`${time.time}-transactionQuantity`] = greaterTerminalQuantityTimeData.totalTransactionQuantity;
                        }
                    } else {
                        if (time.hour === minHour) {
                            groupByData[time.inactiveTime] = minHour + 100;
                        } else {
                            groupByData[time.inactiveTime] = 100;
                        }
                    }
                });

                processedData.push(groupByData);
            });

            setTimes(times);
            setMinHour(minHour);
            setMaxHour(maxHour);
            setMinTransactionQuantity(minTransactionQuantity);
            setMaxTransactionQuantity(maxTransactionQuantity);
            setProcessedData(processedData);
        }
    }, [data, loading, cancel]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress/>
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const axisBottom = value => {
        if (value === 0) {
            return '00:00';
        } else if (value < 1000) {
            return moment(String(value), 'Hmm').format('HH:mm');
        } else {
            return moment(String(value), 'HHmm').format('HH:mm');
        }
    };

    const tooltip = ({ color, id, indexValue, data }) => {
        if (/inactive/.test(id)) {
            const time = times.find(e => e.inactiveTime === id).time;

            return (
                <div
                    style={{
                        whiteSpace: 'pre',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <div style={{ display: 'grid' }}>
                        <span>
                            <strong>{time}</strong>
                        </span>
                    </div>
                </div>
            );
        }

        indexValue = indexValue.toLocaleString(locale);
        const transactionQuantity = data[`${id}-transactionQuantity`].toLocaleString(locale);

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                     <span>
                         <strong>{id}</strong>
                     </span>
                     <span>
                         {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.quantityActiveCashiers.terminalQuantity')}: <strong>{indexValue}</strong>
                     </span>
                    <span>
                         {translate('pos.generic.customers')}: <strong>{transactionQuantity}</strong>
                     </span>
                </div>
            </div>
        );
    };

    const getColor = ({ id, data }) => {
        if (/inactive/.test(id)) {
            return 'transparent';
        }

        const transactionQuantity = data[`${id}-transactionQuantity`];
        if (transactionQuantity !== undefined) {
            const index = _.ceil((transactionQuantity - minTransactionQuantity) / ((maxTransactionQuantity - minTransactionQuantity) / 10));
            return colors[index];
        }

        return 'transparent';
    };

    return (
        <div>
            <Typography variant="subtitle1" align="center">{title}</Typography>
            <div className={classes.chart}>
                <ResponsiveBar
                    data={processedData}
                    label={false}
                    tooltip={tooltip}
                    keys={times.map(e => [e.time, e.inactiveTime]).flat()}
                    indexBy="terminalQuantity"
                    minValue={minHour}
                    maxValue={maxHour}
                    margin={{
                        top: 20,
                        bottom: 100,
                        right: 20,
                        left: 80
                    }}
                    padding={0}
                    layout="horizontal"
                    enableGridY={true}
                    enableGridX={true}
                    valueScale={{ type: 'linear', clamp: true }}
                    indexScale={{ type: 'band', round: true }}
                    colorBy="id"
                    colors={getColor}
                    borderColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                    axisTop={null}
                    axisRight={null}
                    gridXValues={times.map(e => e.hour)}
                    axisBottom={{
                        format: axisBottom,
                        tickValues: times.map(e => e.hour),
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -45,
                        legend: translate('pos.datetime.hour', 1),
                        legendPosition: 'middle',
                        legendOffset: 50
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: translate('resources.transactions.statistics.tabs.operatorProductivity.sections.quantityActiveCashiers.terminalQuantity'),
                        legendPosition: 'middle',
                        legendOffset: -60
                    }}
                    labelSkipWidth={48}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />
            </div>
        </div>
    );
};

const processData = ({ data, loading, cancel, setProcessingData, setProcessedData }) => {
    setProcessingData(true);
    const { firstData, compareData } = data;
    if ((firstData && compareData) && (firstData.length > 0 || compareData.length > 0) && !loading && !cancel) {
        const allData = firstData.concat(compareData);

        const minHour = _.minBy(allData, 'hour').hour;
        const maxHour = _.maxBy(allData, 'hour').hour;

        let processedData = {
            data: []
        };
        for (let i = 0; i <= (maxHour - minHour) / 100; i++) {
            const hour = (i * 100) + minHour;
            let time;
            if (hour === 0) {
                time = '00:00';
            } else if (hour < 1000) {
                time = moment(String(hour), 'Hmm').format('HH:mm');
            } else {
                time = moment(String(hour), 'HHmm').format('HH:mm');
            }

            const firstTimeData = firstData.find(e => e.time === time);
            const compareTimeData = compareData.find(e => e.time === time);

            processedData.data.push({
                hour: hour,
                time: time,
                first: {
                    terminalQuantity: firstTimeData ? firstTimeData.terminalQuantity : 0,
                    transactionQuantity: firstTimeData ? firstTimeData.totalTransactionQuantity : 0
                },
                compare: {
                    terminalQuantity: compareTimeData ? compareTimeData.terminalQuantity : 0,
                    transactionQuantity: compareTimeData ? compareTimeData.totalTransactionQuantity : 0
                }
            });
        }

        setProcessedData(processedData);
        setProcessingData(false);
    }
};

const DataTable = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    const { date, compareWith } = filters;

    useEffect(() => {
        processData({ data, loading, cancel, setProcessingData, setProcessedData });
    }, [data, loading, cancel]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || (data.firstData.length === 0 && data.compareData.length === 0) || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow key="rowHeader-1">
                        <TableCell rowSpan={2}>
                            {translate('pos.datetime.hour', 1)}
                        </TableCell>
                        <TableCell colSpan={2} align="center" className={classes.borderX}>
                            {date ? moment(date, 'YYYY-MM-DD').format(translate('pos.datetime.date')) : emptyText}
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                            {compareWith ? moment(compareWith, 'YYYY-MM-DD').format(translate('pos.datetime.date')) : emptyText}
                        </TableCell>
                    </TableRow>
                    <TableRow key="rowHeader-2">
                        <TableCell align="right" style={{ top: '37px' }} className={classes.borderLeft}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.quantityActiveCashiers.terminalQuantity')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }} className={classes.borderRight}>
                            {translate('pos.generic.customers')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.quantityActiveCashiers.terminalQuantity')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('pos.generic.customers')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={5} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => (
                            <TableRow key={row.time}>
                                <TableCell>
                                    <TextField record={row} source="time" />
                                </TableCell>
                                <TableCell align="right" className={classes.borderLeft}>
                                    <NumberField
                                        record={row}
                                        source="first.terminalQuantity"
                                        locales={locale}
                                    />
                                </TableCell>
                                <TableCell align="right" className={classes.borderRight}>
                                    <NumberField
                                        record={row}
                                        source="first.transactionQuantity"
                                        locales={locale}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="compare.terminalQuantity"
                                        locales={locale}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="compare.transactionQuantity"
                                        locales={locale}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let { date, compareWith, chainId, storeCode, terminalTypeId, terminalNumber, operatorCode } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'active-cashiers',
            groupByTerminalQuantity: true,
            chainId: chainId,
            storeCode: storeCode,
            terminalTypeId: terminalTypeId,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode
        };

        const firstQueryString = stringify({
            ...params,
            date: date
        }, { strictNullHandling: true });
        const firstCancelTokenSource = baseAxios.CancelToken.source();

        const compareQueryString = stringify({
            ...params,
            date: compareWith
        }, { strictNullHandling: true });
        const compareCancelTokenSource = baseAxios.CancelToken.source();

        setCancelTokenSource({
            first: firstCancelTokenSource,
            compare: compareCancelTokenSource
        });

        let promises = [
            axios.get(`/statistics?${firstQueryString}`, { cancelToken: firstCancelTokenSource.token }),
            axios.get(`/statistics?${compareQueryString}`, { cancelToken: compareCancelTokenSource.token })
        ];

        Promise.all(promises)
            .then(([firstResponse, compareResponse]) => {
                const { data: firstData } = firstResponse.data;
                const { data: compareData } = compareResponse.data;

                setData({
                    firstData: firstData.length > 0 ? firstData : [],
                    compareData: compareData.length > 0 ? compareData : []
                });
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [renderKey, date, compareWith, chainId, storeCode, terminalTypeId, terminalNumber, operatorCode]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const QuantityActiveCashiersBar = () => {
    const translate = useTranslate();

    const filters = {
        date: getDateDefaultValue(),
        compareWith: moment(getDateDefaultValue(), 'YYYY-MM-DD').subtract(7, 'day').format('YYYY-MM-DD')
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.quantityActiveCashiers.name')}
                            </Typography>
                        </Grid>
                        <Grid item lg={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} lg={6}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <DataTable
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} lg={6}>
                                    <FirstChart
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <CompareChart
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default QuantityActiveCashiersBar;
