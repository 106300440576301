import * as React from 'react';
import { Datagrid, List, NumberField, TextField, useLocale, useTranslate, useListContext } from 'react-admin';
import CustomReferenceField from '../../custom/CustomReferenceField';
import CustomDateTimeField from '../../custom/CustomDateTimeField';
import InvoiceNumberField from './InvoiceNumberField';
import TypeField from './TypeField';
import TotalTaxesField from './TotalTaxesField';
import SubtotalField from './SubtotalField';
import TransactionFilter from './TransactionFilter';
import TransactionShowButton from './TransactionShowButton';
import CustomBulkActionButtons from '../../custom/CustomBulkActionButtons';
import resourcesConfig from '../../../config/resourcesConfig.json';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    rightAlignColumn: {
        textAlign: 'right',
    },
});

const TransactionList = ({ children, voidTitle = false, perPage, filter, storeCode, filterDefaultValues, disabledFilters, hiddenColumns, exporter, ...props }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const { loading } = useListContext();
    const emptyText = translate('pos.generic.unknown');
    const currency = resourcesConfig.general.currency;

    const title = voidTitle ? ' ' : null;
    filter = {
        ...filter,
        ...(storeCode && { storeCode: storeCode })
    };

    return (
        <List
            {...props}
            title={title}
            actions={null}
            bulkActionButtons={<CustomBulkActionButtons />}
            filters={
                <TransactionFilter
                    filter={filter}
                    filterDefaultValues={filterDefaultValues}
                    disabledFilters={disabledFilters}
                />
            }
            filter={filter}
            filterDefaultValues={filterDefaultValues}
            perPage={perPage}
            exporter={exporter}
        >
            {children ? (
                children({ loading })
            ) : (
                <Datagrid
                    hover={false}
                    rowStyle={(record, index) => {
                        if (index % 2 === 0) {
                            return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                        }
                    }}
                >
                    {(!hiddenColumns || !hiddenColumns.storeCode) &&
                        <CustomReferenceField
                            originalSource="storeCode"
                            injectSource="storeId"
                            reference="stores"
                            label="resources.transactions.fields.storeCode"
                        />
                    }
                    {(!hiddenColumns || !hiddenColumns.sequenceNumber) &&
                        <NumberField source="sequenceNumber" locales={locale} />
                    }
                    {(!hiddenColumns || !hiddenColumns.operatorCode) &&
                        <TextField source="operatorCode" />
                    }
                    {(!hiddenColumns || !hiddenColumns.operatorName) &&
                        <TextField
                            source="store.chain.operators[0].name"
                            label="resources.transactions.fields.operatorName"
                            emptyText={emptyText}
                            sortable={false}
                        />
                    }
                    {(!hiddenColumns || !hiddenColumns.terminalNumber) &&
                        <NumberField source="terminalNumber" locales={locale} />
                    }
                    {(!hiddenColumns || !hiddenColumns.businessDayDate) &&
                        <CustomDateTimeField
                            source="businessDayDate"
                            showFormat={translate('pos.datetime.datetime')}
                        />
                    }
                    {(!hiddenColumns || !hiddenColumns.invoiceNumber) &&
                        <InvoiceNumberField label="resources.transactions.fields.invoiceNumber" />
                    }
                    {(!hiddenColumns || !hiddenColumns.type) &&
                        <TypeField label="resources.transactions.fields.type.name" />
                    }
                    {(!hiddenColumns || !hiddenColumns.subtotal) &&
                        <SubtotalField
                            label="resources.transactions.fields.subtotal"
                            headerClassName={classes.rightAlignColumn}
                            cellClassName={classes.rightAlignColumn}
                        />
                    }
                    {(!hiddenColumns || !hiddenColumns.totalTaxes) &&
                        <TotalTaxesField
                            label="resources.transactions.fields.totalTaxes"
                            headerClassName={classes.rightAlignColumn}
                            cellClassName={classes.rightAlignColumn}
                        />
                    }
                    {(!hiddenColumns || !hiddenColumns.total) &&
                        <NumberField
                            source="total"
                            locales={locale}
                            options={{ style: 'currency', currency: currency }}
                            sortable={false}
                        />
                    }
                    <TransactionShowButton />
                </Datagrid>
            )}
        </List>
    );
};

export default TransactionList;
