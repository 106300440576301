import * as React from 'react';
import { useTranslate } from 'react-admin';

const CustomTitle = ({ action, ...props }) => {
    const translate = useTranslate();
    const title = translate(`pos.titles.${action}`, {
        resource: translate(`resources.${props.resource}.name`, 1),
        id: props.record ? props.record.id : ''
    });
    return <span>{title}</span>;
};

export default CustomTitle;
