import React from 'react';
import { useState, useMemo, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { TextField, Grid, Typography, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LocationOn as LocationOnIcon } from '@material-ui/icons';
import _ from 'lodash';
import axios from 'axios';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

const AddressInput = props => {
    const translate = useTranslate();
    const classes = useStyles();

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const {
        className,
        label,
        format,
        helperText = true,
        onBlur,
        onFocus,
        onChange,
        parse,
        record,
        resource,
        source,
        validate,
        ...rest
    } = props;

    const fetch = useMemo(() => _.throttle(request => {
        setLoading(true);
        const params = {
            ...request,
            format: 'json',
            addressdetails: 1,
            email: 'maxram98@gmail.com'
        };
        const queryString = stringify(params, { strictNullHandling: true });

        axios.get(`https://nominatim.openstreetmap.org/search?${queryString}`)
            .then(({ data }) => {
                //console.log(data);
                setOptions(data);
            })
            .catch(error => {
                //setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, 1000), []);

    useEffect(() => {
        fetch({ q: inputValue });
    }, [fetch, inputValue, value]);

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={options}
            loading={loading}
            filterOptions={x => x}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.display_name)}
            noOptionsText={translate('pos.generic.noOptions')}
            loadingText={translate('ra.page.loading')}
            autoComplete
            includeInputInList
            filterSelectedOptions
            renderInput={params => (
                <TextField
                    {...params}
                    label="Buscar dirección"
                    variant="filled"
                    margin="normal"
                    size="small"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    fullWidth
                    className={className}
                />
            )}
            renderOption={option => {
                const { address } = option;
                let primaryText = _.compact([address.road, address.suburb]);
                let secondaryText = _.compact([address.city, address.state, address.country]).join(', ');
                if (primaryText.length === 0) {
                    primaryText = option.display_name;
                } else {
                    primaryText = primaryText.join(', ');
                }

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body1">
                                {primaryText}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {secondaryText}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};

export default AddressInput;
