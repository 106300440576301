import * as React from 'react';
import { Labeled, NumberField, TextField, ReferenceField, useLocale, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';

const StoreGeneral = props => {
    const locale = useLocale();
    const translate = useTranslate();
    const emptyText = translate('pos.generic.unknown');

    return (
        <span>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.stores.fields.name">
                        <TextField record={props.record} source="name" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.stores.fields.storeCode">
                        <TextField record={props.record} source="storeCode" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.stores.fields.storeNumber">
                        <NumberField record={props.record} source="storeNumber" emptyText={emptyText} />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.stores.fields.address">
                        <TextField record={props.record} source="address" emptyText={emptyText} />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.stores.fields.latitude">
                        <TextField record={props.record} source="latitude" emptyText={emptyText} />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.stores.fields.longitude">
                        <TextField record={props.record} source="longitude" emptyText={emptyText} />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.stores.fields.squareMeters">
                        <NumberField
                            record={props.record}
                            source="squareMeters"
                            locales={locale}
                            emptyText={emptyText}
                        />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.stores.fields.phone">
                        <TextField record={props.record} source="phone" emptyText={emptyText} />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.stores.fields.status">
                        <TextField record={props.record} source="status" emptyText={emptyText} />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.stores.fields.chain">
                        <ReferenceField label="resources.stores.fields.chain" source="chainId" reference="chains" link="show">
                            <TextField source="name" />
                        </ReferenceField>
                    </Labeled>
                </Grid>
            </Grid>
        </span>
    );
};

export default StoreGeneral;
