import * as React from 'react';
import {
    ChangeEvent,
    Children,
    cloneElement,
    isValidElement,
    ReactElement,
    ReactNode,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { escapePath, Record } from 'ra-core';

import { CustomTabbedLayoutTabs, getTabFullPath } from './CustomTabbedLayoutTabs';

const useStyles = makeStyles(theme => ({
    content: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}), { name: 'RaTabbedShowLayout' });

export const CustomTabbedLayout = props => {
    const {
        basePath,
        children,
        classes: classesOverride,
        className,
        record,
        resource,
        syncWithLocation = true,
        tabs,
        value,
        version,
        ...rest
    } = props;
    const match = useRouteMatch();
    const classes = useStyles(props);
    const nonNullChildren = Children.toArray(children).filter(child => child !== null);
    const [tabValue, setTabValue] = useState(0);

    console.log('basePath:', basePath)
    console.log('rest1:', rest)
    console.log('nonNullChildren:', nonNullChildren)

    const handleTabChange = (event, value) => {
        console.log('event:', event)
        console.log('value:', value)
        if (!syncWithLocation) {
            setTabValue(value);
        }
    };

    return (
        <div className={className} key={version} {...rest}>
            {cloneElement(
                tabs,
                {
                    syncWithLocation,
                    onChange: handleTabChange,
                    value: tabValue,
                },
                nonNullChildren
            )}
            <Divider />
            <div className={classes.content}>
                {/*{Children.map(nonNullChildren, (tab, index) =>*/}
                {/*    tab && isValidElement(tab) ? (*/}
                {/*        syncWithLocation ? (*/}
                {/*            <Route*/}
                {/*                exact*/}
                {/*                path={escapePath(*/}
                {/*                    getTabFullPath(tab, index, match.url)*/}
                {/*                )}*/}
                {/*                render={() =>*/}
                {/*                    cloneElement(tab, {*/}
                {/*                        context: 'content',*/}
                {/*                        resource,*/}
                {/*                        record,*/}
                {/*                        basePath,*/}
                {/*                    })*/}
                {/*                }*/}
                {/*            />*/}
                {/*        ) : tabValue === index ? (*/}
                {/*            cloneElement(tab, {*/}
                {/*                context: 'content',*/}
                {/*                resource,*/}
                {/*                record,*/}
                {/*                basePath,*/}
                {/*            })*/}
                {/*        ) : null*/}
                {/*    ) : null*/}
                {/*)}*/}
                {Children.map(nonNullChildren, (tab, index) => {
                    console.log('escapePath:', escapePath(getTabFullPath(tab, index, match.url)));

                    if (tab && isValidElement(tab)) {
                        console.log('tab && isValidElement(tab)')
                        if (syncWithLocation) {
                            console.log('syncWithLocation')
                            //return tab;

                            return (
                                <Route
                                    exact
                                    path={escapePath(getTabFullPath(tab, index, match.url))}
                                    render={() =>
                                        cloneElement(tab, {
                                            context: 'content',
                                            resource,
                                            record,
                                            basePath,
                                        })
                                    }
                                />
                            );
                        }

                        if (tabValue === index) {
                            console.log('tabValue === index')
                            return (
                                cloneElement(tab, {
                                    context: 'content',
                                    resource,
                                    record,
                                    basePath,
                                })
                            );
                        }
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

export interface TabbedShowLayoutProps {
    basePath?: string;
    children: ReactNode;
    record?: Record;
    resource?: string;
    syncWithLocation?: boolean;
    tabs?: ReactElement;
    value?: any;
    version?: number;
}

CustomTabbedLayout.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    location: PropTypes.object,
    match: PropTypes.object,
    record: PropTypes.object,
    resource: PropTypes.string,
    syncWithLocation: PropTypes.bool,
    tabs: PropTypes.element,
    value: PropTypes.number,
    version: PropTypes.number,
};

CustomTabbedLayout.defaultProps = {
    tabs: <CustomTabbedLayoutTabs />,
};

export default CustomTabbedLayout;