import * as React from 'react';
import { TextField, useLocale } from 'react-admin';
import _ from 'lodash';
import moment from 'moment';
import humanize from '../../config/humanizeDuration';

const DurationField = ({ record, source, unit = 'seconds', largest = 3, shortFormat = false, emptyText, ...props}) => {
    const locale = useLocale();

    let value = _.get(record, source);
    if (value !== undefined && value !== null) {
        let valueAsMilliseconds = moment.duration(value, unit).asMilliseconds();
        value = humanize(valueAsMilliseconds, {
            shortFormat: shortFormat,
            language: locale,
            largest: largest
        });
    }

    const newRecord = {
        [source]: value
    };

    return (
        <TextField record={newRecord} source={source} emptyText={emptyText} {...props} />
    );
};

export default DurationField;
