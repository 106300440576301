import getBusinessDayDateConfig from './getBusinessDayDateConfig';
import moment from 'moment';

export default function getBusinessDayDateDefaultValue() {
    const {
        businessDayDateQuantity, businessDayDateUnitOfTime, upperLimitSubtractQuantity, upperLimitSubtractUnitOfTime
    } = getBusinessDayDateConfig();
    return {
        $gte: moment().add(- businessDayDateQuantity, businessDayDateUnitOfTime).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        $lte: moment().subtract(upperLimitSubtractQuantity, upperLimitSubtractUnitOfTime).endOf('day').format('YYYY-MM-DD HH:mm:00')
    };
}
