import * as React from 'react';
import {
    Edit,
    FormWithRedirect,
    maxLength,
    required,
    TextInput,
    TopToolbar,
    useEditController,
    useNotify, useRedirect, useRefresh
} from 'react-admin';
import { Grid } from '@material-ui/core';
import CustomTitle from '../../layout/CustomTitle';
import { CustomListButton, CustomShowButton } from '../../custom/CustomButton';
import CustomEditToolbar from '../../custom/CustomEditToolbar';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    },
});

const ChainEditActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
            <CustomShowButton {...props} record={props.data} />
        </TopToolbar>
    );
};

const nameUniqueValidation = async (value, allValues) => {
    try {
        if (value) {
            const params = {
                name: value,
                id: { $ne: allValues.id }
            }
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/chains?${queryString}`);
            const { data: chains } = response.data;
            if (chains.length > 0) {
                return 'resources.chains.crud.edit.validation.nameNotUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    name: [maxLength(255), required(), nameUniqueValidation],
    status: [maxLength(255)]
};

const ChainEdit = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const { record } = useEditController(props);
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        notify(`resources.chains.crud.edit.notification.success`, 'success');
        redirect(`/chains/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.chains.crud.edit.notification.failed`, 'error');
    };

    return (
        <Edit
            title={<CustomTitle action="edit" {...props} />}
            actions={<ChainEditActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            mutationMode="pessimistic"
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="id" resource="chains" helperText={false} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="chains"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <CustomEditToolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            record={record}
                            {...props}
                        />
                    </form>
                )}
            />
        </Edit>
    );
};

export default ChainEdit;
