import humanizeDuration from 'humanize-duration';

const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: 'shortEn',
    languages: {
        shortEn: {
            y: () => 'y',
            mo: () => 'mo',
            w: () => 'w',
            d: () => 'd',
            h: () => 'h',
            m: () => 'm',
            s: () => 's',
            ms: () => 'ms',
        }
    }
});

const shortSpanishHumanizer = humanizeDuration.humanizer({
    language: 'shortEs',
    languages: {
        shortEs: {
            y: () => 'a',
            mo: () => 'm',
            w: () => 'sem',
            d: () => 'd',
            h: () => 'h',
            m: () => 'min',
            s: () => 'seg',
            ms: () => 'mseg',
        }
    }
});

const humanize = (milliseconds, { shortFormat = false, language = 'es', ...options }) => {
    if (shortFormat) {
        switch (language) {
            case 'en':
            case 'en-US':
                return shortEnglishHumanizer(milliseconds, options);
            case 'es':
            case 'es-US':
            default:
                return shortSpanishHumanizer(milliseconds, options);
        }
    } else {
        switch (language) {
            case 'en':
            case 'en-US':
                language = 'en';
                break;
            case 'es':
            case 'es-US':
            default:
                language = 'es';
        }

        return humanizeDuration(milliseconds, {
            ...options,
            language: language
        });
    }
};

export default humanize;
