import * as React from 'react';
import { Labeled, ReferenceField, TextField } from 'react-admin';
import { Grid } from '@material-ui/core';

const TerminalTypeGeneral = props => {
    return (
        <span>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.terminalTypes.fields.name">
                        <TextField record={props.record} source="name" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.terminalTypes.fields.chain">
                        <ReferenceField label="resources.terminalTypes.fields.chain" source="chainId" reference="chains" link="show">
                            <TextField source="name" />
                        </ReferenceField>
                    </Labeled>
                </Grid>
            </Grid>
        </span>
    );
};

export default TerminalTypeGeneral;
