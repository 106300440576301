import * as React from 'react';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    MenuItem, List, ListItemIcon, ListItemText, Collapse, Tooltip, useMediaQuery, ListItem, Typography
} from '@material-ui/core';
import { usePermissions, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import CustomMenuItemLink from '../custom/CustomMenuItemLink';
import getMenu from '../../config/menuConfig';
import logo from '../../resources/logo.png';
import icon from '../../resources/icon.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '20px',
        width: '100%',
        maxWidth: 360
    },
    nested: {
        paddingLeft: theme.spacing(4),
        paddingTop: '4px',
        paddingBottom: '4px'
    },
    rootListItemIcon: {
        minWidth: '40px'
    },
    logo: {
        position: 'fixed',
        bottom: 0,
        textAlign: 'center',
        width: 'auto'
    }
}));

const NestedMenu = ({ primary, basePath, icon, children, level = 1, classes }) => {
    const translate = useTranslate();
    const location = useLocation();
    const defaultClasses = useStyles();
    const sidebarOpen = useSelector(state => state.admin.ui.sidebarOpen);

    const [openTooltip, setOpenTooltip] = useState(false);
    const handleCloseTooltip = () => {
        setOpenTooltip(false);
    };
    const handleOpenTooltip = () => {
        if (!sidebarOpen) {
            setOpenTooltip(true);
        }
    };

    const [openNested, setOpenNested] = useState(false);
    const [forceCloseNested, setForceOpenNested] = useState(false);
    const handleClickOpenNested = () => {
        setOpenNested(!openNested);
        setForceOpenNested(!forceCloseNested);
    };

    const sidebarOpenEventListener = useCallback(() => {
        if (!sidebarOpen && openNested) {
            setOpenNested(false);
        } else if (sidebarOpen && !openNested && !forceCloseNested) {
            let pathnameArray = location.pathname.split('/');
            pathnameArray.shift();
            const currentBasePath = pathnameArray.slice(0, level + 1);
            const basePathArray = basePath.split('/');

            const openNested = sidebarOpen &&
                currentBasePath.length === basePathArray.length &&
                currentBasePath.every((e, idx) => e === basePathArray[idx]);
            if (openNested) {
                setOpenNested(true);
            }
        }
    }, [sidebarOpen, openNested, forceCloseNested, location, basePath, level]);

    sidebarOpenEventListener();

    return (
        <div>
            <Tooltip placement="right" title={primary} open={openTooltip} onClose={handleCloseTooltip} onOpen={handleOpenTooltip}>
                <MenuItem onClick={handleClickOpenNested} classes={classes} button>
                    {icon ?
                        <ListItemIcon classes={{ root: defaultClasses.rootListItemIcon }}>{icon}</ListItemIcon> : null
                    }
                    <ListItemText primary={primary} />
                    {openNested ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </MenuItem>
            </Tooltip>
            <Collapse in={openNested} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children.map(item => {
                        if (item.children) {
                            return (
                                <NestedMenu
                                    primary={translate(item.translation.key, item.translation.options)}
                                    basePath={item.basePath}
                                    icon={item.icon}
                                    children={item.children}
                                    level={level + 1}
                                    classes={{ root: defaultClasses.nested }}
                                    key={item.name}
                                />
                            );
                        }
                        return (
                            <CustomMenuItemLink
                                to={item.to}
                                primary={translate(item.translation.key, item.translation.options)}
                                icon={item.icon}
                                classes={{ root: defaultClasses.nested }}
                                key={item.name}
                            />
                        );
                    })}
                </List>
            </Collapse>
        </div>
    );
};

const CustomMenu = ({ logout }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const sidebarOpen = useSelector(state => state.admin.ui.sidebarOpen);
    const { permissions, loaded } = usePermissions();

    let menu = [];
    const verifyAccess = (item, permissions, parent = null) => {
        const action = item.action ? item.action : null;
        let subject = parent && parent.subject ? parent.subject : item.subject;
        subject = item.subject ? item.subject : subject;

        return !!(permissions.find(permission =>
            (!action || (permission.action === item.action)) && (permission.subject === subject)
        ));
    };

    const verifyAccessToChildren = (parent, permissions) => {
        let childrenVerified = [];
        parent.children.forEach(item => {
            if (item.isRestricted || parent.isRestricted) {
                if (verifyAccess(item, permissions, parent)) {
                    if (item.children) {
                        item.children = verifyAccessToChildren(item, permissions);
                        if (item.children.length > 0) childrenVerified.push(item);
                    } else {
                        childrenVerified.push(item);
                    }
                }
            } else if (item.children) {
                item.children = verifyAccessToChildren(item, permissions);
                if (item.children.length > 0) childrenVerified.push(item);
            } else if (!item.isRestricted) {
                childrenVerified.push(item);
            }
        });
        return childrenVerified;
    };

    if (loaded) {
        menu = verifyAccessToChildren(getMenu(), permissions);
    }

    return (
        <div>
            <List className={classes.root}>
                {loaded && menu.map(item => {
                    if (item.children) {
                        return (
                            <NestedMenu
                                primary={translate(item.translation.key, item.translation.options)}
                                basePath={item.basePath}
                                icon={item.icon}
                                children={item.children}
                                level={0}
                                key={item.name}
                            />
                        );
                    }
                    return (
                        <CustomMenuItemLink
                            to={item.to}
                            primary={translate(item.translation.key, item.translation.options)}
                            icon={item.icon}
                            key={item.name}
                        />
                    );
                })}
                {!isXSmall &&
                    <ListItem style={{ height: '40px' }} />
                }
                {sidebarOpen ? (
                    <ListItem className={classes.logo} style={{ paddingBottom: 10 }}>
                        <Typography>Born in</Typography>
                        <a href="https://www.33sur.cl/" target="_blank" rel="noopener noreferrer">
                            <img src={logo} width="100px" alt="33 Grados Sur" />
                        </a>
                    </ListItem>
                ) : (
                    <ListItem className={classes.logo} style={{ paddingBottom: 15 }}>
                        <a href="https://www.33sur.cl/" target="_blank" rel="noopener noreferrer">
                            <img src={icon} width="30px" alt="33 Grados Sur" />
                        </a>
                    </ListItem>
                )}
            </List>
            {isXSmall && logout}
            {isXSmall &&
                <ListItem style={{ height: '60px' }} />
            }
        </div>
    );
};

export default CustomMenu;
