import * as React from 'react';
import {
    Create, BooleanInput, TextInput, TopToolbar, FormDataConsumer, FormWithRedirect, Toolbar, useTranslate, maxLength,
    required, number, minValue, maxValue, useNotify, useRedirect, useRefresh
} from 'react-admin';
import { Grid } from '@material-ui/core';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import { CustomListButton } from '../../custom/CustomButton';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import CustomReferenceAutocompleteArrayInput from "../../custom/CustomReferenceAutocompleteArrayInput";

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    },
    input: {
        marginTop: '8px',
        marginBottom: '4px'
    },
});

const OperatorCreateActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
        </TopToolbar>
    );
};

const operatorCodeNameChainIdUniqueValidation = async (value, allValues) => {
    try {
        if (allValues.operatorCode !== undefined && allValues.chainId !== undefined) {
            const params = {
                operatorCode: allValues.operatorCode,
                name: allValues.name || null,
                chainId: allValues.chainId
            }
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/operators?${queryString}`);
            const { data: operators } = response.data;
            if (operators.length > 0) {
                return 'resources.operators.crud.create.validation.operatorCodeNameChainIdUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const storesInChainValidation = async (value, allValues) => {
    try {
        if (value && value.length > 0) {
            const queryString = stringify({ id: value }, { strictNullHandling: true });
            const response = await axios.get(`/stores?${queryString}`);
            const { data: stores } = response.data;
            if (stores.length > 0 && stores.find(store => store.chainId !== allValues.chainId)) {
                return 'resources.operators.crud.create.validation.storesNotInChain';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    operatorCode: [
        number(), minValue(-2147483648), maxValue(2147483647), required(),
        operatorCodeNameChainIdUniqueValidation
    ],
    name: [maxLength(255), required(), operatorCodeNameChainIdUniqueValidation],
    type: [], // choices
    chainId: [required(), operatorCodeNameChainIdUniqueValidation],
    storeIds: [storesInChainValidation]
};

const OperatorCreate = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        notify(`resources.operators.crud.create.notification.success`, 'success');
        redirect(`/operators/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.operators.crud.create.notification.failed`, 'error');
    };

    return (
        <Create
            actions={<OperatorCreateActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="operatorCode"
                                    resource="operators"
                                    validate={validations.operatorCode}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="operators"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="type"
                                    resource="operators"
                                    label="resources.operators.fields.type.name"
                                    validate={validations.type}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="chainId"
                                    reference="chains"
                                    originSource="id"
                                    label={translate('resources.operators.fields.chain')}
                                    optionText="name"
                                    validate={validations.chainId}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormDataConsumer>
                                    {({ formData }) => (
                                        <CustomReferenceAutocompleteArrayInput
                                            source="storeIds"
                                            reference="stores"
                                            originSource="id"
                                            label={translate('resources.operators.fields.stores.name')}
                                            optionText="storeCode"
                                            withNull={false}
                                            validate={validations.storeIds}
                                            additionalFilter={
                                                formData.chainId ?
                                                    { chainId: formData.chainId } :
                                                    null
                                            }
                                            fullWidth={true}
                                            className={classes.input}
                                        />
                                    )}
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput
                                    source="inReport"
                                    resource="operators"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Toolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                        />
                    </form>
                )}
            />
        </Create>
    );
};

export default OperatorCreate;
