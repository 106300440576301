import { stringify } from 'qs';
import generalConfig from '../config/generalConfig';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import { FetchClient } from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';
import { AuthenticationClient } from '@feathersjs/authentication-client';
import axios from './axiosClient';

const app = feathers();

const restClientOptions = {
    customQueryOperators: ['$like']
};

const authClientOptions = {
    storageKey: 'token',
    authenticate: {
        strategy: 'local'
    },
    userKey: 'user',
    permissionsKey: 'permissions', // The key in localStorage used to store permissions from decoded JWT
    permissionsField: 'permissions', // The key in the decoded JWT containing the user's role
    usernameField: 'email',
    passwordField: 'password',
    redirectTo: '/login',
    logoutOnForbidden: false
};

class CustomFetchClient extends FetchClient {
    getQuery(query) {
        if (Object.keys(query).length !== 0) {
            const queryString = stringify(query, {
                strictNullHandling: true
            });
            return `?${queryString}`;
        }
        return '';
    }
}

class CustomAuthenticationClient extends AuthenticationClient {
    setAccessToken(accessToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        return super.setAccessToken(accessToken);
    }

    removeAccessToken() {
        delete axios.defaults.headers.common['Authorization'];
        return super.removeAccessToken();
    }

    reAuthenticate(force, strategy) {
        const accessToken = localStorage.getItem(this.options.storageKey);
        if (accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }
        return super.reAuthenticate(force, strategy);
    }

    logout() {
        delete axios.defaults.headers.common['Authorization'];
        return super.logout();
    }
}

app.configure(auth({
    ...authClientOptions,
    Authentication: CustomAuthenticationClient
}));

const restClient = rest(generalConfig.apiUrl);

const feathersClient = app.configure(restClient.fetch(window.fetch, CustomFetchClient));

export { restClientOptions, authClientOptions };
export default feathersClient;
