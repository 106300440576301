import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, Marker, Popup, Rectangle, TileLayer, Tooltip, useMap, useMapEvent } from 'react-leaflet';
import { useEventHandlers } from '@react-leaflet/core';
import { Button, Grid, IconButton } from '@material-ui/core';
import {
    Lock as LockIcon,
    MyLocation as MyLocationIcon,
    OpenWith as OpenWithIcon
} from '@material-ui/icons';
import axios from 'axios';
import { stringify } from 'qs';

const DraggableMarker = ({ position, setPosition }) => {
    const [draggable, setDraggable] = useState(false);
    const [markerIsClicked, setMarkerIsClicked] = useState(false);
    const [positionData, setPositionData] = useState();
    const [loading, setLoading] = useState(false);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(() => ({
        click() {
            setMarkerIsClicked(true);
        },
        dragend() {
            const marker = markerRef.current;
            if (marker != null) {
                setPosition(marker.getLatLng());
            }
        }
    }), [setPosition]);

    const toggleDraggable = useCallback(() => {
        setDraggable(d => !d);
    }, []);

    useEffect(() => {
        if (position) {
            setLoading(true);
            const params = {
                lat: position.lat,
                lon: position.lng,
                format: 'json',
                addressdetails: 1,
            };
            const queryString = stringify(params, { strictNullHandling: true });

            axios.get(`https://nominatim.openstreetmap.org/reverse?${queryString}`)
                .then(({ data }) => {
                    //console.log(data);
                    console.log(data.address);
                    setPositionData(data);
                })
                .catch(error => {
                    //setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [position, position.lat, position.lng]);

    return (
        <Marker
            draggable={draggable}
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}
        >
            <Popup minWidth={90}>
                <Grid container>
                    <Grid item xs={12}>
                        Dirección: {!positionData || loading ? (
                            'Cargando...'
                        ) : (
                            <strong>{positionData.display_name}</strong>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        Latitud: <strong>{position.lat}</strong>
                    </Grid>
                    <Grid item xs={12}>
                        Longitud: <strong>{position.lng}</strong>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button
                            onClick={toggleDraggable}
                            size="small"
                            color="primary"
                            startIcon={draggable ? <LockIcon /> : <OpenWithIcon />}
                        >
                            {draggable ? 'Bloquear' : 'Mover'}
                        </Button>
                    </Grid>

                </Grid>
            </Popup>
            {!markerIsClicked &&
            <Tooltip>Click para ver más</Tooltip>
            }
        </Marker>
    );
};

const MinimapBounds = ({ parentMap, zoom }) => {
    const minimap = useMap();

    // Clicking a point on the minimap sets the parent's map center
    const onClick = useCallback(
        (e) => {
            parentMap.setView(e.latlng, parentMap.getZoom())
        },
        [parentMap],
    );
    useMapEvent('click', onClick);

    // Keep track of bounds in state to trigger renders
    const [bounds, setBounds] = useState(parentMap.getBounds());
    const onChange = useCallback(() => {
        setBounds(parentMap.getBounds());
        // Update the minimap's view to match the parent map's center and zoom
        minimap.setView(parentMap.getCenter(), zoom);
    }, [minimap, parentMap, zoom]);

    // Listen to events on the parent map
    const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [onChange]);
    useEventHandlers({ instance: parentMap }, handlers);

    return (
        <Rectangle bounds={bounds} pathOptions={{ weight: 1 }} />
    );
};

const MinimapControl = () => {
    const parentMap = useMap();

    // Memoize the minimap so it's not affected by position changes
    const minimap = useMemo(() => (
        <MapContainer
            style={{ height: 80, width: 80 }}
            center={parentMap.getCenter()}
            zoom={0}
            dragging={false}
            doubleClickZoom={false}
            scrollWheelZoom={false}
            attributionControl={false}
            zoomControl={false}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <MinimapBounds parentMap={parentMap} zoom={0} />
        </MapContainer>
    ), [parentMap]);

    return (
        <div className="leaflet-top leaflet-right">
            <div className="leaflet-control leaflet-bar">{minimap}</div>
        </div>
    );
};

const LocationControl = ({ onClick }) => {
    const map = useMap();

    const handleClick = event => {
        onClick(event, map);
    };

    return (
        <div className="leaflet-bottom leaflet-left">
            <div className="leaflet-control leaflet-bar" style={{ backgroundColor: 'white' }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    style={{ backgroundColor: 'white' }}
                >
                    <MyLocationIcon />
                </IconButton>
            </div>
        </div>
    );
};

const MapInput = ({ className, position, setPosition }) => {
    const getCurrentPosition = (options = {}) => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    };

    return (
        <MapContainer
            center={position}
            zoom={13}
            scrollWheelZoom={true}
            //className={classes.map}
            className={className}
        >
            <TileLayer
                attribution='&copy; <a href="https://osm.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker
                position={position}
                setPosition={setPosition}
            />
            <LocationControl
                onClick={(event, map) => {
                    getCurrentPosition()
                        .then(({ coords }) => {
                            setPosition({
                                lat: coords.latitude,
                                lng: coords.longitude
                            });
                            map.flyTo({
                                lat: coords.latitude,
                                lng: coords.longitude
                            }, map.getZoom());
                        });
                }}
            />
            <MinimapControl />
        </MapContainer>
    );
};

export default MapInput;
