import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { SelectInput, NumberInput, NumberField, useTranslate, useLocale } from 'react-admin';
import {
    Button, Grid, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress
} from '@material-ui/core';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import PercentageField from '../../../custom/PercentageField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import _ from 'lodash';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: { maxHeight: '300px' },
    totalCell: { fontWeight: 500 }
}));

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="orderType"
                                    label="pos.generic.order.orderType.name"
                                    choices={[
                                        { id: 'ASC', name: 'pos.generic.order.orderType.asc' },
                                        { id: 'DESC', name: 'pos.generic.order.orderType.desc' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="limit"
                                    label="pos.generic.limit"
                                    min="1"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.tenders.sections.perTransaction')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                businessDayDate: getBusinessDayDateDefaultValue(),
                                orderType: 'DESC',
                                limit: 100
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.tenders.sections.perTransaction')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    mainTable: DataTable
                }}
            />
        </div>
    );
};

const Chart = ({ data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles({ exportable });

    const [processedData, setProcessedData] = useState([]);

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            let processedData = data.map(e => ({
                id: e.tenderQuantity,
                label: e.tenderQuantity,
                value: e.transactionPercentage
            }));
            setProcessedData(processedData);
        }
    }, [data, loading, cancel]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const tooltip = ({ datum }) => {
        let { id, color, value } = datum;

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center',
                    background: 'white',
                    borderRadius: '2px',
                    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
                    padding: '5px 9px'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <span>
                    {id}: <strong>{value.toLocaleString(locale)} %</strong>
                </span>
            </div>
        );
    };

    return (
        <div className={classes.chart}>
            <ResponsivePie
                data={processedData}
                margin={{ top: 40, bottom: 40, right: 200, left: 80 }}
                sortByValue={true}
                innerRadius={0.5}
                padAngle={0.7}
                borderWidth={1}
                colors={{ scheme: 'paired' }}
                tooltip={tooltip}
                //arcLinkLabel={d => `${d.id}: ${d.value}%`}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextXOffset={6}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsOffset={0}
                arcLinkLabelsDiagonalLength={16}
                arcLinkLabelsStraightLength={24}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabel={d => `${d.value.toLocaleString(locale)} %`}
                arcLabelsSkipAngle={15}
                arcLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        translateX: 140,
                        itemsSpacing: 2,
                        itemWidth: 80,
                        itemHeight: 20,
                        symbolSize: 14
                    }
                ]}
            />
        </div>
    );
};

const DataTable = ({ data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    useEffect(() => {
        setProcessingData(true);
        if (data && data.length > 0 && !loading && !cancel) {
            setProcessedData({
                data: data,
                totalQuantity: _.sumBy(data, 'transactionQuantity'),
                totalPercentage: _.round(_.sumBy(data, 'transactionPercentage'))
            });
            setProcessingData(false);
        }
    }, [data, loading, cancel]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="right">
                            {translate('pos.generic.quantityOf', { of: translate('resources.transactions.tabs.tenders') })}
                        </TableCell>
                        <TableCell align="right">
                            {translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })}
                        </TableCell>
                        <TableCell align="right">
                            {translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) })}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => (
                            <TableRow key={row.tenderQuantity}>
                                <TableCell align="right">
                                    <NumberField record={row} source="tenderQuantity" locales={locale} />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField record={row} source="transactionQuantity" locales={locale} />
                                </TableCell>
                                <TableCell align="right">
                                    <PercentageField record={row} source="transactionPercentage" />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key="total">
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.total', 1)}
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="totalQuantity"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <PercentageField
                                    record={processedData}
                                    source="totalPercentage"
                                    className={classes.totalCell}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let { businessDayDate, chainId, storeCode, terminalNumber, operatorCode, orderType, limit } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'tenders-per-transaction',
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderType: orderType,
            limit: limit
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [renderKey, businessDayDate, chainId, storeCode, terminalNumber, operatorCode, orderType, limit]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const TenderPerTransactionPie = () => {
    const translate = useTranslate();

    const filters = {
        businessDayDate: getBusinessDayDateDefaultValue(),
        orderType: 'DESC',
        limit: 100
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.tenders.sections.perTransaction')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default TenderPerTransactionPie;
