import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { BoxLegendSvg } from '@nivo/legends';
import { categoricalColorSchemes } from '@nivo/colors';
import { NumberField, TextField, useLocale, useTranslate } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import PercentageField from '../../../custom/PercentageField';
import CustomTimeInput from '../../../custom/CustomTimeInput';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import resourcesConfig from '../../../../config/resourcesConfig.json';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import moment from 'moment';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const { paired } = categoricalColorSchemes;

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: {
        maxHeight: '600px',
        maxWidth: '95vw'
    },
    totalCell: { fontWeight: 500 },
    tableColumnHighlight: { backgroundColor: theme.palette.grey['100'] }
}));

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <CustomTimeInput
                                    source="period"
                                    record={filters}
                                    label={translate('pos.datetime.period')}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SummaryTable
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                period: moment().hour(2).minutes(0).seconds(0),
                                businessDayDate: getBusinessDayDateDefaultValue()
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SummaryTable
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    summaryTable: SummaryTable,
                    mainTable: DataTable
                }}
            />
        </div>
    );
};

const Chart = ({ data, loading, cancel, error, exportable = false }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const classes = useStyles({ exportable });
    const currency = resourcesConfig.general.currency;

    const [processedData, setProcessedData] = useState([]);
    const [keys] = useState(['amountChangePercentage', 'amountChangePercentageRemaining']);

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            let processedData = [];
            const groupByValues = _.orderBy(_.uniq(data.map(e => e.terminalNumber)), [], ['asc']);
            groupByValues.forEach(groupByValue => {
                let groupByData = {
                    terminalNumber: groupByValue
                };
                let filteredData = data.find(e => e.terminalNumber === groupByValue);
                groupByData = {
                    ...groupByData,
                    amountChangePercentage: _.round(filteredData.amountChangePercentage, 4),
                    amountChangePercentageRemaining: _.round(filteredData.amountChangePercentageRemaining, 4),
                    totalTransactionQuantity: filteredData.totalTransactionQuantity,
                    totalAmountChange: filteredData.totalAmountChange,
                    totalAmountTender: filteredData.totalAmountTender,
                    startTime: filteredData.startTime,
                    endTime: filteredData.endTime,
                    transactionQuantity: filteredData.transactionQuantity,
                    amountChange: filteredData.amountChange,
                    amountTender: filteredData.amountTender,
                };
                processedData.push(groupByData);
            });
            setProcessedData(processedData);
        }
    }, [translate, data, loading, cancel]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const axisLeft = value => `${value.toLocaleString(locale)} %`;

    const label = ({ value }) => `${_.round(value).toLocaleString(locale)} %`;

    const tooltip = ({ data, value, color, id, indexValue }) => {
        const toCurrency = value => Number(value !== undefined ? value : 0).toLocaleString(locale, {
            style: 'currency',
            currency: currency,
        });

        data = {
            ...data,
            totalTransactionQuantity: data.totalTransactionQuantity.toLocaleString(locale),
            totalAmountChange: toCurrency(data.totalAmountChange),
            totalAmountTender: toCurrency(data.totalAmountTender),
            transactionQuantity: data.transactionQuantity.toLocaleString(locale),
            amountChange: toCurrency(data.amountChange),
            amountTender: toCurrency(data.amountTender)
        };

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                    <span>
                        {translate(`resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.${id}`)}
                    </span>
                    <span>
                        {translate('resources.transactions.fields.terminalNumber')}: <strong>{indexValue}</strong>
                    </span>
                    <span>
                        {translate('pos.generic.percentage')}: <strong>{value} %</strong>
                    </span>
                    {id === 'amountChangePercentage' ? (
                        <div style={{ display: 'grid' }}>
                            <span>
                                {translate('pos.datetime.period')}: <strong>{data.startTime} - {data.endTime}</strong>
                            </span>
                            <span>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.transactionQuantity')}: <strong>{data.transactionQuantity}</strong>
                            </span>
                            <span>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.amountChange')}: <strong>{data.amountChange}</strong>
                            </span>
                            <span>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.amountTender')}: <strong>{data.amountTender}</strong>
                            </span>
                        </div>
                    ) : (
                        <div style={{ display: 'grid' }}>
                            <span>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.totalTransactionQuantity')}: <strong>{data.totalTransactionQuantity}</strong>
                            </span>
                            <span>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.totalAmountChange')}: <strong>{data.totalAmountChange}</strong>
                            </span>
                            <span>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.totalAmountTender')}: <strong>{data.totalAmountTender}</strong>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const BarLegend = ({ legends, height, width }) => (
        <React.Fragment>
            {legends.map(legend => (
                <BoxLegendSvg
                    key={JSON.stringify(legend.data.map(({ id }) => id))}
                    {...legend}
                    containerHeight={height}
                    containerWidth={width}
                />
            ))}
        </React.Fragment>
    );

    return (
        <div className={classes.chart}>
            <ResponsiveBar
                data={processedData}
                label={label}
                tooltip={tooltip}
                keys={keys}
                indexBy="terminalNumber"
                margin={{
                    top: 20,
                    bottom: 60,
                    right: 150,
                    left: 80
                }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'paired' }}
                borderColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: translate('resources.transactions.fields.terminalNumber'),
                    legendPosition: 'middle',
                    legendOffset: 50
                }}
                axisLeft={{
                    format: axisLeft,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: translate('pos.generic.percentage'),
                    legendPosition: 'middle',
                    legendOffset: -60
                }}
                labelSkipWidth={24}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                layers={['grid', 'axes', 'bars', 'markers', BarLegend]}
                legends={[
                    {
                        data: keys.map((id, index) => ({
                            color: paired[index],
                            id,
                            label: translate(`resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.${id}Abbr`)
                        })),
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        itemDirection: 'left-to-right',
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        symbolSize: 14
                    }
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
            />
        </div>
    );
};

const DataTable = ({ data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const currency = resourcesConfig.general.currency;

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    useEffect(() => {
        setProcessingData(true);
        if (data && data.length > 0 && !loading && !cancel) {
            let processedData = {
                data: data.map(e => ({
                    terminalNumber: e.terminalNumber,
                    totalTransactionQuantity: e.totalTransactionQuantity,
                    totalAmountChange: _.round(e.totalAmountChange, 4),
                    totalAmountTender: _.round(e.totalAmountTender, 4),
                    startTime: e.startTime,
                    endTime: e.endTime,
                    transactionQuantity: e.transactionQuantity,
                    amountChange: _.round(e.amountChange, 4),
                    //amountTender: _.round(e.amountTender, 4),
                    amountChangePercentage: _.round(e.amountChangePercentage, 4),
                    amountChangePercentageRemaining: _.round(e.amountChangePercentageRemaining, 4),
                    avgChangePerTransaction: _.round(e.totalAmountChange / e.totalTransactionQuantity, 4),
                    //avgTenderPerTransaction: _.round(e.totalAmountTender / e.totalTransactionQuantity, 4),
                    avgChangePerTransactionInPeriod: _.round(e.amountChange / e.transactionQuantity, 4),
                    //avgTenderPerTransactionInPeriod: _.round(e.amountTender / e.transactionQuantity, 4),
                    coinsReqInPeriod: e.transactionQuantity * _.round((e.amountChange / e.transactionQuantity) % 1, 2),
                })),
                total: {
                    totalTransactionQuantity: _.sumBy(data, 'totalTransactionQuantity'),
                    totalAmountChange: _.sumBy(data, 'totalAmountChange'),
                    totalAmountTender: _.sumBy(data, 'totalAmountTender'),
                    transactionQuantity: _.sumBy(data, 'transactionQuantity'),
                    amountChange: _.sumBy(data, 'amountChange'),
                    //amountTender: _.sumBy(data, 'amountTender'),
                },
                avg: {
                    totalTransactionQuantity: _.round(_.meanBy(data, 'totalTransactionQuantity'), 4),
                    totalAmountChange: _.round(_.meanBy(data, 'totalAmountChange'), 4),
                    totalAmountTender: _.round(_.meanBy(data, 'totalAmountTender'), 4),
                    transactionQuantity: _.round(_.meanBy(data, 'transactionQuantity'), 4),
                    amountChange: _.round(_.meanBy(data, 'amountChange'), 4),
                    //amountTender: _.round(_.meanBy(data, 'amountTender'), 4),
                    amountChangePercentage:_.round(_.meanBy(data, 'amountChangePercentage'), 4),
                    amountChangePercentageRemaining: _.round(_.meanBy(data, 'amountChangePercentageRemaining'), 4)
                }
            };
            processedData.avg.changePerTransaction = _.round(_.meanBy(processedData.data, 'avgChangePerTransaction'), 4);
            //processedData.avg.tenderPerTransaction = _.round(_.meanBy(processedData.data, 'avgTenderPerTransaction'), 4);
            processedData.avg.changePerTransactionInPeriod = _.round(_.meanBy(processedData.data, 'avgChangePerTransactionInPeriod'), 4);
            //processedData.avg.tenderPerTransactionInPeriod = _.round(_.meanBy(processedData.data, 'avgTenderPerTransactionInPeriod'), 4);
            processedData.avg.coinsReqInPeriod = _.round(_.meanBy(processedData.data, 'coinsReqInPeriod'), 4)

            setProcessedData(processedData);
            setProcessingData(false);
        }
    }, [translate, data, loading, cancel]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {translate('resources.transactions.fields.terminalNumber')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.totalTransactionQuantity')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.totalAmountChange')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.totalAmountTender')}
                        </TableCell>
                        <TableCell>
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.startTime')}
                        </TableCell>
                        <TableCell>
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.endTime')}
                        </TableCell>
                        <TableCell align="right" className={classes.tableColumnHighlight}>
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.transactionQuantity')}
                        </TableCell>
                        <TableCell align="right" className={classes.tableColumnHighlight}>
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.amountChange')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.amountChangePercentage')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.amountChangePercentageRemaining')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.changePerTransaction')}
                        </TableCell>
                        <TableCell align="right" className={classes.tableColumnHighlight}>
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.changePerTransactionInPeriod')}
                        </TableCell>
                        <TableCell align="right" className={classes.tableColumnHighlight}>
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.coinsReqInPeriod')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={13} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => (
                            <TableRow key={row.terminalNumber}>
                                <TableCell>
                                    <TextField record={row} source="terminalNumber" />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField record={row} source="totalTransactionQuantity" locales={locale} />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="totalAmountChange"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="totalAmountTender"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField record={row} source="startTime" />
                                </TableCell>
                                <TableCell>
                                    <TextField record={row} source="endTime" />
                                </TableCell>
                                <TableCell align="right" className={classes.tableColumnHighlight}>
                                    <NumberField record={row} source="transactionQuantity" locales={locale} />
                                </TableCell>
                                <TableCell align="right" className={classes.tableColumnHighlight}>
                                    <NumberField
                                        record={row}
                                        source="amountChange"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <PercentageField record={row} source="amountChangePercentage"/>
                                </TableCell>
                                <TableCell align="right">
                                    <PercentageField record={row} source="amountChangePercentageRemaining"/>
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="avgChangePerTransaction"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                    />
                                </TableCell>
                                <TableCell align="right" className={classes.tableColumnHighlight}>
                                    <NumberField
                                        record={row}
                                        source="avgChangePerTransactionInPeriod"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                    />
                                </TableCell>
                                <TableCell align="right" className={classes.tableColumnHighlight}>
                                    <NumberField
                                        record={row}
                                        source="coinsReqInPeriod"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key="total">
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.total', 1)}
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData.total}
                                    source="totalTransactionQuantity"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData.total}
                                    source="totalAmountChange"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData.total}
                                    source="totalAmountTender"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell colSpan={2} />
                            <TableCell align="right">
                                <NumberField
                                    record={processedData.total}
                                    source="transactionQuantity"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData.total}
                                    source="amountChange"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell colSpan={5} />
                        </TableRow>
                        <TableRow key="avg">
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.average', 1)}
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData.avg}
                                    source="totalTransactionQuantity"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData.avg}
                                    source="totalAmountChange"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData.avg}
                                    source="totalAmountTender"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell colSpan={2} />
                            <TableCell align="right" className={classes.tableColumnHighlight}>
                                <NumberField
                                    record={processedData.avg}
                                    source="transactionQuantity"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.tableColumnHighlight}>
                                <NumberField
                                    record={processedData.avg}
                                    source="amountChange"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <PercentageField
                                    record={processedData.avg}
                                    source="amountChangePercentage"
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <PercentageField
                                    record={processedData.avg}
                                    source="amountChangePercentageRemaining"
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData.avg}
                                    source="changePerTransaction"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.tableColumnHighlight}>
                                <NumberField
                                    record={processedData.avg}
                                    source="changePerTransactionInPeriod"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.tableColumnHighlight}>
                                <NumberField
                                    record={processedData.avg}
                                    source="coinsReqInPeriod"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const SummaryTable = ({ data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const currency = resourcesConfig.general.currency;

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    useEffect(() => {
        setProcessingData(true);
        if (data && data.length > 0 && !loading && !cancel) {
            //let dayQuantity = _.meanBy(data, 'totalDayQuantity');
            let dayQuantity = _.maxBy(data, 'totalDayQuantity').totalDayQuantity;
            //console.log('dayQuantity:', dayQuantity);
            let avgChangePerTransactionInPeriod = _.meanBy(data, e => e.amountChange / e.transactionQuantity);
            let avgCoinsReqInPeriod = _.meanBy(data, 'transactionQuantity') * _.round(avgChangePerTransactionInPeriod % 1, 2);
            let avgBillsReqInPeriod = _.meanBy(data, 'transactionQuantity') * _.floor(avgChangePerTransactionInPeriod);
            setProcessedData({
                avgTransactionQuantity: _.round(_.meanBy(data, 'transactionQuantity') / dayQuantity, 4),
                avgAmountChange: _.round(_.meanBy(data, 'amountChange') / dayQuantity, 4),
                avgChangePerTransactionInPeriod: _.round(avgChangePerTransactionInPeriod, 4),
                avgCoinsReqInPeriod: _.round(avgCoinsReqInPeriod / dayQuantity),
                avgBillsReqInPeriod: _.round(avgBillsReqInPeriod / dayQuantity),
            });
            setProcessingData(false);
        }
    }, [translate, data, loading, cancel]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} align="center">
                            {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.indicatorsSummary')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        <TableRow key="avgTransactionQuantity">
                            <TableCell>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.avgTransactionQuantity')}
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="avgTransactionQuantity"
                                    locales={locale}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key="avgAmountChange">
                            <TableCell>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.avgAmountChange')}
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="avgAmountChange"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key="avgChangePerTransactionInPeriod">
                            <TableCell>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.avgChangePerTransactionInPeriod')}
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="avgChangePerTransactionInPeriod"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key="avgCoinsReqInPeriod">
                            <TableCell>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.avgCoinsReqInPeriod')}
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="avgCoinsReqInPeriod"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key="avgBillsReqInPeriod">
                            <TableCell>
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.avgBillsReqInPeriod')}
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="avgBillsReqInPeriod"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let { period, businessDayDate, chainId, storeCode, terminalNumber, operatorCode } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'changes-and-cash-by-terminal-number',
            period: moment.isMoment(period) ? period.format('HH:mm:ss') : period,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderBy: 'amountChange',
            orderType: 'DESC',
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [renderKey, period, businessDayDate, chainId, storeCode, terminalNumber, operatorCode]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const ChangesAndCashByTerminalNumberBar = () => {
    const translate = useTranslate();

    const filters = {
        period: moment().hour(2).minutes(0).seconds(0),
        businessDayDate: getBusinessDayDateDefaultValue()
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.changes.sections.changesAndCashByTerminalNumbers.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SummaryTable
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default ChangesAndCashByTerminalNumberBar;
