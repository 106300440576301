import * as React from 'react';
import { useContext } from 'react';
import { AbilityContext } from '../../Can';
import {
    Labeled, TextField, TopToolbar, TabbedShowLayout, TabbedShowLayoutTabs, Show, Tab, ReferenceManyField,
    useShowController
} from 'react-admin';
import { Grid } from '@material-ui/core';
import TerminalTypeGeneral from './TerminalTypeGeneral';
import { TerminalNumberTypeList } from '../terminalNumberTypes';
import CustomTitle from '../../layout/CustomTitle';
import { CustomListButton, CustomEditButton } from '../../custom/CustomButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { flexGrow: 1 },
    tabs: { width: 0 },
    table: { paddingBottom: '20px' }
});

const TerminalTypeShowActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
            <CustomEditButton {...props} record={props.data} />
        </TopToolbar>
    );
};

const TerminalTypeShow = props => {
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const { record } = useShowController(props);

    return (
        <Show
            title={<CustomTitle action="show" {...props} />}
            actions={<TerminalTypeShowActions />}
            className={classes.root}
            {...props}
        >
            <TabbedShowLayout
                tabs={
                    <TabbedShowLayoutTabs
                        classes={{ scrollable: classes.tabs }}
                        variant="scrollable"
                        scrollButtons="on"
                    />
                }
            >
                <Tab label="resources.terminalTypes.tabs.general">
                    <TerminalTypeGeneral />
                </Tab>
                {(record && ability.can('find', 'terminalNumberTypes')) &&
                    <Tab label="resources.terminalTypes.tabs.terminalNumberTypes" path="terminalNumberTypes">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.terminalTypes.fields.name">
                                    <TextField record={record} source="name" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} className={classes.table}>
                                <ReferenceManyField
                                    reference="terminalNumberTypes"
                                    target="id"
                                    addLabel={false}
                                    hiddenColumns={{ terminalType: true }}
                                    className={classes.table}
                                >
                                    <TerminalNumberTypeList voidTitle={true} terminalTypeId={record.id} {...props} />
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    );
};

export default TerminalTypeShow;
