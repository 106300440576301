import * as React from 'react';
import { Labeled, TextField, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';

const ChainGeneral = props => {
    const translate = useTranslate();
    const emptyText = translate('pos.generic.unknown');

    return (
        <span>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.chains.fields.name">
                        <TextField record={props.record} source="name" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.chains.fields.status">
                        <TextField record={props.record} source="status" emptyText={emptyText} />
                    </Labeled>
                </Grid>
            </Grid>
        </span>
    );
};

export default ChainGeneral;
