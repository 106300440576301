import * as React from 'react';
import { Labeled } from 'react-admin';
import { Grid } from '@material-ui/core';
import CustomField from './CustomField';
import resourcesConfig from '../../config/resourcesConfig.json';

const CustomPanel = ({ overrideRecord, record, source }) => {
    const configFilename = resourcesConfig.transactions.related[source].configFilename;
    const config = require(`../../config/${configFilename}`);
    let fieldsConfig = config.fields.filter(fieldConfig => fieldConfig.inUse && !fieldConfig.inTable);
    fieldsConfig = fieldsConfig.sort((a, b) => {
        if (!b.sort) return -1;
        if (!a.sort) return 1;
        return a.sort - b.sort;
    });

    record = overrideRecord ? overrideRecord : record;

    return (
        <Grid container>
            {fieldsConfig.map(fieldConfig => {
                if(fieldConfig.datatype.type === 'ENUM') {
                    return (
                        <Grid item xs={12} sm={6} key={fieldConfig.name}>
                            <Labeled label={`resources.transactions.fields.${source}.${fieldConfig.name}.name`}>
                                <CustomField
                                    record={record}
                                    config={fieldConfig}
                                    fieldTranslate={`resources.transactions.fields.${source}.${fieldConfig.name}`}
                                />
                            </Labeled>
                        </Grid>
                    );
                }
                return (
                    <Grid item xs={12} sm={6} key={fieldConfig.name}>
                        <Labeled label={`resources.transactions.fields.${source}.${fieldConfig.name}`}>
                            <CustomField record={record} config={fieldConfig} />
                        </Labeled>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default CustomPanel;
