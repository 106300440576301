import * as React from 'react';
import { useContext } from 'react';
import { AbilityContext } from '../../Can';
import {
    TopToolbar, TabbedShowLayout, TabbedShowLayoutTabs, Show, Tab, ReferenceManyField, useShowController
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CustomTitle from '../../layout/CustomTitle';
import TransactionSummary from './TransactionSummary';
import TransactionGeneral from './TransactionGeneral';
import {
    ItemList, DiscountList, TenderList, EpsTenderList, TaxList, ChangeList, OverrideList, DataEntryList, TillChangeList,
    UserStringList
} from './associations';
import { CustomListButton } from '../../custom/CustomButton';

const useStyles = makeStyles({
    root: { flexGrow: 1 },
    tabs: { width: 0 },
    table: { paddingBottom: '20px' }
});

const TransactionShowActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
        </TopToolbar>
    );
};

const TransactionShow = props => {
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const { record } = useShowController(props);

    return (
        <Show
            title={<CustomTitle action="show" {...props} />}
            actions={<TransactionShowActions />}
            className={classes.root}
            {...props}
        >
            <TabbedShowLayout
                tabs={
                    <TabbedShowLayoutTabs
                        classes={{ scrollable: classes.tabs }}
                        variant="scrollable"
                        scrollButtons="on"
                    />
                }
            >
                <Tab label="resources.transactions.tabs.summary.name" path="summary">
                    <TransactionSummary />
                </Tab>
                <Tab label="resources.transactions.tabs.general.name" path="general">
                    <TransactionGeneral />
                </Tab>
                {(record && record.items && record.items.length > 0 && ability.can('find', 'items')) &&
                    <Tab label="resources.transactions.tabs.items" path="items">
                        <ReferenceManyField reference="items" target="id" addLabel={false} className={classes.table}>
                            <ItemList voidTitle={true} transactionId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && record.discounts && record.discounts.length > 0 && ability.can('find', 'discounts')) &&
                    <Tab label="resources.transactions.tabs.discounts" path="discounts">
                        <ReferenceManyField reference="discounts" target="id" addLabel={false} className={classes.table}>
                            <DiscountList voidTitle={true} transactionId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && record.tenders && record.tenders.length > 0 && ability.can('find', 'tenders')) &&
                    <Tab label="resources.transactions.tabs.tenders" path="tenders">
                        <ReferenceManyField reference="tenders" target="id" addLabel={false} className={classes.table}>
                            <TenderList voidTitle={true} transactionId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && record.taxes && record.taxes.length > 0 && ability.can('find', 'taxes')) &&
                    <Tab label="resources.transactions.tabs.taxes" path="taxes">
                        <ReferenceManyField reference="taxes" target="id" addLabel={false} className={classes.table}>
                            <TaxList voidTitle={true} transactionId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && record.epsTenders && record.epsTenders.length > 0 && ability.can('find', 'epsTenders')) &&
                    <Tab label="resources.transactions.tabs.epsTenders" path="epsTenders">
                        <ReferenceManyField reference="epsTenders" target="id" addLabel={false} className={classes.table}>
                            <EpsTenderList voidTitle={true} transactionId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && record.changes && record.changes.length > 0 && ability.can('find', 'changes')) &&
                    <Tab label="resources.transactions.tabs.changes" path="changes">
                        <ReferenceManyField reference="changes" target="id" addLabel={false} className={classes.table}>
                            <ChangeList voidTitle={true} transactionId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && record.overrides && record.overrides.length > 0 && ability.can('find', 'overrides')) &&
                    <Tab label="resources.transactions.tabs.overrides" path="overrides">
                        <ReferenceManyField reference="overrides" target="id" addLabel={false} className={classes.table}>
                            <OverrideList voidTitle={true} transactionId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && record.dataEntries && record.dataEntries.length > 0 && ability.can('find', 'dataEntries')) &&
                    <Tab label="resources.transactions.tabs.dataEntries" path="dataEntries">
                        <ReferenceManyField reference="dataEntries" target="id" addLabel={false} className={classes.table}>
                            <DataEntryList voidTitle={true} transactionId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && record.tillChanges && record.tillChanges.length > 0 && ability.can('find', 'tillChanges')) &&
                    <Tab label="resources.transactions.tabs.tillChanges" path="tillChanges">
                        <ReferenceManyField reference="tillChanges" target="id" addLabel={false} className={classes.table}>
                            <TillChangeList voidTitle={true} transactionId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && record.userStrings && record.userStrings.length > 0 && ability.can('find', 'userStrings')) &&
                    <Tab label="resources.transactions.tabs.userStrings" path="userStrings">
                        <ReferenceManyField reference="userStrings" target="id" addLabel={false} className={classes.table}>
                            <UserStringList voidTitle={true} transactionId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    );
};

export default TransactionShow;
