import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NumberField, TextField, ReferenceField, BooleanField, ShowButton, useTranslate, useLocale } from 'react-admin';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
    Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Paper, Table, TableHead, TableRow, TableCell,
    TableBody, TableContainer, CircularProgress
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import CustomError from '../custom/CustomError';
import CustomDateTimeField from '../custom/CustomDateTimeField';
import CustomReferenceField from '../custom/CustomReferenceField';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import axios from '../../clients/axiosClient';
import { stringify } from 'qs';
import humanize from '../../config/humanizeDuration';
import resourcesConfig from '../../config/resourcesConfig.json';

const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxHeight: '300px',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'calc(100vw - 82px)'
        },
    },
    rowTrueStatus: { backgroundColor: 'rgb(237, 247, 237)' },
    rowFalseStatus: { backgroundColor: 'rgb(253, 236, 234)' },
    loading: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const getStatusConfig = () => {
    let statusConfig = {
        statusQuantity: 15,
        statusUnitOfTime: 'minutes'
    };
    if (resourcesConfig && resourcesConfig.general && resourcesConfig.general.health && resourcesConfig.general.health.status) {
        let {
            quantity: statusQuantity,
            unitOfTime: statusUnitOfTime
        } = resourcesConfig.general.health.status;
        statusConfig = {
            ...statusConfig,
            ...(statusQuantity && { statusQuantity }),
            ...(statusUnitOfTime && { statusUnitOfTime })
        };
    }
    return statusConfig;
};

const Status = ({ data, groupBy, componentName }) => {
    const translate = useTranslate();
    const locale = useLocale();

    let { statusQuantity, statusUnitOfTime } = getStatusConfig();
    const valueAsMilliseconds = moment.duration(statusQuantity, statusUnitOfTime).asMilliseconds();
    const duration = humanize(valueAsMilliseconds, {
        shortFormat: false,
        language: locale,
        largest: 3
    });

    let generalStatus;
    switch (groupBy) {
        case 'store.chainId':
        case 'transaction.storeCode':
            generalStatus = !data.find(statusRegister => statusRegister.status === false);
            break;
        case 'transaction.terminalNumber':
        default:
            generalStatus = !!data.find(statusRegister => statusRegister.status === true);
    }

    if (generalStatus) {
        return (
            <Alert severity="success">
                <AlertTitle>{translate('pos.health.status.success.title')}</AlertTitle>
                {translate('pos.health.status.success.message', {
                    duration: duration,
                    component: translate(`pos.health.status.success.components.${componentName}`)
                })}
            </Alert>
        );
    } else {
        return (
            <Alert severity="error">
                <AlertTitle>{translate('pos.health.status.error.title')}</AlertTitle>
                {translate('pos.health.status.error.message', {
                    duration: duration,
                    component: translate(`pos.health.status.error.components.${componentName}`)
                })}
            </Alert>
        );
    }
};

const LastTransactionField = ({ record }) => {
    return (
        <span>{_.capitalize(moment(record.lastBusinessDayDate, 'YYYY-MM-DD HH:mm:ss').fromNow())}</span>
    );
};

const Details = ({ data, groupBy, defaultExpanded }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();

    const isReferenceDeclared = useSelector(state => {
        return (typeof state.admin.resources.chains !== 'undefined' && typeof state.admin.resources.stores !== 'undefined');
    });

    const [expanded, setExpanded] = useState(defaultExpanded);
    const handleChange = () => {
        if (expanded) {
            setExpanded(false);
        } else {
            setExpanded(true);
        }
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{translate('pos.health.showDetails')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {groupBy === 'transaction.storeCode' &&
                                    <TableCell>
                                        {translate('resources.transactions.fields.storeCode')}
                                    </TableCell>
                                }
                                {groupBy === 'transaction.terminalNumber' &&
                                    <TableCell>
                                        {translate('resources.transactions.fields.terminalNumber')}
                                    </TableCell>
                                }
                                {groupBy === 'transaction.operatorCode' &&
                                    <TableCell>
                                        {translate('resources.transactions.fields.operatorCode')}
                                    </TableCell>
                                }
                                {(groupBy === 'store.chainId' || groupBy === 'transaction.storeCode') &&
                                    <TableCell>
                                        {translate('resources.stores.fields.chain')}
                                    </TableCell>
                                }
                                <TableCell>
                                    {translate('pos.health.lastTransaction')}
                                </TableCell>
                                <TableCell>
                                    {translate('pos.health.startLastBusinessDayDate')}
                                </TableCell>
                                <TableCell>
                                    {translate('pos.health.lastBusinessDayDate')}
                                </TableCell>
                                <TableCell>
                                    {translate('pos.health.status.name')}
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(row => (
                                <TableRow
                                    key={row[groupBy.replace(/.*\./, '')]}
                                    className={row.status ? classes.rowTrueStatus : classes.rowFalseStatus}
                                >
                                    {(groupBy === 'transaction.storeCode' && isReferenceDeclared) &&
                                        <TableCell>
                                            <CustomReferenceField
                                                record={row}
                                                originalSource="storeCode"
                                                injectSource="storeId"
                                                reference="stores"
                                            />
                                        </TableCell>
                                    }
                                    {groupBy === 'transaction.terminalNumber' &&
                                        <TableCell>
                                            <NumberField record={row} source="terminalNumber" locales={locale} />
                                        </TableCell>
                                    }
                                    {groupBy === 'transaction.operatorCode' &&
                                        <TableCell>
                                            <TextField record={row} source="operatorCode" />
                                        </TableCell>
                                    }
                                    {((groupBy === 'store.chainId' || groupBy === 'transaction.storeCode') && isReferenceDeclared) &&
                                        <TableCell>
                                            <ReferenceField
                                                record={row}
                                                source="chainId"
                                                reference="chains"
                                                basePath="/chains"
                                                link="show"
                                            >
                                                <TextField source="name" />
                                            </ReferenceField>
                                        </TableCell>
                                    }
                                    <TableCell>
                                        <LastTransactionField record={row} />
                                    </TableCell>
                                    <TableCell>
                                        <CustomDateTimeField
                                            record={row}
                                            source="startLastBusinessDayDate"
                                            showFormat={translate('pos.datetime.datetime')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <CustomDateTimeField
                                            record={row}
                                            source="lastBusinessDayDate"
                                            showFormat={translate('pos.datetime.datetime')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <BooleanField record={row} source="status" />
                                    </TableCell>
                                    <TableCell>
                                        {(() => {
                                            switch (groupBy) {
                                                case 'transaction.storeCode':
                                                    return (
                                                        <ShowButton to={`/stores/${row.storeId}/show/health`} />
                                                    );
                                                case 'store.chainId':
                                                    return (
                                                        <ShowButton to={`/chains/${row.chainId}/show/health`} />
                                                    );
                                                case 'transaction.terminalNumber':
                                                case 'transaction.operatorCode':
                                                default:
                                                    return null;
                                            }
                                        })()}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    );
};

const Health = ({ filters, componentName, detailsExpanded }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    componentName = componentName ? componentName : 'system';
    detailsExpanded = detailsExpanded !== undefined ? detailsExpanded : true;

    let { statusQuantity, statusUnitOfTime } = getStatusConfig();

    let { groupBy, storeCode, chainId } = filters ? filters : {};
    groupBy = groupBy ? groupBy : 'transaction.storeCode';

    useEffect(() => {
        setLoading(true);
        setError(false);

        const params = {
            groupBy: groupBy,
            storeCode: storeCode,
            chainId: chainId
        };
        const queryString = stringify(params, { strictNullHandling: true });

        axios.get(`/healths?${queryString}`)
            .then(response => {
                const { data } = response.data;
                let processedData = [];
                if (groupBy === 'store.chainId') {
                    const chainIds = _.uniq(data.map(e => e[groupBy]));
                    chainIds.forEach(chainId => {
                        const filteredData = data.filter(e => e[groupBy] === chainId);
                        const getDate = fieldName => e => moment(e[fieldName], 'YYYY-MM-DD HH:mm:ss');
                        const minLastBusinessDayDate = _.minBy(filteredData, getDate('lastBusinessDayDate')).lastBusinessDayDate;
                        const maxLastBusinessDayDate = _.maxBy(filteredData, getDate('lastBusinessDayDate')).lastBusinessDayDate;
                        const minStartLastBusinessDayDate = _.minBy(filteredData, getDate('startLastBusinessDayDate')).startLastBusinessDayDate;
                        processedData.push({
                            lastBusinessDayDate: maxLastBusinessDayDate,
                            startLastBusinessDayDate: minStartLastBusinessDayDate,
                            chainId: chainId,
                            status: moment().diff(minLastBusinessDayDate, statusUnitOfTime, true) <= statusQuantity
                        });
                    });
                    processedData = _.orderBy(processedData, ['lastBusinessDayDate'], ['asc']);
                } else {
                    data.forEach(e => {
                        processedData.push({
                            lastBusinessDayDate: e.lastBusinessDayDate,
                            startLastBusinessDayDate: e.startLastBusinessDayDate,
                            [groupBy.replace(/.*\./, '')]: e[groupBy],
                            chainId: e['store.chainId'],
                            ...(e['store.id'] !== undefined && { storeId: e['store.id'] }),
                            status: moment().diff(e.lastBusinessDayDate, statusUnitOfTime, true) <= statusQuantity
                        });
                    });
                }
                setData(processedData);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [statusUnitOfTime, statusQuantity, groupBy, storeCode, chainId]);

    if (loading) {
        return (
            <div className={classes.loading}>
                <CircularProgress/>
            </div>
        );
    }
    if (error) {
        return (
            <CustomError
                errorSecondary={translate('pos.health.errors.noHealth', {
                    component: translate(`pos.health.components.${componentName}`)
                })}
            />
        );
    }
    if (!data || data.length === 0) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('pos.health.errors.noData')}
            />
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    {translate('pos.health.name.base', {
                        component: translate(`pos.health.components.${componentName}`)
                    })}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Status data={data} groupBy={groupBy} componentName={componentName} />
            </Grid>
            <Grid item xs={12}>
                <Details data={data} groupBy={groupBy} defaultExpanded={detailsExpanded} />
            </Grid>
        </Grid>
    );
};

export default Health;
