import * as React from 'react';
import { Datagrid } from 'react-admin';
import CustomPanel from './CustomPanel';
import CustomField from './CustomField';
import resourcesConfig from '../../config/resourcesConfig.json';

const CustomDatagrid = ({ children, record, source }) => {
    const configFilename = resourcesConfig.transactions.related[source].configFilename;
    const config = require(`../../config/${configFilename}`);
    let fieldsConfig = config.fields.filter(fieldConfig => fieldConfig.inUse && fieldConfig.inTable);
    fieldsConfig = fieldsConfig.sort((a, b) => {
        if (!b.sort) return -1;
        if (!a.sort) return 1;
        return a.sort - b.sort;
    });

    let datagridProps = {};
    if (config.withPanel) {
        datagridProps.expand = <CustomPanel source={source} />;
    }

    return (
        <Datagrid
            hover={false}
            rowStyle={(record, index) => {
                if (index % 2 === 0) {
                    return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                }
            }}
            {...datagridProps}
        >
            {children && children({ record })}
            {fieldsConfig.map(fieldConfig => {
                if (fieldConfig.datatype.type === 'ENUM') {
                    return (
                        <CustomField
                            record={record}
                            config={fieldConfig}
                            label={fieldConfig.label ? fieldConfig.label : `resources.transactions.fields.${source}.${fieldConfig.name}.name`}
                            fieldTranslate={`resources.transactions.fields.${source}.${fieldConfig.name}`}
                            key={fieldConfig.name}
                        />
                    );
                }
                return (
                    <CustomField
                        record={record}
                        config={fieldConfig}
                        label={fieldConfig.label ? fieldConfig.label : `resources.transactions.fields.${source}.${fieldConfig.name}`}
                        key={fieldConfig.name}
                    />
                );
            })}
        </Datagrid>
    );
};

export default CustomDatagrid;
