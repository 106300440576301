import * as React from 'react';
import { Datagrid, List, TextField, useTranslate } from 'react-admin';
import FileFilter from './FileFilter';
import { CustomShowButton } from '../../custom/CustomButton';
import CustomBulkActionButtons from '../../custom/CustomBulkActionButtons';
import CustomDateTimeField from '../../custom/CustomDateTimeField';

const FileList = ({ voidTitle = false, storeId, chainId, filterDefaultValues, disabledFilters, hiddenColumns, ...props }) => {
    const translate = useTranslate();

    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(storeId && { storeId: storeId }),
        ...(chainId && { chainId: chainId }),
    };

    return (
        <List
            {...props}
            title={title}
            actions={null}
            bulkActionButtons={<CustomBulkActionButtons />}
            filters={
                <FileFilter
                    filter={filter}
                    filterDefaultValues={filterDefaultValues}
                    disabledFilters={disabledFilters}
                />
            }
            filter={filter}
        >
            <Datagrid
                rowClick="show"
                hover={false}
                rowStyle={(record, index) => {
                    if (index % 2 === 0) {
                        return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                    }
                }}
            >
                {(!hiddenColumns || !hiddenColumns.originalName) &&
                    <TextField source="originalName" />
                }
                {(!hiddenColumns || !hiddenColumns.extension) &&
                    <TextField source="extension" />
                }
                {(!hiddenColumns || !hiddenColumns.createdAt) &&
                    <CustomDateTimeField
                        source="createdAt"
                        showFormat={translate('pos.datetime.datetime')}
                        isUtc={true}
                    />
                }
                <CustomShowButton />
            </Datagrid>
        </List>
    );
};

export default FileList;
