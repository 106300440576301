import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { SelectInput, TextField, NumberField, useTranslate, useLocale } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs,
    Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import SwipeableViews from 'react-swipeable-views';
import ExportModal from './ExportModal';
import PercentageField from '../../../custom/PercentageField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import CustomTab from '../../../custom/CustomTab';
import CustomTabPanel from '../../../custom/CustomTabPanel';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import resourcesConfig from '../../../../config/resourcesConfig.json';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import moment from 'moment';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootDataTable: {
        backgroundColor: theme.palette.grey[100],
        [theme.breakpoints.down('xs')]: {
            width: '95vw'
        }
    },
    scrollableTabs: { width: 0 },
    swipeableViews: {
        width: '100%',
        height: '100%'
    },
    tabPanel: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    tableContainer: { maxHeight: '300px' },
    totalCell: { fontWeight: 500 }
}));

const groupByChoices = [
    {
        id: 'dayOfWeek',
        name: 'resources.transactions.statistics.tabs.generalSales.sections.timeIntervalSales.groupBy.dayOfWeek'
    },
    {
        id: 'dayOfMonth',
        name: 'resources.transactions.statistics.tabs.generalSales.sections.timeIntervalSales.groupBy.dayOfMonth'
    },
    {
        id: 'weekOfYear',
        name: 'resources.transactions.statistics.tabs.generalSales.sections.timeIntervalSales.groupBy.weekOfYear'
    },
    {
        id: 'monthOfYear',
        name: 'resources.transactions.statistics.tabs.generalSales.sections.timeIntervalSales.groupBy.monthOfYear'
    }
];

const dateFormatter = (groupBy, value) => {
    switch (groupBy) {
        case 'monthOfYear':
            return _.capitalize(moment().month(value - 1).format('MMMM'));
        case 'dayOfMonth':
            return moment(value).format('DD');
        case 'dayOfWeek':
            return moment(value).format('HH:00');
        default:
            return value;
    }
};

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="groupBy"
                                    label="pos.generic.groupBy"
                                    choices={groupByChoices}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="quantifier"
                                    label="pos.generic.quantifier"
                                    choices={[
                                        { id: 'quantity', name: 'pos.generic.customers' },
                                        { id: 'amount', name: 'pos.generic.amount' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="terminalTypeId"
                                    record={filters}
                                    reference="terminalTypes"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.transactions.fields.terminalType')}
                                    withNull={true}
                                    helperText={false}
                                    //groupByField="chainId"
                                    additionalFilter={{
                                        ...(form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                        _.isArray(form.getFieldState('chainId').value) ? {
                                            chainId: form.getFieldState('chainId').value
                                        } : {}),
                                        ...(form.getFieldState('storeCode') && form.getFieldState('storeCode').value &&
                                        _.isArray(form.getFieldState('storeCode').value) ? {
                                            storeCode: form.getFieldState('storeCode').value
                                        } : {})
                                    }}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || !data.data || data.data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.generalSales.sections.timeIntervalSales.name', {
                    group_by: translate(groupByChoices.find(e => e.id === filters.groupBy).name)
                })}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                groupBy: 'dayOfWeek',
                                quantifier: 'quantity',
                                businessDayDate: getBusinessDayDateDefaultValue()
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.generalSales.sections.timeIntervalSales.name', {
                    group_by: translate(groupByChoices.find(e => e.id === filters.groupBy).name)
                })}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    mainTable: ExportableDataTable
                }}
            />
        </div>
    );
};

const Chart = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const classes = useStyles({ exportable });
    const currency = resourcesConfig.general.currency;

    const [processedData, setProcessedData] = useState([]);
    const [currentGroupBy, setCurrentGroupBy] = useState('dayOfWeek');
    const [xFormat, setXFormat] = useState('%H:%M:%S');

    let { groupBy, quantifier } = filters;

    useEffect(() => {
        const { groupByField, data: arrayData } = data;
        if (arrayData && arrayData.length > 0 && !loading && !cancel) {
            let processedData = [];
            const groupByValues = _.orderBy(_.uniq(arrayData.map(e => e[groupByField])), [], ['desc']);
            if (!['monthOfYear', 'weekOfYear'].includes(groupBy)) {
                groupByValues.forEach(groupByValue => {
                    let groupByData = arrayData.filter(e => e[groupByField] === groupByValue)
                        .map(e => ({
                            x: e.datetime,
                            y: e[quantifier]
                        }));

                    let id;
                    switch (groupByField) {
                        case 'month':
                            id = _.capitalize(moment().month(groupByValue - 1).format('MMMM'));
                            break;
                        case 'day':
                            id = _.capitalize(moment().day(groupByValue - 1).format('dddd'));
                            break;
                        default:
                            id = groupByValue;
                    }
                    processedData.push({
                        id: id,
                        data: groupByData
                    });
                });
            } else {
                processedData = [{
                    id: translate(`pos.datetime.${groupByField}`, 2),
                    data: arrayData.map(e => ({
                        x: e[groupByField],
                        y: e[quantifier]
                    }))
                }];
            }

            setProcessedData(processedData);
            setCurrentGroupBy(groupBy);
            switch (groupBy) {
                case 'dayOfMonth':
                    setXFormat('%d');
                    break;
                case 'dayOfWeek':
                    setXFormat('%H:%M:%S');
                    break;
                default:
                    setXFormat();
            }
        }
    }, [translate, data, loading, cancel, groupBy, quantifier]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || !data.data || data.data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const axisBottom = value => dateFormatter(currentGroupBy, value);

    const axisLeft = value => {
        if (quantifier === 'amount') {
            return Number(value).toLocaleString(locale, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
        }
        return value.toLocaleString(locale);
    };

    const bottomLegend = currentGroupBy => {
        switch (currentGroupBy) {
            case 'monthOfYear':
                return translate('pos.datetime.month', 1);
            case 'weekOfYear':
                return translate('pos.datetime.week', 1);
            case 'dayOfMonth':
                return translate('pos.datetime.day', 1);
            default:
            case 'dayOfWeek':
                return translate('pos.datetime.hour', 1);
        }
    };

    const tooltip = ({ point }) => {
        let { serieId, borderColor, data } = point;
        let { x, y } = data;

        if (quantifier === 'amount') {
            y = Number(y).toLocaleString(locale, {
                style: 'currency',
                currency: currency,
            });
        } else {
            y = y.toLocaleString(locale);
        }

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center',
                    background: 'white',
                    borderRadius: '2px',
                    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
                    padding: '5px 9px'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: borderColor,
                        marginRight: '7px'
                    }}
                > </span>
                <span>
                    {serieId} (<strong>{dateFormatter(currentGroupBy, x)}</strong>): <strong>{y}</strong>
                </span>
            </div>
        );
    };

    return (
        <div className={classes.chart}>
            <ResponsiveLine
                data={processedData}
                tooltip={tooltip}
                margin={{
                    top: 50,
                    bottom: 120,
                    right: 130,
                    left: quantifier === 'amount' ? 100 : 60
                }}
                xScale={{
                    type: ['monthOfYear', 'weekOfYear'].includes(currentGroupBy) ? 'linear' : 'time',
                    format: xFormat,
                    useUTC: false,
                    ...(['monthOfYear', 'weekOfYear'].includes(currentGroupBy) && {
                        min: 1
                    })
                }}
                format={xFormat}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto'
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    format: axisBottom,
                    orient: 'bottom',
                    tickRotation: -45,
                    legend: bottomLegend(currentGroupBy),
                    legendOffset: 50,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    format: axisLeft,
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: translate(`pos.generic.${quantifier}`),
                    legendPosition: 'middle',
                    legendOffset: quantifier === 'amount' ? -80 : -40
                }}
                curve="monotoneX"
                colors={{ scheme: 'paired' }}
                pointSize={5}
                pointColor="white"
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                useMesh={true}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        itemDirection: 'left-to-right',
                        itemsSpacing: 2,
                        itemWidth: 80,
                        itemHeight: 20,
                        symbolSize: 14
                    }
                ]}
            />
        </div>
    );
};

const processData = ({ data, loading, cancel, groupBy, quantifier, currentGroupBy, setProcessingData, setProcessedData, setCurrentGroupBy, setGroupByValues, setValue = null }) => {
    setProcessingData(true);
    if (setValue) setValue(0);
    setCurrentGroupBy(groupBy);
    const { groupByField, data: arrayData } = data;
    if (arrayData && arrayData.length > 0 && !loading && !cancel) {
        if (!['monthOfYear', 'weekOfYear'].includes(groupBy)) {
            let processedData = [];
            const groupByValues = _.orderBy(_.uniq(arrayData.map(e => e[groupByField])), [], ['asc']);
            groupByValues.forEach(groupByValue => {
                let filteredData = arrayData.filter(e => e[groupByField] === groupByValue);
                let total = _.sumBy(filteredData, quantifier);
                processedData[groupByValue] = {
                    data: filteredData.map(e => ({
                        datetime: e.datetime,
                        [quantifier]: e[quantifier],
                        percentage: _.round(e[quantifier] / (total ? total : 1) * 100, 4)
                    })),
                    total: total
                };
                processedData[groupByValue].totalPercentage = _.round(_.sumBy(processedData[groupByValue].data, 'percentage'));

                if (groupBy === 'dayOfWeek') {
                    processedData[groupByValue].data.forEach((e, index) => {
                        let startRange = moment.duration(e.datetime);
                        let endRange = moment.duration(e.datetime).add(60, 'minutes');
                        if (index !== 0) {
                            startRange = startRange.add(1, 'minutes');
                        }
                        startRange = moment.utc(startRange.asMilliseconds()).format('HH:mm:ss');
                        endRange = moment.utc(endRange.asMilliseconds()).format('HH:mm:ss');
                        e.datetime =`${startRange} - ${endRange}`;
                    });
                }
            });

            setGroupByValues(groupByValues);
            setProcessedData(processedData);
            setProcessingData(false);
        } else {
            let total = _.sumBy(arrayData, quantifier);
            let processedData = {
                data: arrayData.map(e => ({
                    [groupByField]: dateFormatter(currentGroupBy, e[groupByField]),
                    [quantifier]: e[quantifier],
                    percentage: _.round(e[quantifier] / (total ? total : 1) * 100, 4)
                })),
                total: total
            };
            processedData.totalPercentage = _.round(_.sumBy(processedData.data, 'percentage'));
            setProcessedData(processedData);
            setProcessingData(false);
        }
    }
};

const DataTable = ({ filters, data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const theme = useTheme();
    const currency = resourcesConfig.general.currency;

    const [value, setValue] = useState(0);
    const [groupByValues, setGroupByValues] = useState();
    const [currentGroupBy, setCurrentGroupBy] = useState('dayOfWeek');
    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { groupBy, quantifier } = filters;

    useEffect(() => {
        processData({
            data, loading, cancel, groupBy, quantifier, currentGroupBy, setProcessingData, setProcessedData,
            setCurrentGroupBy, setGroupByValues, setValue
        });
    }, [data, loading, cancel, groupBy, quantifier, currentGroupBy]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = index => {
        setValue(index);
    };

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (((!data || !data.data || data.data.length === 0 || !processedData) &&
        ((!groupByValues || !processedData.length === 0) ||
        (!processedData.data || !processedData.data.length === 0))) || cancel
    ) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress/>
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const tabLabel = groupByValue => {
        switch (data.groupByField) {
            case 'month':
                return _.capitalize(moment().month(groupByValue - 1).format('MMMM'));
            case 'day':
                return _.capitalize(moment().day(groupByValue - 1).format('dddd'));
            default:
                return groupByValue;
        }
    };

    const firstColumnTitle = currentGroupBy => {
        switch (currentGroupBy) {
            case 'monthOfYear':
                return translate('pos.datetime.month', 1);
            case 'weekOfYear':
                return translate('pos.datetime.week', 1);
            case 'dayOfMonth':
                return translate('pos.datetime.day', 1);
            default:
            case 'dayOfWeek':
                return translate('pos.datetime.hour', 1);
        }
    };

    if (!['monthOfYear', 'weekOfYear'].includes(currentGroupBy)) {
        return (
            <div className={classes.rootDataTable}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    orientation="horizontal"
                    variant="scrollable"
                    scrollButtons="on"
                    classes={{ scrollable: classes.scrollableTabs }}
                >
                    {(loading || processingData) ? (
                        <CustomTab label={<CircularProgress size={25} />} />
                    ) : (groupByValues.map(groupByValue => (
                        <CustomTab
                            label={tabLabel(groupByValue)}
                            index={groupByValues.indexOf(groupByValue)}
                            key={`tab-${groupByValue}`}
                        />
                    )))}
                </Tabs>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                    enableMouseEvents={true}
                    resistance={true}
                    className={classes.swipeableViews}
                >
                    {groupByValues.map(groupByValue => (
                        <CustomTabPanel
                            value={value}
                            index={groupByValues.indexOf(groupByValue)}
                            key={`tabpanel-${groupByValue}`}
                            dir={theme.direction}
                            className={classes.tabPanel}
                        >
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {firstColumnTitle(currentGroupBy)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {quantifier === 'amount' ? (
                                                    translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })
                                                ) : (
                                                    `${translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) })} ({translate('pos.generic.customers')})
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {(loading || processingData) ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={3} align="center">
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {processedData[groupByValue].data.map(row => (
                                                <TableRow key={`${groupByValue}-${row.datetime}`}>
                                                    <TableCell>
                                                        <TextField record={row} source="datetime" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {quantifier === 'amount' ? (
                                                            <NumberField
                                                                record={row}
                                                                source={quantifier}
                                                                locales={locale}
                                                                options={{style: 'currency', currency: currency}}
                                                            />
                                                        ) : (
                                                            <NumberField
                                                                record={row}
                                                                source={quantifier}
                                                                locales={locale}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <PercentageField record={row} source="percentage" />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow key={`total-${groupByValue}`}>
                                                <TableCell className={classes.totalCell}>
                                                    {translate('pos.generic.total', 1)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {quantifier === 'amount' ? (
                                                        <NumberField
                                                            record={processedData[groupByValue]}
                                                            source="total"
                                                            locales={locale}
                                                            options={{style: 'currency', currency: currency}}
                                                            className={classes.totalCell}
                                                        />
                                                    ) : (
                                                        <NumberField
                                                            record={processedData[groupByValue]}
                                                            source="total"
                                                            locales={locale}
                                                            className={classes.totalCell}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <PercentageField
                                                        record={processedData[groupByValue]}
                                                        source="totalPercentage"
                                                        className={classes.totalCell}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </CustomTabPanel>
                    ))}
                </SwipeableViews>
            </div>
        );
    }

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {firstColumnTitle(currentGroupBy)}
                        </TableCell>
                        <TableCell align="right">
                            {quantifier === 'amount' ? (
                                translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })
                            ) : (
                                `${translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                            )}
                        </TableCell>
                        <TableCell align="right">
                            {translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) })} ({translate('pos.generic.customers')})
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data && processedData.data.map(row => (
                            <TableRow key={row[data.groupByField]}>
                                <TableCell>
                                    <TextField record={row} source={data.groupByField} />
                                </TableCell>
                                <TableCell align="right">
                                    {quantifier === 'amount' ? (
                                        <NumberField
                                            record={row}
                                            source={quantifier}
                                            locales={locale}
                                            options={{style: 'currency', currency: currency}}
                                        />
                                    ) : (
                                        <NumberField record={row} source={quantifier} locales={locale} />
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <PercentageField record={row} source="percentage" />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key="total">
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.total', 1)}
                            </TableCell>
                            <TableCell align="right">
                                {quantifier === 'amount' ? (
                                    <NumberField
                                        record={processedData}
                                        source="total"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                        className={classes.totalCell}
                                    />
                                ) : (
                                    <NumberField
                                        record={processedData}
                                        source="total"
                                        locales={locale}
                                        className={classes.totalCell}
                                    />
                                )}
                            </TableCell>
                            <TableCell align="right">
                                <PercentageField
                                    record={processedData}
                                    source="totalPercentage"
                                    className={classes.totalCell}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const ExportableDataTable = ({ filters, data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const currency = resourcesConfig.general.currency;

    const [groupByValues, setGroupByValues] = useState();
    const [currentGroupBy, setCurrentGroupBy] = useState('dayOfWeek');
    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { groupBy, quantifier } = filters;

    useEffect(() => {
        processData({
            data, loading, cancel, groupBy, quantifier, currentGroupBy, setProcessingData, setProcessedData,
            setCurrentGroupBy, setGroupByValues
        });
    }, [data, loading, cancel, groupBy, quantifier, currentGroupBy]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (((!data || !data.data || data.data.length === 0 || !processedData) &&
        ((!groupByValues || !processedData.length === 0) ||
            (!processedData.data || !processedData.data.length === 0))) || cancel
    ) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress/>
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const firstHeaderLabel = groupByValue => {
        switch (data.groupByField) {
            case 'month':
                return _.capitalize(moment().month(groupByValue - 1).format('MMMM'));
            case 'day':
                return _.capitalize(moment().day(groupByValue - 1).format('dddd'));
            default:
                return groupByValue;
        }
    };

    const firstColumnTitle = currentGroupBy => {
        switch (currentGroupBy) {
            case 'monthOfYear':
                return translate('pos.datetime.month', 1);
            case 'weekOfYear':
                return translate('pos.datetime.week', 1);
            case 'dayOfMonth':
                return translate('pos.datetime.day', 1);
            default:
            case 'dayOfWeek':
                return translate('pos.datetime.hour', 1);
        }
    };

    if (!['monthOfYear', 'weekOfYear'].includes(currentGroupBy)) {
        return (
            <TableContainer component={Paper}>
                {groupByValues.map(groupByValue => (
                    <Table size="small" key={groupByValue}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={3}>
                                    {firstHeaderLabel(groupByValue)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    {firstColumnTitle(currentGroupBy)}
                                </TableCell>
                                <TableCell align="right">
                                    {quantifier === 'amount' ? (
                                        translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })
                                    ) : (
                                        `${translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    {translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) })} ({translate('pos.generic.customers')})
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {(loading || processingData) ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {processedData[groupByValue].data.map(row => (
                                    <TableRow key={`${groupByValue}-${row.datetime}`}>
                                        <TableCell>
                                            <TextField record={row} source="datetime" />
                                        </TableCell>
                                        <TableCell align="right">
                                            {quantifier === 'amount' ? (
                                                <NumberField
                                                    record={row}
                                                    source={quantifier}
                                                    locales={locale}
                                                    options={{style: 'currency', currency: currency}}
                                                />
                                            ) : (
                                                <NumberField
                                                    record={row}
                                                    source={quantifier}
                                                    locales={locale}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            <PercentageField record={row} source="percentage" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow key={`total-${groupByValue}`}>
                                    <TableCell className={classes.totalCell}>
                                        {translate('pos.generic.total', 1)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {quantifier === 'amount' ? (
                                            <NumberField
                                                record={processedData[groupByValue]}
                                                source="total"
                                                locales={locale}
                                                options={{style: 'currency', currency: currency}}
                                                className={classes.totalCell}
                                            />
                                        ) : (
                                            <NumberField
                                                record={processedData[groupByValue]}
                                                source="total"
                                                locales={locale}
                                                className={classes.totalCell}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <PercentageField
                                            record={processedData[groupByValue]}
                                            source="totalPercentage"
                                            className={classes.totalCell}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                ))}
            </TableContainer>
        );
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {firstColumnTitle(currentGroupBy)}
                        </TableCell>
                        <TableCell align="right">
                            {quantifier === 'amount' ? (
                                translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })
                            ) : (
                                `${translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                            )}
                        </TableCell>
                        <TableCell align="right">
                            {translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) })} ({translate('pos.generic.customers')})
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data && processedData.data.map(row => (
                            <TableRow key={row[data.groupByField]}>
                                <TableCell>
                                    <TextField record={row} source={data.groupByField} />
                                </TableCell>
                                <TableCell align="right">
                                    {quantifier === 'amount' ? (
                                        <NumberField
                                            record={row}
                                            source={quantifier}
                                            locales={locale}
                                            options={{style: 'currency', currency: currency}}
                                        />
                                    ) : (
                                        <NumberField record={row} source={quantifier} locales={locale} />
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <PercentageField record={row} source="percentage" />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key="total">
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.total', 1)}
                            </TableCell>
                            <TableCell align="right">
                                {quantifier === 'amount' ? (
                                    <NumberField
                                        record={processedData}
                                        source="total"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                        className={classes.totalCell}
                                    />
                                ) : (
                                    <NumberField
                                        record={processedData}
                                        source="total"
                                        locales={locale}
                                        className={classes.totalCell}
                                    />
                                )}
                            </TableCell>
                            <TableCell align="right">
                                <PercentageField
                                    record={processedData}
                                    source="totalPercentage"
                                    className={classes.totalCell}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let {
        groupBy, quantifier, businessDayDate, chainId, storeCode, terminalTypeId, terminalNumber, operatorCode
    } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'general-group-by-time-interval',
            groupBy: groupBy,
            quantifier: quantifier,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalTypeId: terminalTypeId,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { groupByField, data } = response.data;
                setData(data.length > 0 ? ({ groupByField, data }) : {});
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        renderKey, groupBy, quantifier, businessDayDate, chainId, storeCode, terminalTypeId, terminalNumber, operatorCode
    ]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const TransactionsTimeIntervalLine = () => {
    const translate = useTranslate();

    const filters = {
        groupBy: 'dayOfWeek',
        quantifier: 'quantity',
        businessDayDate: getBusinessDayDateDefaultValue()
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.generalSales.sections.timeIntervalSales.name', {
                                    group_by: translate(groupByChoices.find(e => e.id === filters.groupBy).name)
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default TransactionsTimeIntervalLine;
