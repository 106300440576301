import * as React from 'react';
import { useContext } from 'react';
import { AbilityContext } from '../../Can';
import {
    TabbedShowLayout, TabbedShowLayoutTabs, Show, Tab, TopToolbar, Labeled, ReferenceManyField, TextField,
    useShowController
} from 'react-admin';
import { Grid } from '@material-ui/core';
import GroupGeneral from './GroupGeneral';
import GroupList from './GroupList';
import { StoreList } from '../stores';
import { UserList } from '../users';
import CustomTitle from '../../layout/CustomTitle';
import { CustomListButton, CustomEditButton } from '../../custom/CustomButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { flexGrow: 1 },
    tabs: { width: 0 },
    table: { paddingBottom: '20px' }
});

const GroupShowActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
            <CustomEditButton {...props} record={props.data} />
        </TopToolbar>
    );
};

const GroupShow = props => {
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const { record } = useShowController(props);

    return (
        <Show
            title={<CustomTitle action="show" {...props} />}
            actions={<GroupShowActions />}
            className={classes.root}
            {...props}
        >
            <TabbedShowLayout
                tabs={
                    <TabbedShowLayoutTabs
                        classes={{ scrollable: classes.tabs }}
                        variant="scrollable"
                        scrollButtons="on"
                    />
                }
            >
                <Tab label="resources.groups.tabs.general">
                    <GroupGeneral />
                </Tab>
                {record &&
                    <Tab label="resources.groups.tabs.children" path="children">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.groups.fields.name">
                                    <TextField record={record} source="name" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} className={classes.table}>
                                <ReferenceManyField
                                    reference="groups"
                                    target="id"
                                    addLabel={false}
                                    filterDefaultValues={{ chainId: [record.chainId] }}
                                    disabledFilters={{ chainId: true }}
                                    hiddenColumns={{ parent: true }}
                                >
                                    <GroupList voidTitle={true} parentId={record.id} {...props} />
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </Tab>
                }
                {(record && ability.can('find', 'stores')) &&
                    <Tab label="resources.groups.tabs.stores" path="stores">
                        <ReferenceManyField
                            reference="stores"
                            target="id"
                            addLabel={false}
                            filterDefaultValues={{ chainId: [record.chainId] }}
                            disabledFilters={{ chainId: true }}
                            className={classes.table}
                        >
                            <StoreList voidTitle={true} groupId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && ability.can('find', 'users')) &&
                    <Tab label="resources.groups.tabs.users" path="users">
                        <ReferenceManyField reference="users" target="id" addLabel={false} className={classes.table}>
                            <UserList voidTitle={true} groupId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    );
};

export default GroupShow;
