import * as React from 'react';
import { useContext } from 'react';
import { AbilityContext } from '../../Can';
import {
    TopToolbar, TabbedShowLayout, TabbedShowLayoutTabs, Show, Tab, Labeled, ReferenceManyField, TextField,
    useShowController
} from 'react-admin';
import { Grid } from '@material-ui/core';
import StoreGeneral from './StoreGeneral';
import { TransactionList } from '../transactions';
import { GroupList } from '../groups';
import { UserList } from '../users';
import Health from '../../health/Health';
import CustomTitle from '../../layout/CustomTitle';
import { CustomListButton, CustomEditButton } from '../../custom/CustomButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { flexGrow: 1 },
    tabs: { width: 0 },
    table: { paddingBottom: '20px' }
});

const StoreShowActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
            <CustomEditButton {...props} record={props.data} />
        </TopToolbar>
    );
};

const StoreShow = props => {
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const { record } = useShowController(props);

    return (
        <Show
            title={<CustomTitle action="show" {...props} />}
            actions={<StoreShowActions />}
            className={classes.root}
            {...props}
        >
            <TabbedShowLayout
                tabs={
                    <TabbedShowLayoutTabs
                        classes={{ scrollable: classes.tabs }}
                        variant="scrollable"
                        scrollButtons="on"
                    />
                }
            >
                <Tab label="resources.stores.tabs.general">
                    <StoreGeneral />
                </Tab>
                {(record && ability.can('find', 'transactions')) &&
                    <Tab label="resources.stores.tabs.transactions" path="transactions">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.stores.fields.name">
                                    <TextField record={record} source="name" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Labeled label="resources.stores.fields.storeCode">
                                    <TextField record={record} source="storeCode" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} className={classes.table}>
                                <ReferenceManyField
                                    reference="transactions"
                                    target="storeCode"
                                    addLabel={false}
                                    filterDefaultValues={{ storeCode: record.storeCode }}
                                    disabledFilters={{ storeCode: true }}
                                    hiddenColumns={{ storeCode: true }}
                                >
                                    <TransactionList voidTitle={true} storeCode={record.storeCode} {...props} />
                                </ReferenceManyField>
                            </Grid>
                        </Grid>
                    </Tab>
                }
                {(record && ability.can('find', 'groups')) &&
                    <Tab label="resources.stores.tabs.groups" path="groups">
                        <ReferenceManyField
                            reference="groups"
                            target="id"
                            addLabel={false}
                            filterDefaultValues={{ chainId: [record.chainId] }}
                            disabledFilters={{ chainId: true }}
                            className={classes.table}
                        >
                            <GroupList voidTitle={true} storeId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && ability.can('find', 'users')) &&
                    <Tab label="resources.stores.tabs.users" path="users">
                        <ReferenceManyField reference="users" target="id" addLabel={false} className={classes.table}>
                            <UserList voidTitle={true} storeId={record.id} {...props} />
                        </ReferenceManyField>
                    </Tab>
                }
                {(record && ability.can('find', 'healths')) &&
                    <Tab label="pos.health.name.default" path="health">
                        <Health
                            filters={{ groupBy: 'transaction.terminalNumber', storeCode: record.storeCode }}
                            componentName="store"
                            className={classes.table}
                        />
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    );
};

export default StoreShow;
