import * as React from 'react';
import { List } from 'react-admin';
import CustomDatagrid from '../../../../custom/CustomDatagrid';
import TenderFilter from './TenderFilter';

const TenderList = ({ voidTitle = false, transactionId, ...props }) => {
    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(transactionId && { transactionId: transactionId })
    };

    return (
        <List
            {...props}
            filters={<TenderFilter filter={filter} />}
            title={title}
            filter={filter}
            sort={{ field: 'id', order: 'ASC' }}
        >
            <CustomDatagrid source="tenders" />
        </List>
    );
}

export default TenderList;
