import * as React from 'react';
import {
    Create,
    TextInput,
    TopToolbar,
    FormWithRedirect,
    Toolbar,
    useTranslate, maxLength, required, number, minValue, maxValue, useNotify, useRedirect, useRefresh
} from 'react-admin';
import { Grid } from '@material-ui/core';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import { CustomListButton } from '../../custom/CustomButton';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    },
    input: {
        marginTop: '8px',
        marginBottom: '4px'
    },
});

const StoreCreateActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
        </TopToolbar>
    );
};

const storeCodeUniqueValidation = async (value) => {
    try {
        if (value) {
            const queryString = stringify({ storeCode: value }, { strictNullHandling: true });
            const response = await axios.get(`/stores?${queryString}`);
            const { data: stores } = response.data;
            if (stores.length > 0) {
                return 'resources.stores.crud.create.validation.storeCodeNotUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    name: [maxLength(255), required()],
    storeCode: [maxLength(255), required(), storeCodeUniqueValidation],
    storeNumber: [number(), minValue(-2147483648), maxValue(2147483647)],
    address: [maxLength(255)],
    phone: [maxLength(255)],
    status: [maxLength(255)],
    chainId: [required()]
};

const StoreCreate = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        notify(`resources.stores.crud.create.notification.success`, 'success');
        redirect(`/stores/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.stores.crud.create.notification.failed`, 'error');
    };

    return (
        <Create
            actions={<StoreCreateActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="stores"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="storeCode"
                                    resource="stores"
                                    validate={validations.storeCode}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="storeNumber"
                                    resource="stores"
                                    validate={validations.storeNumber}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="address"
                                    resource="stores"
                                    validate={validations.address}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="phone"
                                    resource="stores"
                                    validate={validations.phone}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="chainId"
                                    reference="chains"
                                    originSource="id"
                                    label={translate('resources.stores.fields.chain')}
                                    optionText="name"
                                    validate={validations.chainId}
                                    className={classes.input}
                                />
                            </Grid>
                        </Grid>
                        <Toolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                        />
                    </form>
                )}
            />
        </Create>
    );
};

export default StoreCreate;
