import * as React from 'react';
import { List } from 'react-admin';
import DataEntryFilter from './DataEntryFilter';
import DataEntryDatagrid from './DataEntryDatagrid';

const DataEntryList = ({ voidTitle = false, transactionId, ...props }) => {
    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(transactionId && { transactionId: transactionId })
    };

    return (
        <List
            {...props}
            filters={<DataEntryFilter filter={filter} />}
            title={title}
            filter={filter}
            sort={{ field: 'id', order: 'ASC' }}
        >
            <DataEntryDatagrid />
        </List>
    );
}

export default DataEntryList;
