import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { categoricalColorSchemes } from '@nivo/colors';
import { useTranslate } from 'react-admin';
import {
    Button, CircularProgress, Grid, Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import moment from 'moment';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const { paired } = categoricalColorSchemes;

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootDataTable: {
        backgroundColor: theme.palette.grey[100],
        [theme.breakpoints.down('xs')]: {
            width: '95vw'
        }
    },
    scrollableTabs: { width: 0 },
    swipeableViews: {
        width: '100%',
        height: '100%'
    },
    tabPanel: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    tableContainer: { maxHeight: '300px' },
    totalCell: { fontWeight: 500 }
}));

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="terminalTypeId"
                                    record={filters}
                                    reference="terminalTypes"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.transactions.fields.terminalType')}
                                    withNull={true}
                                    helperText={false}
                                    //groupByField="chainId"
                                    additionalFilter={{
                                        ...(form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                        _.isArray(form.getFieldState('chainId').value) ? {
                                            chainId: form.getFieldState('chainId').value
                                        } : {}),
                                        ...(form.getFieldState('storeCode') && form.getFieldState('storeCode').value &&
                                        _.isArray(form.getFieldState('storeCode').value) ? {
                                            storeCode: form.getFieldState('storeCode').value
                                        } : {})
                                    }}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.activeCashiers.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                businessDayDate: getBusinessDayDateDefaultValue()
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.activeCashiers.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart
                }}
            />
        </div>
    );
};

const Chart = ({ data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const classes = useStyles({ exportable });

    const [processedData, setProcessedData] = useState([]);
    const [times, setTimes] = useState([]);
    const [minHour, setMinHour] = useState();
    const [maxHour, setMaxHour] = useState();

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            console.log(data);

            let processedData = [];
            const groupByValues = _.orderBy(_.uniq(data.map(e => e['transaction.terminalNumber'])), [], ['asc']);
            const minHour = _.minBy(data, 'hour').hour;
            const maxHour = _.maxBy(data, 'hour').hour + 100;

            console.log('min:', _.minBy(data, 'hour'));
            console.log({minHour, maxHour});
            console.log((maxHour - minHour) / 100);

            let times = [];
            for (let i = 0; i <= (maxHour - minHour) / 100; i++) {
                const hour = (i * 100) + minHour;
                let time;
                if (hour === 0) {
                    time = '00:00';
                } else if (hour < 1000) {
                    time = moment(String(hour), 'Hmm').format('HH:mm');
                } else {
                    time = moment(String(hour), 'HHmm').format('HH:mm');
                }
                times.push({
                    hour: hour,
                    time: time,
                    inactiveTime: `${time}-inactive`
                });
            }

            groupByValues.forEach(groupByValue => {
                let groupByData = {
                    id: groupByValue,
                    'transaction.terminalNumber': groupByValue
                };
                let filteredData = data.filter(e => e['transaction.terminalNumber'] === groupByValue);
                times.forEach(time => {
                    let filteredTimeData = filteredData.find(e => e.time === time.time);
                    if (filteredTimeData) {
                        if (time.hour === minHour) {
                            groupByData[time.time] = minHour + 100;
                        } else {
                            groupByData[time.time] = 100;
                        }
                    } else {
                        if (time.hour === minHour) {
                            groupByData[time.inactiveTime] = minHour + 100;
                        } else {
                            groupByData[time.inactiveTime] = 100;
                        }
                    }
                });

                processedData.push(groupByData);
            });

            console.log('processedData:', processedData)

            setTimes(times);
            setMinHour(minHour);
            setMaxHour(maxHour);
            setProcessedData(processedData);
        }
    }, [data, loading, cancel]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress/>
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const axisBottom = value => {
        if (value === 0) {
            return '00:00';
        } else if (value < 1000) {
            return moment(String(value), 'Hmm').format('HH:mm');
        } else {
            return moment(String(value), 'HHmm').format('HH:mm');
        }
    };

    const tooltip = ({ color, id, indexValue }) => {
        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                     <span>
                         <strong>{id}</strong>
                     </span>
                     <span>
                         {translate('resources.transactions.fields.terminalNumber')}: <strong>{indexValue}</strong>
                     </span>
                </div>
            </div>
        );
    };

    const getColor = bar => {
        if (/inactive/.test(bar.id)) {
            return 'transparent';
        }
        return paired[0];
    };

    return (
        <div className={classes.chart}>
            <ResponsiveBar
                data={processedData}
                label={false}
                tooltip={tooltip}
                keys={times.map(e => [e.time, e.inactiveTime]).flat()}
                indexBy="transaction.terminalNumber"
                minValue={minHour}
                maxValue={maxHour}
                margin={{
                    top: 20,
                    bottom: 100,
                    right: 20,
                    left: 80
                }}
                padding={0.3}
                layout="horizontal"
                enableGridY={false}
                enableGridX={true}
                valueScale={{ type: 'linear', clamp: true }}
                indexScale={{ type: 'band', round: true }}
                colorBy="id"
                colors={getColor}
                borderColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                axisTop={null}
                axisRight={null}
                gridXValues={times.map(e => e.hour)}
                axisBottom={{
                    format: axisBottom,
                    tickValues: times.map(e => e.hour),
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: translate('pos.datetime.hour', 1),
                    legendPosition: 'middle',
                    legendOffset: 70
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: translate('resources.transactions.fields.terminalNumber'),
                    legendPosition: 'middle',
                    legendOffset: -60
                }}
                labelSkipWidth={48}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
            />
        </div>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let { businessDayDate, chainId, storeCode, terminalTypeId, terminalNumber, operatorCode } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'active-cashiers',
            groupByTerminalQuantity: false,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalTypeId: terminalTypeId,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [renderKey, businessDayDate, chainId, storeCode, terminalTypeId, terminalNumber, operatorCode]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const ActiveCashiersBar = () => {
    const translate = useTranslate();

    const filters = {
        businessDayDate: getBusinessDayDateDefaultValue()
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.activeCashiers.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default ActiveCashiersBar;
