import * as React from 'react';
import { Labeled, BooleanField, TextField, ReferenceField, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';
import EnumTextField from '../../custom/EnumTextField';
import CustomDateTimeField from '../../custom/CustomDateTimeField';

const OperatorGeneral = props => {
    const translate = useTranslate();
    const emptyText = translate('pos.generic.unknown');

    return (
        <span>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.operators.fields.operatorCode">
                        <TextField record={props.record} source="operatorCode" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.operators.fields.name">
                        <TextField record={props.record} source="name" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.operators.fields.type.name">
                        <EnumTextField
                            record={props.record}
                            source="type"
                            fieldTranslate="resources.operators.fields.type"
                            emptyText={emptyText}
                        />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.operators.fields.inReport">
                         <BooleanField record={props.record} source="inReport" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.operators.fields.cLastTransactionBusinessDayDate">
                        <CustomDateTimeField
                            record={props.record}
                            source="cLastTransactionBusinessDayDate"
                            showFormat={translate('pos.datetime.datetime')}
                            emptyText={emptyText}
                        />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.operators.fields.chain">
                        <ReferenceField
                            label="resources.operators.fields.chain"
                            source="chainId"
                            reference="chains"
                            link="show"
                        >
                            <TextField source="name"/>
                        </ReferenceField>
                    </Labeled>
                </Grid>
            </Grid>
        </span>
    );
};

export default OperatorGeneral;
