import * as React from 'react';
import { Fragment, useState } from 'react';
import ActionUpdate from '@material-ui/icons/Update';
import { fade } from '@material-ui/core/styles/colorManipulator';
import inflection from 'inflection';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useRefresh, useNotify, useUnselectAll, useResourceContext } from 'ra-core';
import { Button, Confirm, useListContext, useDataProvider } from 'react-admin';

const useStyles = makeStyles(theme => ({
        updateButton: {
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    }),
    { name: 'RaBulkUpdateWithConfirmButton' }
);

const defaultIcon = <ActionUpdate />;

const BulkUpdateWithConfirmButton = props => {
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();
    const unselectAll = useUnselectAll();
    const resource = useResourceContext(props);
    const { data: listData } = useListContext();
    const classes = useStyles(props);
    const [isOpen, setOpen] = useState(false);

    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);

    const {
        basePath,
        mutationMode = 'pessimistic',
        classes: classesOverride,
        confirmTitle = 'ra.message.bulk_update_title',
        confirmContent = 'ra.message.bulk_update_content',
        data,
        icon = defaultIcon,
        label = 'ra.action.update',
        onClick,
        selectedIds,
        onSuccess = () => {
            refresh();
            notify(`resources.${resource}.crud.bulkEdit.notification.success`, 'success', selectedIds.length);
            unselectAll(resource);
        },
        onFailure = error => {
            console.log(error);
            notify(`resources.${resource}.crud.bulkEdit.notification.failed`, 'error', selectedIds.length);
            setOpen(false);
        },
        ...rest
    } = props;

    // const [updateMany, { loading }] = useUpdateMany(
    //     resource,
    //     selectedIds,
    //     data,
    //     {
    //         action: CRUD_UPDATE_MANY,
    //         onSuccess,
    //         onFailure,
    //         mutationMode,
    //     }
    // );

    const updateMany = () => {
        setLoading(true);

        let promises = [];
        for (const selectedId of selectedIds) {
            const previousData = listData[selectedId];
            promises.push(dataProvider.update(resource, {
                id: selectedId,
                data: {
                    ...previousData,
                    ...data
                },
                previousData: previousData
            }));
        }

        Promise.all(promises)
            .then(() => {
                onSuccess();
            })
            .catch(() => {
                onFailure();
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const handleClick = e => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleUpdate = e => {
        updateMany();

        if (typeof onClick === 'function') {
            onClick(e);
        }
    };

    return (
        <Fragment>
            <Button
                onClick={handleClick}
                label={label}
                className={classes.updateButton}
                {...rest}
            >
                {icon}
            </Button>
            <Confirm
                isOpen={isOpen}
                loading={loading}
                title={confirmTitle}
                content={confirmContent}
                translateOptions={{
                    smart_count: selectedIds.length,
                    name: translate(`resources.${resource}.forcedCaseName`, {
                        smart_count: selectedIds.length,
                        _: inflection.humanize(
                            translate(`resources.${resource}.name`, {
                                smart_count: selectedIds.length,
                                _: inflection.inflect(
                                    resource,
                                    selectedIds.length
                                ),
                            }),
                            true
                        ),
                    }),
                }}
                onConfirm={handleUpdate}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default BulkUpdateWithConfirmButton;
