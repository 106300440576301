import * as React from 'react';
import { useEffect, useState } from 'react';
import { CheckboxGroupInput, useGetOne, useTranslate } from 'react-admin';
import { Box, Tabs, CircularProgress } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import CustomTab from '../../custom/CustomTab';
import CustomTabPanel from '../../custom/CustomTabPanel';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        height: '400px'
    },
    loading: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey[100],
        height: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: '200px'
    },
    swipeableViews: {
        width: '100%',
        height: '100%'
    },
    tabPanel: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    }
}));

const PermissionsSelectInput = ({ defaultRoleId, source, record, change, disabled }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [subjectPermissions, setSubjectPermissions] = useState([]);
    const { data: defaultRole, loading, error } = useGetOne('defaultRoles', defaultRoleId);

    useEffect(() => {
        if (defaultRoleId && !loading && !error && defaultRole) {
            const subjects = Array.from(new Set(defaultRole.permissions.map(permission => permission.subject)));
            let selectedPermissions = [];
            let newSubjectPermissions = [];
            subjects.forEach(subject => {
                let subjectPermission = {
                    subject: subject,
                    permissions: defaultRole.permissions.filter(permission => permission.subject === subject)
                };
                if (record && record.userRole && record.userRole.defaultRoleId === defaultRole.id) {
                    let userPermissionsIds = record.userRole.permissions.map(permission => permission.id);
                    selectedPermissions = selectedPermissions.concat(
                        subjectPermission.permissions.filter(permission => userPermissionsIds.includes(permission.id))
                            .map(permission => permission.id)
                    );
                } else {
                    selectedPermissions = selectedPermissions.concat(subjectPermission.permissions.map(permission => permission.id));
                }
                newSubjectPermissions.push(subjectPermission);
            });
            setSubjectPermissions(newSubjectPermissions);
            change(source, selectedPermissions);
        } else {
            change(source, []);
        }
    }, [change, source, record, loading, error, defaultRole, defaultRoleId]);

    if (!defaultRole) {
        return null;
    }

    if (loading) {
        return (
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <div className={classes.loading}>
                {translate('pos.generic.validation.indicateAValid', {
                    field: translate('resources.users.fields.defaultRole.name')
                })}
            </div>
        );
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const optionText = record => {
        return `resources.users.fields.userRole.permissions.${defaultRole.name}.${record.id}`;
    };

    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                orientation="vertical"
                variant="scrollable"
                className={classes.tabs}
            >
                {subjectPermissions.map(subjectPermission => (
                    <CustomTab
                        label={translate(`resources.users.fields.userRole.subjects.${subjectPermission.subject}`)}
                        index={subjectPermissions.indexOf(subjectPermission)}
                        key={`tab-${subjectPermission.subject}`}
                    />
                ))}
            </Tabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                enableMouseEvents={true}
                resistance={true}
                className={classes.swipeableViews}
            >
                {subjectPermissions.map(subjectPermission => (
                    <CustomTabPanel
                        value={value}
                        index={subjectPermissions.indexOf(subjectPermission)}
                        key={`tabpanel-${subjectPermission.subject}`}
                        dir={theme.direction}
                        className={classes.tabPanel}
                    >
                        <Box px={3}>
                            <CheckboxGroupInput
                                source={source}
                                label=""
                                choices={subjectPermission.permissions}
                                optionText={optionText}
                                row={false}
                                disabled={disabled}
                            />
                        </Box>
                    </CustomTabPanel>
                ))}
            </SwipeableViews>
        </div>
    );
};

export default PermissionsSelectInput;
