import * as React from 'react';
import {
    Create,
    TextInput,
    TopToolbar,
    FormWithRedirect,
    Toolbar,
    useTranslate, maxLength, required, number, minValue, maxValue, useNotify, useRedirect, useRefresh
} from 'react-admin';
import { Grid } from '@material-ui/core';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import { CustomListButton } from '../../custom/CustomButton';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    },
    input: {
        marginTop: '8px',
        marginBottom: '4px'
    },
});

const ItemDetailCreateActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
        </TopToolbar>
    );
};

const itemCodeChainIdUniqueValidation = async (value, allValues) => {
    try {
        if (allValues.itemCode !== undefined && allValues.chainId !== undefined) {
            const params = {
                itemCode: allValues.itemCode,
                chainId: allValues.chainId
            }
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/itemDetails?${queryString}`);
            const { data: itemDetails } = response.data;
            if (itemDetails.length > 0) {
                return 'resources.itemDetails.crud.create.validation.itemCodeChainIdUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    itemCode: [
        number(), minValue(-2147483648), maxValue(2147483647), required(),
        itemCodeChainIdUniqueValidation
    ],
    name: [maxLength(255), required()],
    chainId: [required(), itemCodeChainIdUniqueValidation]
};

const ItemDetailCreate = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        notify(`resources.itemDetails.crud.create.notification.success`, 'success');
        redirect(`/itemDetails/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.itemDetails.crud.create.notification.failed`, 'error');
    };

    return (
        <Create
            actions={<ItemDetailCreateActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="itemCode"
                                    resource="itemDetails"
                                    validate={validations.itemCode}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="itemDetails"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="chainId"
                                    reference="chains"
                                    originSource="id"
                                    label={translate('resources.itemDetails.fields.chain')}
                                    optionText="name"
                                    validate={validations.chainId}
                                    className={classes.input}
                                />
                            </Grid>
                        </Grid>
                        <Toolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                        />
                    </form>
                )}
            />
        </Create>
    );
};

export default ItemDetailCreate;
