import * as React from 'react';
import { useEffect, useState } from 'react';
import { ReferenceField, TextField, useTranslate, useDataProvider } from 'react-admin';
import _ from 'lodash';

const CustomReferenceField = ({ originalSource, injectSource, ...props }) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [associatedRecords, setAssociatedRecords] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const value = _.get(props.record, originalSource);

    useEffect(() => {
        if (value !== null) {
            dataProvider.getList(props.reference, {
                filter: { [originalSource]: value }
            })
                .then(({ data }) => {
                    setAssociatedRecords(Object.values(data));
                })
                .catch(error => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [dataProvider, props.reference, value, originalSource]);

    if (loading) {
        const record = { text: value };
        return (
            <TextField record={record} source="text" />
        );
    }
    if (error) return null;

    if (associatedRecords && value !== null) {
        const associatedRecord = associatedRecords.find(e => e[originalSource] === value);
        if (associatedRecord) {
            props.record[injectSource] = associatedRecord.id;
            return (
                <ReferenceField
                    record={props.record}
                    basePath={`/${props.reference}`}
                    source={injectSource}
                    reference={props.reference}
                    link="show"
                >
                    <TextField source={originalSource} />
                </ReferenceField>
            );
        }
    }

    const emptyText = translate('pos.generic.unknown');
    const record = { text: emptyText };
    return (
        <TextField record={record} source="text" />
    );
}

CustomReferenceField.defaultProps = ReferenceField.defaultProps;

export default CustomReferenceField;
