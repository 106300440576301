import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { TextField, NumberField, useTranslate, useLocale } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs,
    Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import SwipeableViews from 'react-swipeable-views';
import ExportModal from './ExportModal';
import PercentageField from '../../../custom/PercentageField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import CustomTab from '../../../custom/CustomTab';
import CustomTabPanel from '../../../custom/CustomTabPanel';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import resourcesConfig from '../../../../config/resourcesConfig.json';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import moment from 'moment';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootDataTable: {
        backgroundColor: theme.palette.grey[100],
        [theme.breakpoints.down('xs')]: {
            width: '95vw'
        }
    },
    scrollableTabs: { width: 0 },
    swipeableViews: {
        width: '100%',
        height: '100%'
    },
    tabPanel: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    tableContainer: { maxHeight: '300px' },
    totalCell: { fontWeight: 500 }
}));

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="itemQuantities"
                                    record={filters}
                                    label={translate('pos.generic.quantityOf', {
                                        of: _.lowerFirst(translate('resources.transactions.tabs.items'))
                                    })}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.tenders.sections.perWeekdayPercentage')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                businessDayDate: getBusinessDayDateDefaultValue()
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.tenders.sections.perWeekdayPercentage')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    mainTable: ExportableDataTable
                }}
            />
        </div>
    );
};

const Chart = ({ data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles({ exportable });

    const [processedData, setProcessedData] = useState([]);
    const [tenderTypes, setTenderTypes] = useState([]);

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            let processedData = [];
            const groupByValues = _.orderBy(_.uniq(data.map(e => e.dayOfWeek)), [], ['asc']);
            const tenderTypes = _.uniq(data.map(e => translate(`resources.transactions.fields.tenders.tenderType.options.${e.tenderType}`)));
            groupByValues.forEach(groupByValue => {
                let groupByData = {
                    dayOfWeek: _.capitalize(moment().day(groupByValue - 1).format('dddd'))
                };
                let filteredData = data.filter(e => e.dayOfWeek === groupByValue)
                    .map(e => [
                        translate(`resources.transactions.fields.tenders.tenderType.options.${e.tenderType}`),
                        e.amount
                    ]);

                let total = _.sumBy(filteredData, 1);
                filteredData.forEach(e => {
                    e[1] = _.round(e[1] / (total ? total : 1) * 100, 4);
                });

                groupByData = Object.assign(groupByData, Object.fromEntries(filteredData));
                processedData.push(groupByData);
            });
            setTenderTypes(tenderTypes);
            setProcessedData(processedData);
        }
    }, [translate, data, loading, cancel]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress/>
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const axisLeft = value => `${value.toLocaleString(locale)} %`;

    const label = ({ value }) => `${value.toLocaleString(locale)} %`;

    const tooltip = ({ value, color, id, indexValue }) => {
        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                    <span>
                        {id} (<strong>{indexValue}</strong>)
                    </span>
                    <span>
                        {translate('pos.generic.percentage')}: <strong>{value.toLocaleString(locale)} %</strong>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.chart}>
            <ResponsiveBar
                data={processedData}
                label={label}
                tooltip={tooltip}
                keys={tenderTypes}
                indexBy="dayOfWeek"
                margin={{
                    top: 20,
                    bottom: 100,
                    right: 150,
                    left: 80
                }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'paired' }}
                borderColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: translate('pos.datetime.weekday'),
                    legendPosition: 'middle',
                    legendOffset: 70
                }}
                axisLeft={{
                    format: axisLeft,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: translate('pos.generic.percentage'),
                    legendPosition: 'middle',
                    legendOffset: -60
                }}
                labelSkipWidth={48}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        itemDirection: 'left-to-right',
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        symbolSize: 14
                    }
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
            />
        </div>
    );
};

const processData = ({
    translate, data, loading, cancel, setProcessingData, setProcessedData,
    setGroupByValues, setValue = null
}) => {
    setProcessingData(true);
    if (setValue) setValue(0);
    if (data && data.length > 0 && !loading && !cancel) {
        let processedData = {};
        const groupByValues = _.orderBy(_.uniq(data.map(e => e.dayOfWeek)), [], ['asc']);
        groupByValues.forEach(groupByValue => {
            let filteredData = data.filter(e => e.dayOfWeek === groupByValue);
            let totalAmount = _.sumBy(filteredData, 'amount');
            processedData[groupByValue] = {
                data: filteredData.map(e => ({
                    tenderType: translate(`resources.transactions.fields.tenders.tenderType.options.${e.tenderType}`),
                    amount: e.amount,
                    percentage: _.round(e.amount / (totalAmount ? totalAmount : 1) * 100, 4)
                })),
                totalAmount: totalAmount
            };
            processedData[groupByValue].totalPercentage = _.round(_.sumBy(processedData[groupByValue].data, 'percentage'));
        });

        setGroupByValues(groupByValues);
        setProcessedData(processedData);
        setProcessingData(false);
    }
};

const DataTable = ({ data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const theme = useTheme();
    const currency = resourcesConfig.general.currency;

    const [value, setValue] = useState(0);
    const [groupByValues, setGroupByValues] = useState();
    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    useEffect(() => {
        processData({
            translate, data, loading, cancel, setProcessingData, setProcessedData, setGroupByValues, setValue
        });
    }, [translate, data, loading, cancel]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!groupByValues || !processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <div className={classes.rootDataTable}>
            <Tabs
                value={value}
                onChange={handleChange}
                orientation="horizontal"
                variant="scrollable"
                scrollButtons="on"
                classes={{ scrollable: classes.scrollableTabs }}
            >
                {(loading || processingData) ? (
                    <CustomTab label={<CircularProgress size={25} />} />
                ) : (groupByValues.map(groupByValue => (
                    <CustomTab
                        label={_.capitalize(moment().day(groupByValue - 1).format('dddd'))}
                        index={groupByValues.indexOf(groupByValue)}
                        key={`tab-${groupByValue}`}
                    />
                )))}
            </Tabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                enableMouseEvents={true}
                resistance={true}
                className={classes.swipeableViews}
            >
                {groupByValues.map(groupByValue => (
                    <CustomTabPanel
                        value={value}
                        index={groupByValues.indexOf(groupByValue)}
                        key={`tabpanel-${groupByValue}`}
                        dir={theme.direction}
                        className={classes.tabPanel}
                    >
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {translate('resources.transactions.tabs.tenders')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('pos.generic.amount')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('pos.generic.percentage')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {(loading || processingData) ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {processedData[groupByValue].data.map(row => (
                                            <TableRow key={`${groupByValue}-${row.tenderType}`}>
                                                <TableCell>
                                                    <TextField record={row} source="tenderType"/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberField
                                                        record={row}
                                                        source="amount"
                                                        locales={locale}
                                                        options={{style: 'currency', currency: currency}}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <PercentageField record={row} source="percentage"/>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow key={`total-${groupByValue}`}>
                                            <TableCell className={classes.totalCell}>
                                                {translate('pos.generic.total', 1)}
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberField
                                                    record={processedData[groupByValue]}
                                                    source="totalAmount"
                                                    locales={locale}
                                                    options={{style: 'currency', currency: currency}}
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <PercentageField
                                                    record={processedData[groupByValue]}
                                                    source="totalPercentage"
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </CustomTabPanel>
                ))}
            </SwipeableViews>
        </div>
    );
};

const ExportableDataTable = ({ data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const currency = resourcesConfig.general.currency;

    const [groupByValues, setGroupByValues] = useState();
    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    useEffect(() => {
        processData({
            translate, data, loading, cancel, setProcessingData, setProcessedData, setGroupByValues
        });
    }, [translate, data, loading, cancel]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!groupByValues || !processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper}>
            {groupByValues.map(groupByValue => (
                <Table size="small" key={groupByValue}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={3}>
                                {_.capitalize(moment().day(groupByValue - 1).format('dddd'))}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {translate('resources.transactions.tabs.tenders')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('pos.generic.amount')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('pos.generic.percentage')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {(loading || processingData) ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {processedData[groupByValue].data.map(row => (
                                <TableRow key={`${groupByValue}-${row.tenderType}`}>
                                    <TableCell>
                                        <TextField record={row} source="tenderType"/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumberField
                                            record={row}
                                            source="amount"
                                            locales={locale}
                                            options={{style: 'currency', currency: currency}}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <PercentageField record={row} source="percentage"/>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow key={`total-${groupByValue}`}>
                                <TableCell className={classes.totalCell}>
                                    {translate('pos.generic.total', 1)}
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={processedData[groupByValue]}
                                        source="totalAmount"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <PercentageField
                                        record={processedData[groupByValue]}
                                        source="totalPercentage"
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            ))}
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let { itemQuantities, businessDayDate, chainId, storeCode, terminalNumber, operatorCode } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'tenders-group-by-weekday',
            itemQuantities: itemQuantities,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderBy: 'dayOfWeek',
            orderType: 'ASC',
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [renderKey, itemQuantities, businessDayDate, chainId, storeCode, terminalNumber, operatorCode]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const TendersPerWeekdayPercentageBar = () => {
    const translate = useTranslate();

    const filters = {
        businessDayDate: getBusinessDayDateDefaultValue()
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.tenders.sections.perWeekdayPercentage')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default TendersPerWeekdayPercentageBar;
