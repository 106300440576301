import * as React from 'react';
import { useState, useEffect } from 'react';
import { InputHelperText, LinearProgress, useTranslate } from 'react-admin';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FieldTitle, useInput } from 'ra-core';
import moment from 'moment';
import 'moment/locale/es';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';

const CustomTimeInput = props => {
    const translate = useTranslate();

    const {
        className,
        label,
        format,
        helperText = true,
        onBlur,
        onFocus,
        onChange,
        parse,
        record,
        resource,
        source,
        validate,
        ...rest
    } = props;

    const {
        id,
        input,
        isRequired,
        meta: { error: inputError, submitError, touched }
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'text',
        validate,
        ...rest
    });

    const [initialValue, setInitialValue] = useState();
    const [value, setValue] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const value = _.get(record, source);
        if (value) {
            setInitialValue(value);
        }
    }, [record, source]);

    useEffect(() => {
        if (initialValue) {
            setLoading(true);
            setValue(initialValue);
            //input.onChange(initialValue);
            setInitialValue();
        }
        setLoading(false);
    }, [initialValue, value]);

    if (loading) return <LinearProgress />;

    const handleChange = newValue => {
        input.onChange(newValue);
        setValue(newValue);
    };

    const handleBlur = () => {
        input.onBlur(value ? moment(value, 'HH:mm:ss') : null);
    };

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <TimePicker
                id={id}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                label={
                    <FieldTitle
                        source={source}
                        resource={resource}
                        label={label}
                        isRequired={isRequired}
                    />
                }
                error={!!(touched && (inputError || submitError))}
                helperText={(helperText && (touched && (inputError || submitError))) && (
                    <InputHelperText
                        touched={touched}
                        error={inputError || submitError}
                        helperText={(touched && inputError) ? inputError : null}
                    />
                )}
                views={['hours', 'minutes', 'seconds']}
                format={translate('pos.datetime.time')}
                ampm={false}
                clearable
                inputVariant="filled"
                margin="normal"
                size="small"
                okLabel={translate('pos.generic.ok')}
                cancelLabel={translate('ra.action.cancel')}
                clearLabel={translate('pos.generic.clear', 1)}
                className={className}
                {...rest}
            />
        </MuiPickersUtilsProvider>
    );
};

export default CustomTimeInput;
