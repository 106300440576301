import * as React from 'react';
import { Datagrid } from 'react-admin';
import EnumTextField from '../../../../custom/EnumTextField';
import CustomField from '../../../../custom/CustomField';
import UserStringPanel from './UserStringPanel';
import resourcesConfig from '../../../../../config/resourcesConfig.json';

const SubtypeField = props => {
    const record = {
        subtype: `${props.record.originator}-${props.record.subtype}`
    };

    return (
        <EnumTextField
            record={record}
            source="subtype"
            fieldTranslate="resources.transactions.fields.userStrings.subtype"
        />
    );
};

const UserStringsDatagrid = props => {
    const configFilename = resourcesConfig.transactions.related.userStrings.configFilename;
    const config = require(`../../../../../config/${configFilename}`);
    let fieldsConfig = config.fields.filter(fieldConfig => fieldConfig.inUse && fieldConfig.inTable);
    fieldsConfig = fieldsConfig.sort((a, b) => {
        if (!b.sort) return -1;
        if (!a.sort) return 1;
        return a.sort - b.sort;
    });

    return (
        <Datagrid
            expand={<UserStringPanel />}
            hover={false}
            rowStyle={(record, index) => {
                if (index % 2 === 0) {
                    return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                }
            }}
        >
            {fieldsConfig.map(fieldConfig => {
                if (fieldConfig.name === 'subtype') {
                    return (
                        <SubtypeField
                            label="resources.transactions.fields.userStrings.subtype.name"
                            key={fieldConfig.name}
                        />
                    );
                } else if (fieldConfig.datatype.type === 'ENUM') {
                    return (
                        <CustomField
                            record={props.record}
                            config={fieldConfig}
                            label={`resources.transactions.fields.userStrings.${fieldConfig.name}.name`}
                            fieldTranslate={`resources.transactions.fields.userStrings.${fieldConfig.name}`}
                            key={fieldConfig.name}
                        />
                    );
                }
                return (
                    <CustomField
                        record={props.record}
                        config={fieldConfig}
                        label={`resources.transactions.fields.userStrings.${fieldConfig.name}`}
                        key={fieldConfig.name}
                    />
                );
            })}
        </Datagrid>
    );
};

export default UserStringsDatagrid;
