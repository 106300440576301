import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useTranslate, useLocale, useSetLocale, Title } from 'react-admin';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
    root: { marginTop: '20px' },
});

const Configuration = () => {
    const translate = useTranslate();
    const locale = useLocale();
    const setLocale = useSetLocale();
    const classes = useStyles();

    const selectLanguage = async locale => {
        await setLocale(locale);
        moment.locale(locale);
        localStorage.setItem('locale', locale);
    };

    return (
        <Card className={classes.root}>
            <Title title="pos.configuration.name" />
            <CardContent>
                <div className={classes.label}>{translate('pos.configuration.language.name')}</div>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={locale === 'es-US' ? 'primary' : 'default'}
                    onClick={() => selectLanguage('es-US')}
                >
                    {translate('pos.configuration.language.spanish')}
                </Button>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={locale === 'en-US' ? 'primary' : 'default'}
                    onClick={() => selectLanguage('en-US')}
                >
                    {translate('pos.configuration.language.english')}
                </Button>
            </CardContent>
        </Card>
    );
};

export default Configuration;
