import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useTranslate, hideNotification, getNotification, undo, complete, undoableEventEmitter } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
        undo: props => ({
            color:
                props.type === 'success'
                    ? theme.palette.success.contrastText
                    : theme.palette.primary.light,
        }),
    }),
    { name: 'RaNotification' }
);

const CustomNotification = props => {
    const translate = useTranslate();
    const classes = useStyles(props);
    const [open, setOpen] = useState(false);
    const notification = useSelector(getNotification);
    const dispatch = useDispatch();

    useEffect(() => {
        setOpen(!!notification);
    }, [notification]);

    const handleRequestClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleExited = useCallback(() => {
        if (notification && notification.undoable) {
            dispatch(complete());
            undoableEventEmitter.emit('end', { isUndo: false });
        }
        dispatch(hideNotification());
    }, [dispatch, notification]);

    const handleUndo = useCallback(() => {
        dispatch(undo());
        undoableEventEmitter.emit('end', { isUndo: true });
    }, [dispatch]);

    return (
        <Snackbar
            open={open}
            autoHideDuration={(() => {
                if (notification) {
                    if (notification.autoHideDuration === false) {
                        return undefined;
                    }
                    if (notification.autoHideDuration) {
                        return notification.autoHideDuration;
                    }
                }
                return 4000;
            })()}
            disableWindowBlurListener={notification && notification.undoable}
            onExited={handleExited}
            onClose={handleRequestClose}
            {...props}
        >
            <Alert
                severity={(notification && notification.type) || 'info'}
                elevation={3}
                action={(() => {
                    if (!notification) {
                        return null;
                    }

                    if (notification.undoable) {
                        return (
                            <Button
                                color="primary"
                                className={classes.undo}
                                size="small"
                                onClick={handleUndo}
                            >
                                {translate('ra.action.undo')}
                            </Button>
                        );
                    }
                    if (notification.autoHideDuration === false) {
                        return (
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        );
                    }
                    return null;
                })()}
            >
                {notification && notification.message &&
                    translate(notification.message, notification.messageArgs)
                }
            </Alert>
        </Snackbar>
    );
};

export default CustomNotification;