import * as React from 'react';
import { useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FieldTitle, useInput } from 'ra-core';
import moment from 'moment';
import 'moment/locale/es';
import MomentUtils from '@date-io/moment';

const CustomDateTimeInput = ({ source, resource, label, isRequired, ...props }) => {
    const translate = useTranslate();
    const { input, meta } = useInput({ source });
    const { touched, error } = meta;

    const handleChange = useCallback(value => {
        value ? input.onChange(value.format('YYYY-MM-DD HH:mm:ss')) : input.onChange(null);
    }, [input]);

    const handleBlur = () => {
        const datetime = input.value ?
            moment(input.value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : null;
        input.onBlur(datetime);
    };

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DateTimePicker
                label={
                    <FieldTitle
                        source={source}
                        resource={resource}
                        label={label}
                        isRequired={isRequired}
                    />
                }
                error={!!(touched && error)}
                helperText={touched && error}
                value={input.value ? new Date(input.value) : null}
                onChange={date => handleChange(date)}
                onBlur={handleBlur}
                format={translate('pos.datetime.datetime')}
                ampm={false}
                clearable
                inputVariant="filled"
                margin="normal"
                size="small"
                okLabel={translate('pos.generic.ok')}
                cancelLabel={translate('ra.action.cancel')}
                clearLabel={translate('pos.generic.clear', 1)}
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
};

export default CustomDateTimeInput;
