import * as React from 'react';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { SelectInput, TextField, NumberField, useTranslate, useLocale } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
    IconButton, Collapse
} from '@material-ui/core';
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon
} from '@material-ui/icons';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import PercentageField from '../../../custom/PercentageField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import resourcesConfig from '../../../../config/resourcesConfig.json';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '450px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: { maxHeight: '450px' },
    totalCell: { fontWeight: 500 }
}));

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="quantifier"
                                    label="pos.generic.quantifier"
                                    choices={[
                                        { id: 'transactionQuantity', name: 'pos.generic.customers' },
                                        { id: 'transactionAmount', name: 'pos.generic.amount' }
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.itemSales.sections.itemsPerTransaction')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                quantifier: 'transactionAmount',
                                businessDayDate: getBusinessDayDateDefaultValue()
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.itemSales.sections.itemsPerTransaction')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    mainTable: DataTable
                }}
            />
        </div>
    );
};

const Chart = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles({ exportable });

    const [processedData, setProcessedData] = useState([]);
    const [tenderTypes, setTenderTypes] = useState([]);
    const [xMarkerValue, setXMarkerValue] = useState(false);
    const [yMarkerValue, setYMarkerValue] = useState(false);
    const [x2MarkerValue, setX2MarkerValue] = useState(false);
    const [y2MarkerValue, setY2MarkerValue] = useState(false);

    let { quantifier } = filters;

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            let processedData = [];
            const groupByValues = _.orderBy(_.uniq(data.map(e => e.itemQuantity)), [], ['asc']);
            const tenderTypes = _.uniq(data.map(e => translate(`resources.transactions.fields.tenders.tenderType.options.${e.tenderType}`)));
            groupByValues.forEach(groupByValue => {
                let filteredData = data.filter(e => e.itemQuantity === groupByValue);
                let groupByData = {
                    itemQuantity: groupByValue,
                    totalTransactionPercentage: _.sumBy(filteredData, 'transactionPercentage')
                };
                filteredData = filteredData.map(e => [
                    translate(`resources.transactions.fields.tenders.tenderType.options.${e.tenderType}`),
                    e.transactionPercentage
                ]);
                groupByData = Object.assign(groupByData, Object.fromEntries(filteredData));
                processedData.push(groupByData);
            });
            setTenderTypes(tenderTypes);

            let i = 0;
            let accumulatedPercentage = 0;
            let xMarkerValue = 0;
            let yMarkerValue = 0;
            while(i < processedData.length && accumulatedPercentage <= 80) {
                accumulatedPercentage += processedData[i].totalTransactionPercentage;
                xMarkerValue = processedData[i].itemQuantity;
                yMarkerValue = processedData[i].totalTransactionPercentage;
                i++;
            }
            if (accumulatedPercentage >= 80) {
                setXMarkerValue(xMarkerValue);
                setYMarkerValue(yMarkerValue);
            } else {
                setXMarkerValue(false);
                setYMarkerValue(false);
            }

            processedData = processedData.slice(0, i + 3);
            setProcessedData(processedData);

            i = 0;
            accumulatedPercentage = 0;
            let x2MarkerValue = 0;
            let y2MarkerValue = 0;
            while(i < processedData.length && accumulatedPercentage <= 20) {
                accumulatedPercentage += processedData[i].totalTransactionPercentage;
                x2MarkerValue = processedData[i].itemQuantity;
                y2MarkerValue = processedData[i].totalTransactionPercentage;
                i++;
            }
            if (accumulatedPercentage >= 20) {
                setX2MarkerValue(x2MarkerValue);
                setY2MarkerValue(y2MarkerValue);
            } else {
                setX2MarkerValue(false);
                setY2MarkerValue(false);
            }
        }
    }, [translate, data, loading, cancel]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const label = ({ value }) => `${value.toLocaleString(locale)} %`;

    const axisBottom = value => value.toLocaleString(locale);

    const axisLeft = value => `${value.toLocaleString(locale)} %`;

    const leftLegend = quantifier => {
        switch (quantifier) {
            case 'transactionQuantity':
                return translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) });
            default:
            case 'transactionAmount':
                return translate('pos.generic.percentageOf', { of: translate('pos.generic.amount') });
        }
    };

    const tooltip = ({ data: barData, color, index, id }) => {
        let { itemQuantity, totalTransactionPercentage } = barData;
        totalTransactionPercentage = _.round(totalTransactionPercentage, 4).toLocaleString(locale);

        let accumulatedPercentage = 0;
        let allBars = processedData;
        let i = 0;
        while(i < allBars.length && i <= index) {
            accumulatedPercentage += allBars[i].totalTransactionPercentage;
            i++;
        }
        accumulatedPercentage = _.round(accumulatedPercentage, 4).toLocaleString(locale);

        let total = 0;
        tenderTypes.forEach(tenderType => {
            if (tenderType in barData) {
                total += barData[tenderType];
            }
        });
        let percentage = _.round(barData[id] / (total ? total : 1) * 100, 4).toLocaleString(locale);

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                    <span>
                        {translate('resources.transactions.tabs.items')} (<strong>{itemQuantity.toLocaleString(locale)}</strong>)
                    </span>
                    <span>
                        {translate('pos.generic.totalPercentage')}: <strong>{totalTransactionPercentage} %</strong>
                    </span>
                    <span>
                        {translate('pos.generic.accumulated')}: <strong>{accumulatedPercentage} %</strong>
                    </span>
                    <span>
                        {id}: <strong>{percentage} %</strong>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.chart}>
            <ResponsiveBar
                data={processedData}
                label={label}
                tooltip={tooltip}
                keys={tenderTypes}
                indexBy="itemQuantity"
                margin={{
                    top: 20,
                    bottom: 60,
                    right: 150,
                    left: 100
                }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'paired' }}
                borderColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    format: axisBottom,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: translate('pos.generic.quantityOf', { of: translate('resources.transactions.tabs.items') }),
                    legendPosition: 'middle',
                    legendOffset: 40
                }}
                axisLeft={{
                    format: axisLeft,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: leftLegend(quantifier),
                    legendPosition: 'middle',
                    legendOffset: -50
                }}
                labelSkipWidth={48}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        itemDirection: 'left-to-right',
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        symbolSize: 14
                    }
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                markers={[
                    ...((xMarkerValue !== false && yMarkerValue !== false) ? [
                        {
                            axis: 'x',
                            value: xMarkerValue,
                            lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
                            legend: '80 %',
                            legendPosition: 'top-left',
                        },
                        {
                            axis: 'x',
                            value: xMarkerValue,
                            lineStyle: { stroke: 'transparent' },
                            legend: '20 %',
                            legendPosition: 'top-right',
                        },
                        {
                            axis: 'y',
                            value: yMarkerValue,
                            lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
                        }
                    ] : []),
                    ...((x2MarkerValue !== false && y2MarkerValue !== false) ? [
                        {
                            axis: 'x',
                            value: x2MarkerValue,
                            lineStyle: { stroke: '#00296a', strokeWidth: 2 },
                            legend: '20 %',
                            legendPosition: 'top-left',
                        },
                        {
                            axis: 'x',
                            value: x2MarkerValue,
                            lineStyle: { stroke: 'transparent' },
                            legend: '80 %',
                            legendPosition: 'top-right',
                        },
                        {
                            axis: 'y',
                            value: y2MarkerValue,
                            lineStyle: { stroke: '#00296a', strokeWidth: 2 },
                        }
                    ] : [])
                ]}
            />
        </div>
    );
};

const Row = ({ data, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const currency = resourcesConfig.general.currency;

    const [open, setOpen] = useState(exportable);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <TextField record={data} source="itemQuantity" />
                </TableCell>
                <TableCell align="right">
                    <NumberField record={data} source="totalTransactionQuantity" locales={locale} />
                </TableCell>
                <TableCell align="right">
                    <NumberField
                        record={data}
                        source="totalTransactionAmount"
                        locales={locale}
                        options={{style: 'currency', currency: currency}}
                    />
                </TableCell>
                <TableCell align="right">
                    <PercentageField record={data} source="totalTransactionPercentage" />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {translate('resources.transactions.tabs.tenders')}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })}
                                    </TableCell>
                                    <TableCell align="right">
                                        {translate('pos.generic.percentage')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.tenders.map(tender => (
                                    <TableRow key={tender.tenderType}>
                                        <TableCell component="th" scope="row">
                                            {translate(`resources.transactions.fields.tenders.tenderType.options.${tender.tenderType}`)}
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                record={tender}
                                                source="transactionQuantity"
                                                locales={locale}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                record={tender}
                                                source="transactionAmount"
                                                locales={locale}
                                                options={{style: 'currency', currency: currency}}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <PercentageField
                                                record={tender}
                                                source="percentage"
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

const DataTable = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const currency = resourcesConfig.general.currency;

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { quantifier } = filters;

    useEffect(() => {
        setProcessingData(true);
        if (data && data.length > 0 && !loading && !cancel) {
            let processedData = {
                data: [],
                totalQuantity: _.sumBy(data, 'transactionQuantity'),
                totalAmount: _.sumBy(data, 'transactionAmount'),
                totalPercentage: _.round(_.sumBy(data, 'transactionPercentage'), 3)
            };

            const groupByValues = _.orderBy(_.uniq(data.map(e => e.itemQuantity)), [], ['asc']);
            groupByValues.forEach(groupByValue => {
                let filteredData = data.filter(e => e.itemQuantity === groupByValue);
                let totalTransactionQuantity = _.sumBy(filteredData, 'transactionQuantity');
                let groupByData = {
                    itemQuantity: groupByValue,
                    totalTransactionPercentage: _.sumBy(filteredData, 'transactionPercentage'),
                    totalTransactionQuantity: totalTransactionQuantity,
                    totalTransactionAmount: _.sumBy(filteredData, 'transactionAmount'),
                    //totalTenderAmount: _.sumBy(filteredData, 'tenderAmount'),
                    tenders: filteredData.map(e => ({
                        tenderType: e.tenderType,
                        transactionQuantity: e.transactionQuantity,
                        transactionAmount: e.transactionAmount,
                        //tenderAmount: e.tenderAmount,
                        percentage: _.round(e.transactionQuantity / (totalTransactionQuantity ? totalTransactionQuantity : 1) * 100, 3)
                    }))
                };
                groupByData.tenders = _.orderBy(groupByData.tenders, ['percentage'], ['desc']);
                processedData.data.push(groupByData);
            });

            setProcessedData(processedData);
            setProcessingData(false);
        }
    }, [data, loading, cancel]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0|| cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const HeaderRow = () => (
        <TableRow>
            <TableCell />
            <TableCell>
                {translate('pos.generic.quantityOf', { of: translate('resources.transactions.tabs.items', 2) })}
            </TableCell>
            <TableCell align="right">
                {translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })} ({translate('pos.generic.customers')})
            </TableCell>
            <TableCell align="right">
                {translate('pos.generic.amountOf', { of: translate('resources.transactions.name', 2) })}
            </TableCell>
            <TableCell align="right">
                {quantifier === 'transactionAmount' ? (
                    translate('pos.generic.percentageOf', { of: translate('pos.generic.amount') })
                ) : (
                    `${translate('pos.generic.percentageOf', { of: translate('resources.transactions.name', 2) })} (${translate('pos.generic.customers')})`
                )}
            </TableCell>
        </TableRow>
    );

    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                {!exportable &&
                    <TableHead>
                        <HeaderRow />
                    </TableHead>
                }
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={5} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => (
                            <React.Fragment>
                                {exportable &&
                                    <HeaderRow />
                                }
                                <Row key={row.itemQuantity} data={row} exportable={exportable} />
                            </React.Fragment>
                        ))}
                        <TableRow key="total">
                            <TableCell />
                            <TableCell className={classes.totalCell}>
                                {translate('pos.generic.total', 1)}
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="totalQuantity"
                                    locales={locale}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <NumberField
                                    record={processedData}
                                    source="totalAmount"
                                    locales={locale}
                                    options={{style: 'currency', currency: currency}}
                                    className={classes.totalCell}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <PercentageField
                                    record={processedData}
                                    source="totalPercentage"
                                    className={classes.totalCell}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let { quantifier, businessDayDate, chainId, storeCode, terminalNumber, operatorCode } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'items-per-transaction',
            quantifier: quantifier,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderBy: 'itemQuantity',
            orderType: 'ASC'
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [renderKey, quantifier, businessDayDate, chainId, storeCode, terminalNumber, operatorCode]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const ItemsPerTransactionBar = () => {
    const translate = useTranslate();

    const filters = {
        quantifier: 'transactionAmount',
        businessDayDate: getBusinessDayDateDefaultValue()
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.itemSales.sections.itemsPerTransaction')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Filter
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                        onSubmit={onSubmit}
                                        cancelTokenSource={cancelTokenSource}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        filters={filters}
                                        data={data}
                                        loading={loading}
                                        cancel={cancel}
                                        error={error}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default ItemsPerTransactionBar;
