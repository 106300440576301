import * as React from 'react';
import { useState, useEffect } from 'react';
import { NumberField, TextField, SelectInput, NumberInput, BooleanInput, useLocale, useTranslate } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import { Form } from 'react-final-form';
import ExportModal from './ExportModal';
import DurationField from '../../../custom/DurationField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: {
        maxHeight: '600px',
        maxWidth: '95vw'
    },
    borderX: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`
    },
    borderLeft: {
        borderLeft: `1px solid ${theme.palette.divider}`
    },
    borderRight: {
        borderRight: `1px solid ${theme.palette.divider}`
    },
}));

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                        _.isArray(form.getFieldState('chainId').value) ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="terminalTypeId"
                                    record={filters}
                                    reference="terminalTypes"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.transactions.fields.terminalType')}
                                    withNull={true}
                                    helperText={false}
                                    //groupByField="chainId"
                                    additionalFilter={{
                                        ...(form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                            _.isArray(form.getFieldState('chainId').value) ? {
                                            chainId: form.getFieldState('chainId').value
                                        } : {}),
                                        ...(form.getFieldState('storeCode') && form.getFieldState('storeCode').value &&
                                            _.isArray(form.getFieldState('storeCode').value) ? {
                                            storeCode: form.getFieldState('storeCode').value
                                        } : {})
                                    }}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="orderType"
                                    label="pos.generic.order.orderType.name"
                                    choices={[
                                        { id: 'ASC', name: 'pos.generic.order.orderType.asc' },
                                        { id: 'DESC', name: 'pos.generic.order.orderType.desc' },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="limit"
                                    label="pos.generic.limit"
                                    min="1"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="nthClient"
                                    label="resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.nthClient"
                                    min="1"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="projectedSecondsPerItemLine"
                                    label="resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.projectedSecondsPerItemLine"
                                    min="1"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput
                                    source="ignoreZeroTimes"
                                    label="resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.ignoreZeroTimes"
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                ignoreZeroTimes: true,
                                businessDayDate: getBusinessDayDateDefaultValue(),
                                orderType: 'ASC',
                                limit: 25
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    mainTable: DataTable
                }}
            />
        </div>
    );
};

const processData = ({ data, loading, cancel, nthClient, projectedSecondsPerItemLine, setProcessingData, setProcessedData }) => {
    setProcessingData(true);
    if (data && data.length > 0 && !loading && !cancel) {
        setProcessedData({
            data: data.map(e => {
                const nthClientWaitTime = _.round(e.avgTransactionTime) * (nthClient - 1);
                const projectedAvgTransactionTime = _.round(projectedSecondsPerItemLine * e.avgItemLineQuantity);
                const projectedNthClientWaitTime = projectedAvgTransactionTime * (nthClient - 1);
                const projectedClientsInQueue = _.round(nthClientWaitTime / projectedAvgTransactionTime, 1);

                return {
                    'transaction.storeCode': e['transaction.storeCode'],
                    current: {
                        secondsPerItemLine: e.secondsPerItemLine,
                        avgItemLineQuantity: e.avgItemLineQuantity,
                        avgTransactionTime: _.round(e.avgTransactionTime),
                        avgTransactionTime2: _.round(e.avgTransactionTime2),
                        nthClientWaitTime: nthClientWaitTime
                    },
                    projected: {
                        avgTransactionTime: projectedAvgTransactionTime,
                        nthClientWaitTime: projectedNthClientWaitTime,
                        clientsInQueue: projectedClientsInQueue
                    }
                };
            })
        });

        setProcessingData(false);
    }
};

const DataTable = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { nthClient, projectedSecondsPerItemLine } = filters;
    
    useEffect(() => {
        processData({
            data, loading, cancel, nthClient, projectedSecondsPerItemLine, setProcessingData, setProcessedData
        });
    }, [data, loading, cancel, nthClient, projectedSecondsPerItemLine]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper} className={!exportable ? classes.tableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow key="rowHeader-1">
                        <TableCell rowSpan={2}>
                            {translate('resources.transactions.fields.storeCode')}
                        </TableCell>
                        <TableCell colSpan={4} align="center" className={classes.borderX}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.currentSituation')}
                        </TableCell>
                        <TableCell colSpan={3} align="center">
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.projectedSituation', {
                                seconds_per_item_line: projectedSecondsPerItemLine || 0
                            })}
                        </TableCell>
                    </TableRow>
                    <TableRow key="rowHeader-2">
                        <TableCell align="right" style={{ top: '37px' }} className={classes.borderLeft}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.secondsPerItemLine')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.avgItemLineQuantity')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.avgTransactionTime')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }} className={classes.borderRight}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.nthClientWaitTime', {
                                nth_client: nthClient
                            })}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.avgTransactionTime')}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.nthClientWaitTime', {
                                nth_client: nthClient
                            })}
                        </TableCell>
                        <TableCell align="right" style={{ top: '37px' }}>
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.clientsInQueue')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={8} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.data.map(row => (
                            <TableRow key={row['transaction.storeCode']}>
                                <TableCell>
                                    <TextField record={row} source="transaction.storeCode" />
                                </TableCell>
                                <TableCell align="right" className={classes.borderLeft}>
                                    <NumberField
                                        record={row}
                                        source="current.secondsPerItemLine"
                                        locales={locale}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="current.avgItemLineQuantity"
                                        locales={locale}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <DurationField
                                        record={row}
                                        source="current.avgTransactionTime"
                                        emptyText={emptyText}
                                        shortFormat
                                    />
                                </TableCell>
                                <TableCell align="right" className={classes.borderRight}>
                                    <DurationField
                                        record={row}
                                        source="current.nthClientWaitTime"
                                        emptyText={emptyText}
                                        shortFormat
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <DurationField
                                        record={row}
                                        source="projected.avgTransactionTime"
                                        emptyText={emptyText}
                                        shortFormat
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <DurationField
                                        record={row}
                                        source="projected.nthClientWaitTime"
                                        emptyText={emptyText}
                                        shortFormat
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={row}
                                        source="projected.clientsInQueue"
                                        locales={locale}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let {
        nthClient, projectedSecondsPerItemLine, ignoreZeroTimes, businessDayDate, chainId, storeCode, terminalTypeId,
        terminalNumber, operatorCode, orderType, limit
    } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'queue-length-per-store',
            groupBy: 'transaction.storeCode',
            ignoreZeroTimes: ignoreZeroTimes,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalTypeId: terminalTypeId,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderType: orderType,
            limit: limit
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        renderKey, nthClient, projectedSecondsPerItemLine, ignoreZeroTimes, businessDayDate, chainId, storeCode,
        terminalTypeId, terminalNumber, operatorCode, orderType, limit
    ]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const QueueLengthPerStore = () => {
    const translate = useTranslate();

    const filters = {
        nthClient: 3,
        projectedSecondsPerItemLine: 5,
        ignoreZeroTimes: true,
        businessDayDate: getBusinessDayDateDefaultValue(),
        orderType: 'ASC',
        limit: 25
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.queueLengthPerStore.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Filter
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                                onSubmit={onSubmit}
                                cancelTokenSource={cancelTokenSource}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default QueueLengthPerStore;
