import * as React from 'react';
import { Datagrid, List, TextField, ReferenceField, useTranslate } from 'react-admin';
import StoreFilter from './StoreFilter';
import CustomDateTimeField from '../../custom/CustomDateTimeField';
import { CustomShowButton, CustomEditButton } from '../../custom/CustomButton';
import CustomBulkActionButtons from '../../custom/CustomBulkActionButtons';

const StoreList = ({ voidTitle = false, chainId, groupId, userId, fileId, operatorId, filterDefaultValues, disabledFilters, hiddenColumns, ...props }) => {
    const translate = useTranslate();
    const emptyText = translate('pos.generic.unknown');
    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(chainId && { chainId: chainId }),
        ...(groupId && { include: 1, groupId: groupId }),
        ...(userId && { include: 1, userId: userId }),
        ...(fileId && { include: 1, fileId: fileId }),
        ...(operatorId && { include: 1, operatorId: operatorId })
    };

    if (operatorId) {
        filterDefaultValues = {
            ...filterDefaultValues,
            include: 1
        };
    }

    return (
        <List
            {...props}
            title={title}
            actions={null}
            bulkActionButtons={<CustomBulkActionButtons />}
            filters={
                <StoreFilter
                    filter={filter}
                    filterDefaultValues={filterDefaultValues}
                    disabledFilters={disabledFilters}
                />}
            filter={filter}
        >
            <Datagrid
                rowClick="show"
                hover={false}
                rowStyle={(record, index) => {
                    if (index % 2 === 0) {
                        return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                    }
                }}
            >
                {(!hiddenColumns || !hiddenColumns.name) &&
                    <TextField source="name" />
                }
                {(!hiddenColumns || !hiddenColumns.storeCode) &&
                    <TextField source="storeCode" />
                }
                {(!hiddenColumns || !hiddenColumns.status) &&
                    <TextField source="status" emptyText={emptyText} />
                }
                {operatorId &&
                    <CustomDateTimeField
                        source="operators[0].operatorStore.cLastTransactionBusinessDayDate"
                        label="resources.operators.fields.cLastTransactionBusinessDayDate"
                        showFormat={translate('pos.datetime.datetime')}
                        emptyText={emptyText}
                    />
                }
                {(!hiddenColumns || !hiddenColumns.chain) &&
                    <ReferenceField label="resources.stores.fields.chain" source="chainId" reference="chains" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                }
                <CustomShowButton />
                <CustomEditButton />
            </Datagrid>
        </List>
    );
};

export default StoreList;
