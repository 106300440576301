import * as React from 'react';
import { useContext } from 'react';
import { ListButton, ShowButton, CreateButton, EditButton, DeleteButton } from 'react-admin';
import BulkDeleteButton from './CustomBulkDeleteButton';
import BulkUpdateButton from './CustomBulkUpdateButton';
import { subject } from '@casl/ability';
import { Can, AbilityContext } from '../Can';
import { updateAbility } from '../../config/abilities';

const CustomListButton = ({ resource, basePath }) => {
    return (
        <Can do="find" on={resource} passThrough>
            {allowed => <ListButton basePath={basePath} disabled={!allowed} />}
        </Can>
    );
}

const CustomShowButton = ({ resource, basePath, record, to, target, rel }) => {
    const props = {
        ...(to && { to }),
        ...(target && { target }),
        ...(rel && { rel })
    };
    return (
        <Can do="get" on={resource} passThrough>
            {allowed => <ShowButton {...props} basePath={basePath} record={record} disabled={!allowed} />}
        </Can>
    );
};

const CustomCreateButton = ({ resource, basePath }) => {
    return (
        <Can do="create" on={resource} passThrough>
            {allowed => <CreateButton basePath={basePath} disabled={!allowed} />}
        </Can>
    );
};

const CustomEditButton = ({ resource, basePath, record }) => {
    const ability = useContext(AbilityContext);
    if (!ability.can('update', subject(resource, record))) {
        updateAbility(record);
    }

    return (
        <Can do="update" on={subject(resource, record)} passThrough>
            {allowed => <EditButton basePath={basePath} record={record} disabled={!allowed} />}
        </Can>
    );
};

const CustomDeleteButton = ({ resource, basePath, record, ...props }) => {
    return (
        <Can do="remove" on={subject(resource, record)} passThrough>
            {allowed =>
                <DeleteButton
                    resource={resource}
                    basePath={basePath}
                    record={record}
                    disabled={!allowed}
                    {...props}
                />
            }
        </Can>
    );
};

const CustomBulkDeleteButton = ({ resource, basePath, selectedIds, ...props }) => {
    return (
        <Can do="remove" on={resource} passThrough>
            {allowed =>
                <BulkDeleteButton
                    resource={resource}
                    basePath={basePath}
                    selectedIds={selectedIds}
                    disabled={!allowed}
                    {...props}
                />
            }
        </Can>
    );
};

const CustomBulkUpdateButton = ({ resource, basePath, selectedIds, ...props }) => {
    return (
        <Can do="update" on={resource} passThrough>
            {allowed =>
                <BulkUpdateButton
                    resource={resource}
                    basePath={basePath}
                    selectedIds={selectedIds}
                    disabled={!allowed}
                    {...props}
                />
            }
        </Can>
    );
};

export {
    CustomListButton,
    CustomShowButton,
    CustomCreateButton,
    CustomEditButton,
    CustomDeleteButton,
    CustomBulkDeleteButton,
    CustomBulkUpdateButton
};
