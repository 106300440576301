import * as React from 'react';
import { cloneElement } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { AppBar, IconButton, Toolbar, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import MenuIcon from '@material-ui/icons/Menu';
import { toggleSidebar } from 'ra-core';
import { LoadingIndicator, HideOnScroll } from 'react-admin';
import CustomUserMenu from './CustomUserMenu';
import resourcesConfig from '../../config/resourcesConfig.json';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
        appBar: {
            backgroundColor: '#03D2AE'
        },
        toolbar: {
            paddingRight: 24
        },
        menuButton: {
            marginLeft: '0.5em',
            marginRight: '0.5em',
        },
        menuButtonIconClosed: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(0deg)',
        },
        menuButtonIconOpen: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(180deg)',
        },
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        logo: {
            flex: 1,
            marginTop: '2px'
        }
    }),
    { name: 'RaAppBar' }
);

const CustomAppBar = props => {
    const translate = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const appBarConfig = resourcesConfig.general.appBar;

    const { title, open, logout, ...rest } = props;

    return (
        <HideOnScroll>
            <AppBar className={classes.appBar} {...rest}>
                <Toolbar
                    disableGutters
                    variant={isXSmall ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    <Tooltip
                        title={translate(
                            open
                                ? 'ra.action.close_menu'
                                : 'ra.action.open_menu',
                            {
                                _: 'Open/Close menu',
                            }
                        )}
                        enterDelay={500}
                    >
                        <IconButton
                            color="inherit"
                            onClick={() => dispatch(toggleSidebar())}
                            className={classNames(classes.menuButton)}
                        >
                            <MenuIcon
                                classes={{
                                    root: open
                                        ? classes.menuButtonIconOpen
                                        : classes.menuButtonIconClosed,
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    />
                    {!isXSmall &&
                        <div className={classes.logo}>
                            <a href={appBarConfig.website} target="_blank" rel="noopener noreferrer">
                                <img src={appBarConfig.logoFilename} height="45px" alt={appBarConfig.brand} />
                            </a>
                        </div>
                    }
                    <LoadingIndicator />
                    {cloneElement(<CustomUserMenu />)}
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    );
}

export default CustomAppBar;
