import * as React from 'react';
import { ChipField, useTranslate } from 'react-admin';
import _ from 'lodash';
import resourcesConfig from '../../../config/resourcesConfig.json';

const TypeField = props => {
    const translate = useTranslate();
    const emptyText = translate('pos.generic.unknown');
    const styles = [
        {
            backgroundColor: '#3f51b5',
            color: 'white'
        },
        {
            backgroundColor: '#2196f3',
            color: 'white'
        },
        {
            backgroundColor: '#e0e0e0',
            color: 'black'
        },
        {
            backgroundColor: '#ce93d8',
            color: 'white'
        },
        {
            backgroundColor: '#f44336',
            color: 'white'
        },
        {
            backgroundColor: '#66bb6a',
            color: 'white'
        },
        {
            backgroundColor: '#ffa726',
            color: 'black'
        },
        {
            backgroundColor: '#670089',
            color: 'white'
        },
        {
            backgroundColor: '#ff7400',
            color: 'white'
        },
        {
            backgroundColor: '#000000',
            color: 'white'
        },
        {
            backgroundColor: '#ffff00',
            color: 'black'
        },
        {
            backgroundColor: '#00ff17',
            color: 'black'
        },
        {
            backgroundColor: '#00fffb',
            color: 'black'
        },
        {
            backgroundColor: '#f000ff',
            color: 'white'
        },
        {
            backgroundColor: '#8b00ff',
            color: 'white'
        },
        {
            backgroundColor: '#090092',
            color: 'white'
        },
        {
            backgroundColor: '#6c6c6c',
            color: 'white'
        },
        {
            backgroundColor: '#02630b',
            color: 'white'
        },
        {
            backgroundColor: '#3c6da9',
            color: 'white'
        },
        {
            backgroundColor: '#31e3ab',
            color: 'black'
        },
        {
            backgroundColor: '#bd0034',
            color: 'white'
        },
        {
            backgroundColor: '#7a3b00',
            color: 'white'
        }
    ];

    const value = _.get(props.record, 'type');
    const typesAvailable = resourcesConfig.transactions.typesAvailable;
    const style = styles[typesAvailable.indexOf(value)] || styles[0];

    const defaultTranslation = value !== null ? value : '';
    const record = {
        [props.source]: translate(`resources.transactions.fields.type.options.${value}`,{
            _: defaultTranslation
        })
    };

    return (
        <ChipField
            record={record}
            source={props.source}
            emptyText={emptyText}
            color="primary"
            size="small"
            style={style}
        />
    );
};

export default TypeField;
