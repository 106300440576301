import * as React from 'react';
import { Datagrid, List, ReferenceField, TextField, ChipField, useTranslate } from 'react-admin';
import TerminalNumberTypeFilter from './TerminalNumberTypeFilter';
import CustomBulkActionButtons from '../../custom/CustomBulkActionButtons';
import CustomReferenceField from '../../custom/CustomReferenceField';

const TerminalNumberTypeList = ({ voidTitle = false, terminalTypeId, storeCode, filterDefaultValues, disabledFilters, hiddenColumns, ...props }) => {
    const translate = useTranslate();
    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(terminalTypeId && { terminalTypeId: terminalTypeId }),
        ...(storeCode && { storeCode: storeCode })
    };

    return (
        <List
            {...props}
            title={title}
            actions={null}
            bulkActionButtons={<CustomBulkActionButtons />}
            filters={
                <TerminalNumberTypeFilter
                    filter={filter}
                    filterDefaultValues={filterDefaultValues}
                    disabledFilters={disabledFilters}
                />
            }
            filter={filter}
        >
            <Datagrid
                hover={false}
                rowStyle={(record, index) => {
                    if (index % 2 === 0) {
                        return { backgroundColor: 'rgba(0, 0, 0, 0.04)' };
                    }
                }}
            >
                {(!hiddenColumns || !hiddenColumns.terminalNumber) &&
                    <ChipField
                        source="terminalNumber"
                        label={translate('resources.terminalNumberTypes.fields.terminalNumber', 1)}
                        color="primary"
                        size="small"
                    />
                }
                {(!hiddenColumns || !hiddenColumns.storeCode) &&
                    <CustomReferenceField
                        originalSource="storeCode"
                        injectSource="storeId"
                        reference="stores"
                        label="resources.terminalNumberTypes.fields.storeCode"
                    />
                }
                {(!hiddenColumns || !hiddenColumns.terminalType) &&
                    <ReferenceField
                        label="resources.terminalNumberTypes.fields.terminalType"
                        source="terminalTypeId"
                        reference="terminalTypes"
                        link="show"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                }
            </Datagrid>
        </List>
    );
};

export default TerminalNumberTypeList;
