import * as React from 'react';
import { useState } from 'react';
import {
    Edit,
    TextInput,
    TopToolbar,
    ListButton,
    ShowButton,
    useTranslate,
    FormWithRedirect,
    useEditController, maxLength, required, number, minValue, maxValue, useNotify, useRedirect, useRefresh
} from 'react-admin';
import { Grid } from '@material-ui/core';
import AddressInput from '../../custom/AddressInput';
import MapInput from '../../custom/MapInput';
import CustomTitle from '../../layout/CustomTitle';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import CustomEditToolbar from '../../custom/CustomEditToolbar';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    },
    input: {
        marginTop: '8px',
        marginBottom: '4px'
    },
    map: {
        height: '450px',
        width: '100%',
        margin: 0
    }
});

const StoreEditActions = ({ basePath, data }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath}/>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );
};

const storeCodeUniqueValidation = async (value, allValues) => {
    try {
        if (value) {
            const params = {
                storeCode: value,
                id: { $ne: allValues.id }
            }
            const queryString = stringify(params, { strictNullHandling: true });
            const response = await axios.get(`/stores?${queryString}`);
            const { data: stores } = response.data;
            if (stores.length > 0) {
                return 'resources.stores.crud.edit.validation.storeCodeNotUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    name: [maxLength(255), required()],
    storeCode: [maxLength(255), required(), storeCodeUniqueValidation],
    storeNumber: [number(), minValue(-2147483648), maxValue(2147483647)],
    address: [maxLength(255)],
    phone: [maxLength(255)],
    status: [maxLength(255)],
    chainId: [required()]
};

const StoreEdit = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const { record } = useEditController(props);
    const classes = useStyles();

    const [position, setPosition] = useState({
        lat: -2.1912,
        lng: -79.8867
    });

    const onSuccess = ({ data }) => {
        notify(`resources.stores.crud.edit.notification.success`, 'success');
        redirect(`/stores/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.stores.crud.edit.notification.failed`, 'error');
    };

    return (
        <Edit
            title={<CustomTitle action="edit" {...props} />}
            actions={<StoreEditActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            mutationMode="pessimistic"
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="id" resource="stores" helperText={false} disabled fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="stores"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="storeCode"
                                    resource="stores"
                                    validate={validations.storeCode}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="storeNumber"
                                    resource="stores"
                                    validate={validations.storeNumber}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="address"
                                    resource="stores"
                                    validate={validations.address}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="phone"
                                    resource="stores"
                                    validate={validations.phone}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="chainId"
                                    record={record}
                                    reference="chains"
                                    originSource="id"
                                    label={translate('resources.stores.fields.chain')}
                                    optionText="name"
                                    validate={validations.chainId}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AddressInput
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MapInput
                                    position={position}
                                    setPosition={setPosition}
                                    className={classes.map}
                                />
                            </Grid>
                        </Grid>
                        <CustomEditToolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            record={record}
                            resource={props.resource}
                            basePath={props.basePath}
                        />
                    </form>
                )}
            />
        </Edit>
    );
};

export default StoreEdit;
