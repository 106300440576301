import * as React from 'react';
import { useEffect } from 'react';
import { Button as RaButton, ExportButton, TextInput, useListContext, useTranslate } from 'react-admin';
import { Button, Grid, InputAdornment } from '@material-ui/core';
import {
    FilterList as FilterListIcon,
    Search as SearchIcon
} from '@material-ui/icons';
import { Form } from 'react-final-form';
import CustomReferenceAutocompleteArrayInput from '../../custom/CustomReferenceAutocompleteArrayInput';
import { CustomCreateButton } from '../../custom/CustomButton';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(2)
    },
    input: {
        marginTop: 0
    }
}));

const TerminalTypeFilter = props => {
    const classes = useStyles();

    return (
        <Grid container spacing={1} className={classes.root}>
            <Grid item xs={12}>
                <TerminalTypeFilterButton {...props} />
            </Grid>
            <Grid item xs={12}>
                <TerminalTypeFilterForm {...props} />
            </Grid>
        </Grid>
    );
};

const TerminalTypeFilterButton = ({ maxResults }) => {
    const translate = useTranslate();
    const {
        currentSort,
        resource,
        filterValues,
        showFilter,
        total,
        basePath
    } = useListContext();

    return (
        <Grid container justifyContent="flex-end">
            <Grid item>
                <RaButton
                    label={translate('pos.generic.filter')}
                    onClick={() => showFilter('main')}
                    size="small"
                    color="primary"
                >
                    <FilterListIcon />
                </RaButton>
            </Grid>
            <Grid item>
                <CustomCreateButton resource={resource} basePath={basePath} />
            </Grid>
            <Grid item>
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={maxResults}
                />
            </Grid>
        </Grid>
    );
};

const TerminalTypeFilterForm = ({ open, filter, filterDefaultValues, disabledFilters }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const {
        displayedFilters,
        filterValues,
        setFilters,
    } = useListContext();

    useEffect(() => {
        if (displayedFilters.main && filterDefaultValues && _.isEmpty(filterValues)) {
            setFilters(filterDefaultValues);
        }
    }, [displayedFilters, filterDefaultValues, filterValues, setFilters]);

    if (!displayedFilters.main) return null;

    const onSubmit = values => {
        setFilters(values);
    };

    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4} lg={3} xl={2}>
                                <TextInput
                                    resettable
                                    helperText={false}
                                    source="q"
                                    label={translate('pos.generic.generalSearch')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon color="disabled" />
                                            </InputAdornment>
                                        )
                                    }}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={3} xl={2}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filterValues}
                                    reference="chains"
                                    originSource="id"
                                    disabled={disabledFilters && disabledFilters.chainId}
                                    label={translate('resources.terminalTypes.fields.chain')}
                                    withNull={false}
                                    optionText="name"
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item container spacing={1} xs justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="outlined" color="primary" type="submit">
                                        {translate('pos.generic.filter')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={resetFilter}>
                                        {translate('ra.action.close')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        </div>
    );
};

export default TerminalTypeFilter;
