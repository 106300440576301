import * as React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './components/configuration/Configuration';
import Help from './components/help/Help';
import Statistics from './components/resources/transactions/statistics/Statistics';
import UserChangePassword from './components/resources/users/UserChangePassword';

const routes = [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/help" render={() => <Help />} />,
    <Route exact path="/transactions/statistics" render={() => <Statistics />} />,
    <Route exact path="/users/changePassword" render={() => <UserChangePassword />} />,
];

export default routes;
