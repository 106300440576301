import * as React from 'react';
import { AbilityContext } from './components/Can';
import defineAbilities from './config/abilities';
import { Admin, Resource, defaultTheme } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';
import feathersClient from './clients/feathersClient';
import { restClientOptions, authClientOptions } from './clients/feathersClient';
import { restClient } from 'ra-data-feathers';
import authClient from './clients/authClient';
import CustomLayout from './components/layout/CustomLayout';
import CustomLoginForm from './components/layout/CustomLoginForm';
import Dashboard from './components/dashboard/Dashboard';
import { TransactionList, TransactionShow } from './components/resources/transactions';
import { ChainList, ChainShow, ChainEdit, ChainCreate } from './components/resources/chains';
import { StoreList, StoreShow, StoreEdit, StoreCreate } from './components/resources/stores';
import {
    TerminalTypeList, TerminalTypeShow, TerminalTypeEdit, TerminalTypeCreate
} from './components/resources/terminalTypes';
import { TerminalNumberTypeList } from './components/resources/terminalNumberTypes';
import { GroupList, GroupShow, GroupEdit, GroupCreate } from './components/resources/groups';
import { OperatorList, OperatorShow, OperatorEdit, OperatorCreate } from './components/resources/operators';
import { ItemDetailList, ItemDetailShow, ItemDetailEdit, ItemDetailCreate } from './components/resources/itemDetails';
import { DepartmentList, DepartmentShow, DepartmentEdit, DepartmentCreate } from './components/resources/departments';
import { FileList, FileShow } from './components/resources/files';
import { UserList, UserShow, UserEdit, UserCreate } from './components/resources/users';
import { ExceptionList, ExceptionShow } from './exceptions';
import i18nProvider from './i18n/i18nProvider';
import customRoutes from './routes';

const theme = createTheme({
    ...defaultTheme,
    sidebar: {
        width: 260
    }
});

const dataProvider = restClient(feathersClient, restClientOptions);
const authProvider = authClient(feathersClient, authClientOptions);

const verifyAccess = permissions => (action, subject) => {
    return !!(permissions.find(permission => permission.action === action && permission.subject === subject));
};

const App = () => {
    return (
        <AbilityContext.Provider value={defineAbilities()}>
            <Admin
                theme={theme}
                layout={CustomLayout}
                loginPage={CustomLoginForm}
                dashboard={Dashboard}
                dataProvider={dataProvider}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                customRoutes={customRoutes}
            >
                {permissions => {
                    const verifyAccessPermissions = verifyAccess(permissions);

                    return [
                        <Resource name="statistics" />,
                        <Resource name="transactions" list={TransactionList} show={TransactionShow} />,
                        <Resource name="changes" />,
                        <Resource name="discounts" />,
                        <Resource name="items" />,
                        <Resource name="journals" />,
                        <Resource name="overrides" />,
                        <Resource name="taxes" />,
                        <Resource name="tenders" />,
                        <Resource name="epsTenders" />,
                        <Resource name="dataEntries" />,
                        <Resource name="tillChanges" />,
                        <Resource name="userStrings" />,
                        <Resource name="exceptions" list={ExceptionList} show={ExceptionShow} />,
                        <Resource
                            name="chains"
                            list={ChainList}
                            show={ChainShow}
                            edit={verifyAccessPermissions('update', 'chains') ? ChainEdit : null}
                            create={verifyAccessPermissions('create', 'chains') ? ChainCreate : null}
                        />,
                        <Resource
                            name="stores"
                            list={StoreList}
                            show={StoreShow}
                            edit={verifyAccessPermissions('update', 'stores') ? StoreEdit : null}
                            create={verifyAccessPermissions('create', 'stores') ? StoreCreate : null}
                        />,
                        <Resource
                            name="terminalTypes"
                            list={TerminalTypeList}
                            show={TerminalTypeShow}
                            edit={verifyAccessPermissions('update', 'terminalTypes') ? TerminalTypeEdit : null}
                            create={verifyAccessPermissions('create', 'terminalTypes') ? TerminalTypeCreate : null}
                        />,
                        <Resource name="terminalNumberTypes" list={TerminalNumberTypeList} />,
                        <Resource
                            name="groups"
                            list={GroupList}
                            show={GroupShow}
                            edit={verifyAccessPermissions('update', 'groups') ? GroupEdit : null}
                            create={verifyAccessPermissions('create', 'groups') ? GroupCreate : null}
                        />,
                        <Resource
                            name="operators"
                            list={OperatorList}
                            show={OperatorShow}
                            edit={verifyAccessPermissions('update', 'operators') ? OperatorEdit : null}
                            create={verifyAccessPermissions('create', 'operators') ? OperatorCreate : null}
                        />,
                        <Resource
                            name="itemDetails"
                            list={ItemDetailList}
                            show={ItemDetailShow}
                            edit={verifyAccessPermissions('update', 'itemDetails') ? ItemDetailEdit : null}
                            create={verifyAccessPermissions('create', 'itemDetails') ? ItemDetailCreate : null}
                        />,
                        <Resource
                            name="departments"
                            list={DepartmentList}
                            show={DepartmentShow}
                            edit={verifyAccessPermissions('update', 'departments') ? DepartmentEdit : null}
                            create={verifyAccessPermissions('create', 'departments') ? DepartmentCreate : null}
                        />,
                        <Resource
                            name="files"
                            list={FileList}
                            show={FileShow}
                        />,
                        <Resource
                            name="users"
                            list={UserList}
                            show={UserShow}
                            edit={verifyAccessPermissions('update', 'users') ? UserEdit : null}
                            create={verifyAccessPermissions('create', 'users') ? UserCreate : null}
                        />,
                        <Resource name="defaultRoles" />,
                        <Resource name="userRoles" />,
                    ];
                }}
            </Admin>
        </AbilityContext.Provider>
    );
};

export default App;
