import * as React from 'react';
import { Tab } from '@material-ui/core';
import { Link } from 'react-router-dom';

const CustomTab = ({ label, index, ...props }) => {
    return (
        <Tab
            label={label}
            id={`scrollable-auto-tab-${index}`}
            aria-controls={`scrollable-auto-tabpanel-${index}`}
            component={Link}
            onClick={event => {
                event.preventDefault();
            }}
            {...props}
        />
    );
};

export default CustomTab;
