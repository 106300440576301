import polyglotI18nProvider from 'ra-i18n-polyglot';
import spanishMessages from './es';
import moment from 'moment';

const i18nProvider = polyglotI18nProvider(locale => {
    moment.locale(locale);
    if (locale === 'en-US') {
        return import('./en').then(messages => messages.default);
    }
    return spanishMessages;
}, 'es-US');

export default i18nProvider;
