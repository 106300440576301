import * as React from 'react';
import {
    Create, FormDataConsumer, FormWithRedirect, TextInput, Toolbar, TopToolbar, maxLength, required, useNotify,
    useRedirect, useRefresh, useTranslate
} from 'react-admin';
import { Grid } from '@material-ui/core';
import CustomReferenceAutocompleteArrayInput from '../../custom/CustomReferenceAutocompleteArrayInput';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import { CustomListButton } from '../../custom/CustomButton';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    },
    input: {
        marginTop: '8px',
        marginBottom: '4px'
    },
});

const GroupCreateActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
        </TopToolbar>
    );
};

const storesInChainValidation = async (value, allValues) => {
    try {
        if (value && value.length > 0) {
            const queryString = stringify({ id: value }, { strictNullHandling: true });
            const response = await axios.get(`/stores?${queryString}`);
            const { data: stores } = response.data;
            if (stores.length > 0 && stores.find(store => store.chainId !== allValues.chainId)) {
                return 'resources.groups.crud.create.validation.storesNotInChain';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    name: [maxLength(255), required()],
    chainId: [required()],
    storeIds: [storesInChainValidation]
};

const GroupCreate = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        notify(`resources.groups.crud.create.notification.success`, 'success');
        redirect(`/groups/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.groups.crud.create.notification.failed`, 'error');
    };

    return (
        <Create
            actions={<GroupCreateActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="groups"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="chainId"
                                    reference="chains"
                                    originSource="id"
                                    label={translate('resources.groups.fields.chain')}
                                    optionText="name"
                                    validate={validations.chainId}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteInput
                                    source="parentId"
                                    reference="groups"
                                    originSource="id"
                                    label={translate('resources.groups.fields.parent')}
                                    optionText="name"
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormDataConsumer>
                                    {({ formData }) => (
                                        <CustomReferenceAutocompleteArrayInput
                                            source="storeIds"
                                            reference="stores"
                                            originSource="id"
                                            label={translate('resources.groups.fields.stores.name')}
                                            optionText="storeCode"
                                            withNull={false}
                                            validate={validations.storeIds}
                                            additionalFilter={
                                                formData.chainId ?
                                                    { chainId: formData.chainId } :
                                                    null
                                            }
                                            fullWidth={true}
                                            className={classes.input}
                                        />
                                    )}
                                </FormDataConsumer>
                            </Grid>
                        </Grid>
                        <Toolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                        />
                    </form>
                )}
            />
        </Create>
    );
};

export default GroupCreate;
