import * as React from 'react';
import { BulkUpdateWithUndoButton } from 'react-admin';
import BulkUpdateWithConfirmButton from './BulkUpdateWithConfirmButton';

const BulkUpdateButton = ({ undoable = true, filterValues, ...props }) => undoable ? (
    <BulkUpdateWithUndoButton {...props} />
) : (
    <BulkUpdateWithConfirmButton {...props} />
);

export default BulkUpdateButton;