import * as React from 'react';
import { Labeled, TextField, ReferenceField } from 'react-admin';
import { Grid } from '@material-ui/core';

const DepartmentGeneral = props => {
    return (
        <span>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.departments.fields.departmentCode">
                        <TextField record={props.record} source="departmentCode" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.departments.fields.name">
                        <TextField record={props.record} source="name" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.departments.fields.chain">
                        <ReferenceField label="resources.departments.fields.chain" source="chainId" reference="chains" link="show">
                            <TextField source="name" />
                        </ReferenceField>
                    </Labeled>
                </Grid>
            </Grid>
        </span>
    );
};

export default DepartmentGeneral;
