import * as React from 'react';
import { useContext, useMemo, useEffect, useState } from 'react';
import { NumberField, TextField, SelectInput, NumberInput, useLocale, useTranslate } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs,
    Typography
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Form } from 'react-final-form';
import SwipeableViews from 'react-swipeable-views';
import ExportModal from './ExportModal';
import DurationField from '../../../custom/DurationField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import CustomTab from '../../../custom/CustomTab';
import CustomTabPanel from '../../../custom/CustomTabPanel';
import getGroupByDefaultValue from '../../../../helpers/getGroupByDefaultValue';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import getGroupByChoices from '../../../../helpers/getGroupByChoices';
import { AbilityContext } from '../../../Can';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import moment from 'moment';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootDataTable: {
        backgroundColor: theme.palette.grey[100],
        [theme.breakpoints.down('xs')]: {
            width: '95vw'
        }
    },
    scrollableTabs: { width: 0 },
    swipeableViews: {
        width: '100%',
        height: '100%'
    },
    tabPanel: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    tableContainer: { maxHeight: '600px' },
    totalCell: { fontWeight: 500 },
    highlight: { backgroundColor: red['100'] },
    summaryTableContainer: { maxHeight: '300px' }
}));

const groupByChoices = [
    {
        id: 'terminalType.id',
        name: 'resources.transactions.fields.terminalType',
        fieldId: 'terminalType.id',
        fieldDataId: 'terminalType.name',
        fieldDataTranslate: 'resources.transactions.fields.terminalType'
    },
    {
        id: 'transaction.terminalNumber',
        name: 'resources.transactions.fields.terminalNumber',
        fieldId: 'transaction.terminalNumber',
        fieldDataId: 'transaction.terminalNumber',
        fieldDataTranslate: 'resources.transactions.fields.terminalNumber'
    },
    {
        id: 'transaction.operatorCode',
        name: 'resources.transactions.fields.operatorCode',
        fieldId: 'transaction.operatorCode',
        fieldDataId: 'operator.name',
        fieldDataTranslate: 'resources.transactions.fields.operatorName'
    },
    {
        id: 'transaction.storeCode',
        name: 'resources.transactions.fields.storeCode',
        fieldId: 'transaction.storeCode',
        fieldDataId: 'transaction.storeCode',
        fieldDataTranslate: 'resources.transactions.fields.storeCode'
    },
    {
        id: 'chain.id',
        name: 'resources.stores.fields.chain',
        fieldId: 'chain.id',
        fieldDataId: 'chain.name',
        fieldDataTranslate: 'resources.stores.fields.chain'
    }
];

const dateFormatter = translate => (precision, value) => {
    switch (precision) {
        case 'year':
            return moment(value).format('YYYY');
        case 'month':
            return moment(value).format(translate('pos.datetime.date').replace(/-DD|DD-/, ''));
        case 'day':
            return moment(value).format(translate('pos.datetime.date'));
        case 'hour':
            return moment(value).format(`${translate('pos.datetime.date')} HH:00`);
        case 'minute':
        default:
            return moment(value).format(translate('pos.datetime.datetime'));
    }
};

const getKey = groupBy => (e, idx = null) => {
    let key = idx !== null ? `${idx}-` : '';
    if (['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id)) {
        key += `${e[groupBy.fieldId]}-${e['chain.id']}-${e['transaction.storeCode']}`;
    } else {
        key += e[groupBy.fieldId];
    }
    return key;
};

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const ability = useContext(AbilityContext);

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const checkedGroupByChoices = useMemo(() => {
        const groupByIds = getGroupByChoices(
            groupByChoices.map(e => e.id), 'cashiers', ability.rulesFor('find', 'statistics')
        );
        return groupByChoices.filter(e => groupByIds.includes(e.id));
    }, [ability]);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="groupById"
                                    label="pos.generic.groupBy"
                                    choices={checkedGroupByChoices}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput
                                    source="precision"
                                    label="pos.generic.precision"
                                    choices={[
                                        { id: 'minute', name: translate('pos.datetime.minute', 2) },
                                        { id: 'hour', name: translate('pos.datetime.hour', 2) },
                                        { id: 'day', name: translate('pos.datetime.day', 2) },
                                        { id: 'month', name: translate('pos.datetime.month', 2) },
                                        { id: 'year', name: translate('pos.datetime.year', 2) },
                                    ]}
                                    helperText={false}
                                    margin="normal"
                                    size="small"
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="terminalTypeId"
                                    record={filters}
                                    reference="terminalTypes"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.transactions.fields.terminalType')}
                                    withNull={true}
                                    helperText={false}
                                    //groupByField="chainId"
                                    additionalFilter={{
                                        ...(form.getFieldState('chainId') && form.getFieldState('chainId').value &&
                                        _.isArray(form.getFieldState('chainId').value) ? {
                                            chainId: form.getFieldState('chainId').value
                                        } : {}),
                                        ...(form.getFieldState('storeCode') && form.getFieldState('storeCode').value &&
                                        _.isArray(form.getFieldState('storeCode').value) ? {
                                            storeCode: form.getFieldState('storeCode').value
                                        } : {})
                                    }}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberInput
                                    source="timeLimit"
                                    label="pos.generic.timeLimit"
                                    min="1"
                                    helperText={false}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                groupById: getGroupByDefaultValue(
                                    groupByChoices.map(e => e.id), 'cashiers',
                                    ability.rulesFor('find', 'statistics'), 'transaction.operatorCode'
                                ),
                                precision: 'day',
                                businessDayDate: getBusinessDayDateDefaultValue(),
                                timeLimit: 10
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    summaryTable: SummaryTable,
                    mainTable: ExportableDataTable
                }}
            />
        </div>
    );
};

const processData = ({
    translate, data, loading, cancel, groupBy, precision, timeLimit, setProcessingData, setProcessedData,
    setGroupByValues, setValue = null
}) => {
    setProcessingData(true);
    if (setValue) setValue(0);
    if (data && data.length > 0 && !loading && !cancel) {
        let processedData = [];
        let groupByValues = data.map(e => ({
            [groupBy.fieldId]: e[groupBy.fieldId],
            [groupBy.fieldDataId]: e[groupBy.fieldDataId],
            ...(['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && {
                'chain.id': e['chain.id'],
                'chain.name': e['chain.name'],
                'transaction.storeCode': e['transaction.storeCode']
            })
        }));
        groupByValues = Object.fromEntries(groupByValues.map(e => [getKey(groupBy)(e), e]));

        Object.entries(groupByValues).forEach(([groupByValue, value]) => {
            let filteredData = data.filter(e => String(getKey(groupBy)(e)) === groupByValue);
            let newData = {
                groupByValue: groupByValue,
                [groupBy.fieldId]: value[groupBy.fieldId],
                [groupBy.fieldDataId]: value[groupBy.fieldDataId],
                ...(['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && {
                    'chain.id': value['chain.id'],
                    'chain.name': value['chain.name'],
                    'transaction.storeCode': value['transaction.storeCode']
                }),
                data: filteredData.map(e => ({
                    datetime: dateFormatter(translate)(precision, e.datetime),
                    transactionQuantity: e.transactionQuantity,
                    itemQuantity: e.itemQuantity,
                    itemLineQuantity: e.itemLineQuantity,
                    ringElapsedTime: _.round(e.ringElapsedTime),
                    avgItemTime: _.round(e.avgItemTime),
                    avgItemLineTime: _.round(e.avgItemLineTime)
                })),
                total: {
                    transactionQuantity: _.sumBy(filteredData, 'transactionQuantity'),
                    itemQuantity: _.sumBy(filteredData, 'itemQuantity'),
                    itemLineQuantity: _.sumBy(filteredData, 'itemLineQuantity'),
                    ringElapsedTime: _.round(_.sumBy(filteredData, 'ringElapsedTime'))
                },
                avg: {
                    avgItemTime: _.round(_.meanBy(filteredData, 'avgItemTime')),
                    avgItemLineTime: _.round(_.meanBy(filteredData, 'avgItemLineTime')),
                }
            };
            newData.highlight = newData.data.some(e => e.avgItemTime > timeLimit || e.avgItemLineTime > timeLimit);
            newData.avgTimes = (newData.avg.avgItemTime + newData.avg.avgItemLineTime) / 2;
            processedData.push(newData);
        });

        processedData = _.orderBy(processedData, 'avgTimes', ['desc']);
        processedData = _.orderBy(processedData, 'highlight', ['desc']);
        groupByValues = Object.fromEntries(processedData.map((e, idx) => [getKey(groupBy)(e, idx), {
            [groupBy.fieldId]: e[groupBy.fieldId],
            [groupBy.fieldDataId]: e[groupBy.fieldDataId],
            highlight: e.highlight,
            ...(['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && {
                'chain.id': e['chain.id'],
                'chain.name': e['chain.name'],
                'transaction.storeCode': e['transaction.storeCode']
            })
        }]));
        processedData = Object.fromEntries(processedData.map((e, idx) => [getKey(groupBy)(e, idx), e]));

        setGroupByValues(groupByValues);
        setProcessedData(processedData);
        setProcessingData(false);
    }
};

const DataTable = ({ filters, data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const [groupByValues, setGroupByValues] = useState();
    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { groupById, precision, timeLimit } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    useEffect(() => {
        processData({
            translate, data, loading, cancel, groupBy, precision, timeLimit, setProcessingData, setProcessedData,
            setGroupByValues, setValue
        });
    }, [translate, data, loading, cancel, groupBy, precision, timeLimit]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = index => {
        setValue(index);
    };

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!groupByValues || !processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const getTabLabel = value => {
        if (groupBy.id === 'terminalType.id') {
            if (value[groupBy.fieldDataId]) {
                return `${value[groupBy.fieldDataId]} (${value['chain.name']} - ${value['transaction.storeCode']})`;
            }
            return `${translate('resources.transactions.fields.withoutTerminalType')} (${value['chain.name']} - ${value['transaction.storeCode']})`;
        } else if (groupBy.id === 'transaction.operatorCode') {
            if (value[groupBy.fieldDataId]) {
                return `${value[groupBy.fieldDataId]} (${value[groupBy.fieldId]} - ${value['chain.name']} - ${value['transaction.storeCode']})`;
            }
            return `${value[groupBy.fieldId]} (${value['chain.name']} - ${value['transaction.storeCode']})`;
        }
        return value[groupBy.fieldDataId];
    };

    return (
        <div className={classes.rootDataTable}>
            <Tabs
                value={value}
                onChange={handleChange}
                orientation="horizontal"
                variant="scrollable"
                scrollButtons="on"
                classes={{ scrollable: classes.scrollableTabs }}
            >
                {(loading || processingData) ? (
                    <CustomTab label={<CircularProgress size={25} />} />
                ) : (Object.entries(groupByValues).map(([groupByValue, value]) => (
                    <CustomTab
                        label={getTabLabel(value)}
                        index={Object.keys(groupByValues).indexOf(groupByValue)}
                        key={`tab-${groupByValue}`}
                        wrapped
                        className={value.highlight ? classes.highlight: undefined}
                    />
                )))}
            </Tabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                enableMouseEvents={true}
                resistance={true}
                className={classes.swipeableViews}
            >
                {Object.keys(groupByValues).map(groupByValue => (
                    <CustomTabPanel
                        value={value}
                        index={Object.keys(groupByValues).indexOf(groupByValue)}
                        key={`tabpanel-${groupByValue}`}
                        dir={theme.direction}
                        className={classes.tabPanel}
                    >
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {translate('pos.datetime.dateName')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })} ({translate('pos.generic.customers')})
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.itemQuantity')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.itemLineQuantity')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('resources.transactions.fields.ringElapsedTime')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.avgItemTime')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.avgItemLineTime')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {(loading || processingData) ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {processedData[groupByValue].data.map(row => (
                                            <TableRow key={`${groupByValue}-${row.datetime}`}>
                                                <TableCell>
                                                    <TextField record={row} source="datetime" />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberField
                                                        record={row}
                                                        source="transactionQuantity"
                                                        locales={locale}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberField
                                                        record={row}
                                                        source="itemQuantity"
                                                        locales={locale}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberField
                                                        record={row}
                                                        source="itemLineQuantity"
                                                        locales={locale}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <DurationField
                                                        record={row}
                                                        source="ringElapsedTime"
                                                        shortFormat
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    className={
                                                        row.avgItemTime > timeLimit ?
                                                            classes.highlight : undefined
                                                    }
                                                >
                                                    <DurationField
                                                        record={row}
                                                        source="avgItemTime"
                                                        shortFormat
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    className={
                                                        row.avgItemLineTime > timeLimit ?
                                                            classes.highlight : undefined
                                                    }
                                                >
                                                    <DurationField
                                                        record={row}
                                                        source="avgItemLineTime"
                                                        shortFormat
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow key={`total-${groupByValue}`}>
                                            <TableCell className={classes.totalCell}>
                                                {translate('pos.generic.total', 1)}
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberField
                                                    record={processedData[groupByValue].total}
                                                    source="transactionQuantity"
                                                    locales={locale}
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberField
                                                    record={processedData[groupByValue].total}
                                                    source="itemQuantity"
                                                    locales={locale}
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberField
                                                    record={processedData[groupByValue].total}
                                                    source="itemLineQuantity"
                                                    locales={locale}
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <DurationField
                                                    record={processedData[groupByValue].total}
                                                    source="ringElapsedTime"
                                                    shortFormat
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                            <TableCell colSpan={2} />
                                        </TableRow>
                                        <TableRow key={`avg-${groupByValue}`}>
                                            <TableCell className={classes.totalCell}>
                                                {translate('pos.generic.average', 1)}
                                            </TableCell>
                                            <TableCell colSpan={4} />
                                            <TableCell
                                                align="right"
                                                className={
                                                    processedData[groupByValue].avg.avgItemTime > timeLimit ?
                                                        classes.highlight : undefined
                                                }
                                            >
                                                <DurationField
                                                    record={processedData[groupByValue].avg}
                                                    source="avgItemTime"
                                                    shortFormat
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                className={
                                                    processedData[groupByValue].avg.avgItemLineTime > timeLimit ?
                                                        classes.highlight : undefined
                                                }
                                            >
                                                <DurationField
                                                    record={processedData[groupByValue].avg}
                                                    source="avgItemLineTime"
                                                    shortFormat
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </CustomTabPanel>
                ))}
            </SwipeableViews>
        </div>
    );
};

const ExportableDataTable = ({ filters, data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();

    const [groupByValues, setGroupByValues] = useState();
    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { groupById, precision, timeLimit } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    useEffect(() => {
        processData({
            translate, data, loading, cancel, groupBy, precision, timeLimit, setProcessingData, setProcessedData,
            setGroupByValues
        });
    }, [translate, data, loading, cancel, groupBy, precision, timeLimit]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!groupByValues || !processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const firstHeaderLabel = value => {
        if (groupBy.id === 'terminalType.id') {
            if (value[groupBy.fieldDataId]) {
                return `${value[groupBy.fieldDataId]} (${value['chain.name']} - ${value['transaction.storeCode']})`;
            }
            return `${translate('resources.transactions.fields.withoutTerminalType')} (${value['chain.name']} - ${value['transaction.storeCode']})`;
        } else if (groupBy.id === 'transaction.operatorCode') {
            if (value[groupBy.fieldDataId]) {
                return `${value[groupBy.fieldDataId]} (${value[groupBy.fieldId]} - ${value['chain.name']} - ${value['transaction.storeCode']})`;
            }
            return `${value[groupBy.fieldId]} (${value['chain.name']} - ${value['transaction.storeCode']})`;
        }
        return value[groupBy.fieldDataId];
    };

    return (
        <TableContainer component={Paper}>
            {Object.entries(groupByValues).map(([groupByValue, value]) => (
                <Table size="small" key={groupByValue}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={7}>
                                {firstHeaderLabel(value)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {translate('pos.datetime.dateName')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('pos.generic.quantityOf', { of: translate('resources.transactions.name', 2) })} ({translate('pos.generic.customers')})
                            </TableCell>
                            <TableCell align="right">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.itemQuantity')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.itemLineQuantity')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('resources.transactions.fields.ringElapsedTime')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.avgItemTime')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.avgItemLineTime')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {(loading || processingData) ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={7} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {processedData[groupByValue].data.map(row => (
                                <TableRow key={`${groupByValue}-${row.datetime}`}>
                                    <TableCell>
                                        <TextField record={row} source="datetime" />
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumberField
                                            record={row}
                                            source="transactionQuantity"
                                            locales={locale}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumberField
                                            record={row}
                                            source="itemQuantity"
                                            locales={locale}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumberField
                                            record={row}
                                            source="itemLineQuantity"
                                            locales={locale}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <DurationField
                                            record={row}
                                            source="ringElapsedTime"
                                            shortFormat
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className={
                                            row.avgItemTime > timeLimit ?
                                                classes.highlight : undefined
                                        }
                                    >
                                        <DurationField
                                            record={row}
                                            source="avgItemTime"
                                            shortFormat
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className={
                                            row.avgItemLineTime > timeLimit ?
                                                classes.highlight : undefined
                                        }
                                    >
                                        <DurationField
                                            record={row}
                                            source="avgItemLineTime"
                                            shortFormat
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow key={`total-${groupByValue}`}>
                                <TableCell className={classes.totalCell}>
                                    {translate('pos.generic.total', 1)}
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={processedData[groupByValue].total}
                                        source="transactionQuantity"
                                        locales={locale}
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={processedData[groupByValue].total}
                                        source="itemQuantity"
                                        locales={locale}
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={processedData[groupByValue].total}
                                        source="itemLineQuantity"
                                        locales={locale}
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <DurationField
                                        record={processedData[groupByValue].total}
                                        source="ringElapsedTime"
                                        shortFormat
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                                <TableCell colSpan={2} />
                            </TableRow>
                            <TableRow key={`avg-${groupByValue}`}>
                                <TableCell className={classes.totalCell}>
                                    {translate('pos.generic.average', 1)}
                                </TableCell>
                                <TableCell colSpan={4} />
                                <TableCell
                                    align="right"
                                    className={
                                        processedData[groupByValue].avg.avgItemTime > timeLimit ?
                                            classes.highlight : undefined
                                    }
                                >
                                    <DurationField
                                        record={processedData[groupByValue].avg}
                                        source="avgItemTime"
                                        shortFormat
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={
                                        processedData[groupByValue].avg.avgItemLineTime > timeLimit ?
                                            classes.highlight : undefined
                                    }
                                >
                                    <DurationField
                                        record={processedData[groupByValue].avg}
                                        source="avgItemLineTime"
                                        shortFormat
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            ))}
        </TableContainer>
    );
};

const SummaryTable = ({ filters, data, loading, cancel, error, exportable = false }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');

    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    let { groupById, precision, chainId, timeLimit } = filters;

    const groupBy = useMemo(() => {
        return groupByChoices.find(e => e.id === groupById);
    }, [groupById]);

    useEffect(() => {
        setProcessingData(true);
        if (data && data.length > 0 && !loading && !cancel) {
            let processedData = [];
            let groupByValues = data.map(e => ({
                [groupBy.fieldId]: e[groupBy.fieldId],
                [groupBy.fieldDataId]: e[groupBy.fieldDataId],
                ...(['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && {
                    'chain.id': e['chain.id'],
                    'chain.name': e['chain.name'],
                    'transaction.storeCode': e['transaction.storeCode']
                })
            }));

            groupByValues = Object.fromEntries(groupByValues.map(e => [getKey(groupBy)(e), e]));

            Object.entries(groupByValues).forEach(([groupByValue, value]) => {
                let filteredData = data.filter(e => String(getKey(groupBy)(e)) === groupByValue);
                let highlightFilter = filteredData.filter(e => _.round(e.avgItemTime) > timeLimit || _.round(e.avgItemLineTime) > timeLimit);
                if (highlightFilter.length > 0) {
                    let newData = {
                        groupByValue: groupByValue,
                        [groupBy.fieldId]: value[groupBy.fieldId],
                        [groupBy.fieldDataId]: value[groupBy.fieldDataId],
                        ...(['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && {
                            'chain.id': value['chain.id'],
                            'chain.name': value['chain.name'],
                            'transaction.storeCode': value['transaction.storeCode']
                        }),
                        repetitionQuantity: highlightFilter.length,
                        avgItemTime: _.round(_.meanBy(filteredData, 'avgItemTime')),
                        avgItemLineTime: _.round(_.meanBy(filteredData, 'avgItemLineTime'))
                    };
                    newData.avgTimes = (newData.avgItemTime + newData.avgItemLineTime) / 2;
                    processedData.push(newData);
                }
            });

            processedData = _.orderBy(processedData, 'avgTimes', ['desc']);
            setProcessedData(processedData);
            setProcessingData(false);
        }
    }, [translate, data, loading, cancel, groupBy, chainId, timeLimit]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    return (
        <TableContainer component={Paper} className={!exportable ? classes.summaryTableContainer : undefined}>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow key="rowHeader-1">
                        <TableCell
                            colSpan={groupBy.id === 'terminalType.id' ? 5 : groupBy.id === 'transaction.operatorCode' ? 6 : 4}
                            align="center"
                        >
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.summaryTitle', {
                                group_by: translate(groupBy.name)
                            })}
                        </TableCell>
                    </TableRow>
                    <TableRow key="rowHeader-2">
                        {groupBy.id !== 'terminalType.id' &&
                            <TableCell>
                                {translate(groupBy.name)}
                            </TableCell>
                        }
                        {['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) && (
                            <React.Fragment>
                                <TableCell>
                                    {translate(groupBy.fieldDataTranslate)}
                                </TableCell>
                                <TableCell>
                                    {translate('resources.transactions.fields.storeCode')}
                                </TableCell>
                            </React.Fragment>
                        )}
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.repetitionQuantity', {
                                precision: translate(`pos.datetime.${precision}`, 2)
                            })}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.avgItemTime')}
                        </TableCell>
                        <TableCell align="right">
                            {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.avgItemLineTime')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {(loading || processingData) ? (
                    <TableBody>
                        <TableRow>
                            <TableCell
                                colSpan={groupBy.id === 'terminalType.id' ? 5 : groupBy.id === 'transaction.operatorCode' ? 6 : 4}
                                align="center"
                            >
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {processedData.length === 0 ? (
                            <TableRow>
                                <TableCell
                                    colSpan={groupBy.id === 'terminalType.id' ? 5 : groupBy.id === 'transaction.operatorCode' ? 6 : 4}
                                    align="center"
                                >
                                    {translate('resources.transactions.statistics.errors.noData')}
                                </TableCell>
                            </TableRow>
                        ) : (processedData.map(row => {
                            if (!(groupBy.fieldId in row)) {
                                return null;
                            }

                            return (
                                <TableRow key={getKey(groupBy)(row)}>
                                    {groupBy.id !== 'terminalType.id' &&
                                        <TableCell>
                                            <TextField
                                                record={row}
                                                source={groupBy.id === 'chain.id' ? groupBy.fieldDataId : groupBy.fieldId}
                                            />
                                        </TableCell>
                                    }
                                    {groupBy.id === 'terminalType.id' &&
                                        <TableCell>
                                            <TextField
                                                record={row}
                                                source="terminalType.name"
                                                emptyText={translate('resources.transactions.fields.withoutTerminalType')}
                                            />
                                        </TableCell>
                                    }
                                    {groupBy.id === 'transaction.operatorCode' &&
                                        <TableCell>
                                            <TextField record={row} source="operator.name" emptyText={emptyText} />
                                        </TableCell>
                                    }
                                    {['terminalType.id', 'transaction.operatorCode'].includes(groupBy.id) &&
                                        <TableCell>
                                            <TextField record={row} source="transaction.storeCode" />
                                        </TableCell>
                                    }
                                    <TableCell align="right">
                                        <NumberField record={row} source="repetitionQuantity" locales={locale} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <DurationField
                                            record={row}
                                            source="avgItemTime"
                                            shortFormat
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <DurationField
                                            record={row}
                                            source="avgItemLineTime"
                                            shortFormat
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        }))}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let {
        groupById, precision, businessDayDate, chainId, storeCode, terminalTypeId, terminalNumber, operatorCode, limit
    } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const groupBy = groupByChoices.find(e => e.id === groupById);
        const params = {
            type: 'cashiers',
            groupBy: groupBy.fieldId,
            precision: precision,
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalTypeId: terminalTypeId,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        renderKey, groupById, precision, businessDayDate, chainId, storeCode, terminalTypeId, terminalNumber,
        operatorCode, limit
    ]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const Cashiers = () => {
    const translate = useTranslate();
    const ability = useContext(AbilityContext);

    const filters = {
        groupById: getGroupByDefaultValue(
            groupByChoices.map(e => e.id), 'cashiers',
            ability.rulesFor('find', 'statistics'), 'transaction.operatorCode'
        ),
        precision: 'day',
        businessDayDate: getBusinessDayDateDefaultValue(),
        timeLimit: 10
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.operatorProductivity.sections.cashiers.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Filter
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                                onSubmit={onSubmit}
                                cancelTokenSource={cancelTokenSource}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <SummaryTable
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default Cashiers;
