import * as React from 'react';
import { createRef, useEffect, useState } from 'react';
import { NumberField, TextField, useTranslate, useLocale } from 'react-admin';
import {
    CardContent, Card, CardHeader, IconButton, CircularProgress, Menu, MenuItem, Paper, Table, TableHead, TableRow,
    TableCell, TableBody, TableContainer, Divider
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import CustomError from '../../custom/CustomError';
import getBusinessDayDateRange from '../../../helpers/getBusinessDayDateRange';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { exportComponentAsPNG } from 'react-component-export-image';
import resourcesConfig from '../../../config/resourcesConfig.json';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    card: {
        minHeight: '150px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loading: {
        minHeight: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: { maxHeight: '300px' },
});

const periodOptions = [
    { id: 'yesterday', name: 'pos.dashboard.kpi.periods.yesterday' },
    { id: 'thisMonth', name: 'pos.dashboard.kpi.periods.thisMonth' },
    { id: 'lastMonth', name: 'pos.dashboard.kpi.periods.lastMonth' },
    { id: 'thisYear', name : 'pos.dashboard.kpi.periods.thisYear' },
    { id: 'lastYear', name: 'pos.dashboard.kpi.periods.lastYear' }
];

const PosOperatorField = props => {
    const record = {};
    if (props.record['transactionOperator.name']) {
        record.posOperator = `${props.record['transactionOperator.name']} (${props.record['transaction.operatorCode']})`;
    } else {
        record.posOperator = props.record['transaction.operatorCode'];
    }

    return (
        <TextField record={record} source="posOperator" />
    );
};

const VoidedItemsTransactionOperatorsAmountRanking = ({ filters, period = 'thisMonth' }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const currency = resourcesConfig.general.currency;

    const [periodSelected, setPeriodSelected] = useState(period);
    const [anchorEl, setAnchorEl] = useState(null);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const tableRef = createRef();

    let { storeCode, chainId } = filters ? filters : {};

    const handleClickMenuButton = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickMenuItem = (event, period) => {
        setPeriodSelected(period);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExportTable = () => {
        if (tableRef && tableRef.current) {
            const { scrollLeft, scrollTop } = tableRef.current.parentElement;
            tableRef.current.parentElement.scrollTo(0, 0);
            exportComponentAsPNG(tableRef, {
                fileName: `${translate('pos.dashboard.kpi.voidedItemsTransactionOperatorsAmountRanking.name')} ` +
                    `(${translate(`pos.dashboard.kpi.periods.${periodSelected}`)})`,
                html2CanvasOptions: {
                    backgroundColor: 'white',
                    scale: 2
                }
            })
                .then(() => {
                    tableRef.current.parentElement.scrollTo(scrollLeft, scrollTop);
                });
        }
    };

    useEffect(() => {
        setLoading(true);

        const params = {
            type: 'overrides-voided-items',
            groupBy: ['transaction.operatorCode', 'chain.id'],
            businessDayDate: getBusinessDayDateRange(periodSelected),
            storeCode: storeCode,
            chainId: chainId,
            orderBy: 'amount',
            orderType: 'DESC',
            limit: 10,
        };
        const queryString = stringify(params, { strictNullHandling: true });

        axios.get(`/statistics?${queryString}`)
            .then(response => {
                const { data } = response.data;
                setData(data);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [storeCode, chainId, periodSelected]);

    if (error) {
        return (
            <CustomError
                errorSecondary={translate('pos.dashboard.kpi.errors.noKpi', {
                    kpi_name: translate('pos.dashboard.kpi.voidedItemsTransactionOperatorsAmountRanking.name')
                })}
            />
        );
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton onClick={handleClickMenuButton}>
                        <MoreVertIcon />
                    </IconButton>
                }
                title={translate('pos.dashboard.kpi.voidedItemsTransactionOperatorsAmountRanking.name')}
                subheader={translate(`pos.dashboard.kpi.periods.${periodSelected}`)}
                titleTypographyProps={{
                    variant: 'h5',
                    align: 'center'
                }}
                subheaderTypographyProps={{
                    variant: 'h6',
                    align: 'center',
                    color: 'textSecondary'
                }}
            />
            <CardContent>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table size="small" stickyHeader ref={tableRef}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {translate('pos.dashboard.kpi.voidedItemsTransactionOperatorsAmountRanking.posOperator', 2)}
                                </TableCell>
                                <TableCell>
                                    {translate('resources.chains.name', 1)}
                                </TableCell>
                                <TableCell align="right">
                                    {translate('pos.generic.amountOf', { of: translate('resources.transactions.tabs.items', 2) })}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {data.length === 0 ? (
                                    <TableRow>
                                        <TableCell align="center" colSpan={3}>
                                            {translate('pos.dashboard.kpi.errors.noData')}
                                        </TableCell>
                                    </TableRow>
                                ) : (data.map(row => (
                                    <TableRow key={`${row['transaction.operatorCode']}-${row['chain.name']}`}>
                                        <TableCell>
                                            <PosOperatorField record={row} />
                                        </TableCell>
                                        <TableCell>
                                            <TextField record={row} source="chain.name" />
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                record={row}
                                                source="amount"
                                                locales={locale}
                                                options={{style: 'currency', currency: currency}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </CardContent>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
            >
                {periodOptions.map(option => (
                    <MenuItem
                        key={option.id}
                        selected={option.id === periodSelected}
                        onClick={event => handleClickMenuItem(event, option.id)}
                    >
                        {translate(option.name)}
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem
                    key="export"
                    onClick={handleExportTable}
                    disabled={loading || !data || (data && data.length === 0)}
                >
                    {translate('pos.generic.exportTable')}
                </MenuItem>
            </Menu>
        </Card>
    );
};

export default VoidedItemsTransactionOperatorsAmountRanking;
