import * as React from 'react';
import { useEffect, useState } from 'react';
import { FieldTitle, InputHelperText, LinearProgress, ResettableTextField, useTranslate } from 'react-admin';
import { useInput } from 'ra-core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip } from '@material-ui/core';
import _ from 'lodash';

const CustomMultiselectInput = props => {
    const translate = useTranslate();

    const {
        className,
        label,
        format,
        helperText = true,
        onBlur,
        onFocus,
        onChange,
        parse,
        record,
        resource,
        source,
        validate,
        ...rest
    } = props;

    const {
        id,
        input,
        isRequired,
        meta: { error: inputError, submitError, touched }
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'text',
        validate,
        ...rest
    });

    const [initialValue, setInitialValue] = useState();
    const [value, setValue] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const value = _.get(record, source);
        if (value) {
            setInitialValue(value);
        }
    }, [record, source]);

    useEffect(() => {
        if (initialValue) {
            setLoading(true);
            setValue(initialValue);
            //input.onChange(initialValue);
            setInitialValue();
        }
        setLoading(false);
    }, [initialValue, onChange, value]);

    if (loading) return <LinearProgress />;

    const handleChange = (event, newValue) => {
        input.onChange(newValue);
        setValue(newValue);
        if (onChange) {
            onChange(event, newValue, input, setValue);
        }
    };

    return (
        <Autocomplete
            value={value}
            inputValue={inputValue}
            onChange={handleChange}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={[]}
            multiple
            freeSolo
            renderTags={(value, getTagProps) => {
                value = Array.isArray(value) ? value : [value];
                setValue(value);
                return value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                ));
            }}
            renderInput={(params) => (
                <ResettableTextField
                    id={id}
                    {...input}
                    {...params}
                    label={(label !== '' && label !== false) && (
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    )}
                    placeholder={translate('pos.generic.pressEnter')}
                    variant="filled"
                    margin="normal"
                    size="small"
                    error={!!(touched && (inputError || submitError))}
                    helperText={(helperText && (touched && (inputError || submitError))) && (
                        <InputHelperText
                            touched={touched}
                            error={inputError || submitError}
                            helperText={(touched && inputError) ? inputError : null}
                        />
                    )}
                    className={className}
                    {...rest}
                />
            )}
            {...rest}
        />
    );
};

export default CustomMultiselectInput;
