import * as React from 'react';
import { List } from 'react-admin';
import CustomDatagrid from '../../../../custom/CustomDatagrid';
import TillChangeFilter from './TillChangeFilter';

const TillChangeList = ({ voidTitle = false, transactionId, ...props }) => {
    const title = voidTitle ? ' ' : null;
    const filter = {
        ...(transactionId && { transactionId: transactionId })
    };

    return (
        <List
            {...props}
            filters={<TillChangeFilter filter={filter} />}
            title={title}
            filter={filter}
            sort={{ field: 'id', order: 'ASC' }}
        >
            <CustomDatagrid source="tillChanges" />
        </List>
    );
}

export default TillChangeList;
