import * as React from 'react';
import { useState } from 'react';
import { Button, useTranslate, downloadCSV } from 'react-admin';
import { Toolbar, Typography, IconButton, MenuItem, Menu } from '@material-ui/core';
import {
    GetApp as DownloadIcon,
    Clear as ClearIcon,
    MoreVert as MoreVertIcon
} from '@material-ui/icons';
import clsx from 'clsx';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import _ from 'lodash';
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';
import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    toolbarRoot: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        minHeight: '48px'
    },
    highlight: {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
    },
    title: {
        flex: '1 1 100%',
    },
    buttonsRoot: {
        display: 'inline-flex'
    },
}));

const CustomTableToolbar = ({
    children, data, rowIdKey, headCells, title, numSelected, onSelectAllClick, order, orderBy, selected,
    menuButtons = false, exportExcelComponent = false
}) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickMenuButton = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickExportCsv = () => {
        let csv;
        let filteredHeadCells = headCells.filter(e => !e.noExport);
        if (numSelected > 0) {
            let filteredData = data.data.filter(e => selected.includes(e[rowIdKey]));
            csv = convertToCSV({
                data: _.orderBy(filteredData, [orderBy], [order]),
                fields: _.sortBy(filteredHeadCells, 'sort').map(e => e.id)
            });
        } else {
            csv = convertToCSV({
                data: _.orderBy(data.data, [orderBy], [order]),
                fields: _.sortBy(filteredHeadCells, 'sort').map(e => e.id)
            });
        }
        const filename = title ? `${title}-data` : 'data';
        downloadCSV(csv, filename);
    };

    const handleClickExportExcel = () => {
        let dataToSave;
        if (numSelected > 0) {
            dataToSave = data.data.filter(e => selected.includes(e[rowIdKey])).map(e => ({ ...e }));
        } else {
            dataToSave = data.data.map(e => ({ ...e }));
        }
        dataToSave = _.orderBy(dataToSave, [orderBy], [order]);

        const filteredHeadCells = _.sortBy(headCells.filter(e => !e.noExport), 'sort');
        const header = filteredHeadCells.map(e => e.id);
        let customHeader = filteredHeadCells.map(e => ({ [e.id]: e.label }));
        customHeader = customHeader.reduce((obj, item) => Object.assign(obj, { ...item }), {});

        dataToSave = dataToSave.map(e => {
            for (let key in e) {
                if (header.includes(key)) {
                    let headCell = filteredHeadCells.find(e => e.id === key);
                    if (headCell.formatter) {
                        e[key] = headCell.formatter(e[key]);
                    }
                } else {
                    delete e[key];
                }
            }
            return e;
        });

        dataToSave.unshift(customHeader);

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const filename = title ? `${title}-data.xlsx` : 'data.xlsx';

        const worksheet = XLSX.utils.json_to_sheet(dataToSave, { header: header, skipHeader: true, cellStyles: true });
        const workbook = { Sheets: { 'data': worksheet, cellStyles: true }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });
        const excel = new Blob([excelBuffer], { type: fileType });
        saveAs(excel, filename);
    };

    return (
        <Toolbar
            className={clsx(classes.toolbarRoot, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 && (
                <IconButton onClick={onSelectAllClick} style={{ padding: '3px', marginRight: '0.5em' }}>
                    <ClearIcon />
                </IconButton>
            )}
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected === 1 ? (
                        translate('ra.action.bulk_actions', 1)
                    ) : (
                        translate('ra.action.bulk_actions', numSelected)
                    )}
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}
            <div className={classes.buttonsRoot}>
                {menuButtons ? (
                    <React.Fragment>
                        <IconButton onClick={handleClickMenuButton}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={!!anchorEl}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={handleClickExportCsv}
                                disabled={data.data.length === 0}
                                key="export-all-selected-csv"
                            >
                                {numSelected === 0 ?
                                    translate('pos.generic.exportAllAs', { as: 'CSV' }) :
                                    translate('pos.generic.exportSelectedAs', { as: 'CSV' })
                                }
                            </MenuItem>
                            {!exportExcelComponent ? (
                                <MenuItem
                                    onClick={handleClickExportExcel}
                                    disabled={data.data.length === 0}
                                    key="export-all-selected-xlsx"
                                >
                                    {numSelected === 0 ?
                                        translate('pos.generic.exportAllAs', { as: 'Excel' }) :
                                        translate('pos.generic.exportSelectedAs', { as: 'Excel' })
                                    }
                                </MenuItem>
                            ) : exportExcelComponent}
                            {children && children()}
                        </Menu>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Button
                            label={numSelected === 0 ?
                                translate('pos.generic.exportAllAs', { as: 'CSV' }) :
                                translate('pos.generic.exportSelectedAs', { as: 'CSV' })
                            }
                            onClick={handleClickExportCsv}
                            disabled={data.data.length === 0}
                        >
                            <DownloadIcon />
                        </Button>
                        <Button
                            label={numSelected === 0 ?
                                translate('pos.generic.exportAllAs', { as: 'Excel' }) :
                                translate('pos.generic.exportSelectedAs', { as: 'Excel' })
                            }
                            onClick={handleClickExportExcel}
                            disabled={data.data.length === 0}
                        >
                            <DownloadIcon />
                        </Button>
                        {children && children()}
                    </React.Fragment>
                )}
            </div>
        </Toolbar>
    );
};

export default CustomTableToolbar;
