import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
    Create, FormDataConsumer, FormWithRedirect, BooleanInput, PasswordInput, TextInput, Toolbar, TopToolbar, email, maxLength,
    maxValue, minLength, minValue, number, required, useNotify, useRedirect, useRefresh, useTranslate
} from 'react-admin';
import { Typography, Grid } from '@material-ui/core';
import { CustomListButton } from '../../custom/CustomButton';
import CustomReferenceAutocompleteInput from '../../custom/CustomReferenceAutocompleteInput';
import CustomReferenceAutocompleteArrayInput from '../../custom/CustomReferenceAutocompleteArrayInput';
import PermissionsSelectInput from './PermissionsSelectInput';
import { AbilityContext } from '../../Can';
import _ from 'lodash';
import axios from '../../../clients/axiosClient';
import { stringify } from 'qs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    grid: {
        padding: '1em'
    }
});

const UserCreateActions = props => {
    return (
        <TopToolbar>
            <CustomListButton {...props} />
        </TopToolbar>
    );
};

const emailUniqueValidation = async (value) => {
    try {
        if (value) {
            const queryString = stringify({ email: value }, { strictNullHandling: true });
            const response = await axios.get(`/users?${queryString}`);
            const { data: users } = response.data;
            if (users.length > 0) {
                return 'resources.users.crud.create.validation.emailNotUnique';
            }
        }
        return undefined;
    } catch {
        return undefined;
    }
};

const validations = {
    email: [email(), maxLength(255), required(), emailUniqueValidation],
    password: [required(), minLength(8)],
    name: [maxLength(255)],
    lastname: [maxLength(255)],
    operatorCode: [number(), minValue(-2147483648), maxValue(2147483647)],
    status: [maxLength(255)],
    'userRole.defaultRoleId': [required()],
    chainIds: [required()],
    groupIds: [required()],
    storeIds: [required()]
};

const UserCreate = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles();
    const [defaultRoleIds, setDefaultRoleIds] = useState();
    const [defaultRoleName, setDefaultRoleName] = useState();

    const ability = useContext(AbilityContext);

    useEffect(() => {
        setDefaultRoleIds(_.uniq(ability.rulesFor('create', 'users')
            .map(rule => {
                if (rule.conditions && rule.conditions['userRole.defaultRoleId'] && rule.conditions['userRole.defaultRoleId'].$in) {
                    return rule.conditions['userRole.defaultRoleId'].$in;
                }
                return [];
            }).flat())
        );
    }, [ability]);

    const onSuccess = ({ data }) => {
        notify(`resources.users.crud.create.notification.success`, 'success');
        redirect(`/users/${data.id}/edit`);
        refresh();
    };

    const onFailure = error => {
        console.log(error);
        notify(`resources.users.crud.create.notification.failed`, 'error');
    };

    return (
        <Create
            actions={<UserCreateActions />}
            onSuccess={onSuccess}
            onFailure={onFailure}
            {...props}
        >
            <FormWithRedirect
                {...props}
                render={({ saving, handleSubmitWithRedirect, form }) => (
                    <form>
                        <Grid container spacing={1} className={classes.grid}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.users.crud.create.identity')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="email"
                                    resource="users"
                                    type="email"
                                    validate={validations.email}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PasswordInput
                                    source="password"
                                    resource="users"
                                    validate={validations.password}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="name"
                                    resource="users"
                                    validate={validations.name}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="lastname"
                                    resource="users"
                                    validate={validations.lastname}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="operatorCode"
                                    resource="users"
                                    validate={validations.operatorCode}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    source="status"
                                    resource="users"
                                    validate={validations.status}
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput
                                    source="twoFaIsActive"
                                    resource="users"
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput
                                    source="notifyUser"
                                    resource="users"
                                    label="resources.users.crud.create.notifyUser"
                                    helperText={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.users.crud.create.rolesAndPermissions')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomReferenceAutocompleteInput
                                    source="userRole.defaultRoleId"
                                    reference="defaultRoles"
                                    originSource="id"
                                    label={translate('resources.users.fields.defaultRole.name')}
                                    optionText="name"
                                    validate={validations['userRole.defaultRoleId']}
                                    onChange={(event, newValue) => {
                                        if (!newValue) {
                                            setDefaultRoleName(newValue);
                                            form.change('userRole.permissions', []);
                                        } else {
                                            setDefaultRoleName(newValue.name);
                                        }
                                        form.change('chainIds', []);
                                        form.change('groupIds', []);
                                        form.change('storeIds', []);
                                    }}
                                    getOptionLabel={(option) => {
                                        return translate(`resources.users.fields.defaultRole.options.${option.name}`)
                                            + ` (${option.name})`;
                                    }}
                                    additionalFilter={{ id: { $in: defaultRoleIds } }}
                                />
                                {['CHAIN-ADMIN', 'CHAIN-ROOT'].includes(defaultRoleName) &&
                                    <CustomReferenceAutocompleteArrayInput
                                        source="chainIds"
                                        reference="chains"
                                        originSource="id"
                                        label={translate('resources.users.fields.chains.name')}
                                        optionText="name"
                                        withNull={false}
                                        validate={validations.chainIds}
                                    />
                                }
                                {['ZONE-MANAGER'].includes(defaultRoleName) &&
                                    <CustomReferenceAutocompleteArrayInput
                                        source="groupIds"
                                        reference="groups"
                                        originSource="id"
                                        label={translate('resources.users.fields.groups.name')}
                                        optionText="name"
                                        withNull={false}
                                        validate={validations.groupIds}
                                    />
                                }
                                {['STORE-ADMIN', 'CASH-MANAGER', 'CASHIER', 'STORE-ROOT'].includes(defaultRoleName) &&
                                    <CustomReferenceAutocompleteArrayInput
                                        source="storeIds"
                                        reference="stores"
                                        originSource="id"
                                        label={translate('resources.users.fields.stores.name')}
                                        optionText="storeCode"
                                        withNull={false}
                                        validate={validations.storeIds}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormDataConsumer>
                                    {({ formData }) => formData.userRole && formData.userRole.defaultRoleId ? (
                                        <PermissionsSelectInput
                                            source="userRole.permissions"
                                            defaultRoleId={
                                                formData.userRole && formData.userRole.defaultRoleId ?
                                                    formData.userRole.defaultRoleId : undefined
                                            }
                                            change={form.change}
                                        />
                                    ) : null}
                                </FormDataConsumer>
                            </Grid>
                        </Grid>
                        <Toolbar
                            saving={saving}
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                        />
                    </form>
                )}
            />
        </Create>
    );
};


export default UserCreate;
