import { AbilityBuilder, Ability } from '@casl/ability';
import _ from 'lodash';

let ability;

_.templateSettings.interpolate = /"\${(.+?)}"/g;

function getUser() {
    let user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
}

function compiledOptionalConditions(conditions, record) {
    Object.keys(conditions).forEach(key => {
        let keys = key.split('.');
        let fieldName = keys[0];
        if (conditions[key]._isOptional && [undefined, null].includes(record[fieldName])) {
            if (conditions[key].$in) {
                conditions[key].$in.push(null);
                delete conditions[key]._isOptional;
            } else {
                delete conditions[key];
            }
        } else if (conditions[key]._isOptional && Array.isArray(record[fieldName]) && record[fieldName].length === 0) {
            delete conditions[key];
        } else {
            delete conditions[key]._isOptional;
        }
    });
}

function compiledArrayConditions(conditions, record) {
    Object.keys(conditions).forEach(key => {
        if (key.indexOf('.*') !== -1) {
            let keys = key.split('.*');
            let fieldName = keys[0];
            if (Array.isArray(record[fieldName])) {
                let arrayLength = record[fieldName].length;
                let rule = conditions[key];
                for (let i = 0; i < arrayLength; i++) {
                    conditions[`${fieldName}.${i}`] = rule;
                }
            }
            delete conditions[key];
        }
    });
}

function generatePermissions(can, record = null) {
    let user = getUser();
    if (user) {
        can('get', 'users', { id: user.id });

        let { userData, defaultRoleIds } = user.authorizationData;
        let stringifyUserData = {};
        for (const key in userData) {
            stringifyUserData[key] = JSON.stringify(userData[key]);
        }

        const permissions = user.userRole.permissions;
        permissions.forEach(permission => {
            if (_.isEmpty(permission.conditions)) {
                can(permission.action, permission.subject);
            } else {
                let compiled = _.template(JSON.stringify(permission.conditions));
                let compiledConditions = JSON.parse(compiled({ user: stringifyUserData, defaultRoleIds: defaultRoleIds }));

                compiledOptionalConditions(compiledConditions, {});
                if (record) {
                    compiledOptionalConditions(compiledConditions, record);
                    compiledArrayConditions(compiledConditions, record);
                }

                can(permission.action, permission.subject, compiledConditions);
                /*console.log({
                    action: permission.action,
                    subject: permission.subject,
                    conditions: compiledConditions
                });*/
            }
        });
    }
}

export default function defineAbilities() {
    const { can, rules } = new AbilityBuilder();
    generatePermissions(can);
    ability = new Ability(rules);
    return ability;
}

export function updateAbility(record = null) {
    let { can, rules } = new AbilityBuilder(Ability);
    generatePermissions(can, record);
    ability.update(rules);
}
