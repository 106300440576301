import * as React from 'react';
//import { useMemo, forwardRef } from 'react';
import { useTranslate } from 'react-admin';
import { Link, Typography, Breadcrumbs } from '@material-ui/core';
import { Route, MemoryRouter } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import breadcrumbConfig from '../../config/breadcrumbConfig';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 10px 10px 10px',
    },
});

const LinkRouter = (props) => {
    // const renderLink = useMemo(() => {
    //     return forwardRef((itemProps, ref) => {
    //         console.log('props.to:', props.to);
    //         return <RouterLink to={props.to} ref={ref} {...itemProps} />;
    //     });
    // }, [props.to]);

    return (
        <Link {...props} component={RouterLink} />
    );
}

const CustomBreadcrumb = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const location = useLocation();

    const pathnameArray = location.pathname.split('/').filter((x) => x);

    return (
        <MemoryRouter initialEntries={['/transactions']} initialIndex={0}>
            <div className={classes.root}>
                <Route>
                    <Breadcrumbs aria-label="breadcrumb">
                        <LinkRouter color="inherit" to="/">
                            {translate('pos.dashboard.name')}
                        </LinkRouter>
                        {pathnameArray.map((value, index) => {
                            const last = index === pathnameArray.length - 1;
                            const to = `/${pathnameArray.slice(0, index + 1).join('/')}`;
                            const namePath = to.replace(/\d+/g, '*');
                            const breadcrumb = breadcrumbConfig[namePath];

                            if (!breadcrumb) {
                                return null;
                            }

                            if (breadcrumb.name === 'id') {
                                return (
                                    <Typography color="inherit" key={to}>
                                        {value}
                                    </Typography>
                                );
                            } else if (last) {
                                return (
                                    <Typography color="textPrimary" key={breadcrumb.name}>
                                        {translate(breadcrumb.translation.key, breadcrumb.translation.options)}
                                    </Typography>
                                );
                            } else if (!breadcrumb.isValidLink) {
                                return (
                                    <Typography color="inherit" key={to}>
                                        {translate(breadcrumb.translation.key, breadcrumb.translation.options)}
                                    </Typography>
                                );
                            }
                            return (
                                <LinkRouter color="inherit" to={to} key={to}>
                                    {translate(breadcrumb.translation.key, breadcrumb.translation.options)}
                                </LinkRouter>
                            );
                        })}
                    </Breadcrumbs>
                </Route>
            </div>
        </MemoryRouter>
    );
}

export default CustomBreadcrumb;
