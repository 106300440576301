import * as React from 'react';
import { Labeled, TextField, ReferenceField } from 'react-admin';
import { Grid } from '@material-ui/core';
import ParentReferenceField from './ParentReferenceField';

const GroupGeneral = props => {
    return (
        <span>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.groups.fields.name">
                        <TextField record={props.record} source="name" />
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.groups.fields.chain">
                        <ReferenceField
                            label="resources.groups.fields.chain"
                            source="chainId"
                            reference="chains"
                            link="show"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Labeled label="resources.groups.fields.parent">
                        <ParentReferenceField label="resources.groups.fields.parent" {...props} />
                    </Labeled>
                </Grid>
            </Grid>
        </span>
    );
};

export default GroupGeneral;
