import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { NumberField, TextField, useLocale, useTranslate } from 'react-admin';
import {
    Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs,
    Typography, IconButton, darken
} from '@material-ui/core';
import {
    RotateLeft as RotateLeftIcon
} from '@material-ui/icons';
import { Form } from 'react-final-form';
import SwipeableViews from 'react-swipeable-views';
import ExportModal from './ExportModal';
import PercentageField from '../../../custom/PercentageField';
import CustomDateTimeInput from '../../../custom/CustomDateTimeInput';
import CustomDialog from '../../../custom/CustomDialog';
import CustomError from '../../../custom/CustomError';
import CustomMultiselectInput from '../../../custom/CustomMultiselectInput';
import CustomReferenceAutocompleteArrayInput from '../../../custom/CustomReferenceAutocompleteArrayInput';
import CustomTab from '../../../custom/CustomTab';
import CustomTabPanel from '../../../custom/CustomTabPanel';
import getBusinessDayDateDefaultValue from '../../../../helpers/getBusinessDayDateDefaultValue';
import limitLevels from '../../../../helpers/limitLevels'
import resourcesConfig from '../../../../config/resourcesConfig.json';
import baseAxios from 'axios';
import axios from '../../../../clients/axiosClient';
import { stringify } from 'qs';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    input: {
        minWidth: '190px',
        marginTop: 0,
    },
    chart: props => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            ...(!props.exportable && { width: '85vw' })
        },
        [theme.breakpoints.up('md')]: {
            height: '600px',
            minWidth: '300px',
        }
    }),
    border: {
        [theme.breakpoints.down('lg')]: {
            borderRight: '0px',
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        [theme.breakpoints.up('lg')]: {
            borderRight: `1px solid ${theme.palette.divider}`,
            borderBottom: '0px'
        }
    },
    loadingTable: {
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootDataTable: {
        backgroundColor: theme.palette.grey[100],
        [theme.breakpoints.down('xs')]: {
            width: '95vw'
        }
    },
    scrollableTabs: { width: 0 },
    swipeableViews: {
        width: '100%',
        height: '100%'
    },
    tabPanel: {
        overflow: 'auto',
        width: '100%',
        height: '100%',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    tableContainer: { maxHeight: '600px' },
    totalCell: { fontWeight: 500 }
}));

const Filter = ({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource, noCompareButton = false }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [openCompareModal, setOpenCompareModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const handleOpenCompareModal = () => {
        setOpenCompareModal(true);
    };
    const handleCloseCompareModal = () => {
        setOpenCompareModal(false);
    };

    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filters}>
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$gte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.from')})`}
                                    maxDate={
                                        form.getFieldState('businessDayDate[$lte]') && form.getFieldState('businessDayDate[$lte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$lte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDateTimeInput
                                    source="businessDayDate[$lte]"
                                    label={`${translate('resources.transactions.fields.businessDayDate')} (${translate('pos.generic.to')})`}
                                    minDate={
                                        form.getFieldState('businessDayDate[$gte]') && form.getFieldState('businessDayDate[$gte]').value ?
                                            new Date(form.getFieldState('businessDayDate[$gte]').value) :
                                            undefined
                                    }
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="chainId"
                                    record={filters}
                                    reference="chains"
                                    originSource="id"
                                    optionText="name"
                                    label={translate('resources.stores.fields.chain')}
                                    withNull={false}
                                    helperText={false}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomReferenceAutocompleteArrayInput
                                    source="storeCode"
                                    record={filters}
                                    reference="stores"
                                    label={translate('resources.transactions.fields.storeCode')}
                                    withNull={false}
                                    helperText={false}
                                    additionalFilter={
                                        form.getFieldState('chainId') && form.getFieldState('chainId').value ?
                                            { chainId: form.getFieldState('chainId').value } :
                                            null
                                    }
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="terminalNumber"
                                    record={filters}
                                    label={translate('resources.transactions.fields.terminalNumber')}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomMultiselectInput
                                    source="operatorCode"
                                    record={filters}
                                    label={translate('resources.transactions.fields.operatorCode')}
                                    className={classes.input}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => cancelTokenSource.cancel()}
                                >
                                    {translate('ra.action.refresh')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => cancelTokenSource.cancel()}
                                    disabled={cancel}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </Grid>
                            {!noCompareButton &&
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={handleOpenCompareModal}
                                    >
                                        {translate('pos.generic.compare')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={handleOpenExportModal}
                                    disabled={
                                        loading || error || cancel || !data || data.length === 0
                                    }
                                >
                                    {translate('ra.action.export')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <CustomDialog
                title={translate('resources.transactions.statistics.tabs.overrides.sections.overridesVoidedTransactions.name')}
                open={openCompareModal}
                onClose={handleCloseCompareModal}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} className={classes.border}>
                        <GetData defaultFilters={filters}>
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <GetData
                            defaultFilters={{
                                businessDayDate: getBusinessDayDateDefaultValue()
                            }}
                        >
                            {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Filter
                                            filters={filters}
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                            onSubmit={onSubmit}
                                            cancelTokenSource={cancelTokenSource}
                                            noCompareButton={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chart
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            data={data}
                                            loading={loading}
                                            cancel={cancel}
                                            error={error}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </GetData>
                    </Grid>
                </Grid>
            </CustomDialog>
            <ExportModal
                name={translate('resources.transactions.statistics.tabs.overrides.sections.overridesVoidedTransactions.name')}
                open={openExportModal}
                onClose={handleCloseExportModal}
                filters={filters}
                data={data}
                loading={loading}
                noCompareButton={noCompareButton}
                exportComponents={{
                    chart: Chart,
                    mainTable: ExportableDataTable
                }}
            />
        </div>
    );
};

const Chart = ({ data, loading, cancel, error, exportable = false }) => {
    const locale = useLocale();
    const translate = useTranslate();
    const classes = useStyles({ exportable });
    const currency = resourcesConfig.general.currency;

    const [processedData, setProcessedData] = useState();
    const [initialLimitedData, setInitialLimitedData] = useState();
    const [limitedData, setLimitedData] = useState();
    const [clickedData, setClickedData] = useState();
    const [originalColor, setOriginalColor] = useState();

    useEffect(() => {
        if (data && data.length > 0 && !loading && !cancel) {
            let processedData = {
                name: 'root',
                children: []
            };

            let groupByValues = data.map(e => ({
                key: `${e['override.overrideNumber']}-${e['chain.name']}`,
                'override.overrideNumber': e['override.overrideNumber'],
                'override.initials': e['override.initials'],
                'overrideOperator.name': e['overrideOperator.name'],
                'chain.name': e['chain.name']
            }));
            groupByValues = Object.fromEntries(groupByValues.map(e => [e.key, e]));

            Object.entries(groupByValues).forEach(([groupByValue, value]) => {
                let filteredData1 = data.filter(e => `${e['override.overrideNumber']}-${e['chain.name']}` === groupByValue);
                let groupByValues1 = filteredData1.map(e => ({
                    key: e['transaction.operatorCode'],
                    'transaction.operatorCode': e['transaction.operatorCode'],
                    'transactionOperator.name': e['transactionOperator.name']
                }));
                groupByValues1 = Object.fromEntries(groupByValues1.map(e => [e.key, e]));

                let groupByData1 = {
                    name: `${value['override.overrideNumber']}-${value['chain.name']}`,
                    parentName: processedData.name,
                    value: _.sumBy(filteredData1, 'amount'),
                    children: [],
                    ...value
                };

                Object.entries(groupByValues1).forEach(([groupByValue1, value1]) => {
                    let filteredData2 = filteredData1.filter(e => String(e['transaction.operatorCode']) === groupByValue1);

                    let groupByData2 = {
                        name: `${value1['transaction.operatorCode']}-${groupByValue}`,
                        parentName: groupByData1.name,
                        value: _.sumBy(filteredData2, 'amount'),
                        ...value1,
                        ...value
                    };
                    groupByData1.children.push(groupByData2);
                });
                processedData.children.push(groupByData1);
            });

            const limitedData = {
                name: 'root',
                children: limitLevels(processedData.children, 0, 1)
            };

            setProcessedData(processedData);
            setInitialLimitedData(limitedData);
            setLimitedData(limitedData);
            setClickedData();
        }
    }, [translate, data, loading, cancel]);

    if (loading) {
        return (
            <div className={classes.chart}>
                <CircularProgress />
            </div>
        );
    }
    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noChart')} />
        );
    }
    if (!data || data.length === 0 || cancel) {
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const CenteredMetric = ({ centerX, centerY }) => {
        const fields = useMemo(() => {
            if (!clickedData) {
                return [];
            }

            return [
                ...(clickedData['chain.name'] ? [{
                    name: translate('resources.chains.name', 1),
                    value: clickedData['chain.name']
                }] : []),
                ...(clickedData['overrideOperator.name'] ? [{
                    name: translate('resources.transactions.statistics.tabs.overrides.supervisor', 1),
                    value: clickedData['overrideOperator.name'],
                    additionalValue: clickedData['override.overrideNumber']
                }] : [{
                    name: translate('resources.transactions.statistics.tabs.overrides.supervisor', 1),
                    value: clickedData['override.initials'],
                    additionalValue: clickedData['override.overrideNumber']
                }])
            ];
        }, []);

        if (!clickedData) {
            return (
                <text
                    x={centerX}
                    y={centerY}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                        fontSize: '24px',
                        fontWeight: 600,
                    }}
                >
                    <tspan>
                        {translate('resources.transactions.statistics.tabs.overrides.supervisor', 2)}
                    </tspan>
                </text>
            );
        }

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '12px',
                    fontWeight: 600,
                }}
            >
                {fields.map((field, index) => {
                    const offset = index === 0 ? - 0.6 * ((fields.length + 1) / 2) : 1.2;

                    return (
                        <tspan x={centerX} dy={`${offset}em`} key={index}>
                            {field.name}: {field.value} {field.additionalValue ? `(${field.additionalValue})` : ``}
                        </tspan>
                    );
                })}
            </text>
        );
    };

    const arcLabel = ({ percentage }) => `${_.round(percentage, 2).toLocaleString(locale)} %`;

    const tooltip = ({ data, value, percentage, color }) => {
        value = Number(value).toLocaleString(locale, {
            style: 'currency',
            currency: currency,
        });

        return (
            <div
                style={{
                    whiteSpace: 'pre',
                    display: 'flex',
                    alignItems: 'center',
                    background: 'white',
                    borderRadius: '2px',
                    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
                    padding: '5px 9px'
                }}
            >
                <span
                    style={{
                        display: 'block',
                        width: '12px',
                        height: '12px',
                        background: color,
                        marginRight: '7px'
                    }}
                > </span>
                <div style={{ display: 'grid' }}>
                    <span>
                        {translate('resources.chains.name', 1)}: <strong>{data['chain.name']}</strong>
                    </span>
                    {data['overrideOperator.name'] ? (
                        <span>
                            {translate('resources.transactions.statistics.tabs.overrides.supervisor', 1)}: <strong>{data['overrideOperator.name']}</strong> ({data['override.overrideNumber']})
                        </span>
                    ) : (
                        <span>
                            {translate('resources.transactions.statistics.tabs.overrides.supervisor', 1)}: <strong>{data['override.initials']}</strong> ({data['override.overrideNumber']})
                        </span>
                    )}
                    {(data['transaction.operatorCode'] && data['transactionOperator.name']) &&
                        <span>
                            {translate('resources.transactions.statistics.tabs.overrides.posOperator')}: <strong>{data['transactionOperator.name']}</strong> ({data['transaction.operatorCode']})
                        </span>
                    }
                    {(data['transaction.operatorCode'] && !data['transactionOperator.name']) &&
                        <span>
                            {translate('resources.transactions.statistics.tabs.overrides.posOperator')}: <strong>{data['transaction.operatorCode']}</strong>
                        </span>
                    }
                    <span>
                        {translate('pos.generic.amount')}: <strong>{value}</strong>
                    </span>
                    <span>
                        {translate('pos.generic.percentage')}: <strong>{percentage.toLocaleString(locale)} %</strong>
                    </span>
                </div>
            </div>
        );
    };

    const flatten = data =>
        data.reduce((acc, item) => {
            if (item.children) {
                return [...acc, item, ...flatten(item.children)]
            }

            return [...acc, item]
        }, []);

    const findObject = (data, name) => data.find(searchedName => searchedName.name === name);

    return (
        <Grid container justifyContent="flex-end">
            {!exportable &&
                <Grid item>
                    <IconButton
                        aria-label={translate('pos.generic.reset')}
                        color="primary"
                        onClick={() => {
                            if (clickedData) {
                                let beforeData = processedData.children.find(e => e.name === limitedData.parentName);
                                beforeData = beforeData ?
                                    beforeData :
                                    _.flatMapDeep(processedData.children, 'children').find(e => e.name === limitedData.parentName);

                                if (beforeData) {
                                    setClickedData(beforeData);
                                    setLimitedData({
                                        ...beforeData,
                                        value: undefined,
                                        children: limitLevels(beforeData.children, 0, 1)
                                    });
                                } else {
                                    setClickedData();
                                    setLimitedData(initialLimitedData);
                                }
                            } else {
                                setClickedData();
                                setLimitedData(initialLimitedData);
                            }
                        }}
                    >
                        <RotateLeftIcon />
                    </IconButton>
                </Grid>
            }
            <Grid item xs={12}>
                <div className={classes.chart}>
                    <ResponsiveSunburst
                        data={limitedData}
                        tooltip={tooltip}
                        id="name"
                        value="value"
                        margin={{
                            top: 10,
                            bottom: 10,
                            right: 10,
                            left: 10
                        }}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [[ 'darker', 1.6 ]] }}
                        colors={{ scheme: 'paired' }}
                        childColor={{ from: 'color', modifiers: [[ 'brighter', 0.3 ]] }}
                        enableArcLabels={true}
                        arcLabel={arcLabel}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ from: 'color', modifiers: [[ 'darker', 1.4 ]] }}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        layers={['arcs', 'arcLabels', CenteredMetric]}
                        onClick={clickedArc => {
                            setClickedData(clickedArc.data);
                            const foundObject = findObject(flatten(processedData.children), clickedArc.id);
                            if (foundObject && foundObject.children) {
                                setLimitedData({
                                    ...foundObject,
                                    value: undefined,
                                    children: limitLevels(foundObject.children, 0, 1)
                                });
                            }
                        }}
                        onMouseEnter={(data, event) => {
                            setOriginalColor(data.color);
                            event.target.style.fill = darken(data.color, 0.1);
                            data.color = darken(data.color, 0.1);
                        }}
                        onMouseLeave={(data, event) => {
                            event.target.style.fill = originalColor;
                            data.color = originalColor;
                            setOriginalColor();
                        }}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

const processData = ({
    data, loading, cancel, setProcessingData, setProcessedData, setGroupByValues, setValue = null
}) => {
    setProcessingData(true);
    if (setValue) setValue(0);
    if (data && data.length > 0 && !loading && !cancel) {
        let processedData = [];
        let groupByValues = data.map(e => ({
            key: `${e['override.overrideNumber']}-${e['chain.name']}`,
            'override.overrideNumber': e['override.overrideNumber'],
            'override.initials': e['override.initials'],
            'overrideOperator.name': e['overrideOperator.name'],
            'chain.name': e['chain.name']
        }));
        groupByValues = Object.fromEntries(groupByValues.map(e => [e.key, e]));

        Object.entries(groupByValues).forEach(([groupByValue, value]) => {
            let filteredData = data.filter(e => `${e['override.overrideNumber']}-${e['chain.name']}` === groupByValue);
            let totalAmount = _.sumBy(filteredData, 'amount');
            let groupByData = {
                groupByValue: groupByValue,
                'override.overrideNumber': value['override.overrideNumber'],
                'override.initials': value['override.initials'],
                'overrideOperator.name': value['overrideOperator.name'],
                'chain.name': value['chain.name'],
                data: filteredData.map(e => ({
                    'transaction.operatorCode': e['transaction.operatorCode'],
                    'transactionOperator.name': e['transactionOperator.name'],
                    //'override.reasonCode': e['override.reasonCode'],
                    quantity: e.quantity,
                    amount: e.amount,
                    percentage: _.round(e.amount / (totalAmount ? totalAmount : 1) * 100, 4)
                })),
                totalQuantity: _.sumBy(filteredData, 'quantity'),
                totalAmount: totalAmount
            };
            groupByData.totalPercentage = _.round(_.sumBy(groupByData.data, 'percentage'));
            processedData.push(groupByData);
        });

        processedData = _.orderBy(processedData, 'totalAmount', ['desc']);
        groupByValues = Object.fromEntries(processedData.map(e => [e.groupByValue, {
            key: `${e['override.overrideNumber']}-${e['chain.name']}`,
            'override.overrideNumber': e['override.overrideNumber'],
            'override.initials': e['override.initials'],
            'overrideOperator.name': e['overrideOperator.name'],
            'chain.name': e['chain.name']
        }]));
        processedData = Object.fromEntries(processedData.map(e => [e.groupByValue, e]));

        setGroupByValues(groupByValues);
        setProcessedData(processedData);
        setProcessingData(false);
    }
};

const DataTable = ({ data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const theme = useTheme();
    const emptyText = translate('pos.generic.unknown');
    const currency = resourcesConfig.general.currency;

    const [value, setValue] = useState(0);
    const [groupByValues, setGroupByValues] = useState();
    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    useEffect(() => {
        processData({ data, loading, cancel, setProcessingData, setProcessedData, setGroupByValues, setValue });
    }, [data, loading, cancel]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!groupByValues || !processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const getTabLabel = value => {
        if (value['overrideOperator.name']) {
            return `${value['overrideOperator.name']} (${value['override.overrideNumber']} - ${value['chain.name']})`;
        }
        return `${value['override.initials']} (${value['override.overrideNumber']} - ${value['chain.name']})`;
    };

    return (
        <div className={classes.rootDataTable}>
            <Tabs
                value={value}
                onChange={handleChange}
                orientation="horizontal"
                variant="scrollable"
                scrollButtons="on"
                classes={{ scrollable: classes.scrollableTabs }}
            >
                {(loading || processingData) ? (
                    <CustomTab label={<CircularProgress size={25} />} />
                ) : (Object.entries(groupByValues).map(([groupByValue, value]) => (
                    <CustomTab
                        label={getTabLabel(value)}
                        index={Object.keys(groupByValues).indexOf(groupByValue)}
                        key={`tab-${groupByValue}`}
                        wrapped
                    />
                )))}
            </Tabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                enableMouseEvents={true}
                resistance={true}
                className={classes.swipeableViews}
            >
                {Object.keys(groupByValues).map(groupByValue => (
                    <CustomTabPanel
                        value={value}
                        index={Object.keys(groupByValues).indexOf(groupByValue)}
                        key={`tabpanel-${groupByValue}`}
                        dir={theme.direction}
                        className={classes.tabPanel}
                    >
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {translate('resources.transactions.statistics.tabs.overrides.posOperatorCode')}
                                        </TableCell>
                                        <TableCell>
                                            {translate('resources.transactions.statistics.tabs.overrides.posOperatorName')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('pos.generic.quantity')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('pos.generic.amount')}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate('pos.generic.percentage')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {(loading || processingData) ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {processedData[groupByValue].data.map(row => (
                                            <TableRow key={`${groupByValue}-${row['transaction.operatorCode']}-${row['item.itemCode']}`}>
                                                <TableCell>
                                                    <TextField record={row} source="transaction.operatorCode" />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        record={row}
                                                        source="transactionOperator.name"
                                                        emptyText={emptyText}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberField
                                                        record={row}
                                                        source="quantity"
                                                        locales={locale}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberField
                                                        record={row}
                                                        source="amount"
                                                        locales={locale}
                                                        options={{style: 'currency', currency: currency}}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <PercentageField record={row} source="percentage" />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow key={`total-${groupByValue}`}>
                                            <TableCell className={classes.totalCell}>
                                                {translate('pos.generic.total', 1)}
                                            </TableCell>
                                            <TableCell />
                                            <TableCell align="right">
                                                <NumberField
                                                    record={processedData[groupByValue]}
                                                    source="totalQuantity"
                                                    locales={locale}
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumberField
                                                    record={processedData[groupByValue]}
                                                    source="totalAmount"
                                                    locales={locale}
                                                    options={{style: 'currency', currency: currency}}
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <PercentageField
                                                    record={processedData[groupByValue]}
                                                    source="totalPercentage"
                                                    className={classes.totalCell}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </CustomTabPanel>
                ))}
            </SwipeableViews>
        </div>
    );
};

const ExportableDataTable = ({ data, loading, cancel, error }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const emptyText = translate('pos.generic.unknown');
    const currency = resourcesConfig.general.currency;

    const [groupByValues, setGroupByValues] = useState();
    const [processingData, setProcessingData] = useState(true);
    const [processedData, setProcessedData] = useState();

    useEffect(() => {
        processData({ data, loading, cancel, setProcessingData, setProcessedData, setGroupByValues });
    }, [data, loading, cancel]);

    if (error) {
        return (
            <CustomError errorSecondary={translate('resources.transactions.statistics.errors.noDataError')} />
        );
    }
    if (!groupByValues || !processedData || !data || data.length === 0 || cancel) {
        if ((loading || (loading && processingData)) && !cancel) {
            return (
                <div className={classes.loadingTable}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <CustomError
                severity="warning"
                errorPrimary={translate('pos.generic.warning')}
                errorSecondary={translate('resources.transactions.statistics.errors.noData')}
            />
        );
    }

    const firstHeaderLabel = value => {
        if (value['overrideOperator.name']) {
            return `${value['overrideOperator.name']} (${value['override.overrideNumber']} - ${value['chain.name']})`;
        }
        return `${value['override.initials']} (${value['override.overrideNumber']} - ${value['chain.name']})`;
    };

    return (
        <TableContainer component={Paper}>
            {Object.entries(groupByValues).map(([groupByValue, value]) => (
                <Table size="small" key={groupByValue}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={5}>
                                {firstHeaderLabel(value)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {translate('resources.transactions.statistics.tabs.overrides.posOperatorCode')}
                            </TableCell>
                            <TableCell>
                                {translate('resources.transactions.statistics.tabs.overrides.posOperatorName')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('pos.generic.quantity')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('pos.generic.amount')}
                            </TableCell>
                            <TableCell align="right">
                                {translate('pos.generic.percentage')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {(loading || processingData) ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {processedData[groupByValue].data.map(row => (
                                <TableRow key={`${groupByValue}-${row['transaction.operatorCode']}-${row['item.itemCode']}`}>
                                    <TableCell>
                                        <TextField record={row} source="transaction.operatorCode" />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            record={row}
                                            source="transactionOperator.name"
                                            emptyText={emptyText}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumberField
                                            record={row}
                                            source="quantity"
                                            locales={locale}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumberField
                                            record={row}
                                            source="amount"
                                            locales={locale}
                                            options={{style: 'currency', currency: currency}}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <PercentageField record={row} source="percentage" />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow key={`total-${groupByValue}`}>
                                <TableCell className={classes.totalCell}>
                                    {translate('pos.generic.total', 1)}
                                </TableCell>
                                <TableCell />
                                <TableCell align="right">
                                    <NumberField
                                        record={processedData[groupByValue]}
                                        source="totalQuantity"
                                        locales={locale}
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberField
                                        record={processedData[groupByValue]}
                                        source="totalAmount"
                                        locales={locale}
                                        options={{style: 'currency', currency: currency}}
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <PercentageField
                                        record={processedData[groupByValue]}
                                        source="totalPercentage"
                                        className={classes.totalCell}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            ))}
        </TableContainer>
    );
};

const GetData = ({ children, defaultFilters = {} }) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [renderKey, setRenderKey] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cancel, setCancel] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState();
    const [error, setError] = useState();

    const onSubmit = values => {
        setRenderKey(renderKey + 1);
        setFilters(values);
    };

    let { businessDayDate, chainId, storeCode, terminalNumber, operatorCode } = filters;

    useEffect(() => {
        setLoading(true);
        setCancel(false);
        setError(false);

        const params = {
            type: 'overrides-voided-transactions',
            businessDayDate: businessDayDate,
            chainId: chainId,
            storeCode: storeCode,
            terminalNumber: terminalNumber,
            operatorCode: operatorCode,
            orderBy: 'amount',
            orderType: 'DESC',
        };
        const queryString = stringify(params, { strictNullHandling: true });
        const cancelTokenSource = baseAxios.CancelToken.source();
        setCancelTokenSource(cancelTokenSource);

        axios.get(`/statistics?${queryString}`, {
            cancelToken: cancelTokenSource.token
        })
            .then(response => {
                const { data } = response.data;
                setData(data.length > 0 ? data : []);
            })
            .catch(error => {
                if (baseAxios.isCancel(error)) {
                    setCancel(true);
                } else {
                    setError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [renderKey, businessDayDate, chainId, storeCode, terminalNumber, operatorCode]);

    return (
        <div>
            {children({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource })}
        </div>
    );
};

const OverridesVoidedTransactionsSunburst = () => {
    const translate = useTranslate();

    const filters = {
        businessDayDate: getBusinessDayDateDefaultValue()
    };

    return (
        <Grid container spacing={1}>
            <GetData defaultFilters={filters}>
                {({ filters, data, loading, cancel, error, onSubmit, cancelTokenSource }) => (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {translate('resources.transactions.statistics.tabs.overrides.sections.overridesVoidedTransactions.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Filter
                                filters={filters}
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                                onSubmit={onSubmit}
                                cancelTokenSource={cancelTokenSource}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Chart
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                data={data}
                                loading={loading}
                                cancel={cancel}
                                error={error}
                            />
                        </Grid>
                    </Grid>
                )}
            </GetData>
        </Grid>
    );
};

export default OverridesVoidedTransactionsSunburst;
